import {gql, useQuery} from "@apollo/client";

export default function useRelatedProducts(product) {

    const { loading, error, data } = useQuery(RELATED_PRODUCTS, {
        variables: { sku: product.sku },
        fetchPolicy: 'network-only',
    });

    return data?.products.items[0].related_products;
}

const RELATED_PRODUCTS = gql`
    query ($sku: String!) {
        products(filter: {sku: {eq: $sku}}) {
            items {
                related_products {
                    sku
                    name
                    ont_mask_sku
                    short_description {
                        html
                    }
                    ont_image_url_1
                    categories {
                        name
                    }
                    price_range {
                        maximum_price {
                            final_price {
                            currency
                            value
                            }
                        }
                    }
                    ... on BundleProduct {
                        items {
                            option_id
                            type
                            required
                            options {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`;
