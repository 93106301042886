import React, { Fragment } from 'react';
import { NanoSelect, NanoOption, NanoInput } from '@nanoporetech-digital/components-react';
import UiButton from '../../../Ui/UiButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import {quickAdd, product} from './style.module.css';

export default function Form({products, formState, updateFormState, submitForm}) {
    const ButtonLabel = () => formState.is_saving ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Add to basket';
    const updateState = field => updateFormState({type: field.name, payload: field.value});

    return (
        <div className={quickAdd}>
            <div>
                Quick add:
            </div>
            <div className={product}>
                <NanoSelect
                    className="selectmulti"
                    multiple="true"
                    max="1"
                    placeholder="Product..."
                    name="sku_selections"
                    showInlineError="false"
                    value={formState.sku_selections}
                    onNanoChange={e => updateState(e.target)}
                >
                    <ProductList products={products} />
                </NanoSelect>
            </div>
            <div>
                <NanoInput
                    hide-label="true"
                    clear-input="true"
                    placeholder="Qty"
                    required="true"
                    name="qty"
                    value={formState.qty}
                    showInlineError="false"
                    onNanoChange={e => updateState(e.target)}
                />
            </div>
            <div>
                <UiButton
                    className="button button--primary button--keyline"
                    onClick={e => submitForm()}
                    type="button"
                    disabled={formState.is_saving}
                >
                    <ButtonLabel />
                </UiButton>
            </div>
        </div>

    );
}

function ProductList({products}) {
    return products
        .filter(product => {
            return !(
                product.__typename === 'SimpleProduct'
                && product.ont_render_type === 'FLOWCELL'
            );
        })
        .map(product => {
            return (
                <Fragment key={product.id}>
                    <ProductItem product={product} />
                    <ConfigurableChildren parentProduct={product} />
                </Fragment>
            );
        })
}

function ProductItem({product}) {
    const price = product.price.regularPrice.amount;
    const formatter = Intl.NumberFormat('en-US', {style: "currency", currency: price.currency});
    const displayPrice = formatter.format(price.value);
    const suffix = product.variants?.length ? '(Please select option)' : null;

    return <SelectOption
        value={product.sku}
        label={`${product.sku} ${product.name} ${displayPrice}`}
        suffix={suffix}
    />;
}

function ConfigurableChildren({parentProduct}) {
    if (!parentProduct.variants) {
        return null;
    }

    return [...parentProduct.variants]
        .sort((a, b) => {
            return a.product.price_range.minimum_price.final_price.value
                - b.product.price_range.minimum_price.final_price.value
        })
        .map ((variant,i) => {
            return <ConfigurableChild key={i} childProduct={variant.product} />
        })
}

function ConfigurableChild({childProduct}) {
    return <SelectOption
        value={childProduct.sku}
        label={childProduct.name}
        indentLevel={1}
    />;
}

function SelectOption({value, label, indentLevel = 0, suffix}) {
    if (indentLevel === 1) {
        label = '--- ' + label;
    }

    if (suffix) {
        label = label + ' ' + suffix;
    }

    return <>
        <NanoOption
            value={value}
            label={label}
        />
    </>;
}
