import React from 'react';
import UiMarkdown from '../../../Ui/UiMarkdown';
import { headlineInfo } from './style.module.css';

export default function HeadlineInfo({category}) {
    return (
        <div className={headlineInfo}>
            <UiMarkdown source={category.ont_product_text} skipHtml={true} />
        </div>
    );
}
