import React from 'react';
import Remote from '../components/Authentication/Remote';


export default function RemoteAuthenticate({ location }) {
    if (typeof window==='undefined') return null;

    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code') ?? null;

    return <Remote code={code} />;
}
