import React from 'react';
import {carousel, slide, image, name, price} from "./style.module.css";
import { NanoSlide, NanoSlides } from "@nanoporetech-digital/components-react";
import { shouldShowPrice } from "../../../../hooks/siteData";
import ContentfulImage from "../../../Contentful/ContentfulImage";
import UiPublicOrCustomPrice from '../../../Ui/UiPublicOrCustomPrice';
import { useTranslation } from 'react-i18next';

export default function Carousel({category}) {
    const { t} = useTranslation();

    if (!category?.products) {
        return null;
    }

    return (
        <div className={carousel}>
            <h2>{t('Most popular')}</h2>
            <NanoSlides onNanoSlidesReady={(e) => forceStyling(e)} navbtns="true" animation="scroll" pager="false" current-slide="2">
                {category.products.items.map(p => <Slide product={p} key={p.id} />)}
            </NanoSlides>
        </div>
    );
}

function Slide ({product}) {
    return (
        <NanoSlide>
            <a href={`/${product.url_key}.html`}>
            <div className={slide}>
                <div className={image}><ContentfulImage src={product.ont_image_url_1} width={150} browserWidth={150} /></div>
                <div className={name}>{product.name}</div>
                <div className={price}><ProductPrice product={product} /></div>
            </div>
            </a>
        </NanoSlide>
    );
}

const ProductPrice = ({product, prefix}) => {
    if (!product || !shouldShowPrice(product.sku)) {
        return null;
    }

    return (
        <div className={price}>
            {prefix && <span>{prefix}</span>}
            <UiPublicOrCustomPrice price={product.price_range.minimum_price.final_price} customPrice={product.ont_custom_price} />
        </div>
    );
}

function forceStyling(e) {
    const slideshow = e.target.shadowRoot.childNodes.item(0);
    const prev = slideshow.querySelector('button.previous');
    const next = slideshow.querySelector('button.next');
    const chevrons = Array.from(slideshow.querySelectorAll('.flickity-button-icon'));
    const css = {
        insetBlockStart: '73%',
        borderRadius: "unset",
        height: "100%",
        width: "60px",
        opacity: 1,
        boxShadow: 'none'
    }
    for (var property in css) {
        prev.style[property] = css[property];
        next.style[property] = css[property];
    }
    prev.style.insetInlineStart = 0;
    next.style.insetInlineEnd = 0;
    chevrons.forEach(chevron => chevron.style.height = '24px');
}
