import React from 'react'
import { NanoDialog } from '@nanoporetech-digital/components-react';

export default function ModalPicker({messages, closeModal}) {

    const firstMessage = messages.find((message) => message.type==='modal');
    if (firstMessage === undefined) {
        return null;
    }

    return (
        <NanoDialog
            label={firstMessage.title}
            open={true}
            onNanoHide={() => closeModal()}
        >
            {firstMessage.content}
            <Footer message={firstMessage} closeModal={closeModal} />
        </NanoDialog>
    );
}

function Footer({message, closeModal}) {
    if (message.title == 'Renewal notice') {
        return (
            <button slot="footer" className="button button--secondary" onClick={() => closeModal('/device-warranty.html')}>Device Warranty</button>
        );
    }

    return null;
}
