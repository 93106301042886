import React from 'react';
import {Helmet} from "react-helmet";
import Checkout from '../components/Checkout';

export default function CheckoutPage(props) {
    if (typeof window==='undefined') return null;

    return (
        <>
            <Helmet>
                <title>Checkout</title>
            </Helmet>
            <Checkout {...props} />
        </>
    );
}
