import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import useLoginLink from '../hooks/useLoginLink';
import { resetCredentials } from '../components/Authentication/hooks/useLogin';

export default function ReauthenticatePage() {
    const loginLink = useLoginLink();

    if (typeof window !== 'undefined') {
        resetCredentials();
        window.location.href = loginLink;
    }

    return (
        <center>
            <h2 style={{textAlign: 'center', fontWeight: 'lighter'}}>Logging you in...</h2>
            <p><FontAwesomeIcon icon={faSpinner} size="lg" spin /></p>
        </center>
    );
}
