import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faSpinner } from '@fortawesome/pro-light-svg-icons';
import UiButton from "../../../../Ui/UiButton";
import { buttons } from './style.module.css';

export default function Actions({sectionCache, submitter}) {
    return (
        <div>
            <div className={buttons}>
                <div><EditButton sectionCache={sectionCache} /></div>
                <div><ContinueButton submitter={submitter} /></div>
            </div>
        </div>
    );
}

function EditButton({sectionCache}) {
    if (sectionCache.data.mode === 'preview-only') return null;

    const editData = () => sectionCache.set({
        ...sectionCache.data,
        mode: 'edit'
    });

    return (
        <UiButton className="button button--primary" onClick={editData}>
            <span><FontAwesomeIcon icon={faChevronLeft} size="sm" />Edit tax status</span>
        </UiButton>
    );
}

function ContinueButton({submitter}) {
    const Content = () => {
        return (submitter.isSubmitting)
        ? <FontAwesomeIcon icon={faSpinner} spin />
        : <span>Continue &nbsp;<FontAwesomeIcon icon={faChevronRight} size="sm" /></span>;
    }

    return (
        <UiButton className="button button--secondary" disabled={submitter.isSubmitting} onClick={submitter.submit}>
            <Content />
        </UiButton>
    );
}
