import {ApolloClient, from, InMemoryCache} from '@apollo/client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import fetch from 'cross-fetch';
import { getStoredCustomer } from "../contexts/CustomerContext";
import { getSiteKey, isMainSite } from "../hooks/siteData";
import {getCurrentVersion, handleApolloError} from "../utils/version";
import {onError} from "@apollo/client/link/error";

const httpLink = createHttpLink({
    uri: process.env.GATSBY_MAGENTO_BACKEND_URL + 'graphql',
    credentials: 'include',
    fetch
});

const authLink = setContext((_, { headers }) => {
    // get the customer from local storage
    const customer = getStoredCustomer();
    const intendedStoreView = (isMainSite() && customer.intended_store_view)
        ? customer.intended_store_view
        : getSiteKey();

    const version = getCurrentVersion();

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: customer.token ? `Bearer ${customer.token}` : "",
            store: intendedStoreView,
            "x-ont-fe-version": version,
        }
    }
});

const errorLink = onError(({operation }) => {
    handleApolloError(operation);
});

export const client = new ApolloClient({
    link: from([
        authLink,
        errorLink,
        httpLink,
    ]),
    cache: new InMemoryCache({
        possibleTypes: {
            CategoryInterface: [
                'CategoryTree'
            ],
            ProductInterface: [
                'SimpleProduct',
                'BundleProduct',
                'ConfigurableProduct',
                'GroupedProduct',
                'VirtualProduct',
            ],
        }
    })
});
