import React from 'react';
import {productPhase, level, name, help} from './style.module.css';

export default function ProductPhase({product}) {
    const phase = product.ont_phase_info;

    if (!phase?.level) return null;

    return (
        <div className={productPhase}>
            <span className={level}>{phase.level}</span>
            <span className={name}>{phase.name}</span>
            {phase.help && <span className={help} dangerouslySetInnerHTML={{__html: phase.help}}></span>}
        </div>
    );
}
