import React, {createContext, useContext} from 'react';
import {useLocalStorage} from "usehooks-ts";

const WebStoreContext = createContext({});

export function WebStoreContextProvider(props) {

    const [webstore, setWebstore] = useLocalStorage('ont_webstore', {
        geoIpCountryIso: '',
        geoIpCountryName: '',
        countryIsoPreference: '',
        guessedStore: false,
        designatedStore: '',
    });

    return (
        <WebStoreContext.Provider value={{webstore, setWebstore}}>
            {props.children}
        </WebStoreContext.Provider>
    );
}

export const useWebStoreContext = () => useContext(WebStoreContext);
