import { useQuery, useMutation, gql } from "@apollo/client";

// TODO
export function mockRetrieveQuotation(quotationId, dpCode, skipFlag=false) {
    return {
        data: {
            retrieveQuotation: {
                customer_is_guest: false,
                items: [{id:1},{id:2}],
                available_payment_methods: [
                    {code: 'purchaseorder', title: 'Purchase Order'},
                    {code: 'chcybersource', title: 'Credit or debit card'},
                ],
                total: {
                    subtotal: {currency: 'USD', value: 100},
                    total_tax: {currency: 'USD', value: 20},
                    discounts: [{amount: 0, label: ''}],
                    base_grand_total: {currency: 'USD', value: 200},
                    total_shipping: {currency: 'USD', value: 80},
                },
                shipping_schedule: {
                    shipments: [
                        {date: "2021-01-01", shipment_items: [1]},
                        {date: "2021-02-02", shipment_items: [2]}
                    ],
                    cost_per_shipment: 40,
                },
            }
        },
        error: '',
        loading: false,
    };
}

export function useRetrieveQuotation(quotationId, dpCode, skipFlag=false) {
    return useQuery(QUOTATION, {
        variables: { quotationId: quotationId, dpCode: dpCode },
        fetchPolicy: 'network-only',
        skip: skipFlag,
    });
}

const QUOTATION = gql`
    query($quotationId: String!, $dpCode: String!) {
        retrieveQuotation( filter: {dp_code: {eq: $dpCode}, quotation_id: {eq: $quotationId}}) {
            new_cart
            order {
                order_number
                grand_total
                sub_total
                tax
                shipping
                discount
                currency
                contains_devices
                contains_flowcells
            }
            customer_is_guest
            shipping_schedule {
                shipments {
                    date
                    shipment_items {
                        date
                        item_id
                        sku
                        name
                        qty
                        pack_size
                    }
                }
                cost_per_shipment
            }
            billing_country_id
            shipping_country_id
            available_payment_methods {
                code
                title
            }
        }
    }
`;
