import React from 'react';
import {Helmet} from "react-helmet";
import QuotationCreate from '../components/Quotation/Create';

export default function CreateQuotePage(props) {
    if (typeof window==='undefined') return null;

    return (
        <>
            <Helmet>
                <title>Create a quote</title>
            </Helmet>
            <QuotationCreate {...props} />
        </>
    );
}
