import React from 'react';
import {compare, interest, footerBanner} from "./style.module.css";

export function BlockRightCol({block}) {
    return (block?.content)
        ? <div className={compare} dangerouslySetInnerHTML={{__html: block.content}} />
        : null;
}

export function BlockRightMarketing({block}) {
    return (block?.content)
        ? <div className={interest} dangerouslySetInnerHTML={{__html: block.content}} />
        : null;
}

export function BlockFooter({block}) {
    return (block?.content)
        ? <div className={footerBanner} dangerouslySetInnerHTML={{__html: block.content}} />
        : null;
}

export function BlockHelp({block}) {
    return (block?.content)
        ? <div dangerouslySetInnerHTML={{__html: block.content}} />
        : null;
}
