import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import Product from './product';
import { getUtcDate, getUiDate } from '../../../../ShippingScheduler/helpers';
import { isExpiryWithinOneMonth } from '../../helpers';

export default function LabCert({certSummary, products, choices, setChoices}) {
    const sku = certSummary?.lab_certification?.sku;
    if (!sku) return <center><FontAwesomeIcon icon={faSpinner} spin /></center>;

    const product = products.find(product => product.sku===sku);
    if (!product) return 'Error in loading certification product ' + sku;

    const labCertification = certSummary.lab_certification;
    const fullPrice = product.price_range.minimum_price.final_price;

    return (
        <Product 
            product={product}
            name={labCertification.name}
            status={getStatus(labCertification, fullPrice)}
            mode={isPurchaseAllowed(labCertification) ? 'lab' : 'info'}
            choices={choices}
            setChoices={setChoices}
        />
    );
}

function getStatus(labCertification, fullPrice) {
    const formatter = Intl.NumberFormat('en-US', {style: "currency", currency: fullPrice.currency});

    switch (labCertification.status) {
        case 'active':
            return getActiveStatus(labCertification, fullPrice);
        case 'unknown':
            return 'Certification in progress';
    }

    return 'Purchase 1-year certification for ' + formatter.format(fullPrice.value);
}

function getActiveStatus(labCertification, fullPrice) {
    const expiry = getUtcDate(labCertification.expiry);
    const formatter = Intl.NumberFormat('en-US', {style: "currency", currency: fullPrice.currency});

    return (isPurchaseAllowed(labCertification)) 
        ? 'Certified until ' + getUiDate(expiry) + '. Purchase another year for ' + formatter.format(fullPrice.value)
        : 'Certified until ' + getUiDate(expiry);
}

function isPurchaseAllowed(labCertification) {
    if (!labCertification.status || labCertification.status=='expired') {
        return true;
    }

    if (labCertification.status=='active') {
        const labCertExpiry = getUtcDate(labCertification.expiry);
        return isExpiryWithinOneMonth(labCertExpiry);
    }

    return false;
}
