import { useCustomerContext } from "../../../../../contexts/CustomerContext";
import { useWebStoreContext } from "../../../../../contexts/WebStoreContext";
import { useCartContext } from "../../../../../contexts/CartContext";
import { useCheckoutContext } from "../../../../../contexts/CheckoutContext";
import { updateContextCart } from "../../../../../hooks/useCart";
import { redirectIfRequired } from "../../../../../utils/webStore";
import { frontendToBackendAddress, getStoreForBillingCountry, isStoreSwitchNeeded } from "../helpers";
import useSetBillingInfo from "./useSetBillingInfo";

export default function useSubmitBillingInfo(setIsSubmitting, cartId, sectionCache) {
    const { customer, setCustomer } = useCustomerContext();
    const { webstore, setWebstore } = useWebStoreContext();
    const { cart, setCart } = useCartContext();
    const [checkout, setCheckout] = useCheckoutContext();

    const [mutation] = useSetBillingInfo();
    const billingInfo = sectionCache.data.billing_info
    const sendBillingInfo = {
        ...billingInfo,
        billing: frontendToBackendAddress(billingInfo.billing)
    }

    function switchStores(requiredStore) {
        if (requiredStore) {
            setIsSubmitting(false);
            setCustomer({
                ...customer,
                intended_store_view: requiredStore
            });
            setWebstore({
                ...webstore,
                designatedStore: requiredStore
            })
            redirectIfRequired(requiredStore);
        } else {
            handleError({message: "Unable to switch to selected billing country"});
        }
    }

    const handleError = error => {
        setIsSubmitting(false);
        setCheckout({
            ...checkout,
            submitStatus: error.message
        });
    }

    return () => {
        if (['complete', 'myaccount', 'invacc'].includes(billingInfo.status)) {
            setIsSubmitting(true);
            mutation({
                variables: {
                    cartId: cartId, 
                    emailInvoiceTo: sendBillingInfo.email_invoice_to,
                    financeEmail: sendBillingInfo.finance_email,
					financeFirstname: sendBillingInfo.finance_firstname,
					financeLastname: sendBillingInfo.finance_lastname,
                    billingAddress: sendBillingInfo.billing
                }
            })
            .then(data => {
                if (isStoreSwitchNeeded(billingInfo)) {
                    const requiredStore = getStoreForBillingCountry(sendBillingInfo);
                    switchStores(requiredStore);
                } else {
                    setIsSubmitting(false);
                    updateContextCart(cart, setCart, data);
                    sectionCache.submit();
                }
            })
            .catch(error => handleError(error));
        }

        // TODO - handle store switch if needed
    };
}


