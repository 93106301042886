import React from 'react';

export default function ContentfulImage({src, width, browserWidth}) {
    const queryString = width ? `?w=${width}` : ``;

    return (
        browserWidth
            ? <img src={`${src}${queryString}`}  alt="" width={browserWidth}/>
            : <img src={`${src}${queryString}`}  alt=""/>
    )
}
