import React from 'react';
import { NanoInput, NanoFileUpload } from '@nanoporetech-digital/components-react';
import * as styles from '../style.module.css';
import cx from 'classnames';

export default function DataElements({formData, dataActions}) {

    return (
        <>
            <h3>Step 1 - Upload order template</h3>
            <div className={cx(styles.step, styles.uploadWrapper)}>
                <NanoFileUpload
                    name="order_file"
                    className="multi"
                    accept="application/excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    maxFileSize="2"
                    maxFiles="2"
                    onNanoChange={e => dataActions.setFileValue(e)}
                >
                    <span slot="helper">Accepted formats: xls, xlsx</span>
                </NanoFileUpload>
            </div>

            <h3>Step 2 - Upload purchase order</h3>
            <div className={cx(styles.step, styles.uploadWrapper)}>
                <NanoFileUpload
                    name="po_file"
                    className="multi"
                accept="image/png, image/jpeg, application/pdf, image/tiff, image/x-tiff, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    maxFileSize="2"
                    maxFiles="2"
                    onNanoChange={e => dataActions.setFileValue(e)}
                >
                    <span slot="helper">Accepted formats: png, jpg, pdf, tif, doc, docx, xls, xlsx</span>
                </NanoFileUpload>
            </div>

            <h3>Step 3 - Identification</h3>
            <div className={styles.step}>
                <NanoInput
                    type="text"
                    placeholder="Enter customer identifier..."
                    required={true}
                    show-inline-error="dirty"
                    name="identifier"
                    value={formData.identifier}
                    hideLabel={true}
                    debounce="300"
                    onNanoChange={(e) => dataActions.setTextValue(e)}
                    maxlength="20"
                />
            </div>
        </>
    );
}
