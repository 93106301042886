import React from 'react';
import { NanoIcon } from '@nanoporetech-digital/components-react';

export default function Search({textFilter, setTextFilter}) {
    return (
        <>
            <div className="product-search">
                <div><strong>Search:</strong></div>
                <div className="form__field-container form__entry">
                    <div className="search">
                        <input
                            className="form__entry-input"
                            id="search"
                            data-cy="catalogSearch"
                            placeholder="Product name or SKU…"
                            type="text"
                            value={textFilter}
                            onChange={e => setTextFilter(e.currentTarget.value)}
                            autoFocus={true}
                        />
                        <span className={"reset" + (textFilter.length ? "" : " hide")}>
                            <NanoIcon name="light/times" onClick={() => setTextFilter('')} />
                        </span>
                        <span className="icon"><NanoIcon name="solid/search" /></span>
                    </div>
                </div>
            </div>
            <style jsx>{`
                .product-search {
                    // flex: 2;
                    // padding-right: 1rem;
                    display:flex;
                    flex-direction:column;
                    gap:.5rem;
                }
                .search {
                    width: 100%;
                }
                .form__entry-input {
                    border: 1px solid #99a3a6;
                    border-radius: 4px;
                    // padding: .484rem;
                }
                .reset {
                    color: #99a3a6;
                    position: absolute;
                    right: 2rem;
                    top: 1px;
                    z-index: 1;
                    padding: 7px;
                    cursor: pointer;
                }
                .icon {
                    border-left: 1px solid #99a3a6;
                    color: #99a3a6;
                    position: absolute;
                    right: 0;
                    top: 1px;
                    z-index: 1;
                    padding: 9px 7px 5px;
                    cursor: pointer;
                }
                .hide {
                    display: none;
                }
            `}</style>
        </>
    );
}
