import React, { useState, useEffect, useCallback } from 'react';
import { NanoCheckbox } from '@nanoporetech-digital/components-react';
import * as styles from './style.module.css';


export default function CatalogFilters(props) {
    if (props.category.url_key !== 'sequencing-kits') return null;
    const [state, setState] = useState("state");
    const loadDataOnlyOnce = useCallback(() => {
      const queryParams = new URLSearchParams(window.location.search)
      const term = queryParams.get("ont_approach")
      const sample = queryParams.get("ont_sample_type")
      const filters = Array.from(document.querySelectorAll('nano-checkbox'));

      filters.forEach((filter, i) => {
          if(((filter.name == "ont_sample_type") && (filter.value == sample))
            || ((filter.name == "ont_approach") && (filter.value == term))) {
                filter.checked =true;
                filter.setAttribute("checked", "true");
          }
      });
    }, [state]);

    useEffect(() => {
        loadDataOnlyOnce();
    }, [loadDataOnlyOnce]);

    return(
        <div className={'filters ' + styles.filters}>
            <div id="clear-filters" className={styles.clear}>
                <a href="#" onClick={() => props.setFilters({})}>Reset filters</a>
            </div>
            <div className={styles.panel}>
                <FilterList {...props} />
            </div>
        </div>
    );
}

const FilterList = (props) => {
    return props.attributes.map(attribute => <FilterAttribute {...props} key={attribute.attribute_code} attribute={attribute} />);
}

const FilterAttribute = (props) => {
    return(
        <div className={styles.item}>
            <div className={'attributeLabel ' + styles.panelHeading}>{attributeLabels[props.attribute.attribute_code]}
                <FilterOptions {...props} />
            </div>
        </div>
    );
}

const FilterOptions = ({attribute, filters, setFilters}) => {
    const code = attribute.attribute_code;

    return attribute.attribute_options.map(option => {
        return (
            <div className={styles.filterCheckbox} key={code + '_' + option.value}>
                <NanoCheckbox
                    type="radio"
                    className="filterCheckbox"
                    value={option.value}
                    name={code}
                    key={code + '_' + option.value}
                    label={option.label}
                    checked={filters.hasOwnProperty(code) && filters[code] === option.value}
                    onNanoChange={e => {
                        if (e.detail.checked) {
                            setFilters({...filters, [e.currentTarget.name]: e.currentTarget.value })
                        }
                    }}
                />
            </div>
        )
    });
}

//TODO GraphQL endpoint needs enhancing, to get this from Magento
const attributeLabels = {
    ont_sample_type: "Sample type",
    ont_multiplexing: "Multiplexing",
    ont_approach: "PCR-Free"
};
