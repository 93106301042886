import { useQuery, gql } from "@apollo/client";

export default function useTaxInfo() {
    return useQuery(TAX_INFO, {fetchPolicy: 'no-cache'});
}

const TAX_INFO = gql`
    query {
        getTaxInfo {
            has_account
            tax_status
            vat_number
            vat_exempt
            has_certificate
        }
    }
`;
