import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { navigate } from 'gatsby';
import * as styles from './style.module.css';

export default function UIBackButton() {
    function goBack() {
        if (typeof window !== 'undefined') {
            if (window.ontBackButtonUrl?.length) {
                navigate(window.ontBackButtonUrl);
            } else {
                navigate(-1);
            }
        }
    }

    return (
        <button className={styles.backButton} type="button" onClick={() => goBack()}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </button>
    );
}
