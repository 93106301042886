import React, { useMemo } from 'react';
import TopItemDrawer from './drawer';
import TopItemLink from './link';
import { NanoNavItem } from '@nanoporetech-digital/components-react';
import { getMenuByTitle } from '../helpers';

export default function TopItem({title, menuPrimary, config}) {
    const menu = useMemo(() => getMenuByTitle(menuPrimary, title), [menuPrimary, title]);
    if (!menu) return null;

    const Content = () => {
        switch (menu.component) {
            case 'menu': return <TopItemDrawer menuData={menu} config={config} />;
            case 'menu-link': return <TopItemLink menuData={menu} config={config} />;
        }
    }

    return (
        <NanoNavItem slot="main" selected={!!config?.selected}>
            <Content />
        </NanoNavItem>
    );
}

