import React from 'react';
import { useCustomerContext } from '../../../contexts/CustomerContext';
import { getLabel } from '../../../hooks/siteData';
import { navigate } from 'gatsby';

export default function CurrencySwitcher() {

    const { customer, setCustomer } = useCustomerContext();

    if (customer.token && !customer.email.endsWith('nanoporetech.com')) return null;

    function activateCountrySelect() {
        setCustomer({...customer, intended_store_view: ''});
        navigate('/countrySelect/');
    }

    return(
        <li className="footer__item">
            <a onClick={activateCountrySelect}>Change store ({getLabel()})</a>
            <style jsx>{`
                a:hover { cursor: pointer }
            `}</style>
        </li>
    );
}
