import React from 'react';
import { useTranslation } from 'react-i18next';
import { getUniqueKitChoices } from './helpers';
import UiLinkedSelect from '../../../Ui/UiLinkedInputs/UiLinkedSelect';
import { productSkuOption } from '../../../Ui/UiSelect/optionFormats';

export default function SelectKits(props) {
    const { t } = useTranslation();

    return (
        <div>
            {t("Select 1 sequencing kit")}:
            <UiLinkedSelect {...props} optionFormat={productSkuOption} />
        </div>
    );
}

export function getKitOptions(bundleProducts) {
    return getUniqueKitChoices(bundleProducts);
}

