// extracted by mini-css-extract-plugin
export var costs = "style-module--costs--35b69";
export var error = "style-module--error--ee272";
export var last = "style-module--last--d3773";
export var parent = "style-module--parent--79ab8";
export var removeLink = "style-module--removeLink--3a0d7";
export var rowName = "style-module--rowName--65d3c";
export var schedulerButtons = "style-module--schedulerButtons--80a0f";
export var shipmentDate = "style-module--shipmentDate--7a12d";
export var shipmentItem = "style-module--shipmentItem--3dede";
export var shipmentItemSpacer = "style-module--shipmentItemSpacer--dd8a3";
export var shippingScheduler = "style-module--shippingScheduler--c54f2";
export var sub = "style-module--sub--3268e";