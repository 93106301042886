import React from 'react';
import { useCheckoutContext } from '../../../../../contexts/CheckoutContext';
import { isUsTaxExemptCreditCard } from '../helpers';
import BlockedText from '../TaxInfo/blockedText';
import * as styles from './style.module.css';

export default function SalestaxPreview({taxInfo, taxSystem}) {
    const [checkout] = useCheckoutContext();
    const isBlocked = isUsTaxExemptCreditCard(checkout, taxSystem, taxInfo) && !taxInfo.has_account;

    return (
        <div>
            <h3>Please confirm your organisation's tax status.</h3>
            <div className={styles.subsection}>
                <h4>Tax status:</h4>
                <p>{(taxInfo.tax_status === 'tax_exempt') ? 'Sales Tax exempt' : 'Add Sales Tax'}</p>
            </div>
            <div className={styles.subsection}>
                <h4>Exemption certificate:</h4>
                <p>{(taxInfo.certificate || taxInfo.has_certificate) ? 'Yes' : 'No'}</p>
            </div>
            <BlockedText show={isBlocked} />
        </div>
    );
}
