import loadScript from '../../utils/loadScript';

export default function Mavenoid() {
    if (typeof window==='undefined') return null;

    if (window.mavenoid) {
        updateMavenoidIfNeeded();
    }
    return null;
}

export function loadMavenoid(user) {
    if (typeof window==='undefined') return null;

    const isLegacy = isLegacyPage();
    const config = getMavenoidConfig(user, isLegacy, false);

    if (window.mavenoidPushHandler) {
        window.mavenoidPushHandler(config);
    } else {
        loadScript("https://widget-hosts.mavenoid.com/custom-embedding-scripts/oxford-nanopore-corporate-website.js")
            .then(() => window.mavenoidPushHandler(config))
            .catch(error => {
                console.log('Mavenoid error');
            })
    }
}

function isLegacyPage() {
    const url = new URL(window.location.href);

    if (url.pathname.includes('legacy')) return true;

    if (url.pathname.includes('catalog')) {
        return url.search.includes('id=46');
    }

    return false;
}

function updateMavenoidIfNeeded() {
    const prevData = (typeof window.mavenoid.unstable__getPushedEvents === 'undefined')
        ? {}
        : window.mavenoid.unstable__getPushedEvents()?.findLast(e => e.event == 'troubleshooter-open')?.initFormData ?? {};
    const isLegacy = isLegacyPage();
    const buttonClick = isLegacy ? 'legacy' : '';

    if (buttonClick != prevData['button-click']) {
        const config = getMavenoidConfig(prevData, isLegacy, true);
        window.mavenoidPushHandler(config);
    }
}

function getMavenoidConfig(user, isLegacy, usePrevious) {
    const initFormData = usePrevious ? user : {
        customerEmail: user?.email || '',
        firstName: user?.first_name || '',
        lastName: user?.last_name || '',
    };

    const config = {
        initFormData: {
            ...initFormData,
            'button-click': isLegacy ? 'legacy' : ''
        },
        openByDefault: isLegacy
    }

    return config;
}
