import React from 'react';
import * as styles from './style.module.css';

export default function VatPreview({taxInfo}) {
    return (
        <div>
            <h3>Please confirm your organisation's tax status.</h3>
            <div className={styles.subsection}>
                <h4>Tax status:</h4>
                <p>{(taxInfo.tax_status === 'vat_registered') ? 'VAT Registered' : 'Not VAT Registered'}</p>
            </div>
            <div className={styles.subsection}>
                <h4>VAT Registration number:</h4>
                <p>{taxInfo.vat_number ?? 'Not provided'}</p>
            </div>
            <div className={styles.subsection}>
                <h4>VAT Exempt:</h4>
                <p>{taxInfo.vat_exempt ? 'Yes' : 'No'}</p>
            </div>
        </div>
    );
}
