import React from 'react';
import { filterAndSort } from './helpers';
import SubLevel from './subLevel';
import * as styles from "./style.module.css";

// top level nav item, i.e. either a clickable link OR heading+list of clickable links
export default function TopLevel({category, activeCategory}) {
    if (category.is_anchor) {
        if (category.children?.length) {
            const sortedChildren = filterAndSort(category.children);
            return (
                <div className={styles.section}>
                    <div className={styles.sectionName}>{category.name}</div>
                    {sortedChildren.map(child => <SubLevel key={child.url_key} parentCategory={child} activeCategory={activeCategory} />)}
                </div>
            );
        } else {
            return null;
        }
    }

    return (
        <div className={styles.section}>
            <SubLevel parentCategory={category} activeCategory={activeCategory} />
        </div>
    );
}
