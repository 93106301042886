import VERSION from '../../version.json';
import axios from 'axios';
import {Operation} from "@apollo/client";
import {compareVersions} from "compare-versions";

import Swal from "sweetalert2";

export function getCurrentVersion() {
    return VERSION['version'] ?? ''
}

export function getAvailableFeVersion(): Promise<any> {
    return axios.get('/version.json')
        .then(response => {
            return response.data?.version ?? null;
        })
        .catch(e => {
            return null;
        });
}

export function handleApolloError(apolloOperation: Operation): void
{
    if (isFrontendCompatibleWithBackend(apolloOperation)) {
        return;
    }

    try {
        fetch('/version.json', {signal: AbortSignal.timeout(5000)})
            .then(response => response.json())
            .then(data => {
                const availableVersion = data.version ?? null;

                if (availableVersion && compareVersions(availableVersion, getCurrentVersion()) === 1) {
                    alertNewerVersionAvailable();
                } else {
                    alertNoNewVersionAvailable();
                }
            })
            .catch(() => {
                alertNoNewVersionAvailable();
            });
    } catch (e) {
        alertNoNewVersionAvailable()
    }
}

export function isFrontendCompatibleWithBackend(apolloOperation: Operation) :boolean {
    const { response } = apolloOperation.getContext();
    if (!response) {
        return true;
    }
    const serverRequiredVersion = response.headers.get('X-Ont-Required-Fe-Version');
    if (!serverRequiredVersion) {
        return true;
    }

    return compareVersions(serverRequiredVersion, getCurrentVersion()) === 0;
}

function alertNewerVersionAvailable(): void {
    Swal.fire({
        title: 'New version available',
        text: 'Do you want to reload?',
        confirmButtonText: 'Reload',
        cancelButtonText: "Don't reload",
        showCancelButton: true,
    }).then(() => {
        console.log('confirmed');
        window.location.reload();
    })
}

function alertNoNewVersionAvailable(): void
{
    Swal.fire({
        title: 'Bad version but no new version available',
        text: 'Sorry about that',
        confirmButtonText: 'Cheers then',
    }).then(() => {
        console.log('confirmed');
        window.location.reload();
    })
}
