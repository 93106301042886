import React from 'react';
import {graphql, useStaticQuery} from "gatsby";
import Catalog from "../Catalog";
import "../../fragments/static/catalog";
import {useCustomerContext} from "../../contexts/CustomerContext";
import LiveCatalog from "../Live/LiveCatalog";

export default function Home({defaultCategoryChildren, filterMeta}) {
    const devicesCategory = getDevicesCategory();
    const { customer } = useCustomerContext();

    return customer.has_extra_permission
        ? <LiveCatalog
            categoryId={9}
            isHome={true}
          />
        : <Catalog
            category={devicesCategory}
            categories={defaultCategoryChildren}
            filterAttributes={filterMeta}
            isHome={true}
          />;
}

function getDevicesCategory() {
    const data =  useStaticQuery(graphql`
{
  magento {
    categoryList(filters: {ids: {eq: "9"}}) {
      ...categoryContent
      children {
        ...categoryContent
      }
    }
  }
}
    `);
    return data.magento.categoryList[0];
}
