import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import VisibilitySensor from 'react-visibility-sensor';
import ContentTab from './contentTab';
import { NanoGrid } from '@nanoporetech-digital/components-react';

export default function InformationTabs({
                                            information,
                                            workflow,
                                            protocols,
                                            safetyAndLegal,
                                            whatsInTheBox,
                                            multiplexing,
                                            thirdPartyMaterials,
                                            compatibility,
                                        }) {
    const [activeTab, setActiveTab] = useState('information');
    const [isFixed, setFixed] = useState(false);
    const informationRef = useRef(null);
    const workflowRef = useRef(null);
    const protocolsRef = useRef(null);
    const safetyAndLegalRef = useRef(null);
    const whatsInTheBoxRef = useRef(null);
    const multiplexingRef = useRef(null);
    const thirdPartyMaterialsRef = useRef(null);
    const compatibilityRef = useRef(null);
    const numberOfTabs = [
      information,
      workflow,
      protocols,
      safetyAndLegal,
      whatsInTheBox,
      multiplexing,
      thirdPartyMaterials,
      compatibility,
    ].reduce((acc, current) => current !== undefined ? acc + 1 : acc, 0);
    const [isSticky, setSticky] = useState(false);
    const [scrolled,setScrolled]=React.useState(false);
    const ref = useRef(null);
    const handleScroll = () => {
        const offset=(typeof window==='undefined') ? 0 : window.scrollY;
        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().top <=0 &&  offset >0 );
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', () => handleScroll);
            };
        }

    }, [handleScroll]);

    return (
        <>
            <VisibilitySensor
                offset={{top:100}}
                onChange={
                    (isVisible) => {
                        if (!isVisible) {
                            setFixed(true);
                        } else {
                            setFixed(false);
                        }
                    }
                }>
                <div className="sensor"></div>
            </VisibilitySensor>
    <NanoGrid
        l-cols="10"
        s-cols="1"
        xl-cols="10"
        class="header"
        >
        <div grid-states="xl-col-span-10 xl-col-start-1 xl-row-start-1 l-col-span-10 l-row-start-1">
            <div className={`sticky${isSticky ? ' fixed' : ''}`} ref={ref}>
                <nav className="sub-nav nav__sub-nav">
                    <div className="sub-nav__inner">
                        { information && (
                            <a
                                className={clsx('sub-nav__link', activeTab === 'information' && 'sub-nav__link--active')}
                                onClick={() => {
                                    informationRef.current.scrollIntoView({ behavior: 'smooth',block: "start", inline: "nearest" });
                                    setActiveTab('information'); console.log(informationRef.current);
                                }}
                            >Information
                            </a>
                        )}
                        { workflow && (
                            <a
                                className={clsx('sub-nav__link', activeTab === 'workflow' && 'sub-nav__link--active')}
                                onClick={() => {
                                    workflowRef.current.scrollIntoView({ behavior: 'smooth',block: "start", inline: "nearest" });
                                    setActiveTab('workflow'); console.log(workflowRef.current);
                                }}
                            >Workflow</a>
                        )}
                        { protocols && (
                            <a
                                className={clsx('sub-nav__link', activeTab === 'protocols' && 'sub-nav__link--active')}
                                onClick={() => {
                                    protocolsRef.current.scrollIntoView({ behavior: 'smooth',block: "start", inline: "nearest" });
                                    setActiveTab('protocols');
                                }}
                            >Protocols</a>
                        )}
                        { safetyAndLegal && (
                            <a
                                className={clsx('sub-nav__link', activeTab === 'safetyAndLegal' && 'sub-nav__link--active')}
                                onClick={() => {
                                    safetyAndLegalRef.current.scrollIntoView({ behavior: 'smooth',block: "start", inline: "nearest" });
                                    setActiveTab('safetyAndLegal');
                                }}
                            >Safety and legal</a>
                        )}
                        { whatsInTheBox && (
                            <a
                                className={clsx('sub-nav__link', activeTab === 'whatsInTheBox' && 'sub-nav__link--active')}
                                onClick={() => {
                                    whatsInTheBoxRef.current.scrollIntoView({ behavior: 'smooth',block: "start", inline: "nearest" });
                                    setActiveTab('whatsInTheBox');
                                }}
                            >What's in the box</a>
                        )}
                        { multiplexing && (
                            <a
                                className={clsx('sub-nav__link', activeTab === 'multiplexing' && 'sub-nav__link--active')}
                                onClick={() => {
                                    multiplexingRef.current.scrollIntoView({ behavior: 'smooth',block: "start", inline: "nearest" });
                                    setActiveTab('multiplexing');
                                }}
                            >Multiplexing</a>
                        )}
                        { thirdPartyMaterials && (
                            <a
                                className={clsx('sub-nav__link', activeTab === 'thirdPartyMaterials' && 'sub-nav__link--active')}
                                onClick={() => {
                                    thirdPartyMaterialsRef.current.scrollIntoView({ behavior: 'smooth',block: "start", inline: "nearest" });
                                    setActiveTab('thirdPartyMaterials');
                                }}
                            >3rd party materials</a>
                        )}
                        { compatibility && (
                            <a
                                className={clsx('sub-nav__link', activeTab === 'compatibility' && 'sub-nav__link--active')}
                                onClick={() => {
                                    compatibilityRef.current.scrollIntoView({ behavior: 'smooth',block: "start", inline: "nearest" });
                                    setActiveTab('compatibility');
                                }}>Compatibility</a>
                        )}
                    </div>
                </nav>
            </div>
            </div>
            </NanoGrid>
            <NanoGrid
        l-cols="10"
        s-cols="1"
        xl-cols="10"
        >
        <div grid-states="xl-col-span-10 xl-col-start-1 xl-row-start-1 l-col-span-8 l-row-start-1">
        <div className={`content${isSticky ? ' stickyTabBar' : ''}`}>
                { information && (
                    <ContentTab
                        onVisible={() => setActiveTab('information')}
                        title="Information"
                        content={information}
                        ref={informationRef}
                    />
                )}
                { workflow && (
                    <ContentTab
                        onVisible={() => setActiveTab('workflow')}
                        title="Workflow"
                        content={workflow}
                        ref={workflowRef}
                    />
                )}
                { protocols && (
                    <ContentTab
                        onVisible={() => setActiveTab('protocols')}
                        title="Protocols"
                        content={protocols}
                        ref={protocolsRef}
                    />
                )}
                { safetyAndLegal && (
                    <ContentTab
                        onVisible={() => setActiveTab('safetyAndLegal')}
                        title="Safety and legal"
                        content={safetyAndLegal}
                        ref={safetyAndLegalRef}
                    />
                )}
                { whatsInTheBox && (
                    <ContentTab
                        onVisible={() => setActiveTab('whatsInTheBox')}
                        title="What's in the box"
                        content={whatsInTheBox}
                        ref={whatsInTheBoxRef}
                    />
                )}
                { multiplexing && (
                    <ContentTab
                        onVisible={() => setActiveTab('multiplexing')}
                        title="Multiplexing"
                        content={multiplexing}
                        ref={multiplexingRef}
                    />
                )}
                { thirdPartyMaterials && (
                    <ContentTab
                        onVisible={() => setActiveTab('thirdPartyMaterials')}
                        title="3rd party materials"
                        content={thirdPartyMaterials}
                        ref={thirdPartyMaterialsRef}
                    />
                )}
                { compatibility && (
                    <ContentTab
                        onVisible={() => setActiveTab('compatibility')}
                        title="Compatibility"
                        content={compatibility}
                        ref={compatibilityRef}
                    />
                )}
            </div>
        </div>
        </NanoGrid>

            <style jsx>{`
            .sensor {
                height: 1px;
            }
            .content {
                padding: 1rem;;
            }
            .sticky {
                margin-left: calc(50% - 50vw);
                margin-right: calc(50% - 50vw);
                width: 100vw;
                 overflow: hidden;
            }
            .fixed {
                position: fixed;
                top: 0;
                left: 0;
                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;
                width: 100%;
                z-index: 2;
            }
            .sub-nav {
                background-color: #e9f4fa;
                border-bottom: none;
                box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
            }
            .sub-nav__inner {
                padding: 0 1rem 0 1rem;
                // max-width: calc(1044px - 2rem);
                width:100%;
                margin: auto;
                overflow: auto;
                white-space: nowrap;
            }
            .sub-nav__link {
                color: #0084a9;
                cursor: pointer;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 1.2px;
                text-transform: uppercase;
            }
            .sub-nav__link:hover {
                text-decoration: none;
            }
            .sub-nav__link--active {
                border-bottom: 0.25rem solid #90c6e7;
                color: #0c5a71;
            }
            @media screen and (max-width: 721px) {
                .sub-nav__inner {
                margin: 0 14px;
            }
         }

            @media screen and (min-width: 721px) {
                .sub-nav__inner  {
                    margin: 0 50px;
                }
            }

            @media screen and (min-width: 1125px) {
                .sub-nav__inner  {
                    margin: 0 83px;
                }
            }
            @media screen and (min-width: 1647px) {
                .sub-nav__inner  {
                    margin: 0 auto;
                }
            }
          `}</style>
        </>
    );
}
