import React, { useState } from 'react';
import { NanoCheckbox } from '@nanoporetech-digital/components-react';
import BillingForm from '../../../../Form/BillingForm';
import ShippingForm from '../../../../Form/ShippingForm';
import Navigate from '../navigate';
import { useQuotationContext } from '../../../../../contexts/QuotationContext';
import { useCartContext } from '../../../../../contexts/CartContext';
import { useSetGuestBillingAddress, useSetGuestShippingAddress, useSetShippingMethod, updateContextCart } from '../../../../../hooks/useCart';
import { autofill, validate } from '../../../../Form/validate';
import { frontendToBackendCartAddress } from '../../../helpers';
import * as styles from '../style.module.css';

export default function Guest({setMode, setActiveSection, status, setStatus}) {
    const [quotation, setQuotation] = useQuotationContext();
    const {cart, setCart} = useCartContext();
    const [setBillingMutation] = useSetGuestBillingAddress(cart.id);
    const [setShippingMutation] = useSetGuestShippingAddress(cart.id);
    const [setShippingMethodMutation] = useSetShippingMethod();
    const [billing, setBilling] = useState({...quotation.billing});
    const [shipping, setShipping] = useState({...quotation.shipping});

    async function submit(e) {
        e.preventDefault();

        if (autofill(billing, e.target, 'billing_')) {
            setBilling({...billing});
        }
        if (!quotation.use_for_shipping) {
            if (autofill(shipping, e.target, 'shipping_')) {
                setShipping({...shipping});
            }
        }

        if (!validate(e.target, 'billing_')) return;
        if (!quotation.use_for_shipping) {
            if (!validate(e.target, 'shipping_')) return;
        }

        const updatedQuotation = {...quotation, billing: billing, shipping: shipping, loading: true};
        setQuotation(updatedQuotation);

        try {
            await setBillingMutation({variables: {cartId: cart.id, address: frontendToBackendCartAddress(billing), useForShipping: updatedQuotation.use_for_shipping}});
            if (!updatedQuotation.use_for_shipping && !cart.is_virtual) {
                await setShippingMutation({variables: {cartId: cart.id, address: frontendToBackendCartAddress(shipping)}});
            }
            const responseData = await setShippingMethodMutation({ variables: { cartId: cart.id } });
            updateContextCart(cart, setCart, responseData);
            setStatus('');
            setQuotation({...updatedQuotation, loading: false});
            setActiveSection(2);
        } catch(err) {
            setStatus("Error saving addresses");
        }
    }

    function toggleUseForShipping() {
        const form = document.getElementById('guestForm');
        autofill(billing, form, 'billing_');
        setQuotation({...quotation, billing: billing, shipping: shipping, use_for_shipping: !quotation.use_for_shipping});
    }

    function SubmitStatus() {
        return (status ? <p className={styles.status}>{status}</p> : null);
    }

    return (
        <form id="guestForm" className={styles.sectionInner} onSubmit={e => submit(e)}>
            <Navigate setMode={setMode} />
            <BillingForm formData={billing} setFormData={setBilling} />
            <h3>Your order will be delivered to the same address</h3>
            {!cart.is_virtual && <p>
                <NanoCheckbox
                    label="Deliver to a different address"
                    checked={!quotation.use_for_shipping}
                    onNanoChange={toggleUseForShipping} />
            </p>}
            {!quotation.use_for_shipping && <ShippingForm formData={shipping} setFormData={setShipping} />}
            <Navigate setMode={setMode} disable={quotation.loading} />
            <SubmitStatus />
        </form>
    );
}
