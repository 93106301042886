import React from 'react';
import { NanoSlide } from '@nanoporetech-digital/components-react';
import ContentfulImage from '../../../Contentful/ContentfulImage';
import { slide } from './style.module.css';

export default function Slide({url}) {
    return (
        <div className={slide}>
            <NanoSlide>
                <ContentfulImage src={url} width={400} />
            </NanoSlide>
        </div>
    );
}
