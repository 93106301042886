import React from "react";
import { getRegistrationEnvironment } from '../../../../../hooks/useEnvironment';
import * as styles from './style.module.css';

export default function NoRegistrationWarning({billingInfo}) {
    if (billingInfo.has_registered_on_my_account) return null;

    const hostname = getRegistrationEnvironment();
    const url = `https://${hostname}.nanoporetech.com/account/create`;

    return (
        <div className={styles.noRegistrationWarning}>
            <div className="info-banner info-banner--warning">
                <div className="info-banner__content">
                    <span className="info-banner__label">Warning</span>
                    <span className="info-banner__heading">No account registration found</span>
                    <p className="info-banner__text">According to our records, you have not yet registered to purchase in our store. Please <a href={url}>register an account</a> before placing your order. We will be unable to process your order until your account is set up and approved.</p>
                </div>
            </div>
        </div>
    );
}
