import React from "react";

export default function BillingNotice() {
    return (
        <div>
            <div className="info-banner info-banner--caution">
                <div className="info-banner__content">
                    <p className="info-banner__text">The information needed for Billing Information and Tax Status will normally be available on your Purchase Order. If it is not, or you are paying by Credit Card, please consult your Finance team for any information you do not know before proceeding.</p>
                </div>
            </div>
        </div>
    );
}
