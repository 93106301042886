import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function LoggingIn() {
    return (
        <center>
            <h2 style={{textAlign: 'center', fontWeight: 'lighter'}}>Logging you in...</h2>
            <p><FontAwesomeIcon icon={faSpinner} size="lg" spin /></p>
        </center>
    );
}
