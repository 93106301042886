import React, {useEffect, useState} from "react";
import AtcButton from "../../AtcButton";
import {TransactionalContent} from "../../../hooks/siteData";
import useQuantity from "../../ProductQuantity/useQuantity";

export default function AddToCart({product, value, onValueChange}) {
    const [focusKey, setFocusKey] = useState('');

    const [quantityValue, quantityComponent] = useQuantity(
        1,
        'atc-input-' + product.id,
        onValueChange,
        null,
        1,
        focusKey,
        setFocusKey
    );

    useEffect(() => {
        setFocusKey('atc-input-' + product.id);
    }, [quantityValue])

    function QtySelector() {
        if (product.ont_cta_button) return null;
        return (
            <>
                <div className="quantity">
                    {quantityComponent}
                </div>
                <style jsx>{`
                    .quantity {
                        display: inline-block;
                        margin: 0 2rem 2rem 0;
                    }
                `}</style>
            </>
        );
    }

    return (
        <>
            <TransactionalContent>
                <QtySelector />
            </TransactionalContent>

            <div className="cartActions">
                <AtcButton product={product} qty={quantityValue} />
            </div>
            <style jsx>{`
                .cartActions {
                    display: inline-block;
                }
            `}</style>
        </>
    );
}
