import { useQuery, gql } from "@apollo/client";
import { useCheckoutContext } from '../contexts/CheckoutContext';

export function useDisplayEcoAlert() {

    const [checkout] = useCheckoutContext();
    const { data } = useQuery(ECO_COUNTRIES);
    const ecoCountries = data?.getEcoCountries;

    if (ecoCountries?.length) {
        const shippingCountryId = checkout['shipping_address']?.shippingCountryId ?? null;
        if (shippingCountryId) {
            if (ecoCountries.findIndex(country => country.id===shippingCountryId) >=0 ) {
                return true;
            }
        }
    }

    return false;
}


const ECO_COUNTRIES = gql`
query {
    getEcoCountries {
        id
        full_name_english
    }
}
`;
