import React from 'react';
import { Link } from 'gatsby';
import cx from "classnames";
import * as styles from "../style.module.css";
import UiMarkdown from "../../../Ui/UiMarkdown";
import ProductPhase from "../../../AtcButton/ProductPhase";
import {getCheapestFlowcellInCategory, getProductUrlPath} from "../helpers";
import {useCustomerContext} from "../../../../contexts/CustomerContext";
import {shouldShowPrice} from "../../../../hooks/siteData";
import {CtaButton} from "./ctaButton";
import {Thumbnail} from "./thumbnail";
import {ProductPrice} from "./productPrice";

export const SubCategoryItem = ({subCategory}) => {
    if (!subCategory.products?.items?.length) return null;

    switch(subCategory.ont_category_type) {
        case 'CONFIGURATOR':
        case 'MINION':
            return <ConfiguratorItem subCategory={subCategory} />;
        case 'FLOWCELLS':
            return <FlowcellsItem subCategory={subCategory} />;
        case 'FAMILY':
            return <FamilyItem subCategory={subCategory} />;
    }
    return subCategory.products.items.map(product => <StandardItem key={'p' + product.id} product={product} />);
}

const ConfiguratorItem = ({subCategory}) => {
    return (
        <>
            <div id={'configurator-' + subCategory.url_key} className={cx(styles.configurator, styles.item)}>
                <h2 className={styles.title}>{subCategory.name}</h2>
                <div className={styles.details}>
                    <div className={styles.innercontent}>
                        <Link to={getCategoryUrlPath(subCategory.url_key, subCategory.id)} className={styles.image}><img src={subCategory.ont_image_url_1} /></Link>
                        <div className={styles.description}>
                            <UiMarkdown source={subCategory.description} />
                            <div className={styles.priceContainer}>
                                <ProductPrice prefix="Packages from: " product={getCheapestProduct(subCategory)} />
                                <CtaButton
                                    target={getCategoryUrlPath(subCategory.url_key, subCategory.id)}
                                    productOrCategory={subCategory}
                                />
                                <ProductPhase product={subCategory} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const FamilyItem = ({subCategory}) => {
    const cheapestProduct = getCheapestProduct(subCategory);
    return (
        <div id={'family-' + subCategory.url_key} className={cx(styles.configurator, styles.item)}>
            <h2 className={styles.title}>{subCategory.name}</h2>
            <div className={styles.details}>
                <div className={styles.innercontent}>
                    <Link to={getProductUrlPath(cheapestProduct)} className={styles.image}><Thumbnail src={subCategory.ont_image_url_1} /></Link>
                    <div className={styles.description}>
                        <UiMarkdown source={subCategory.description}/>
                        <div className={styles.priceContainer}>
                        <ProductPrice prefix="Packages from: " product={cheapestProduct} />
                        <CtaButton
                            target={getProductUrlPath(cheapestProduct)}
                            productOrCategory={subCategory}
                        />
                        <ProductPhase product={subCategory} />
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const FlowcellsItem = ({subCategory}) => {
    const { configurable, price_each } = getCheapestFlowcellInCategory(subCategory);
    const cheapestProduct = {
        sku: configurable.sku,
        price_range: {
            minimum_price: {
                final_price: price_each
            }
        }
    }

    return (
        <div className={cx(styles.flowcells, styles.item)}>
            <h2 className={styles.title}>{subCategory.name}</h2>
            <div className={styles.flowDetails}>
                <Link className={styles.image} to={getProductUrlPath(configurable)}><Thumbnail src={subCategory.ont_image_url_1} /></Link>
                <div className={styles.description}>
                    <UiMarkdown source={subCategory.description} />
                    <div className={styles.priceFlowContainer}>
                        <ProductPrice prefix="Flow cells from: " product={cheapestProduct} />
                        <CtaButton
                            target={getProductUrlPath(configurable)}
                            productOrCategory={subCategory}
                        />
                        <ProductPhase product={subCategory} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const getCategoryUrlPath = (slug, id) => {
    const {customer} = useCustomerContext();
    return (customer.has_extra_permission || customer.has_custom_pricing)
	? `/category/?id=${id}`
	: `/${slug}.html`;
}

const getCheapestProduct = category => {
    const sortedProducts = [...category.products.items].sort(function (a, b) {
        if(!shouldShowPrice(a.sku)) return 1;
        if(!shouldShowPrice(b.sku)) return 1;
        if (a.price_range.minimum_price.final_price.value === b.price_range.minimum_price.final_price.value) {
            return 0;
        }

        return a.price_range.minimum_price.final_price.value - b.price_range.minimum_price.final_price.value;
    });

    return sortedProducts[0];
}
