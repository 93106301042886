import { isDistributorRootStore, isNonTransactional } from "../../../hooks/siteData";

export function filterAndSort(categories) {
    return [...categories]
        .filter(c => {return c.include_in_menu === 1;})
        .sort((a,b) => a.position - b.position);
}

export function isActive(category, activeCategoryId) {
    if (activeCategoryId === 0) return false;
    if (category.id === activeCategoryId) return true;

    // are any of this category's children active?
    if (category.is_anchor && category.children) {
        return category.children.reduce((found, subcategory) => {
            return (found || subcategory.id === activeCategoryId);
        }, false);
    }

    return false;
}

export function getCategoryUrlPath(category, customerHasExtraPermission) {
    if (category.url_key == 'store-home') return '/';

    if (customerHasExtraPermission) {
        if (isDirectBuy(category)) {
            return `/category/?id=${category.id}`
        } else if (category.level) {
            return `/catalog/?id=${category.id}`
        } else {
            return `/productDetail/?id=${category.url_key}`;
        }
    }

    return `/${category.url_key}.html`;
}

export function isDirectBuy(category) {
    if (['CERTIFICATION'].includes(category.ont_category_type)) {
        return !isDistributorRootStore() && !isNonTransactional();
    }
    return false;
}
