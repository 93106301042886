import { useQuery, gql } from "@apollo/client";

export default function useAddresses() {
    const { loading, error, data } = useQuery(ADDRESSES);
    
    const billing = {};
    const shipping = [];
    const notLoaded = loading || error;

    if (data?.customer?.addresses?.length) {
        data.customer.addresses.forEach(address => {
            if (address.default_billing) Object.assign(billing, address);
            if (address.default_shipping) {
                shipping.unshift(address);
            } else {
                shipping.push(address);
            }
        });
    }

    return {billing, shipping, addressesLoaded: !notLoaded};
}

const ADDRESSES = gql`
    query {
        customer {
            addresses {
                id
                firstname
                lastname
                company
                street
                city
                postcode
                region {
                    region
                    region_code
                }
                country_id
                telephone
                default_billing
                default_shipping
            }
        }
    }
`;
