import React from 'react';
import { useTranslation } from 'react-i18next';
import { licenseWarranty } from './style.module.css';

export default function LicenseWarranty() {
    const { t } = useTranslation();

    return (
        <section className={licenseWarranty}>
            <h2>{t("License and Warranty")}</h2>
            <a target="_blank" href="/license-warranty-mk1b-60.html">{t("License and Warranty 60 months")}</a>
        </section>
    );
}
