import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { isTaxInfoComplete } from "../helpers";
import UiButton from "../../../../Ui/UiButton";
import * as styles from './style.module.css';

export default function Actions({sectionCache, taxSystem}) {
    const taxInfo = sectionCache.data.tax_info;
    const isDisabled = !isTaxInfoComplete(taxInfo, taxSystem);

    const loadPreview = () => sectionCache.set({
        ...sectionCache.data,
        mode: 'preview'
    });

    return (
        <div className={styles.actions}>
            <UiButton type="submit" className="button button--secondary" disabled={isDisabled} onClick={loadPreview}>
                <span>Continue &nbsp;<FontAwesomeIcon icon={faChevronRight} size="sm" /></span>
            </UiButton>
        </div>
    );
}
