import React from 'react';
import * as styles from './style.module.css';

export default function WarningText({children, show, taxSystem}) {
    if (!show) return null;

    return (
        <div className={styles.warningText}>
            <div className="info-banner info-banner--warning">
                <div className="info-banner__content">
                    <p className="info-banner__heading">{children}</p>
                    <p className="info-banner__text">{taxSystem.warning_text}</p>
                </div>
            </div>
        </div>
    );
}
