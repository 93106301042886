import React, { useState } from 'react';
import { useCartContext } from '../../../../../contexts/CartContext';
import { useCheckoutContext } from '../../../../../contexts/CheckoutContext';
import SectionSubmit from '../../sectionSubmit';
import { NanoInput, NanoFileUpload } from '@nanoporetech-digital/components-react';
import QuotationInfo from '../QuotationInfo';
import * as styles from '../../style.module.css';

export default function PurchaseOrder({section, submitSection, billingAddress}) {
    const { cart } = useCartContext();
    const [checkout] = useCheckoutContext();
    const [cache, setCache] = useState({
        purchase_order_number: section.purchase_order_number ?? '',
        po_file: section.po_file ?? {},
        quotation_number: '',
        additional_information: {
            cup: section.additional_information?.cup ?? '',
            cig: section.additional_information?.cig ?? '',
        },
    });

    const disableSubmit = (cache.purchase_order_number.length <= 0 || cache.po_file.size===undefined);

    async function submit() {
        submitSection({
            highlight: `Purchase order: ${cache.purchase_order_number}`,
            purchase_order_number: cache.purchase_order_number,
            po_file: cache.po_file,
            quotation_number: cache.quotation_number,
            additional_information: cache.additional_information,
        });
    }

    return(
        <>
            <SectionContent cache={cache} setCache={setCache} billingAddress={billingAddress}/>
            <SectionSubmit callback={submit} disable={disableSubmit} />
        </>
    );
}

function SectionContent({cache, setCache, billingAddress}) {
    function setFile(e) {
        if (e.target.files?.length && !e.target.invalid) {
            const file = e.target.files[e.target.files.length - 1]
            setCache({...cache, po_file: file});
        }
    }

    const isItalyBilling = billingAddress?.country_code === 'IT';

    return(
        <div className={styles.sectionContent}>
            <div className="info-banner info-banner--caution">
                <div className="info-banner__content">
                    <span className="info-banner__heading">Purchase order requirements</span>
                    <p className="info-banner__text">We have specific requirements for valid purchase orders, which you can view <a href="https://assets.ctfassets.net/obrbum38fjyj/69Zy8B8iFDS9oDWzbVI5HK/e3a067af1ce119bc303cf98d4c5b740d/Purchase_Order_Guide_Nov_19.pdf" target="_blank">here</a>. Only one purchase order to be provided. Please note the following will not be accepted, and may delay the processing of your order:</p>
                    <ul style={{fontSize: "1rem"}}>
                        <li>Oxford Nanopore quotation</li>
                        <li>Requisition request</li>
                    </ul>
                    <p className="info-banner__text">Accepted file types: PDF, JPG, JPEG, PNG, TIF, TIFF, DOC, DOCX, XLS, XLSX</p>
                </div>
            </div>
            <NanoInput
                type="text"
                placeholder="Enter number..."
                required={true}
                show-inline-error="dirty"
                name="purchase_order_number"
                value={cache.purchase_order_number}
                label="Purchase order number*"
                onNanoChange={(e) => setCache({...cache, purchase_order_number: e.target.value})}
                maxlength="20"
            />
            <NanoFileUpload
                name="po_file"
                className="multi"
                accept="image/png, image/jpeg, application/pdf, image/tiff, image/x-tiff, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                label="Upload document*"
                maxFileSize="2"
                maxFiles="1"
                validateOn="dirty"
                onNanoChange={e => setFile(e)}
            >
                <span slot="helper">Accepted formats: png, jpg, pdf, tif, doc, docx, xls, xlsx</span>
            </NanoFileUpload>
            {isItalyBilling && <CupCigFields cache={cache} setCache={setCache} />}
            <QuotationInfo cache={cache} setCache={setCache} />
        </div>
    );
}

const CupCigFields = ({cache, setCache}) => {
    return (
        <>
            <NanoInput
                type="text"
                placeholder="Enter CIG..."
                required={false}
                show-inline-error="dirty"
                name="cig"
                value={cache.additional_information.cig}
                label="CIG (optional)"
                maxlength="15"
                onNanoChange={(e) => setCache({
                    ...cache, additional_information: {
                        ...cache.additional_information,
                        cig: e.target.value
                    }
                })}
            />
            <NanoInput
                type="text"
                placeholder="Enter CUP..."
                required={false}
                show-inline-error="dirty"
                name="cup"
                value={cache.additional_information.cup}
                label="CUP (optional)"
                maxlength="15"
                onNanoChange={(e) => setCache({
                    ...cache, additional_information: {
                        ...cache.additional_information,
                        cup: e.target.value
                    }
                })}
            />
        </>
    )

}
