import React from 'react';
import useStoryblokGlobalNav from './useStoryblokGlobalNav';
import { isChinaStore } from '../../hooks/siteData';
import { NanoNavItem } from '@nanoporetech-digital/components-react';
import PromotionItems from './PromotionItems/promotionItems';
import TopItem from './TopItem';

export default function MegaDropDown() {
    const globalNav = useStoryblokGlobalNav();

    return (
        <>
            <OverflowSlot />
            <MainSlot menuPrimary={globalNav.menu_primary} />
            <PromotionSlot promoCards={globalNav.promo_cards} />
        </>
    );
}

function OverflowSlot() {
    return null;

    return (
        <NanoNavItem slot="overflow">
            <a onClick={() => {}}>Frequently asked questions</a>
        </NanoNavItem>
    );
}

function MainSlot({menuPrimary}) {
    return (
        <>
            <TopItem
                title="Products"
                menuPrimary={menuPrimary}
                config={{
                    cssColumnCount: 2,
                    cssColSpan: 1,
                    displayBlockSections: ['Discover nanopore sequencing', 'Explore products']
                }}
            />

            <TopItem
                title="Applications"
                menuPrimary={menuPrimary}
                config={{
                    cssColumnCount: 4,
                    cssColSpan: 2,
                    displayBlockSections: []
                }}
            />

            <TopItem title="Store" menuPrimary={menuPrimary} config={{selected: true}} />

            <TopItem
                title="Resources"
                menuPrimary={menuPrimary}
                config={{
                    cssColumnCount: 3,
                    cssColSpan: 1,
                    displayBlockSections: []
                }}
            />

            <TopItem title="Support" menuPrimary={menuPrimary} />

            <TopItem
                title="About"
                menuPrimary={menuPrimary}
                config={{
                    cssColumnCount: 6,
                    cssColSpan: 2,
                    displayBlockSections: ['Global partners', 'News & Events']
                }}
            />
        </>
    );
}

function PromotionSlot({promoCards}) {
    if (isChinaStore()) return null;

    return (
        <NanoNavItem slot="promotion">
            <PromotionItems promoCards={promoCards}/>
        </NanoNavItem>
    );
}
