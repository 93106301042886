import React from "react";
import { useWebStoreContext } from '../../../contexts/WebStoreContext';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as styles from './style.module.css';

/*
 * Readme: https://gitlab.com/catamphetamine/react-phone-number-input
 * Examples: https://catamphetamine.gitlab.io/react-phone-number-input/
 * CSS: https://gitlab.com/catamphetamine/react-phone-number-input/blob/master/style.css
 */
export default function UiTelephone({label, address, updateAddress}) {
    const { webstore } = useWebStoreContext();
    const geoIpCountry = (webstore.geoIpCountryIso?.length > 0) ? webstore.geoIpCountryIso : 'GB';
    const defaultCountry = address.country_code ?? geoIpCountry;
    const isError = address.telephone ? !isValidPhoneNumber(address.telephone): false;

    return (
        <div className={styles.telephone}>
            <label htmlFor="telephone">{label}</label>
            <div className={styles.phoneInputWrapper}>
                <PhoneInput
                    maxLength="40"
                    required={true}
                    name="telephone"
                    value={address.telephone}
                    onChange={newVal => updateAddress('telephone', newVal)}
                    defaultCountry={defaultCountry}
                />
            </div>
            <div className={styles.error}>
                {isError && <span>This telephone number appears to be invalid.</span>}
            </div>
        </div>
    );
}
