import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import find from 'lodash/find';

import Price from "../Ui/Price";
import UiPublicOrCustomPrice from "../Ui/UiPublicOrCustomPrice";

import VariantsSelect from './variantsSelect';
import InformationTabs from '../InformationTabs';

// import GET_PRODUCTS_BY_CATEGORY from './getProductsByCategory.graphql';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import AtcButton, { getConfigurableOptions } from "../AtcButton/atcButton";
import UiSelect from "../Ui/UiSelect";
import {shouldShowPrice, TogglePrice} from "../../hooks/siteData";
import TopActions from '../TopActions';
import ProductImage from "../Product/Image";
import UiMarkdown from "../Ui/UiMarkdown";
import {shouldAllowChemistrySwitch} from "../../hooks/productConfig";
import {isConfigurable} from "../../utils/product";
import { NanoGrid } from '@nanoporetech-digital/components-react';
import { getUiDate } from '../ShippingScheduler/helpers'
import Header from '../Header';


function getDisplayPrice(active, selectedVariant, getTierPrice)
{
    if (! shouldShowPrice(active.sku)) {
        return null;
    }

    //selectedVariant.product.price.regularPrice.amount
    if (selectedVariant)
    {
        if (! shouldShowPrice(selectedVariant.sku)) {
            return null;
        }

        const priceObject = selectedVariant.product.ont_custom_price ?? selectedVariant.product.price.regularPrice.amount;
        const displayPrice = getTierPrice(priceObject.value, parseInt(selectedVariant.size.label));

        return(
            <Price
                currencyCode={priceObject.currency}
                value={displayPrice}
            />
        );
    }

    const priceObject = {
        value: getTierPrice(active.price_range.minimum_price.final_price.value, 1),
        currency: active.price_range.minimum_price.final_price.currency
    }

    return(
        <>
            <TogglePrice sku={active.sku}>
                <small>From:</small><br/>
                <UiPublicOrCustomPrice
                    price={priceObject}
                    customPrice={active.ont_custom_price}
                />
                <style jsx>{`
                    small {font-size: 1rem;}
                `}</style>
            </TogglePrice>
        </>
    );
}

export default function ConfigurableFlowcellProduct({
    urlKey,
    product,
    siblingProducts
    // handleAddToCart,
    // isAddToCartDisabled,
}) {
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [active, setActive] = useState(product);

    const [selectedType, setSelectedType] = useState({
        label: product.configurable_options[0].values[0].label,
        value: product.configurable_options[0].values[0].value_index,
    });

    const productsByCategory = [product, ...siblingProducts];

    const { data: tierData } = useQuery(gql`
        query {
            getFlowcellTierPrices(sku: "${productsByCategory[0].sku}") {
                tier_unit_price
                tier_expiry
            }
        }
    `);

    const getTierPrice = (publicPrice, packQty) => {
        if (!tierData?.getFlowcellTierPrices?.tier_unit_price) return publicPrice;
        const tierPrice = tierData.getFlowcellTierPrices.tier_unit_price * packQty;
        return (tierPrice > publicPrice) ? publicPrice : tierPrice;
    }

    useEffect(
        () => {
            if(productsByCategory) {
                const productFromUrl = find(productsByCategory, type => type.url_key === urlKey);
                setActive(productFromUrl);
                setSelectedType({
                    label: productFromUrl.configurable_options[0].values[0].label,
                    value: productFromUrl.configurable_options[0].values[0].value_index,
                    info: getPackTypeInfoText(productFromUrl.configurable_options[0].values[0].label),
                });
            }
        },
        [product],
    );

    const {
        name,
        sku,
        short_description,
        ont_product_overview: information,
        ont_workflow: workflow,
        ont_apps_and_pubs: protocols,
        ont_safety_and_legal: safetyAndLegal,
        ont_whats_in_the_box: whatsInTheBox,
        ont_multiplex_detail: multiplexing,
        ont_party_materials: thirdPartyMaterials,
        ont_compatibility: compatibility,
        ont_image_url_1,
        ont_mask_sku,
    } = active;
    const packType = find(active.configurable_options, { attribute_code: 'ont_pack_type' });
    const packSize = find(active.configurable_options, { attribute_code: 'ont_pack_size' });

    const disableCallToAction = () => {
        if (typeof product === 'undefined' || typeof selectedVariant === 'undefined') return true;
        if (!product?.id || !selectedVariant?.product?.id) return true;
        return false;
    };
    const chemistrySiblings = productsByCategory.filter(product => {
        return isConfigurable(product) && product.ont_render_type === 'FLOWCELL';
    })

    function TierPricingText() {
        if (!tierData?.getFlowcellTierPrices?.tier_expiry?.length) return null;

        const displayDate = getUiDate(new Date(tierData.getFlowcellTierPrices.tier_expiry));
        return(
            <>
                <div>
                    <FontAwesomeIcon icon={faExclamationCircle} />&nbsp;
                    Special pricing effective until {displayDate}
                </div>
                <style jsx>{`
                    div { color: #17BB75; margin-bottom: 1rem; }
                `}</style>
            </>

        );
    }

    function getPackTypeInfoText(label) {
        switch(label) {
            case 'Top-up packs':
                return 'Dispatch in a single shipment (subject to availability)';
            case 'Annual packs':
                return 'To be used within 12 months';
        }
        return '';
    }

    function getChemistryTypeFromName(name) {
        const ct = name.match(/\((.*)\)/);
        return (ct && ct.length > 1) ? ct[1] : name;
    }

    return (
        <>
        <TopActions  black="black"  main="inner"/>

        <div className="contentWrapper">
        <NanoGrid
        l-cols="10"
        s-cols="1"
        xl-cols="10"
        class="header"
        >
        <div grid-states="xl-col-span-9 xl-col-start-1 xl-row-start-1 l-col-span-10 l-row-start-2">
                <Header categories={product.categories} title={name} sku={ont_mask_sku ?? sku} />
        </div>

        </NanoGrid>
        <NanoGrid
            l-cols="10"
            s-cols="1"
            xl-cols="10"
            class="header"
            style={{position: 'relative', zIndex: 1}}
            >
         <div grid-states="xl-col-span-9 xl-col-start-1 xl-row-start-2 l-col-span-8 l-row-start-2">
                <div className="container">
                    <ProductImage src={ont_image_url_1} />
                    <div className="details">
                        <div className="shortDescription">
                            <UiMarkdown source={short_description.html} />
                        </div>
                        {
                            shouldAllowChemistrySwitch(product.sku) && chemistrySiblings.length > 1 && (
                                <div className="select">
                                    <div className="label">Chemistry type:</div>
                                    <UiSelect
                                        id="chemistry-type-select"
                                        isSearchable={false}
                                        placeholder="Chemistry type ..."
                                        onChange={({value}) => {
                                            const newActiveProduct = find(chemistrySiblings, type => type.sku === value)
                                            setActive(newActiveProduct);
                                            setSelectedType({
                                                label: newActiveProduct.configurable_options[0].values[0].label,
                                                value: newActiveProduct.configurable_options[0].values[0].value_index,
                                            });
                                            setSelectedVariant(null);
                                        }}
                                        options={
                                            chemistrySiblings.map(({sku, name}) => ({
                                                value: sku,
                                                label: getChemistryTypeFromName(name),
                                            }))
                                        }
                                        value={{
                                            value: active.sku,
                                            label: getChemistryTypeFromName(active.name),
                                        }}
                                    />
                                </div>
                            )
                        }
                        {
                            packType && (
                                <div className="select">
                                    <div className="label">Pack type:</div>
                                    <UiSelect
                                        id="pack-type-select"
                                        isSearchable={false}
                                        placeholder=""
                                        onChange={type => {
                                            setSelectedType(type);
                                            setSelectedVariant(null);
                                        }}
                                        options={
                                            packType.values.map(({label, value_index}) => ({
                                                value: value_index,
                                                label: label,
                                                info: getPackTypeInfoText(label)
                                            }))
                                        }
                                        value={selectedType}
                                    />
                                    <div className="packTypeInfo">{selectedType.info}</div>
                                </div>
                            )
                        }
                        {
                            packSize && (
                              <VariantsSelect
                                id="pack-size-select"
                                active={active}
                                packSize={packSize}
                                setSelectedVariant={setSelectedVariant}
                                selectedVariant={selectedVariant}
                                selectedType={selectedType}
                                getTierPrice={getTierPrice}
                              />
                            )
                        }
                        <div className="price">
                            {getDisplayPrice(active, selectedVariant, getTierPrice)}
                        </div>
                        <TierPricingText />
                        <div className="cartActions">
                            {/*
                            <Button
                                className="button button--primary btn-cart"
                                priority="high"
                                onClick={handleAddToCart}
                                disabled={isAddToCartDisabled}
                            >
                                Add to basket
                                <FontAwesomeIcon icon={faShoppingCart} />
                            </Button>
                            */}
                            <AtcButton product={active} qty="1" options={getConfigurableOptions(selectedVariant)} isDisabled={disableCallToAction()} />
                        </div>
                    </div>
                </div>
        </div>
        </NanoGrid>
                <InformationTabs
                            information={information}
                            workflow={workflow}
                            protocols={protocols}
                            safetyAndLegal={safetyAndLegal}
                            whatsInTheBox={whatsInTheBox}
                            multiplexing={multiplexing}
                            thirdPartyMaterials={thirdPartyMaterials}
                            compatibility={compatibility}
                        />
            </div>


            <style jsx>{`

                .title {
                   display:flex;
                   flex-direction:column;

                }

                .headerInner{
                    display:flex;
                    flex-direction:row;
                    padding-top: .2rem;
                    flex-wrap: nowrap;
                }
                .container {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    gap: 2rem;
                    margin-bottom:1rem;
                }

                .details {
                  width:50%;
                }
                @media only screen and (max-width: 768px) {
                    .container {
                        flex-direction: column;
                    }
                    .details{
                        width:100%!important;
                        padding-top: 1rem;
                    }

                }

                .sku {
                    color: #455556;
                    font-size: 1rem;
                    font-weight: 100;
                    letter-spacing: 0.05rem;
                    line-height: 1.5rem;
                }
                .price {
                    color: #455556;
                    font-size: 1.6rem;
                    font-weight: normal;
                    margin-top: 1.6rem;
                    margin-bottom: 1rem;
                    letter-spacing: 0.5px;
                }
                .select {
                    margin: 0 0 1.5rem;
                    max-width: 320px;
                }
                .quantity {
                    display: inline-block;
                }
                .cartActions {
                    display: inline-block;
                    margin: 0;
                    margin-bottom: 53px;
                }
                .packsFrom {
                    margin: 20px 0px;
                }
                .variants :global(.option) {
                    background-color: transparent;
                    color: inherit;
                    cursor: default;
                    display: block;
                    font-size: inherit;
                    padding: 8px 12px;
                    width: 100%;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    -webkit-tap-highlight-color: rgba(0,0,0,0);
                    box-sizing: border-box;
                }
                .select .label {
                    color: #455556;
                    font-size: 1rem;
                    font-stretch: normal;
                    font-style: normal;
                    font-weight: bold;
                    height: 1.5rem;
                    letter-spacing: normal;
                    line-height: 1.5;
                    margin-bottom: .375rem;

                }
                .select :global(div[class*='control']) {
                    min-height: 3rem;
                }
                .packsFrom {
                    height: 38px;
                    font-size: 28px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.36;
                    letter-spacing: 0.5px;
                    color: #455556;
                }
                .packTypeInfo {
                    font-size: smaller;
                    font-style: italic;
                    margin: 0.5rem 0;
                }
            `}</style>
        </>
    );
}
