import React, { useReducer } from 'react';
import { useCartContext } from '../../../../contexts/CartContext';
import { useAdminCatalog, useAdminCartQuickAdd } from './useQuickAdd';
import Form from './form';
import { updateContextCart } from '../../../../hooks/useCart';
import * as styles from './style.module.css';

export default function QuickAdd({refetchCart}) {
    const { cart, setCart } = useCartContext();
    const { loading, error, data } = useAdminCatalog();
    const [quickAddMutation] = useAdminCartQuickAdd();

    const [formState, updateFormState] = useReducer(formStateUpdater, {
        sku_selections: [],
        qty: '',
        is_saving: false
    });

    const submit = () => formSubmitter(data?.adminCatalog?.product ?? [], quickAddMutation, formState, updateFormState, cart, setCart, refetchCart);

    if (loading) {
        return <div className={styles.quickAdd}>Loading products...</div>;
    }

    if (error) {
        return <div className={styles.quickAdd}>Unable to load products.</div>;
    }

    if (data?.adminCatalog?.product) {
        return <Form products={data.adminCatalog.product} formState={formState} updateFormState={updateFormState} submitForm={submit} />;
    }

    return null;
}

function formStateUpdater(state, action) {
    switch (action.type) {
        case 'saving':
            return {...state, is_saving: true};
        case 'reset':
            return {sku_selections: [], qty: '', is_saving: false};
        case 'stopsaving':
            return {...state, is_saving: false};
        case 'sku_selections':
            const sku_selections = action.payload;
            const noChange = (sku_selections.length && state.sku_selections.length && sku_selections[0] == state.sku_selections[0])
                             || (!sku_selections.length && !state.sku_selections.length);
            return (noChange) ? state : {...state, sku_selections};
        case 'qty':
            const qty = parseInt(action.payload);
            return (isNaN(qty))
                ? {...state, qty: ''}
                : {...state, qty};
    }
}

function formSubmitter(products, quickAddMutation, formState, updateFormState, cart, setCart, refetchCart) {
    updateFormState({type: 'saving'});

    const getParentSkuAndOptions = (sku) => {
        for (const parentProduct of products) {
            if (!parentProduct.variants) {
                continue;
            }

            for (const v of parentProduct.variants) {
                if (v.product.sku === sku) {
                    const selectedOptions = v.attributes.map(attr => attr.uid) ?? [];
                    return [parentProduct.sku, selectedOptions];
                }
            }
        }
        return [null, []];

    }

    const sku = formState.sku_selections.length ? formState.sku_selections[0] : null;

    if (!sku) {
        updateFormState({type: 'stopsaving'});
        return;
    }

    const quantity = isNaN(parseInt(formState.qty)) ? 1 : parseInt(formState.qty);
    const [parentSku, options] = getParentSkuAndOptions(sku);

    quickAddMutation({
        variables: {
            cartId: cart.id,
            items: [{
                data: {
                    sku: parentSku ?? sku,
                    quantity: quantity,
                    selected_options: options,
                    entered_options: []
                }
            }]
        }
    })
    .then(data => {
        refetchCart();
        updateContextCart(cart, setCart, data);
        updateFormState({type: 'reset'});
    })
    .catch(error => {
        console.log(error);
        updateFormState({type: 'stopsaving'});
    });
}
