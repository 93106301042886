import { useWebStoreContext } from "../../../contexts/WebStoreContext";
import { useCustomerContext } from "../../../contexts/CustomerContext";
import { useCartContext } from '../../../contexts/CartContext';
import { getSsoLogoutUrl } from "../../../hooks/useLoginLink";

export default function useLogout() {
    const { setWebstore } = useWebStoreContext();
    const { resetCustomer } = useCustomerContext();
    const { resetCart } = useCartContext();
    const ssoLogoutLink = getSsoLogoutUrl();

    return () => {
        setWebstore({});
        resetCustomer();
        resetCart();
        if (typeof window !== 'undefined') {
            window.location.href = ssoLogoutLink;
        }
    }
}
