import React from 'react';

// ideal for a product dropdown with name and sku
export function productSkuOption(option, state) {
    const isSelectOpen = state.context == 'menu';
    return (
        <div style={{display: 'flex', flexFlow: 'row wrap', alignItems: 'center'}}>
            <div>{option.label}&nbsp;</div>
            {isSelectOpen && <div style={{fontSize: 'x-small'}}>{option.sku}</div>}
        </div>
    );
}

