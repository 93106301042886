import useSiteData from '../../hooks/siteData';

export function getMenuByTitle(menuPrimary, title) {
    return menuPrimary.find((menu) => {
        const fieldToCheck = menu.title ?? menu.label ?? null;
        return fieldToCheck ? (fieldToCheck === title) : false;
    });
}

export function getCwsUrl() {
    const { home } = useSiteData();
    return home.startsWith('https') ? home : 'https://nanoporetech.com';
}
