import React, { useMemo } from 'react';
import UiMarkdown from "../../../Ui/UiMarkdown";
import {sectionStatus, sectionMain, sectionContent, item, title, details, image, description as descriptionClass} from './style.module.css';
import { OptionalStatus } from './sectionStatus';
import UiRadio from '../../../Ui/UiRadio';
import { NanoGrid } from '@nanoporetech-digital/components-react';
import LegacyAnnualService from "./legacyAnnualService";

export default function AnnualService(props) {
    const name = 'License and Warranty';
    const option = props.options.find(option => option.title===props.section.label);
    if (option.type === 'checkbox') {
        return <LegacyAnnualService {...props} />;
    }

    const defaultOption = useMemo(() => option.options.find(option => option.is_default === true), []);
    const cheapestOption = useMemo(() => option.options.reduce(
        (prev, curr) => prev.price < curr.price ? prev : curr), []
    );
    const selection = option.options[0];

    const updateSection = id => {
        if (!id) {
            return;
        }
        const selectedOption = option.options.find(o => o.id === id);
        const cache = {selectedId: id};
        const selections = [{id: option.option_id, quantity: selectedOption.quantity, value: [id.toString()]}];
        const items = [{
            name: name,
            custom_qty: cheapestOption.product.ont_entitlement_months
                ? cheapestOption.product.ont_entitlement_months + ' months'
                : '',
            surcharge: 0.00,
            type: 'included',
        }];

        const additionalItems = [];

        if (id !== cheapestOption.id) {
            const customQty = (selectedOption.product.ont_entitlement_months && cheapestOption.product.ont_entitlement_months)
                ? (selectedOption.product.ont_entitlement_months - cheapestOption.product.ont_entitlement_months) + ' months'
                : '';

            additionalItems.push({
                name: name,
                custom_qty: customQty,
                surcharge: selectedOption.price - cheapestOption.price,
                type: 'included',
            })
        }
        // const additionalItems = [{name: selectedOption.product.name, qty: 1, surcharge: 0.00}];

        props.updateSection({
            ...props.section,
            selections,
            items,
            additionalItems,
            cache,
        });
    }

    if (props.section.cache.selectedId === undefined) {
        updateSection(defaultOption.id);
    }

    return (
        <>
            <NanoGrid className={sectionStatus}>
                <OptionalStatus noun="Annual service" goNextStep={props.goNextStep} />
            </NanoGrid>
            <NanoGrid  l-cols="10"
            m-cols="10"
            s-cols="1"
            xl-cols="10"
            xxl-cols="10" className={sectionMain}>
                <div grid-states="xl-col-span-6 l-col-span-6 m-col-span-10" className={sectionContent}>
                    <Item
                        options={option.options.sort((a, b) => {return (a.position < b.position) ? -1 : 1})}
                        selectionItem={selection}
                        selectedId={props.section.cache.selectedId}
                        updateSection={updateSection}
                    />
                </div>
                {props.summary}
            </NanoGrid>
            </>

    );
}

function Item({options, selectionItem, selectedId, updateSection}) {
    const product = selectionItem.product;
    const description = product.short_description.html.replace(/<[^>]+>/g, '');

    const getEntitlementYears = (product) => {
        const months = product.ont_entitlement_months ?? 12;
        return months / 12;
    }

    const Radios = () => {
        return options.map(option => {
            return (
                <UiRadio
                    key={option.id}
                    name="as_id"
                    value={option.id}
                    defaultChecked={selectedId === option.id}
                    onClick={() => updateSection(option.id)}
                >
                    {getEntitlementYears(option.product)} year
                </UiRadio>
            );
        });
    }

    return (
        <div className={item}>
            <h2 className={title}>{product.name}</h2>
            <div className={details}>
                <div className={image}><img src={product.ont_image_url_1} /></div>
                <div className={descriptionClass}>
                    <div><UiMarkdown source={description} /></div>
                    <Radios />
                </div>
            </div>
        </div>
    );
}
