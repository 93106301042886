import React, { useState } from 'react';
import { ChoicesContextProvider } from './useChoicesContext';

export default function UiLinkedInputs(props) {
    const [sources] = useState(props.sources);

    return (
        <ChoicesContextProvider sources={sources} postHigher={props.updater}>
            {props.children}
        </ChoicesContextProvider>
    );
}

