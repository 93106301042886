import React, { useState } from 'react';
import BillingAddress from '../../../../Checkout/Address/billing';
import ShippingAddress from '../../../../Checkout/Address/shipping';
import ShippingSelect from '../../../../Checkout/Address/shippingSelect';
import useAddresses from '../../../../../hooks/useAddresses';
import { useQuotationContext } from '../../../../../contexts/QuotationContext';
import ShippingForm from '../../../../Form/ShippingForm';
import Navigate from '../navigate';
import { useCartContext } from '../../../../../contexts/CartContext';
import { useSetCustomerBillingAddress, useSetCustomerNewShippingAddress, useSetCustomerExistingShippingAddress, useSetShippingMethod, updateContextCart } from '../../../../../hooks/useCart';
import { autofill, validate } from '../../../../Form/validate';
import { frontendToBackendCartAddress } from '../../../helpers';
import * as styles from '../style.module.css';

export default function Customer({setMode, setActiveSection, status, setStatus}) {
    const [quotation, setQuotation] = useQuotationContext();
    const {cart, setCart} = useCartContext();
    const [setBillingMutation] = useSetCustomerBillingAddress(cart.id);
    const [setNewShippingMutation] = useSetCustomerNewShippingAddress(cart.id);
    const [setExistingShippingMutation] = useSetCustomerExistingShippingAddress(cart.id);
    const [setShippingMethodMutation] = useSetShippingMethod();
    const { billing, shipping, addressesLoaded } = useAddresses();
    const [newShipping, setNewShipping] = useState(quotation.shipping);
    const createNewShipping = quotation.shipping_address_selection==='_new';

    const changeSelection = (val) => setQuotation({...quotation, shipping_address_selection: val});

    function getShippingAddressIdFromCurrentSelection() {
        switch(quotation.shipping_address_selection) {
            case '': return shipping[0].id;
            case '_new': return null;
        }
        return parseInt(quotation.shipping_address_selection);
    }

    async function submit(e) {
        e.preventDefault();

        const saId = getShippingAddressIdFromCurrentSelection();
        const baId = parseInt(billing.id);
        const useForShipping = (baId && baId===saId) || Boolean(cart.is_virtual);
        if (!saId) {    // new address form
            if (autofill(newShipping, e.target, 'shipping_')) {
                setNewShipping({...newShipping});
            }
            if (!validate(e.target, 'shipping_')) return;
        }

        const updatedQuotation = {...quotation, shipping: newShipping, shipping_address_id: saId, use_for_shipping: useForShipping, loading: true};
        setQuotation(updatedQuotation);

        try {
            await setBillingMutation({variables: {cartId: cart.id, useForShipping: useForShipping, customerAddressId: baId}});
            if (!useForShipping) {
                if (saId) {
                    await setExistingShippingMutation({variables: {cartId: cart.id, customerAddressId: saId}})
                } else {
                    const backendAddress = frontendToBackendCartAddress(newShipping);
                    await setNewShippingMutation({variables: {cartId: cart.id, address: {...backendAddress, save_in_address_book: true}}});
                }
            }
            const responseData = await setShippingMethodMutation({ variables: { cartId: cart.id } })
            updateContextCart(cart, setCart, responseData);
            setStatus('');
            setQuotation({...updatedQuotation, loading: false});
            setActiveSection(2);
        } catch(err) {
            setStatus("Error saving addresses");
        }
    }

    function SubmitStatus() {
        return (status ? <p className={styles.status}>{status}</p> : null);
    }

    if (addressesLoaded) {
	    if (billing?.id) {
		    return (
				<form className={styles.sectionInner} onSubmit={e => submit(e)}>
					<div className={styles.customerAddresses}>
						<BillingAddress address={billing} />
						{!cart.is_virtual && <ShippingAddress addresses={shipping} shippingAddressId={quotation.shipping_address_selection} />}
					</div>
					{!cart.is_virtual && <ShippingSelect addresses={shipping} selection={quotation.shipping_address_selection} changeSelection={changeSelection} />}
					{createNewShipping && <ShippingForm formData={newShipping} setFormData={updatedShipping => setNewShipping(updatedShipping)} />}
					<Navigate setMode={setMode} allowBack={false} disable={quotation.loading} />
					<SubmitStatus />
				</form>
		    );
		} else {
			setMode('guest');
			return null;
		}
    }

	return <p>Loading...</p>;
}
