import React from 'react';
import { NanoIcon } from '@nanoporetech-digital/components-react';
import * as styles from './style.module.css';

export default function PromotionItems({promoCards}) {
    if (!promoCards) {
        return null;
    }

    return <>
        {promoCards.map((promoCard, i) => <PromoCard promoCard={promoCard} key={i} />)}
    </>;
}

function PromoCard({promoCard}) {
    if (!(promoCard && promoCard.link?.url && promoCard.link_label)) {
        return null;
    }

    return <div className={styles.promotionItems}>
        <div className={styles.advert}>
            <a className={styles.adHead} href={promoCard.link.url}>
                <div>{promoCard.title}</div>
                <div><NanoIcon name="light/chevron-right" role="img" aria-label="chevron right"/></div>
            </a>

            <div className={styles.adBody}>
                <div>
                    <NanoIcon name="light/calendar" role="img" aria-label="calendar"/>
                    <span>&nbsp;&nbsp;{promoCard.body}</span>
                </div>
            </div>
            <div className={styles.adCta}>
                <button type="button" className="button button--secondary"
                        onClick={() => window.location.href = promoCard.link.url}>
                    Register now
                </button>
            </div>
        </div>
    </div>;
}
