import React from 'react';
import UiMarkdown from '../../../../Ui/UiMarkdown';
import UiPublicOrCustomPrice from '../../../../Ui/UiPublicOrCustomPrice';
import { NanoCheckbox } from '@nanoporetech-digital/components-react';
import Quantity from '../../../../ProductQuantity';
import * as styles from './style.module.css';

export default function Product({product, choices, setChoices}) {
    const price = product.price_range.minimum_price.final_price;

    const Selector = () => {
        if (product.sku === 'LAB-FEE') {
            return <NanoCheckbox
                checked={!!choices[product.sku]}
                onNanoChange={e => setChoices({
                    ...choices,
                    [product.sku]: (e.target.checked ? 1 : 0)
                })}
            />;
        }
        return <Quantity value={choices[product.sku] ?? 0} onValueChange={qty => setChoices({...choices, [product.sku]: qty})} />
    }

    return (
        <div className={styles.product}>
            <div className={styles.productInfo}>
                <h3>{product.name}</h3>
                <UiMarkdown source={product.short_description.html} skipHtml />
            </div>
            <div className={styles.cartInfo}>
                <div>
                    <UiPublicOrCustomPrice price={price} customPrice={product.ont_custom_price} /> per year
                </div>
                <div className={styles.selector}>
                    <Selector />
                </div>
            </div>
        </div>
    );
}
