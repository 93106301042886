import React from 'react';
import * as styles from '../Sections/style.module.css';

export default function CheckoutPrevented() {
    return (
        <div className={styles.sectionContent}>
            <div>
                <h3>Checkout prevented</h3>
                <p>
                    For further information, please contact <a href={"mailto:support@nanoporetech.com"}>support@nanoporetech.com</a> quoting code K37PW.
                </p>
            </div>
        </div>
    );
}
