// extracted by mini-css-extract-plugin
export var checkoutNow = "style-module--checkoutNow--69f9e";
export var content = "style-module--content--886cf";
export var divider = "style-module--divider--d071f";
export var followUp = "style-module--followUp--24c56";
export var proceed = "style-module--proceed--574d0";
export var quoteCreate = "style-module--quoteCreate--ded39";
export var quoteSuccess = "style-module--quoteSuccess--2af5a";
export var recipients = "style-module--recipients--4174b";
export var sections = "style-module--sections--76426";
export var whatNext = "style-module--whatNext--0a158";