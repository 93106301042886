import React from 'react';
import { Link, navigate } from 'gatsby';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faBook, faNewspaper, faLifeRing, faDownload, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faClock, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import analytics from './analytics';
import * as styles from './style.module.css';

export default function Success(props) {
    const order = props?.location?.state?.order;

    if (order?.order_number) {
        analytics(order);
    } else if (typeof window !== 'undefined') {
        navigate('/404');
    }

    return(
        <div className={styles.success}>
            <div className={styles.proceed}>
                <Link to="/">
                    <FontAwesomeIcon icon={faChevronLeft} />&nbsp;&nbsp;Continue shopping
                </Link>
            </div>

            <h1>Thank you</h1>

            <h2><FontAwesomeIcon icon={faCheck} color="#16bb75" />&nbsp; We have received your order</h2>
            <p>Your order number is <strong>#{order?.order_number}</strong></p>
            <p>
                We have sent you an email confirmation.<br/>
                If the email has not arrived within 5 minutes, please check your spam folders.
            </p>

            <div className="info-banner">
                <div className="info-banner__content">
                    <FontAwesomeIcon icon={faClock} color="#0084a9" size="lg" />&nbsp; <span className="info-banner__heading">Order status</span>
                    <p className="info-banner__text">Your order is being processed by our operations team and we will notify you when your delivery has shipped.</p>
                </div>
            </div>

            <MyAccount {...props} />

            <h2>Welcome to the Nanopore Community</h2>
            <p>Thank you for placing your order with Oxford Nanopore Technologies.</p>

            <div className={styles.tiles}>
                <div className={styles.tile}>
                    <div><FontAwesomeIcon icon={faBook} color="#90c6e7" size="lg" /></div>
                    <div>
                        <strong>Knowledge centre</strong>
                        <br/>
                        <p>Access to protocols, guides and tutorials on using our technology.</p>
                    </div>
                </div>
                <div className={styles.tile}>
                    <div><FontAwesomeIcon icon={faNewspaper} color="#90c6e7" size="lg" /></div>
                    <div>
                        <strong>Community discussions</strong>
                        <br/>
                        <p>Interact with thousands of other nanopore sequencers from around the globe.</p>
                    </div>
                </div>
                <div className={styles.tile}>
                    <div><FontAwesomeIcon icon={faLifeRing} color="#90c6e7" size="lg" /></div>
                    <div>
                        <strong>Online support</strong>
                        <br/>
                        <p>Direct technical support assistance from our helpful team.</p>
                    </div>
                </div>
                <div className={styles.tile}>
                    <div><FontAwesomeIcon icon={faDownload} color="#90c6e7" size="lg" /></div>
                    <div>
                        <strong>Software updates</strong>
                        <br/>
                        <p>Download latest versions of sequencing and analysis software.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function MyAccount(props) {
    const goToMyAccount = () => window.location.href = 'https://myaccount.nanoporetech.com/account/orders';

    return (
        <div className={styles.myAccount}>
            <p>You can check the status of your order at any time by visiting the "My account" section.</p>
            <div>
                <button type="button" className="button button--primary" onClick={goToMyAccount}>
                    My account <FontAwesomeIcon icon={faChevronRight} size="sm" />
                </button>
            </div>
        </div>
    );
}
