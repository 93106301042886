import React from 'react';
import * as styles from './style.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import cx from 'classnames';
import {gql, useMutation} from "@apollo/client";
import {useCustomerContext} from "../../../contexts/CustomerContext";
import {useCartContext} from "../../../contexts/CartContext";

const SWITCH_TO_PERSONA = gql`
    mutation switchToPersona($id: Int!) {
        switchToCustomerPersona(
            personaId: $id
        ) {
            token
            messages {
                type
                title
                content
            }
        }
    }`;

export default function Persona({persona, activePersonaId, setActivePersonaId}) {
    const isActive = activePersonaId && persona.id === activePersonaId;

    const { customer, setCustomer } = useCustomerContext();
    const { resetCart } = useCartContext();

    const activeContent = () => {
        if (isActive) {
            return (
                <span>
                    Active
                    <br />
                    <FontAwesomeIcon icon={faCheck} />
                </span>
            );
        }
    };

    const [switchPersona] = useMutation(SWITCH_TO_PERSONA, {
        update: (proxy, mutationResult) => {
            if (mutationResult) {
                const token = mutationResult.data?.switchToCustomerPersona?.token;
                if (token) {
                    setCustomer({
                        ...customer,
                        token: token,
                        messages: mutationResult.data.switchToCustomerPersona.messages
                    });
                    resetCart();
                    setActivePersonaId(persona.id);
                    window.location.reload();
                }
            }
        },
        variables: {
            id: persona.id
        }
    });

    return(
        <li className={cx(styles.persona, isActive ? styles.active : null)}>
            <a href="#" onClick={switchPersona} className={styles.link}>
                <div className={styles.firstLine}>
                    <span className={styles.cnumber}>{persona.c_number}</span>
                    <span className={styles.use}><FontAwesomeIcon icon={faChevronRight} /> Use this account</span>
                </div>
                <div>{persona.organisation_name}</div>
                <div>{persona.name}</div>
            </a>
            <div className={styles.activeContent}>{activeContent()}</div>
        </li>
    );
}
