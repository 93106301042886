import { useStaticQuery, graphql } from 'gatsby';

export default function getShopEvents() {
    return( useStaticQuery(graphql`
        query getShopEvents {
            contentful {
                shopEventsCollection(order: [date_ASC]) {
                    items {
                        sys {
                            id
                        }
                        name
                        taxable
                        date
                        capacity
                        location
                        venue {
                            json
                        }
                        standalonePrice
                        seriesPrice
                        predecessor {
                        ... on Contentful_ShopEvents {
                                name
                            }
                        }
                    }
                }
            }
        }
    `));
}
