import React, { useState } from 'react';
import UiSelect from '../Ui/UiSelect';
import find from 'lodash/find';
import UiPublicOrCustomPrice from "../Ui/UiPublicOrCustomPrice";
import InformationTabs from '../InformationTabs';
import TopActions from '../TopActions';
import {TogglePrice} from "../../hooks/siteData";
import AddToCart from "../Product/AddToCart/addToCart";
import ProductImage from "../Product/Image";
import UiMarkdown from "../Ui/UiMarkdown";
import { NanoGrid } from '@nanoporetech-digital/components-react';
import Header from '../Header';


export default function BundleFamilyProduct({
    product,
    siblingProducts,
}) {
    const [selectedType, setSelectedType] = useState({
        label: product.name,
        value: product.sku,
    });
    const [active, setActive] = useState(product);
    const [qty, setQty] = useState(1);

    const allFamilyProducts = getAllFamilyProducts(product, siblingProducts);

    const {
        name,
        sku,
        short_description,
        ont_apps_and_pubs,
        ont_compatibility,
        ont_multiplex_detail,
        ont_party_materials,
        ont_product_overview,
        ont_safety_and_legal,
        ont_whats_in_the_box,
        ont_workflow,
        ont_image_url_1,
        ont_mask_sku,
    } = active;

    const price = active.price?.regularPrice?.amount ?? active.price_range?.maximum_price?.final_price;

    return (
        <>
          <TopActions  black="black"  main="inner"/>
        <div className="contentWrapper">
            <NanoGrid
                l-cols="10"
                s-cols="1"
                xl-cols="10"
                class="header"
                >
            <div grid-states="xl-col-span-9 xl-col-start-1 xl-row-start-1 l-col-span-10 l-row-start-2">
                <Header categories={product.categories} title={name} sku={ont_mask_sku ?? sku} />
                </div>
                </NanoGrid>
        <NanoGrid
            l-cols="10"
            s-cols="1"
            xl-cols="10"
            class="header"
            style={{position: 'relative', zIndex: 1}}
        >
         <div grid-states="xl-col-span-9 xl-col-start-1 xl-row-start-2 l-col-span-8 l-row-start-2">
                <div className="container">
                    <ProductImage src={ont_image_url_1} />
                    <div className="details">
                        <div className="shortDescription">
                            <UiMarkdown source={short_description.html} />
                        </div>
                        <div className="price">
                            <TogglePrice sku={product.sku}>
                                <UiPublicOrCustomPrice
                                    price={price}
                                    customPrice={active.ont_custom_price}
                                />
                            </TogglePrice>
                        </div>
                        <div className="select">
                            <UiSelect
                                onChange={({value}) => {
                                    const newActiveProduct = find(allFamilyProducts, type => type.sku === value)
                                    setActive(newActiveProduct);
                                    setSelectedType({
                                        label: newActiveProduct.name,
                                        value: newActiveProduct.sku,
                                    });
                                }}
                                options={
                                    allFamilyProducts.map(({sku, name}) => ({
                                        value: sku,
                                        label: name,
                                    }))
                                }
                                value={{
                                    value: active.sku,
                                    label: active.name,
                                }}
                            />
                        </div>
                        <AddToCart
                            product={active}
                            value={qty}
                            onValueChange={qty => {setQty(qty)}}
                        />
                    </div>
                </div>
                </div>
                </NanoGrid>

                <InformationTabs
                    information={ont_product_overview}
                    workflow={ont_workflow}
                    protocols={ont_apps_and_pubs}
                    safetyAndLegal={ont_safety_and_legal}
                    whatsInTheBox={ont_whats_in_the_box}
                    multiplexing={ont_multiplex_detail}
                    thirdPartyMaterials={ont_party_materials}
                    compatibility={ont_compatibility}
                />
            </div>
            <style jsx>{`

                .container {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    gap: 2rem;
                    margin-bottom:1rem;

                }
                .image {
                    margin: 0 auto;
                }
                .image > img {
                    max-height: 300px;
                }
                .details {
                    width:50%;
                  }
                  @media only screen and (max-width: 768px) {
                      .container {
                          flex-direction: column;
                      }
                      .details{
                          width:100%!important;
                          padding-top: 1rem;
                      }

                  }

                .sku {
                    color: #455556;
                    font-size: 1rem;
                    font-weight: 100;
                    letter-spacing: 0.05rem;
                    line-height: 1.5rem;
                }
                .price {
                    color: #455556;
                    font-size: 1.6rem;
                    font-weight: normal;
                    margin-top: 1.6rem;
                    margin-bottom: 1rem;
                    letter-spacing: 0.5px;
                }
                .select {
                    margin: 0 0 1.5rem;
                }
            `}</style>
        </>
    );
}

function getAllFamilyProducts(currentProduct, siblingProducts) {
    const all = [currentProduct, ...siblingProducts];
    return all.sort((a, b) => {
        const priceA = a.price?.regularPrice?.amount.value ?? a.price_range?.maximum_price?.final_price.value ?? 0;
        const priceB = b.price?.regularPrice?.amount.value ?? b.price_range?.maximum_price?.final_price.value ?? 0;
        return (priceA - priceB);
    });
}
