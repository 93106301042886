import React from 'react';
import {Helmet} from "react-helmet";
import StandardTop from '../../Product/Page/StandardTop';
import { useCustomerContext } from '../../../contexts/CustomerContext';
import LoggedOut from './LoggedOut';
import LoggedIn from './LoggedIn';
import InformationTabs from '../../InformationTabs';
import liveQuery from "../../../utils/liveQuery";
import GET_CERT_SUMMARY from "./LoggedIn/getOwnedDevicesAndCertifications.graphql";

export default function Certification(props) {
    const displayProduct = {
        name: 'Certification',
        short_description: props.products[0].description,
        ont_product_overview: props.products[0].ont_product_overview,
        ont_image_url_1: props.products[0].ont_image_url_1,
        ont_category_type: props.category.ont_category_type
    }

    return (
        <>
            <Helmet>
                <title>Nanopore store: {displayProduct.name}</title>
            </Helmet>
            <StandardTop category={props.category} product={displayProduct}>
                <CertificationChoices {...props} />
            </StandardTop>
            <div className="contentWrapper">
                <InformationTabs
                    information={displayProduct.ont_product_overview}
                />
            </div>
            <style jsx>{`
                /* Temporary workaround until InformationTabs can be refactored */
                .contentWrapper {
                    margin: 0rem 50px 2rem;;
                    max-width: 1440px;
                    padding-top: 3rem;
                }

                @media screen and (min-width: 1125px) {
                    .contentWrapper {
                        margin: 0 83px 2rem;
                    }
                }
            `}</style>
        </>
    );
}

function CertificationChoices(props) {
    const { customer } = useCustomerContext();

    if (customer.token) {
        const { data } = liveQuery(GET_CERT_SUMMARY);
        if (data?.getOwnedDevicesAndCertifications) {
            return <LoggedIn
                products={props.products}
                ownedDevicesAndCertifications={data?.getOwnedDevicesAndCertifications}
            />;
        }

    }
    return <LoggedOut {...props} />;
}
