import React, { useState } from 'react';
import { NanoSelect, NanoOption } from '@nanoporetech-digital/components-react';
import { useCheckoutContext } from "../../../../../contexts/CheckoutContext";
import { updateCachedTaxInfo, isUsTaxExemptCreditCard } from '../helpers';
import InfoText from './infoText';
import UploadCertificate from './uploadCertificate';
import WarningText from './warningText';
import BlockedText from './blockedText';
import Actions from './actions';

export default function SalestaxForm({taxSystem, sectionCache}) {
    const [checkout] = useCheckoutContext();
    const [showWarning, setShowWarning] = useState(false);
    const taxInfo = sectionCache.data.tax_info;
    const isCheckoutBlocked = isUsTaxExemptCreditCard(checkout, taxSystem, taxInfo);

    const setTextField = e => {
        sectionCache.set({
            ...sectionCache.data,
            tax_info: {
                [e.target.name]: e.target.value
            }
        });
        setShowWarning(true);
    };

    return (
        <div>
            <InfoText taxSystem={taxSystem} />
            <NanoSelect
                label={taxSystem.dropdown_text}
                name="tax_status"
                value={taxInfo?.tax_status}
                onNanoChange={e => setTextField(e)}
            >
                <NanoOption key="pay_tax" value="pay_tax" label="Add Sales Tax" />
                <NanoOption key="tax_exempt" value="tax_exempt" label="Sales Tax exempt" />
            </NanoSelect>
            <SalestaxOptions sectionCache={sectionCache} />
            <WarningText show={!isCheckoutBlocked && showWarning} taxSystem={taxSystem}>Are you sure you wish to change Sales Tax status?</WarningText>
            <BlockedText show={isCheckoutBlocked} />
            <Actions taxSystem={taxSystem} sectionCache={sectionCache} />
        </div>
    );
}

function SalestaxOptions({sectionCache}) {
    const taxInfo = sectionCache.data.tax_info;
    const taxStatus = taxInfo?.tax_status;
    const setUpload = file => updateCachedTaxInfo(sectionCache, 'certificate', file);

    return (taxStatus === 'tax_exempt')
        ? <UploadCertificate setUpload={setUpload} />
        : null;
}
