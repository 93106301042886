import React from 'react';
import Carousel from './Carousel';
import Header from '../../Header';
import * as styles from "./style.module.css";

export default function CatalogBanner({category, cmsBlocks}) {
    if (!category) return null;

    return (cmsBlocks?.header?.length)
        ? <Carousel category={category} cmsBlocks={cmsBlocks} />
        : <Banner category={category} />;
}

function Banner({category}) {
    const categoryClass = category?.name?.split(' ')[0].toLowerCase();

    return (
        <>
            <div className={styles.mainBanner + ' ' + styles[categoryClass]}>
                <Header category={category} title={category.name} isHome={false} isProductPage={false} />

                <div className={styles.containerImage}>
                    <img className={styles.bannerImage} alt={category.name} src={category.ont_image_url_1} />
                </div>

                <div />
            </div>
        </>
    );
}
