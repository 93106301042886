export default function analytics(order) {
    const data = {
        purchase_qty: order.purchase_qty,
        purchase_value: order.purchase_value,
        purchase_skus: order.purchase_skus,
        purchase_date_latest: order.purchase_date_latest,
        purchase_count: order.purchase_count,
        purchase_date_first: order.purchase_date_first
    }
    if (typeof window !== 'undefined' && window.analytics) {
        window.analytics.track('Purchase', data);
    }
}
