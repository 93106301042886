import { useQuery, useMutation, gql } from "@apollo/client";

export function useCartScheduler(id, skipFlag=false) {
    return useQuery(FULL_SCHEDULER, {
        variables: { cartId: id },
        fetchPolicy: 'network-only',
        skip: skipFlag,
    });
}

const FULL_SCHEDULER = gql`
    query ($cartId: String!) {
        shippingScheduleSetup(filter: {cart_id: {eq: $cartId}}) {
            first_available_date
            last_available_date
            first_common_date
            holidays
            cost_per_shipment
            shipping_days
            new_shipment_gap
            device_carriage
            schedule_rows {
                row_id
                item_id
                name
                sku
                is_shippable
                qty
                has_followers
                info_html
                split_max
                split_suggested
                split_suggested_gap
                has_children
                fad
                lad
                is_ship_first
                is_dc_device
                info
                contents
                sub_rows {
                    row_id
                    item_id
                    name
                    sku
                    is_shippable
                    qty
                    has_followers
                    info_html
                    split_max
                    split_suggested
                    split_suggested_gap
                    has_children
                    fad
                    lad
                    is_ship_first
                    is_dc_device
                    info
                }
            }
        }
    }
`;
