import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

export default function RegisterButton({product, isDisabled, mobile = ''}) {
    const buttonClick = () => {
        if (product.ont_cta_link) {
            location.href = product.ont_cta_link;
        }
    };

    return (
        <button className={`${mobile} button button--primary`} type="button" onClick={buttonClick} disabled={isDisabled}>
            <span>{product.ont_cta_button ?? 'Register to pre-order'} &nbsp;<FontAwesomeIcon icon={faChevronRight} size="sm" /></span>
        </button>
    );
}
