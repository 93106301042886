import React, { useEffect, useState } from 'react';
import useLocalStorage from "../../../utils/useLocalStorage";
import useGenerateCustomerTokenFromSsoCode from './useGenerateCustomerTokenFromSsoCode';
import useLogin, { resetCredentials } from '../hooks/useLogin';
import LoggingIn from '../Status/loggingIn';
import TokenError from '../Status/tokenError';
import CartError from '../Status/cartError';
import { getRedirectUrl } from '../helpers';

export default function Standard({code}) {
    const [authDestPath] = useLocalStorage('ont_auth_dest_path');
    const [customerTokenMutation, {called: customerTokenMutationCalled, data: customerTokenData, error: customerTokenError}] = useGenerateCustomerTokenFromSsoCode();
    const [cartError, setCartError] = useState({});
    const redirectUrl = getRedirectUrl(authDestPath);
    const newToken = customerTokenData?.generateCustomerTokenFromSsoCode?.token;
    const performLogin = useLogin(redirectUrl, setCartError, newToken);

    useEffect(() => addListenerToGlobalNav(), []);

    resetCredentials(!customerTokenMutationCalled);

    const addListenerToGlobalNav = () => {
        document.querySelector('#global-nav-main')
            .addEventListener('nanoIsReady', (e) => requestToken(e), {once: true});
    };

    const requestToken = (globalNavEvent) => {
        const countryCode = globalNavEvent.target.myAccountUser?.country_code ?? null;
        customerTokenMutation({variables: {ssoCode: code, countryCode: countryCode}}).then(data => {
            performLogin(data.data.generateCustomerTokenFromSsoCode, {});
        });
    }

    if (customerTokenError) {
        return <TokenError errorObject={customerTokenError} />
    }

    if (cartError && Object.keys(cartError).length > 0) {
        return <CartError errorObject={cartError} />
    }

    return <LoggingIn />;
}

