import React from 'react';
import { useTranslation } from 'react-i18next';
import { getUniqueFlowcellChoices } from './helpers';
import UiLinkedSelect from '../../../Ui/UiLinkedInputs/UiLinkedSelect';
import { productSkuOption } from '../../../Ui/UiSelect/optionFormats';

export default function SelectFlowcells(props) {
    const { t } = useTranslation();

    return (
        <div>
            {t("Select your flow cell type")}:
            <UiLinkedSelect {...props} optionFormat={productSkuOption} />
        </div>
    );
}

export function getFlowcellOptions(bundleProducts) {
   return getUniqueFlowcellChoices(bundleProducts);
}

