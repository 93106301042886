import React from 'react';
import { navigate } from 'gatsby';
import Retrieve from '../../components/Quotation/Retrieve';

export default function QuoteRetrievePage(props) {
    if (typeof window==='undefined') return null;

    const urlParams = new URLSearchParams(props.location.search);
    const quotationId = urlParams.get('quotationId');
    const dpCode = urlParams.get('dpCode');

    if (!quotationId || !dpCode) navigate('/404');

    return <Retrieve quotationId={quotationId} dpCode={dpCode} />;
}
