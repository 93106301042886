import React from 'react';
import { graphql } from 'gatsby';
import Catalog from '../components/Catalog'
import "../fragments/static/catalog";

export default function CatalogPage(props) {
    const { data, pageContext } = props;

    return <Catalog category={data.magento.categoryList[0]}
                    categories={pageContext.categories}
                    filterAttributes={pageContext.filterMeta.items}
                    location={props.location}
                    liveMode={false} />;
}

export const query = graphql`
    query($categoryId: String) {
        magento {
            categoryList(filters: { ids: { eq: $categoryId } }) {
                ...categoryContent
                children {
                    ...categoryContent
                }
            }
        }
    }
`;
