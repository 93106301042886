import React from "react";

export default function UiButton(props) {
    const {
        children,
        className,
        type="button",
        disabled,
        ...restProps
    } = props;

    return (
        <button
            className={className}
            type={type}
            disabled={disabled}
            {...restProps}
        >
            {children}
        </button>
    );
}
