import { useQuery, gql } from "@apollo/client";

export default function useCountries() {
    const { data } = useQuery(COUNTRIES);
    return data?.allowed_countries;
}

export function getCountryName(countries, id) {
    let found = null;
    if (countries?.length) {
        found = countries.find(country => country.id===id);
    }
    return found ? found.full_name_english : null;
}

export function getRegionsFromCountryCode(countries, countryCode) {
    const country = countries?.find(country => country.id===countryCode);
    return country?.available_regions ?? [];
}

export function getOntRegionsFromCountryCode(countries, countryCode) {
    const country = countries?.find(country => country.id===countryCode);
    const regions = country?.ont_available_regions ?? [];

    return regions.map(r => r.name)
        .sort();
}

export function getRegionFromCountryAndRegionCode(countries, countryCode, regionCode) {
    const regions = getRegionsFromCountryCode(countries, countryCode);
    return (regions.length) ? regions.find(region => region.code===regionCode) : null;
}

const COUNTRIES = gql`
    query {
        allowed_countries {
            id
            full_name_english
            available_regions {
                id
                code
                name
            }
            ont_available_regions {
                name
            }
        }
    }
`;
