import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import usePlaceQuickOrder from './usePlaceQuickOrder';
import OrderForm from '../OrderForm';
import Error from './error';

export default function OrderRouter() {

    const [placeOrderMutation, {called, data, error}] = usePlaceQuickOrder();

    const [requestData, setRequestData] = useState({
        order_file: null,
        po_file: null,
        identifier: "",
        captcha: "",
    });

    const submitter = {
        isSubmitting: called,
        placeOrder: (payload) => placeOrderMutation({variables: {orderData: payload}})
    };

    if (data) {
        navigate('/checkout/success', {state: {order: data.placeQuickOrder.order}});
        return <p><FontAwesomeIcon icon={faSpinner} size="lg" spin /></p>;
    }

    if (error) {
        return <Error errorObject={error} />;
    }

    return (
        <OrderForm formData={requestData} setFormData={setRequestData} submitter={submitter} />
    );
}
