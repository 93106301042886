import React from 'react';
import salesQr from './SalesONT.png';
import { shouldShowQrCodes } from "../../hooks/siteData";
import * as styles from './style.module.css';

export default function QrCode({isLight}) {
    if (!shouldShowQrCodes()) {
        return null;
    }

    return(
        <div className={styles.qrCode}>
            <div className={styles.qrSection}>
                <div className={styles.qrImage}><img src={salesQr} /></div>
                <div className={isLight ? styles.light : null}>WeChat: Sales</div>
            </div>
        </div>
    );
}
