import React, {createContext, useContext, useState} from 'react';

const defaultConfig = {
    flowcell: '',
    kit: '',
    enhancedPack: false,
    rapidStart: false,
}

const MinionContext = createContext(defaultConfig);

export function MinionContextProvider({children, surcharges, bundleProducts}) {

    const [minionConfig, setMinionConfig] = useState(defaultConfig);

    return (
        <MinionContext.Provider value={{minionConfig, setMinionConfig, surcharges, bundleProducts}}>
            {children}
        </MinionContext.Provider>
    );
}

export const useMinionContext = () => useContext(MinionContext);
