import React from 'react';
import UiButton from '../../Ui/UiButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';

export default function Error({errorObject}) {
    return (
        <div>
            <h4>We have been unable to place your order.</h4>
            <p style={{marginBottom: "2rem"}}>
                <code>{errorObject?.message}</code><br />
                {errorObject?.graphQLErrors?.length && <code>{errorObject?.graphQLErrors[0].debugMessage}</code>}
            </p>
            <UiButton className="button button--primary" onClick={() => window.location.reload()}>
                <FontAwesomeIcon icon={faChevronLeft} /> Start again
            </UiButton>
        </div>
    );
}
