import React, { useEffect, useState } from 'react';
import { useCartContext } from '../../../contexts/CartContext';
import { useCustomerContext } from '../../../contexts/CustomerContext';
import { useGetCartMeta } from './MetaPanel/useCartMeta';
import MetaPanel from './MetaPanel';
import QuickAdd from './QuickAdd';

export default function AdminSection({refetchCart}) {
    const { customer } = useCustomerContext();
    if (!customer.is_admin_mode) return null;

    const { cart } = useCartContext();
    const [reveal, setReveal] = useState(true);
    const [metaData, setMetaData] = useState({});
    const [saveStatus, setSaveStatus] = useState('');
    const { data } = useGetCartMeta(cart.id);
    const loadedMetaData = data?.getCartMeta;

    useEffect(() => {
        if (loadedMetaData && !Object.keys(metaData).length) {
            setMetaData({
                ont_origin_code: loadedMetaData.ont_origin_code,
                ont_inco_terms: loadedMetaData.ont_inco_terms,
                ont_sales_tag: loadedMetaData.ont_sales_tag,
                override_increment_id: loadedMetaData.override_increment_id,
                ont_admin_send_email: loadedMetaData.ont_admin_send_email
            });
        }
    }, [loadedMetaData]);

    return (
        <div className="info-banner info-banner--warning" style={{paddingBottom: '1.25rem'}}>
            <MetaPanel state={{reveal, setReveal, metaData, setMetaData, saveStatus, setSaveStatus}} />
            <QuickAdd refetchCart={refetchCart} />
        </div>
    );
}
