import React from 'react';
import { NanoCheckbox } from '@nanoporetech-digital/components-react';
import Price from '../../../Ui/Price';

export default function SurchargeCheckbox({name, minionConfig, setMinionConfig, surcharges, children}) {
    return (
        <NanoCheckbox
            name={name}
            checked={minionConfig[name]}
            onNanoChange={e => setMinionConfig({...minionConfig, [e.target.name]: e.target.checked})}
        >
            {children} (+<Price currencyCode={surcharges[name].currency} value={surcharges[name].value} />)
        </NanoCheckbox>
    );
}
