import React from 'react';
import CatalogSearch from '../CatalogSearch';
import CatalogFilters from '../CatalogFilters';
import * as styles from './style.module.css';

export default function CatalogControls({category, textFilter, setTextFilter, filterAttributes, filters, setFilters}) {
    if (['sequencing-kits', 'sample-prep', 'expansion-packs', 'voltrax'].includes(category.url_key)) {
        return (
            <form className={styles.catalogControls} onSubmit={e => e.preventDefault()}>
                <CatalogSearch textFilter={textFilter} setTextFilter={setTextFilter} />
                <CatalogFilters
                    category={category}
                    attributes={filterAttributes}
                    filters={filters}
                    setFilters={setFilters}
                />
            </form>
        );
    }

    return null;
}
