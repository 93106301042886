import React from 'react';
import { isActive, filterAndSort } from './helpers';
import NavLink from './navLink';

// either a clickable link or a list of clickable links, the first of which is a "parent" category
export default function SubLevel({parentCategory, activeCategory}) {

    // the isActive here is only to decide whether or not to reveal children of parentCategory
    if (isActive(parentCategory, activeCategory.id) && parentCategory.is_anchor && parentCategory.children) {
        const sortedChildren = filterAndSort(parentCategory.children);
        return (
            <>
                <NavLink category={parentCategory} isActive={parentCategory.id === activeCategory.id} />
                {sortedChildren.map(child =>
                    <NavLink key={child.url_key} category={child} hasParent={true} isActive={child.id === activeCategory.id} />
                )}
            </>
        );
    }

    return <NavLink category={parentCategory} hasParent={false} isActive={parentCategory.id === activeCategory.id} />;
}
