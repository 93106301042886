import React from 'react';
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronRight, faShoppingBasket} from "@fortawesome/pro-light-svg-icons";
import {minicart, image, actions, contents, name, added} from './style.module.css';

export default function MiniCart({product, qty, cart}) {
    let formatter = Intl.NumberFormat('en-US', {style: "currency", currency: cart.subtotal.currency});

    return(
        <div className={minicart} data-cy="minicart">
            <div className={image}>
                <img src={product.ont_image_url_1} />
            </div>
            <div className={contents}>
                <div>
                    <div className={name}>{qty}x {product.name}</div>
                    <div className={added}><FontAwesomeIcon icon={faCheck} /> Added to basket</div>
                </div>
                <div>
                    <FontAwesomeIcon icon={faShoppingBasket} />
                    <span> Subtotal </span>
                    <strong>{formatter.format(cart.subtotal.value)}</strong>
                </div>
            </div>
            <div className={actions}>
                <div>
                    <Link className="button button--secondary button--keyline" to="/checkout">
                        <span>Checkout</span>
                    </Link>
                </div>
                <div>
                    <Link className="button button--secondary" to="/cart">
                        <span>View basket <FontAwesomeIcon icon={faChevronRight} size="sm" /></span>
                    </Link>
                </div>
            </div>
        </div>
    );
}
