import React, { useState } from 'react';
import UiMarkdown from "../../../Ui/UiMarkdown";
import UiCheckbox from "../../../Ui/UiCheckbox";
import * as styles from "./style.module.css";
import getShopEvents from "./getShopEvents";
import LocationFilter from './locationFilter';
import EventList from './eventList';
import TopActions from '../../../TopActions';
import ProductImage from "../../Image";
import AtcButton from '../../../AtcButton';
import { NanoGrid } from '@nanoporetech-digital/components-react';
import RedeemToken from './redeemToken';
import Header from '../../../Header';

export default function Workshop({ product, token=null }) {
    const {contentful:{shopEventsCollection:{items : events}}} = getShopEvents();
    const [showSoldout, setShowSoldout] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [atcFirst, atcAdditional] = getAtcOptions(product, selectedEvents);
    const [openTokenModal, setOpenTokenModal] = useState(false);

    const {
        name,
        sku,
        price,
        ont_custom_price = null,
        description,
        short_description,
        ont_image_url_1,
        ont_mask_sku
    } = product;

    function BuyOrReserve() {
        if (token) {
            return (
                <button
                    className="button button--primary btn-cart"
                    type="button"
                    onClick={() => setOpenTokenModal(true)}
                    disabled={!selectedEvents.length}>
                        Reserve your place
                </button>
            );
        }

        return <AtcButton product={product} qty={1} isDisabled={!atcFirst.length} options={atcFirst} additionalProducts={atcAdditional} />;
    }

    return (
        <>
            <TopActions black="black"  main="inner"/>
            <RedeemToken events={selectedEvents} open={openTokenModal} setOpenTokenModal={setOpenTokenModal} token={token} />
            <form className="contentWrapper">
                <NanoGrid l-cols="1" s-cols="1" xl-cols="10">
                    <div grid-states="xl-col-span-5 xl-col-start-1 xl-row-start-1">
                        <Header categories={product.categories}  title={name} sku={ont_mask_sku ?? sku}></Header>
                    </div>

                    <div grid-states="xl-col-span-9  xl-col-start-1 xl-row-start-2">
                        <div  className="innerContent">
                            <ProductImage src={ont_image_url_1} />
                            <div className="details">
                                <div>
                                    <UiMarkdown source={short_description.html} />
                                </div>
                                <BuyOrReserve />
                            </div>
                        </div>
                    </div>
                </NanoGrid>

                <NanoGrid l-cols="1" s-cols="1" xl-cols="10" style={{position: 'relative', zIndex: 1}}>
                    <div grid-states="xl-col-span-9 xl-col-start-1 xl-row-start-1 l-col-span-1 l-row-start-1">
                        <div className="formControls">
                            <div className={styles.locations}>
                                <label>Filter:</label>
                                <LocationFilter events={events}
                                                selectedLocation={selectedLocation}
                                                setSelectedLocation={setSelectedLocation} />
                            </div>
                            <div>
                                <UiCheckbox id="events-filter"
                                            name="checkbox-events"
                                            checked={showSoldout}
                                            onChange={setShowSoldout}>
                                    Show sold out events
                                </UiCheckbox>
                            </div>
                            {/* <div>
                            <TogglePrice sku={sku}>
                                <>
                                    Discount: Buy 2 consecutive dates and save $250
                                </>
                            </TogglePrice>
                            </div> */}
                        </div>
                        <EventList
                            events={events}
                            price={ont_custom_price ?? price.regularPrice.amount}
                            showSoldout={showSoldout}
                            selectedLocation={selectedLocation}
                            selectedEvents={selectedEvents}
                            setSelectedEvents={setSelectedEvents}
                            showPrice={!token}
                        />
                    </div>
                </NanoGrid>
                <NanoGrid l-cols="1" s-cols="1" xl-cols="10">
                    <div grid-states="xl-col-span-9 xl-col-start-1 xl-row-start-1 l-col-span-1 l-row-start-1">
                        <div className="workshopDescription">
                            <UiMarkdown source={description.html} />
                        </div>
                    </div>
                </NanoGrid>
            </form>
            <style jsx>{`
                .mainForm {
                    padding:1rem 3rem;
                    margin-top: 2rem;
                }

                .formControls {
                    display:flex;
                    flex-direction:row;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1rem;
                    padding-top:2rem;
                }

                @media only screen and (max-width: 768px){
                    .formControls {
                        flex-direction:column;
                        align-items: flex-start;
                        gap:1rem;
                    }
                    .details {
                        width:100%!important;

                    }
                    .innerContent {
                        flex-direction:column;
                        flex-wrap: wrap;
                        padding-right:1.5rem;
                    }
                }

                .innerContent {
                    display:flex;
                    flex-direction:row;
                    gap:2rem;
                }

                .details {
                    width:50%;
                }

                .productTitle {
                    z-index: 0;
                }

                .workshopDescription {
                    border-top: solid 2px #0084a9;
                    margin-top: 2rem;
                    padding-top: 1rem;
                }
            `}</style>
        </>
    );
}

function getAtcOptions(product, selectedEvents) {
    const firstEvent = [];
    const additionalEvents = [];

    const uid = product.options?.reduce((acc, curr) => {
        if (curr.title=='event_id') return curr.value.uid;
    }, null);

    if (uid) {
        selectedEvents.forEach((event, i) => {
            if (i==0) {
                firstEvent.push({uid: uid, value: event.sys.id});
            } else {
                additionalEvents.push({
                    product: product,
                    qty: 1,
                    options: {uid: uid, value: event.sys.id}
                });
            }
        });
    }

    return [firstEvent, additionalEvents];
}
