import React, {useState} from 'react';
import { Form } from 'informed';
import UiPublicOrCustomPrice from "../Ui/UiPublicOrCustomPrice";
import InformationTabs from '../InformationTabs';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import TopActions from "../TopActions";
import {TogglePrice} from "../../hooks/siteData";
import AddToCart from "../Product/AddToCart/addToCart";
import ProductImage from "../Product/Image";
import UiMarkdown from "../Ui/UiMarkdown";
import { NanoGrid } from '@nanoporetech-digital/components-react';
import Header from '../Header';

const BlockquoteRenderer = props => {
    return(
        <>
            {
                props.children.map((child, i) => (
                    <div key={i}>
                        <strong>
                            <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                            <span>{child.props.children[0].props.children}</span>
                        </strong>
                    </div>
                ))
            }
            <style jsx>{`
                div {
                    color: #0084a9;
                    }
            `}</style>
        </>
    );
};

export default function SimpleProduct({
      product,
      handleSetQuantity,
      handleAddToCart,
      isAddToCartDisabled,
      currencyCode,
      value,
    }) {
    const {
        name,
        sku,
        price,
        short_description,
        ont_product_overview,
        ont_workflow,
        ont_apps_and_pubs,
        ont_safety_and_legal,
        ont_whats_in_the_box,
        ont_multiplex_details,
        ont_party_materials,
        ont_compatibility,
        ont_image_url_1,
        ont_mask_sku,
    } = product;



    // const breadcrumbCategoryId = product.categories[1].id;

    // const breadcrumbs = breadcrumbCategoryId ? (
    //     <Breadcrumbs
    //         categoryId={breadcrumbCategoryId}
    //         currentProduct={product.name}
    //     />
    // ) : null;

    const breadcrumbs = null;

    const [qty, setQty] = useState(1);

    return (
        <>
         <TopActions className="innerPage" black="black"  main="inner"/>
        <div className="contentWrapper">
            <NanoGrid
            l-cols="10"
            s-cols="1"
            xl-cols="10"
            class="header"
            >
        <div grid-states="xl-col-span-5 xl-col-start-1 xl-row-start-1 l-col-span-8 l-row-start-1">
        {breadcrumbs}
         </div>
        </NanoGrid>
            <Form className="formContainer">
            <div className="formContainer">
            <NanoGrid
            l-cols="10"
            s-cols="1"
            xl-cols="10"
            class="header"
             >
            <div grid-states="xl-col-span-9 xl-col-start-1 xl-row-start-1 l-col-span-8 l-row-start-1">
                <Header categories={product.categories} title={name} sku={ont_mask_sku ?? sku} />
                </div>
                </NanoGrid>
                <NanoGrid
                l-cols="10"
                s-cols="1"
                xl-cols="10"
                class="header"
             >
                <div grid-states="xl-col-span-9 xl-col-start-1 xl-row-start-1 l-col-span-8 l-row-start-1">
                <div className="formContent">
                    <ProductImage src={ont_image_url_1} />
                    <div className="details">
                        <div className="shortDescription">
                            <UiMarkdown source={short_description.html} />
                        </div>
                        <div className="price">
                            <TogglePrice sku={product.sku}>
                                <UiPublicOrCustomPrice
                                    price={price.regularPrice.amount}
                                    customPrice={product.ont_custom_price}
                                />
                            </TogglePrice>
                        </div>
                        <AddToCart
                            product={product}
                            value={qty}
                            onValueChange={qty => {setQty(qty)}}
                        />
                    </div>
                </div>
                </div>
                </NanoGrid>
                <InformationTabs
                    information={ont_product_overview}
                    workflow={ont_workflow}
                    protocols={ont_apps_and_pubs}
                    safetyAndLegal={ont_safety_and_legal}
                    whatsInTheBox={ont_whats_in_the_box}
                    multiplexing={ont_multiplex_details}
                    thirdPartyMaterials={ont_party_materials}
                    compatibility={ont_compatibility}
                />
                 </div>
            </Form>
           </div>
            <style jsx>{`

            @media only screen and (max-width: 768px) {

                .title {
                    flex-direction: column!important;
                    align-items: flex-start!important;

                }

                .image{
                    background-position: center;
                }

                .formContent {
                    flex-direction:column!important;
                    padding: 0!important;
                    gap:0!important;
                }

                .details{
                    width:100%!important;
                    padding-top: 1rem;
                }
            }

            .headerInner{
                display: inline-flex;
                flex-wrap: nowrap;
                padding-top: .2rem;
                // gap: 12px;
            }
            .formContainer{
                display:flex;
                flex-direction:column;
                // padding: 1rem;
            }
            .formContent{
                display: flex;
                margin-bottom: 1rem;
                flex-wrap: nowrap;
                flex-direction:row;
                padding: 2rem 1rem;
                gap: 1rem;
            }

                .title {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    // gap: 1rem;
                    flex-direction: column;
                    flex-wrap: nowrap;
                }
                .image {
                    margin: 3rem auto;
                }
                .image > img {
                    max-height: 340px;
                }
                .details {
                  width:50%;
                //   margin-top: auto;
                }
                .productName {
                    color: #455556;
                    font-weight: 600;
                    font-size: 1.75rem;
                    line-height: 2.6rem;
                    margin-bottom: 0;
                    font-size:2rem;
                }
                .sku {
                    color: #455556;
                    font-size: 1rem;
                    font-weight: 100;
                    letter-spacing: 0.05rem;
                    line-height: 1.5rem;
                }
                .price {
                    color: #455556;
                    font-size: 1.6rem;
                    font-weight: normal;
                    margin-top: 1.6rem;
                    margin-bottom: 1rem;
                    letter-spacing: 0.5px;
                }
                .shortDescription :global(h1) {
                    margin-top: 0;
                }
            `}</style>
            </>

    );

}
