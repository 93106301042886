import React from 'react';
import Configurator from "./Configurator";
import Certification from "./Certification";
import Minion from "./Minion";

export default function Category({category}) {
    const products = category.products.items;

    switch (category.ont_category_type) {
        case 'CONFIGURATOR':
            return <Configurator products={products} category={category} />;
        case 'CERTIFICATION':
            return <Certification products={products} category={category} />;
        case 'MINION':
            return <Minion category={category} />;
    }

    return null;
}
