import React from 'react';
import { getCwsUrl } from '../helpers';

export default function Link({item}) {
    const linkUrl = item.link.url;
    const useUrl = linkUrl.startsWith('/') ? getCwsUrl() + linkUrl : linkUrl;

    return (
        <a href={useUrl}>
            {item.label}
            {item.sub_text && <small>{item.sub_text}</small>}
        </a>
    );
}
