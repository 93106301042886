import React from 'react';
import { MinionContextProvider } from './useMinionContext';
import { NanoGrid } from '@nanoporetech-digital/components-react';
import ImageBox from '../ImageBox';
import SellBox from '../SellBox';
import WhatsInTheBox from '../WhatsInTheBox';
import ProductInformation from '../ProductInformation';
import { getSurcharges } from './helpers';

export default function Configurator({category}) {
    const surcharges = getSurcharges(category);
    const bundleProducts = category.products.items;

    return (
        <MinionContextProvider surcharges={surcharges} bundleProducts={bundleProducts}>
            <div>
                <NanoGrid sCols={1} mCols={1} lCols={2} xlCols={2} xxlCols={2}>
                    <ImageBox category={category} />
                    <SellBox category={category} />
                </NanoGrid>
                <WhatsInTheBox />
                <ProductInformation category={category} />
            </div>
        </MinionContextProvider>
    );
}

