import React, {useState} from "react";

import Modal from '../../Modal';
import {isDistributorOnly, isDistributorOptional} from "../../../utils/webStore";
import {useWebStoreContext} from "../../../contexts/WebStoreContext";

export default function DistributorModal({isOpen, modalCountryIso, setModalIsOpen}) {
    const {webstore, setWebstore} = useWebStoreContext();

    const redirectToDistributorsPage = countryIso => {
        const url = `https://nanoporetech.com/about/channel-partners/country/` + countryIso.toLowerCase()
        window.location.replace(url)
    }

    return <Modal
        open={isOpen}
        setter={() => setModalIsOpen(false)}
        hideClosers={false}
        message={
            <>
            <h2>Channel Partners are available in your territory</h2>
            <div>
                {isDistributorOptional(modalCountryIso) &&
                    <div>
                        <p>To continue to purchase directly with us please click continue. Alternatively, please click
                            the link below to see available distributors.</p>
                        <p>
                            <button className="button button--primary" onClick={() => {
                                setWebstore({...webstore, designatedStore: "default"});
                                setModalIsOpen(false);
                            }}>Continue
                            </button>
                            &nbsp;
                            <button className="button button--primary" onClick={() => {
                                redirectToDistributorsPage(modalCountryIso);
                            }}>Available distributors
                            </button>
                        </p>
                    </div>
                }

                {isDistributorOnly(modalCountryIso) &&
                    <div>
                        <p>Please click the link below to identify the Channel Partner who will be your primary source for purchasing Oxford Nanopore Technologies products and solutions in your territory. Clicking the link below will include information of their areas of expertise and their contact details to help guide you though your project.</p>
                        <p>
                            <button className="button button--primary" onClick={() => {
                                redirectToDistributorsPage(modalCountryIso)
                            }}>Channel Partners in your territory
                            </button>
                        </p>
                    </div>
                }
            </div>
            </>
        }
     />
}
