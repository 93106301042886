import React from 'react';
import * as styles from './style.module.css';

export default function BlockedText({show}) {
    if (!show) return null;

    return (
        <div className={styles.blockedText}>
            <div className="info-banner info-banner--warning">
                <div className="info-banner__content">
                    <p className="info-banner__label">Tax exemption review</p>
                    <p className="info-banner__text">Click Continue to submit your certificate.</p>
                    <p className="info-banner__text">Your tax exemption certificate will then need to be reviewed. Please return in <strong>2 working days</strong> to complete your order, which will then calculate the correct tax value. Your basket & checkout details are now recorded for your later use.</p>
                    <p className="info-banner__text">Thank you.</p>
                </div>
            </div>
        </div>
    );
}
