import React from 'react';
import * as styles from '../style.module.css';

export default function ShipmentItems({shipmentItems, schedule, setSchedule, rowId}) {

    function updateQty(shipmentItem, qty, inputKey) {
        const intQty = parseInt(qty);
        if (isNaN(intQty)) {
            shipmentItem.qty = 0;
        } else {
            shipmentItem.qty = intQty;
        }
        setSchedule({...schedule, focus: inputKey, interacted: true});
    }

    const sortedItems = shipmentItems.sort((a,b) => (a.date < b.date) ? -1 : 1);
    return sortedItems.map(si => {
        const inputKey = rowId + '_' + si.date;
        return <ShipmentItem key={inputKey} inputKey={inputKey} shipmentItem={si} updateQty={updateQty} schedule={schedule} />
    });
}

function ShipmentItem({shipmentItem, updateQty, inputKey, schedule}) {
    return (
        <>
            <td className={styles.shipmentItemSpacer}>&nbsp;</td>
            <td className={styles.shipmentItem}>
                <div>
                    <input
                        type="text"
                        value={shipmentItem.qty}
                        onChange={(e) => updateQty(shipmentItem, e.target.value, inputKey)}
                        autoFocus={inputKey===schedule.focus}
                    />
                </div>
            </td>
        </>
    );
}
