import React from 'react';

export default function MaintenancePage() {
    return (
        <>
            <div className="content">
                <h1>Under maintenance</h1>
                <p>Our store is currently undergoing a scheduled upgrade and will be back up and running soon.</p>
                <p>For any urgent enquiries please contact <a href="mailto:support@nanoporetech.com">support@nanoporetech.com</a></p>
            </div>
            <style jsx>{`
                .content {
                    margin: 4rem;
                    max-width: 1440px;
                    min-height: 640px;
                }
            `}</style>
        </>

    );
}
