// extracted by mini-css-extract-plugin
export var addRecipient = "style-module--addRecipient--eff92";
export var changeLink = "style-module--changeLink--65b0d";
export var createCta = "style-module--createCta--8250d";
export var cta = "style-module--cta--80914";
export var customerAddresses = "style-module--customerAddresses--6050b";
export var divider = "style-module--divider--d55df";
export var notifications = "style-module--notifications--76f3e";
export var or = "style-module--or--e20ef";
export var recipient = "style-module--recipient--794df";
export var section = "style-module--section--2e54e";
export var sectionInner = "style-module--sectionInner--1ea1a";
export var status = "style-module--status--1720e";
export var stepNumber = "style-module--stepNumber--cb208";
export var welcomeContent = "style-module--welcomeContent--c705e";