import React from 'react';
import SectionButton from './button'
import { menuCta } from './style.module.css';

export default function MenuCta({section, config}) {
    if (!section) return null;

    const { cssColSpan = 1 } = config;
    const gridStates = `s-col-span-1, l-col-span-${cssColSpan}`;

    return (
        <div className={menuCta} grid-states={gridStates}>
            <h2>{section.title}</h2>
            <p>{section.body}</p>
            <SectionButton item={section} />
        </div>
    );
}

