import { useQuery, gql } from "@apollo/client";

export default function useBillingInfo() {
    return useQuery(BILLING_INFO, {fetchPolicy: 'no-cache'});
}

const BILLING_INFO = gql`
    query {
        getBillingInfo {
            status
            c_number
            email_invoice_to
            finance_email
			finance_firstname
			finance_lastname
            has_registered_on_my_account
            billing {
                id
                firstname
                lastname
                company
                street
                city
                region {
                    region
                    region_id
                    region_code
                }
                postcode
                country_code
                telephone
            }
        }
    }
`;
