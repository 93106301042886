import React from 'react';
import { useCheckoutContext } from '../../../contexts/CheckoutContext';
import Billing from './Billing';
import PaymentMethod from './PaymentMethod';
import ShippingAddress from './ShippingAddress';
import ShippingSchedule from './ShippingSchedule';
import QuotationShipping from './QuotationShipping';
import Declarations from './Declarations';
import Terms from './Terms';
import PaymentDetails from './PaymentDetails';
import QuoteReview from './Review/quoteReview';
import OrderReview from './Review/orderReview';
import Tax from './Tax';

export default function Sections({cartData}) {
    const [checkout] = useCheckoutContext();
    let step = 0;
    const isVirtual = cartData.is_virtual;
    const showQuestions = doesCartContainsFlowcells(cartData);
    const isQuotation = checkout.redeemQuote ?? false;
    const isMyAccountPayment = checkout.payment_method?.selected_payment_method === 'ont_my_account'

    return(
        <div>
            {!isQuotation && <Billing step={++step} cartData={cartData} />}
            <PaymentMethod step={++step} cartData={cartData} />
            {!isQuotation && <Tax step={++step} cartData={cartData} />}
            {!isVirtual && !isQuotation && <ShippingAddress step={++step} cartData={cartData} />}
            {!isVirtual && !isQuotation && <ShippingSchedule step={++step} cartData={cartData}/>}
            {!isVirtual && isQuotation && <QuotationShipping step={++step} cartData={cartData}/>}
            {showQuestions && <Declarations step={++step} />}
            {!isMyAccountPayment && <PaymentDetails step={++step} code="payment_details" />}
            <Terms step={++step} code="terms" cartData={cartData} />
            <Review step={++step} code="review" cartData={cartData} isQuotation={isQuotation} />
        </div>
    );
}

function Review(props) {
    return (props.isQuotation) ? <QuoteReview {...props} /> : <OrderReview {...props} />;
}

function doesCartContainsFlowcells(cartData) {
    for (const item of cartData.items) {
        if (item.product.sku.includes('FLO')) return true;
        if (item.bundle_options?.length) {
            for (const option of item.bundle_options) {
                for (const selection of option.values) {
                    if (selection.label.toLowerCase().includes('single flow cell')) return true;
                }
            }
        }
    }

    return cartData.contains_flowcells ?? false;
}
