import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faSpinner } from '@fortawesome/pro-light-svg-icons';
import UiButton from '../../Ui/UiButton';
import { useCheckoutContext } from '../../../contexts/CheckoutContext';
import * as styles from './style.module.css';
import translate from "../../../utils/translate";

export default function SectionSubmit({callback, disable=false}) {
    const [checkout, setCheckout] = useCheckoutContext();
    const [disabled, setDisabled] = useState(false);

    return(
        <div className={styles.sectionSubmit}>
            <UiButton
            disabled={disable || disabled}
            type="button"
            className="button button--secondary"
            onClick={() => submit(checkout, setCheckout, callback, setDisabled)}>
                <ButtonContent disabled={disabled} />
            </UiButton>
            {checkout.submitStatus
                && <div className={styles.submitStatus}>{processSubmitStatus(checkout.submitStatus)}</div>
            }
        </div>
    );
}

function ButtonContent({disabled}) {
    if (disabled) {
        return <FontAwesomeIcon icon={faSpinner} spin />
    }

    return (
        <span>
            Confirm &nbsp;&nbsp; <FontAwesomeIcon icon={faChevronRight} size="sm" />
        </span>
    );
}

function processSubmitStatus(submitStatus) {
    return translate(submitStatus);
}

async function submit(checkout, setCheckout, callback, setDisabled) {
    setDisabled(true);
    try {
        await callback();
    } catch(error) {
        setDisabled(false);
        setCheckout({
            ...checkout,
            submitStatus: getErrorMessageFromObject(error),
        });
    }
}

function getErrorMessageFromObject(err) {
    if (typeof err === 'string') {
        return err;
    }

    if (err.graphQLErrors?.length && err.graphQLErrors[0].debugMessage) {
        return 'Error: ' + err.graphQLErrors[0].debugMessage;
    }

    return err.message;
}
