import React from "react";
import { getMyAccountEnvironment } from '../../../../../hooks/useEnvironment';
import * as styles from './style.module.css';

export default function InvAccNote({billingInfo}) {
    if (billingInfo.status == 'invacc') {
        const myAccountEnv = getMyAccountEnvironment();

        return (
            <p className={styles.invAccNote}>
                You are purchasing on behalf of <strong>{billingInfo.c_number}</strong>. However, we will invoice <strong>your account</strong> for this transaction.
                If you wish to change the billing details we hold for you, please visit <a href={`https://${myAccountEnv}.nanoporetech.com/account/billing_and_tax`}>My Account</a>. Any changes will be subject to approval before your order is shipped.
            </p>
        );
    }

    return false;
}
