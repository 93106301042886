import React, { forwardRef, useMemo } from 'react';
import {getUiDate, getUtcDate, isShippingDay, getHolidayDates, getOriginalDates, getExcludeDates} from '../../helpers';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShippingFast } from '@fortawesome/pro-light-svg-icons';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as styles from '../style.module.css';

export default function Shipments({schedule, openDates, changeDate}) {
    const excludeDates = useMemo(() => getExcludeDates(schedule, openDates), [schedule]);

    return schedule.shipments.map((shipment,i) =>
        <Shipment
            key={shipment.date}
            index={i}
            shipment={shipment}
            excludeDates={excludeDates}
            openDates={openDates}
            changeDate={changeDate}
            schedule={schedule}
        />
    );
}

function Shipment({shipment, index, excludeDates, openDates, changeDate, schedule}) {
    const CustomInput = forwardRef(DateButton);
    const fad = getUtcDate(shipment.fad);
    const lad = getUtcDate(shipment.lad);

    function Contents() {
        return shipment.shipment_items.map((item,i) => <Item key={shipment.date + '_' + i} item={item} />)
    }

    function Item({item}) {
        return <li>{item.qty}x {item.name}</li>
    }

    function DateButton({ value, onClick }, ref) {
        return <button className="button button--primary" onClick={onClick} ref={ref}>{value}</button>;
    }

    function DateLine() {
        if (shipment.status=='Processing') {
            return(
                <div className={styles.shipmentDate}>
                    Estimated dispatch date:&nbsp;&nbsp;
                    <DatePicker
                        selected={openDates[shipment.date]}
                        onChange={date => changeDate(shipment.date, date)}
                        dateFormat="dd MMM yyyy"
                        customInput={<CustomInput />}
                        filterDate={date => isShippingDay(schedule, date)}
                        excludeDates={excludeDates}
                        minDate={fad}
                        maxDate={lad}
                    />
                </div>
            );
        }

        return <p>Dispatch date: {getUiDate(getUtcDate(shipment.date))}</p>;
    }

    return(
        <div className={styles.orderShipment}>
            <div className={styles.statusRow}>
                <h4><FontAwesomeIcon icon={faShippingFast} color="#0184aa" />&nbsp;&nbsp;Shipment #{index+1}</h4>
                <div className={styles.status}>Status: {shipment.status}</div>
            </div>
            <DateLine />
            <ul><Contents /></ul>
        </div>
    );
}
