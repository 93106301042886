import React from "react";
import VatForm from "./vatForm";
import SalestaxForm from "./salestaxForm";

export default function TaxInfo(props) {
    switch (props.taxSystem.tax_system_code) {
        case 'vat':
            return <VatForm {...props} />;
        case 'salestax':
            return <SalestaxForm {...props} />;
    }

    return <p>Unable to determine tax system.</p>;
}

