import {useCustomerContext} from "../../../contexts/CustomerContext";

export function getCheapestFlowcellInCategory(category) {
    const cheapest = {
        configurable: null,
        price_each: {
            value: null,
            currency: null
        }
    }

    const setCheapest = (configurable, unitPrice, currency) => {
        if (!cheapest.price_each.value || cheapest.price_each.value > unitPrice) {
            cheapest.configurable = configurable;
            cheapest.price_each = {
                value: unitPrice,
                currency: currency
            }
        }
    }

    const getUnitPrice = (attributes, price, flowcellsPerPack) => {
        const packSize = attributes.reduce((size, attribute) => {
            if (attribute.code == 'ont_pack_size') return parseInt(attribute.label);
        }, 0);
        return (packSize > 0) ? (price / packSize / flowcellsPerPack) : 0;
    }

    category.products.items.forEach(configurable => {
        const flowcellsPerPack = (configurable.sku.includes('FLO-PRO')) ? 4 : 1;
        if (configurable.variants) {
            configurable.variants.forEach(simple => {
                const {value, currency} = simple.product.price_range.minimum_price.final_price;
                const unitPrice = getUnitPrice(simple.attributes, value, flowcellsPerPack);
                setCheapest(configurable, unitPrice, currency);
            });
        } else {
            const {value, currency} = configurable.price_range.minimum_price.final_price;
            setCheapest(configurable, value, currency);
        }
    });

    return cheapest;
}

export const getProductUrlPath = product => {
    const {customer} = useCustomerContext();
    return (customer.has_extra_permission || customer.has_custom_pricing)
	? `/productDetail/?id=${product.url_key}` 
	: `/${product.url_key}.html`
}

