import React, { useState } from 'react';
import Modal from '../../../Modal';
import { format } from "date-fns";
import { useMutation, gql } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function RedeemToken({events, open, setOpenTokenModal, token}) {
    const [redeemMutation, { called, data, error }] = useMutation(REDEEM_MUTATION);

    function submit() {
        if (!events.length) return;
        const eventId = events[0].sys.id;
        redeemMutation({variables: { eventToken: token, eventId: eventId }});
    }

    return (
        <Modal
            title="Confirm your reservation"
            message={<Message token={token} events={events} submit={submit} submitted={called} result={data} error={error}/>}
            open={open}
            setter={() => setOpenTokenModal(false)}
        />
    );
}

function Message({token, events, submit, submitted, result, error}) {
    if (!events.length) return null;
    const event = events[0];

    if (result || error) {
        return (result?.redeemEventToken?.success)
            ? <p>Thank you. Your place has been reserved.</p>
            : <p>Sorry, your request was unsuccessful. Please contact customer services quoting workshop token <code>{token}</code>.</p>;
    }

    function ButtonContent() {
        return (submitted)
            ? <FontAwesomeIcon icon={faSpinner} spin />
            : <span>Confirm</span>;
    }

    return (
        <div>
            <p>You have selected: {event.name} on <EventDate event={event} /></p>
            <button type="button" className="button button--secondary" style={{minWidth: "6rem"}} onClick={submit}><ButtonContent /></button>
        </div>
    );
}

function EventDate({event}) {
    const date = new Date(event.date);
    const formatted = format(date, "E, do MMMM, y");
    return <span>{formatted}</span>;
};

const REDEEM_MUTATION = gql`
mutation ($eventToken: String!, $eventId: String!) {
    redeemEventToken(
        eventToken: $eventToken
        eventId: $eventId
    ) {
        success
    }
}
`;
