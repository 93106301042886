import React from 'react';
import { useCheckoutContext } from '../../../../../contexts/CheckoutContext';

export default function InfoText({taxSystem}) {
    return (
        <div>
            <div className="info-banner info-banner--caution">
                <div className="info-banner__content">
                    <span className="info-banner__label">Information</span>
                    <p className="info-banner__text">{taxSystem.info_text}</p>
                    <PoText taxSystem={taxSystem} />
                </div>
            </div>
        </div>
    );
}

function PoText({taxSystem}) {
    const [checkout] = useCheckoutContext();

    if (checkout.payment_method?.selected_payment_method === 'purchaseorder') {
        return (
            <>
                <p className="info-banner__heading">Paying by Purchase Order?</p>
                <p className="info-banner__text">{taxSystem.info_text_po}</p>
            </>
        );
    }

    return null;
}
