import React from 'react';
import { navigate } from 'gatsby';
import { useCustomerContext } from '../../../contexts/CustomerContext';
import { getLabel } from '../../../hooks/siteData';
import { getUiQuotationRef } from '../helpers';

export default function InvalidStore(props) {
    const { customer, setCustomer } = useCustomerContext();

    function switchStore() {
        setCustomer({...customer, intended_store_view: ''});
        navigate('/countrySelect/');
    }

    return (customer.token) ? <LoggedIn {...props} /> : <LoggedOut {...props} link={switchStore} />;
}

function LoggedIn({quotationId, designatedStore}) {
    return <p>Your buying store is currently set to {getLabel(designatedStore)}. 
        However, quotation {getUiQuotationRef(quotationId)} was created in a different store. 
        Please either obtain a new quotation in the current store or if you believe this store is incorrect, please contact Customer Services to update your buying account.</p>;
}

function LoggedOut({quotationId, link, designatedStore}) {
    return <p>You are currently in our {getLabel(designatedStore)} store. 
        However, quotation {getUiQuotationRef(quotationId)} was created in a different currency store. 
        Please <a onClick={link} style={{cursor:"pointer"}}>click here to change stores</a>. Once you are in the correct store please try again to redeem your quotation.</p>;
}
