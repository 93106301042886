import React from 'react';
import { isChinaStore, isStaticJapanStore } from "../../hooks/siteData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

export default function EnquireButton({product, isDisabled, mobile = ''}) {
    const { t } = useTranslation();

    const buttonClick = () => {
        if (product.ont_cta_link) {
            location.href = product.ont_cta_link;
        } else {
            if (isChinaStore()) {
                location.href="https://nanoporetech.net/contact";
            } else if (isStaticJapanStore()) {
                location.href="https://nanoporetech.com/jp/dealers";
            } else {
                location.href='mailto:store@nanoporetech.com?subject=Enquiry for ' + product.name + ' ' + product.sku;
            }
        }
    };

    return (
        <button className={`${mobile} button button--primary`} type="button" onClick={buttonClick} disabled={isDisabled}>
            <span>{product.ont_cta_button ?? t('Enquire')} &nbsp;<FontAwesomeIcon icon={faChevronRight} size="sm" /></span>
        </button>
    );
}
