import React from 'react';
import "./src/assets/ont-styleguide/styleguide.css";
import Layout from './src/components/Layout';
export { wrapRootElement } from './src/apollo/wrap-root-element';
import * as ReactDOM from 'react-dom/client';

export function wrapPageElement({ element, props }) {
    return <Layout {...props}>{element}</Layout>;
}

export function onClientEntry() {
    if (process.env.GATSBY_ENABLE_MAINTENANCE_MODE === 'true' && !window.location.pathname.includes('/maintenance')) {
        window.location = '/maintenance/';
    }
}

export function onRouteUpdate() {
    if (process.env.GATSBY_ENABLE_MAINTENANCE_MODE === 'true' && !window.location.pathname.includes('/maintenance')) {
        window.location = '/maintenance/';
    }
}

export function onInitialClientRender() {
    if (typeof window === 'undefined') return;
    function OptanonWrapper() { }
}

export const replaceHydrateFunction = () => {
    return (element, container) => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}
