import { getSiteKey, isMainSite } from "../../../../hooks/siteData";
import { getStoreFromCountryIso } from "../../../../utils/webStore";

export function getBillingInfoWithNewValue(billingInfo, key, value) {
    return {...billingInfo, [key]: value};
}

export function getBillingInfoWithNewAddressValue(billingInfo, key, value) {
    return {
        ...billingInfo,
        billing: {
            ...billingInfo.billing,
            [key]: value
        }
    };
}

export function backendToFrontendAddress(backendAddress) {
    return {
        firstname: backendAddress?.firstname,
        lastname: backendAddress?.lastname,
        telephone: backendAddress?.telephone,
        country_code: backendAddress?.country_code,
        company: backendAddress?.company ?? '',
        street: backendAddress?.street ?? '',
        city: backendAddress?.city ?? '',
        postcode: backendAddress?.postcode ?? '',
        region_id: backendAddress?.region?.region_id ?? 0,
        region: backendAddress?.region?.region
    };
}

export function frontendToBackendAddress(frontendAddress) {
    return {
        firstname: frontendAddress?.firstname,
        lastname: frontendAddress?.lastname,
        telephone: frontendAddress?.telephone,
        country_code: frontendAddress?.country_code,
        company: frontendAddress?.company,
        street: frontendAddress?.street,
        city: frontendAddress?.city,
        postcode: frontendAddress?.postcode,
        region: {
            region_id: frontendAddress?.region_id ?? 0,
            region: frontendAddress?.region
        }
    };
}

export function isStoreSwitchNeeded(billingInfo) {
    if (!isMainSite()) return false;
    if (['myaccount', 'invacc'].includes(billingInfo.status)) return false;

    const currentStore = getSiteKey();
    const requiredStore = getStoreForBillingCountry(billingInfo);

    return (requiredStore && currentStore !== requiredStore);
}

export function getStoreForBillingCountry(billingInfo) {
    const billingIso2 = billingInfo.billing?.country_code ?? null;
    return billingIso2 ? getStoreFromCountryIso(billingIso2) : null;
}
