export function getUniqueKitChoices(bundleProducts) {
    const choices = {};
    bundleProducts.forEach(bundleProduct => {
        const option = getFirstKitOptionFromBundleProduct(bundleProduct);
        addOptionOptionsToChoices(option, choices);
    });
    return Object.values(choices);
}

function getFirstKitOptionFromBundleProduct(product) {
    const options = product.items ?? [];
    return options.find(option => option.title.startsWith('Kit'));
}

function addOptionOptionsToChoices(option, choices) {
    option?.options?.forEach(kit => {
        choices[kit.product.sku] = {
            label: kit.label,
            value: kit.product.sku,
            filter: kit.product.ont_chemistry_type,
            sku: kit.product.ont_mask_sku ?? kit.product.sku
        };
    });
}
