import React from 'react';
import { NanoCheckbox } from '@nanoporetech-digital/components-react';
import * as styles from '../../style.module.css';

export default function FlowcellReturns({settings}) {
    const {cache, setCache} = settings;

    function update(newValue) {
        // bug in onNanoChange? gets called twice once with old value, once with new
        if (newValue !== cache.ont_fc_returns) {
            setCache({
                ...cache,
                ont_fc_returns: newValue,
            });
        }
    }

    function Radio({label}) {
        const isChecked = (cache.ont_fc_returns && cache.ont_fc_returns===label) ?? false;

        return(
            <li>
                <NanoCheckbox
                    key={label}
                    type="radio"
                    label={label}
                    name="ont_fc_returns"
                    value={label}
                    checked={isChecked}
                    onNanoChange={(e) => update(e.target.value)}
                />
            </li>
        );
    }

    return(
        <div>
            <h3>Please choose an option:</h3>
            <ul className={styles.ul}>
                <Radio label="I intend to use only purified DNA/RNA when using my MinION" />
                <Radio label="I intend to use other types of sample that may include serum, blood or other hazards" />
            </ul>
        </div>
    );
}
