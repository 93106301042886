import React from "react";
import {description, resultsClass} from './style.module.css';

export default function LoqateResults({results, performRetrieve}) {
    if (!results.length) return null;

    const LoqateItem = ({item}) => {
        if (item.Type === 'Address') {
            return (
                <li onClick={() => performRetrieve(item.Id)}>
                    <span>{item.Text}</span>&nbsp;
                    <span className={description}>{item.Description}</span>
                </li>
            );
        }

        return null;
    };

    return (
        <div className={resultsClass}>
            <ul>{results.map(item => <LoqateItem key={item.Id} item={item} />)}</ul>
        </div>
    );
}
