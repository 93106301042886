import { useStaticQuery, graphql } from 'gatsby';

export default function useChemistryType() {
    const data = useStaticQuery(graphql`
        query globalNav {
            customApi {
                story {
                    content {
                        global_nav {
                            promo_cards {
                                component
                                title
                                link_label
                                body
                                link {
                                    url
                                }
                            }

                            menu_primary {
                                component
                                label
                                link {
                                    url
                                }
                                title
                                items {
                                    component
                                    title
                                    body
                                    description
                                    featured
                                    link_label
                                    link {
                                        url
                                    }
                                    items {
                                        label
                                        sub_text
                                        link {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return data?.customApi?.story?.content?.global_nav[0] ?? {};
}
