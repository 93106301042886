import { useCustomerContext } from '../../../contexts/CustomerContext';
import { useCartContext } from '../../../contexts/CartContext';
import { useEffect } from 'react';
import { useLazyCustomerCart, useMergeCarts } from '../../../hooks/useCart';
import { navigate } from 'gatsby';

export default function useCartLoader(redirectUrl, setCartError) {
    const { customer } = useCustomerContext();
    const { cart, updateCart } = useCartContext();
    const [customerCartQuery, {called: customerCartQueryCalled, data: customerCartData, error: customerCartError}] = useLazyCustomerCart();
    const [mergeCartsMutation] = useMergeCarts();

    useEffect(() => {
        if (customerCartData?.customerCart) {
            const customerCart = customerCartData.customerCart;
            if (isCartMergeNeeded(cart.id, customerCart.id)) {
                console.log('Cart merge needed');
                mergeCarts(cart, customerCart, mergeCartsMutation, updateCart);
            } else {
                console.log('Updating context cart with loaded data');
                updateCart(customerCart);
            }
        }

        if (customerCartError) {
            setCartError(customerCartError);
        }
	}, [customerCartData, customerCartError]);

    useEffect(() => {
        const customerCartId = customerCartData?.customerCart?.id;
        if (typeof window !== 'undefined' && cart.id && cart.id == customerCartId) {
            console.log('Redirecting after login', redirectUrl);
            if (redirectUrl.startsWith('https')) {
                window.location.replace(redirectUrl);
            } else {
                navigate(redirectUrl); // will not force a store change
            }
        }
	}, [cart]);

    return () => {
        if (!customerCartQueryCalled && customer.token) {
            console.log('Requesting customer cart, from token', customer.token);
            customerCartQuery();
        }
    }
}

function mergeCarts(guestCart, customerCart, mergeCartsMutation, updateCart) {
    mergeCartsMutation({
        variables: {
            guestCartId: guestCart.id, 
            customerCartId: customerCart.id
        }
    })
    .then(data => updateCart(data.data.mergeCarts))
    .catch(error => updateCart(customerCart));
}

function isCartMergeNeeded(guestCartId, customerCartId) {
    return (guestCartId?.length && customerCartId?.length && guestCartId != customerCartId);
}

