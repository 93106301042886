import React from 'react';
import useSiteData from '../../hooks/siteData';
import { useCustomerContext } from '../../contexts/CustomerContext'
import { getLowestCategory, buildTrail } from './helpers';
import Crumb from './crumb';
import * as styles from './style.module.css';

export default function Breadcrumbs(props) {
    const { customer } = useCustomerContext();
    const { home } = useSiteData();
    const category = props.category || getLowestCategory(props.categories);
    const isProductPage = props.categories?.length > 0 || isSpecialCategory(category);
    const trail = buildTrail(category, home, customer.has_extra_permission || customer.has_custom_pricing, isProductPage);
    const crumbsClass = isProductPage ? styles.breadcrumbs : `${styles.breadcrumbs} ${styles.catalog}`;

    // TODO - review why we are doing this
    if (typeof window !== 'undefined' && trail.length) {
        window.ontBackButtonUrl = trail[trail.length-1].url;
    }

    return (
        <div className={crumbsClass}>
            {trail.map((crumb, i) => <Crumb key={i} crumb={crumb} />)}
        </div>
    );
}

function isSpecialCategory(category) {
    return Boolean(category && ['CONFIGURATOR', 'CERTIFICATION', 'MINION'].includes(category.ont_category_type));
 }
