import React from 'react';
import { navigate } from 'gatsby';
import { useCustomerContext, isExpired } from '../../contexts/CustomerContext';
import { NanoGlobalNav, NanoGlobalSearchResults } from '@nanoporetech-digital/components-react';
import MegaDropDown from '../MegaDropDown';
import Footer from "../Footer";
import * as styles from "./style.module.css";
import cx from 'classnames';
import PersonaSwitcher from "./PersonaSwitcher/personaSwitcher";
import { useCartContext } from '../../contexts/CartContext';
import useLoginLink, { getReAuthenticateUrl, getCartUrl } from '../../hooks/useLoginLink';
import { isProduction } from '../../hooks/useEnvironment';
import useSiteData, { isLoginRequired, isTransactional } from '../../hooks/siteData';
import Mavenoid, { loadMavenoid } from './mavenoid';

export default function GlobalNav({children}) {
    const { customer } = useCustomerContext();
    const {cart} = useCartContext();
    const cartCount = cart?.items?.length ?? 0;
    const { home } = useSiteData();
    const reAuthLink = getReAuthenticateUrl();
    const loginLink = useLoginLink();
    const cartLink = getCartUrl();
    const env = isProduction() ? 'prod' : 'test';

    function globalNavReady(e) {
        if (typeof window !== 'undefined') {
            const user = e.target.myAccountUser?.id?.length ? e.target.myAccountUser : null;
            forceLoginIfNeeded(customer, user, loginLink);
            loadSegmentAnalytics(user);
            loadMavenoid(user);
            updateCartCookie(cartCount);
            configureTopSections(e.target);
        }
    }

    return(
        <NanoGlobalNav
        id="global-nav-main"
        className={customer.token ? 'loggedIn' : ''}
        logo-url={home}
        env={env}
        search-index="Store"
        show-search={(isTransactional() && !isLoginRequired()) ? 'true' : 'false'}
        cart-url={cartLink}
        cartCount={cartCount}
        sso-redirect={reAuthLink}
        onNanoIsReady={e => globalNavReady(e)}>
            <Mavenoid />
            <MegaDropDown />
            <PersonaSwitcher />
            <NanoGlobalSearchResults>
                <main className={cx("site-main", styles.siteMain)}>
                    <div className={cx("site-main__content", styles.siteMain__content)}>{children}</div>
                </main>
                <Footer />
            </NanoGlobalSearchResults>
        </NanoGlobalNav>
    );
}

function forceLoginIfNeeded(contextCustomer, user, loginLink) {
    if (contextCustomer.is_admin_mode || contextCustomer.is_remote_mode) {
        return;
    }

    if (!user && !isLoginRequired()) {
        return;
    }

    const controllingCustomer = contextCustomer.controlling_customer;
    const userHasChanged = user && controllingCustomer && controllingCustomer != user.id;
    const sessionExpired = isExpired(contextCustomer);

    if (!contextCustomer.token || userHasChanged || sessionExpired) {
        const url = new URL(window.location.href);
        if (!url.pathname.toLowerCase().includes('authenticate')) {
            console.log('Mandatory login');
            navigate(loginLink);
        }
    }
}

function loadSegmentAnalytics(user) {
    if (window.analytics) {
        window.analytics.page();
        if (user) {
            window.analytics.identify(user.uuid, {
                name: user.name,
                email: user.email,
                external_id: user.id
            });
        }
    }
}

function updateCartCookie(cartCount) {
    if (typeof window === 'undefined') {
        return;
    }

    const name = isProduction() ? 'ONTCART' : 'ONTCART-TEST';
    const domain = ".nanoporetech.com";
    const maxAge = 30 * 60; // 30 minutes
    const cartUrl = window.location.origin + '/cart/';

    const cookieData = [
        cartCount,
        cartUrl
    ];

    const encodedValue = btoa(
        JSON.stringify(cookieData)
    );

    document.cookie = name + '=' + encodedValue
        + ';Domain=' + domain
        + ';Max-Age=' + maxAge;
}

function configureTopSections(globalNav) {
    if (!isTransactional()) {
        globalNav.activeMyAccountSections = ['about'];
    }
}
