import React, {forwardRef} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import Markdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

function Heading(props) {
    const level = (props.level < 3) ? 3 : props.level;
    const tagName = `h${level}`;
    return React.createElement(
        tagName, { style: {color: "#0084a9"} },
        props.children
    );
}

function Table(props) {
    return (
        <>
            <table cellSpacing="0" cellPadding="0">{props.children}</table>
            <style jsx>{`
            table {
                width: 100%;
                margin-bottom: 1.5rem;
            }
            table :global(th) {
                color: white;
                background-color: #0084a9;
                padding: 6px 8px;
                text-align: left;
            }
            table :global(td) {
                padding: 2px 8px;
                text-align: left;
                border-bottom: solid 1px #0184aa;
            }
            `}</style>
        </>
    );
}

function Image(props) {
    return (
        <>
            <img {...props} className="contentTabImg" />
            <style jsx>{`
            img {
                max-width: 30%;
            }
            `}</style>
        </>
    );
}

function Paragraph(props) {
    return (
        <>
        <p>{props.children}</p>
        <style jsx>{`
        p {
            font-size: 16px;
        }
        p :global(a:not(.anchor--light):not(.button):not(.scroller__btn)) {
            text-decoration: underline;
            border-bottom: none;
        }
        `}</style>
        </>
    );
}

function List(props) {
    return (
        <>
            <ul>{props.children}</ul>
            <style jsx>{`
            ul {
                font-size: 16px;
            }
            `}</style>
        </>
    );
}

function Link(props) {
    const target = props.href?.includes('store.nanoporetech.com') ? '_self' : '_blank';
    return (
        <>
            <a href={props.href} target={target}>{props.children}</a>
            <style jsx>{`
            a {
                text-decoration: underline;
                border-bottom: none;
            }
            `}</style>
        </>
    );
}

const ContentTab = forwardRef(({onVisible, title, content}, ref) => (
    <>
        <VisibilitySensor
            scrollCheck={true}
            onChange={isVisible => {
                if(isVisible) {
                    onVisible();
                }
            }}>
            <div className="sensor"></div>
        </VisibilitySensor>
        <div className="contentItem" ref={ref}>
            <h2>{title}</h2>
            <Markdown
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                components={{
                    h1: Heading,
                    h2: Heading,
                    h3: Heading,
                    h4: Heading,
                    h5: Heading,
                    h6: Heading,
                    table: Table,
                    img: Image,
                    p: Paragraph,
                    ul: List,
                    a: Link
                }}
                // skipHtml={false}
                // escapeHtml={false}
            >{content}</Markdown>
        </div>
        <style jsx>{`
        h2 {
            font-weight: lighter;
            border-bottom: solid 1px #0184aa;
        }
        .sensor {
            height: 1px;
        }
        .contentItem {
            scroll-margin: 175px;
        }
        :global(.stickyTabBar) .contentItem {
            scroll-margin: 75px;
        }
        .contentItem h3 {
            border-bottom: 1px solid #0184aa;
            color: #455556;
            font-weight: normal;
            padding-bottom: 2px;
        }
        `}</style>
    </>
));

export default ContentTab;
