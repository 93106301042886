import React from 'react';
import SectionMenu from './menu';
import SectionMenuCta from './menuCta';

export default function Section({item, config}) {
    if (item?.featured === true) return null;

    switch (item?.component) {
        case 'menu': return <SectionMenu section={item} config={config} />;
        case 'menu-cta': return <SectionMenuCta section={item} config={config} />;
    }

    return null;
}
