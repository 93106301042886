import React from 'react';
import SectionLink from './link';
import { menu, links } from './style.module.css';

export default function Menu({section, config}) {
    if (!section) return null;

    const { cssColSpan = 1, displayBlockSections = [] } = config;
    const gridStates = `s-col-span-1, l-col-span-${cssColSpan}`;
    const ulStyle = displayBlockSections.includes(section.title)
        ? {display: 'block'}
        : {};

    return (
        <div className={menu} grid-states={gridStates}>
            <h2>{section.title}</h2>
            <div className={links}>
                <ul style={ulStyle}>
                    {section.items?.map((item, i) => <li key={i}><SectionLink item={item} /></li>)}
                </ul>
            </div>
        </div>
    );
}

