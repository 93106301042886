import React from 'react';
import UiSelect from "../../../Ui/UiSelect";

function LocationFilter({events, selectedLocation, setSelectedLocation}) {

    const locations = [... new Set(
        events.map(event => event.location)
    )];

    const options = locations.map(location => {
        return { value: location, label: location }
    });

    options.unshift({value: '', label: 'All timezones'});

    return (
        <>
            <UiSelect
                placeholder="All timezones"
                options={options}
                onChange={option => setSelectedLocation(option.value)}
            />
        </>
    );
}

export default LocationFilter;
