import React from 'react';
import { filterAndSort } from './helpers';
import TopLevel from './topLevel';
import * as styles from "./style.module.css";
import PriceListLink from "./priceListLink";

// wrapper for the entire left nav panel
export default function LeftNav(props) {
    const sortedTopLevel = filterAndSort(props.categories);
    const activeCategory = props.activeCategory ?? {};

    return (
        <div className={styles.nav}>
            {sortedTopLevel.map(category => <TopLevel key={category.url_key} category={category} activeCategory={activeCategory} />)}
            <PriceListLink />
        </div>
    );
}
