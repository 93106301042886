import React from 'react';
import {card, cardName, cardTitle, cardPrice, hide} from './style.module.css';
import UiPublicOrCustomPrice from "../../Ui/UiPublicOrCustomPrice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import ConfiguratorOption, { OptionItem } from './configuratorOption';
import {TogglePrice, isTransactional, isChinaStore, isStaticJapanStore} from "../../../hooks/siteData";
import UiButton from "../../Ui/UiButton";
import { useCustomerContext } from '../../../contexts/CustomerContext';
import { navigate } from "gatsby";
import { useTranslation } from 'react-i18next';

export default function ProductCard({product, category}) {
    return(

        <div id={'card-' + product.sku} className={card}>
            <div className={cardTitle}>
                <div className={cardName}>{product.name}</div>
                <div className={cardPrice}>
                    <TogglePrice sku={product.sku}>
                        <UiPublicOrCustomPrice
                            price={product.price_range.minimum_price.final_price}
                            customPrice={product.ont_custom_price}
                            classes={{decimal: hide, fraction: hide}}
                        />
                    </TogglePrice>
                </div>
            </div>
            <ConfigureButton product={product} />
            <ConfiguratorOptions options={product.items} />
            <ConfigureButton product={product} />
        </div>

    );
}

const ConfiguratorOptions = ({options}) => {
    const { t } = useTranslation();
    const sortedOptions = [...options].sort(function (a, b) {
        return a.position - b.position;
    });

    const inclusiveKits = sortedOptions.reduce((acc, curr) => {
        const qty = (curr.type==='radio' && curr.title.startsWith('Kit') && curr.options[0]?.qty) ? curr.options[0].qty : 0;
        return acc + qty;
    }, 0);

    const inclusiveKitsItem = {
        qty: inclusiveKits,
        label: t('Sequencing kits'),
        product: {
            sku: ''
        }
    }

    return(

        <div className={options}>
            {sortedOptions.map(option => <ConfiguratorOption option={option} key={option.option_id} />)}
            {inclusiveKits > 0 && <OptionItem item={inclusiveKitsItem} />}
        </div>

    );
}

const ConfigureButton = ({product}) => {
    const {customer} = useCustomerContext();
    const { t } = useTranslation();

    if (!product.ont_phase_info.allow_purchase) return null;

    const link = (customer.has_extra_permission || customer.has_custom_pricing)
		? `/productDetail/?id=${product.url_key}`
		: `/${product.url_key}.html`;

    const gotoExternalLink = () => {
        if (isChinaStore()) {
            location.href="https://nanoporetech.net/contact";
        } else if (isStaticJapanStore()) {
            location.href="https://nanoporetech.com/jp/dealers";
        } else {
            location.href='mailto:store@nanoporetech.com?subject=Enquiry for ' + product.name + ' ' + product.sku;
        }
    }

    if (isTransactional()) {
        return(
            <UiButton className="button button--primary button--keyline" onClick={() => navigate(link)}>
                <span>Configure package</span>
                <span><FontAwesomeIcon icon={faChevronRight} /></span>
            </UiButton>
        );
    } else {
        return(
            <UiButton className="button button--primary button--keyline" onClick={() => gotoExternalLink()}>
                <span>{t('Enquire')}</span>
                <span><FontAwesomeIcon icon={faChevronRight} /></span>
            </UiButton>
        );
    }
    return null;
}


