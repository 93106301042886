// extracted by mini-css-extract-plugin
export var attributePoints = "style-module--attributePoints--dbc78";
export var configurator = "style-module--configurator--575f6";
export var content = "style-module--content--deee2";
export var cta = "style-module--cta--94e74";
export var description = "style-module--description--f2ba7";
export var details = "style-module--details--4693e";
export var flowDetails = "style-module--flowDetails--80db8";
export var flowcells = "style-module--flowcells--c0337";
export var hide = "style-module--hide--e23be";
export var image = "style-module--image--ca1ab";
export var innercontent = "style-module--innercontent--7d8c8";
export var item = "style-module--item--ca47e";
export var itemCount = "style-module--itemCount--72154";
export var price = "style-module--price--b46b1";
export var priceContainer = "style-module--priceContainer--a73cb";
export var priceFlowContainer = "style-module--priceFlowContainer--23a85";
export var sku = "style-module--sku--d6b55";
export var standard = "style-module--standard--a9673";
export var title = "style-module--title--68236";