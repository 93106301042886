// extracted by mini-css-extract-plugin
export var buttonConfirm = "style-module--buttonConfirm--4c012";
export var confirm = "style-module--confirm--bb933";
export var cta = "style-module--cta--9a25f";
export var ctaBottom = "style-module--ctaBottom--0405d";
export var detailsHeading = "style-module--detailsHeading--e141d";
export var group = "style-module--group--87c01";
export var hide = "style-module--hide--2d4cc";
export var image = "style-module--image--3ea63";
export var included = "style-module--included--eaf50";
export var right = "style-module--right--80694";
export var title = "style-module--title--8bba5";