import React from "react";
import { navigate } from "gatsby";
import LiveCategory from "../components/Live/LiveCategory";

export default function LiveCategoryPage(props) {
    if (typeof window==='undefined') return null;

    const urlParams = new URLSearchParams(props.location.search);
    const id = urlParams.get('id') ?? null;

    if (!id) navigate('/404');

    return (
        <LiveCategory categoryId={id} />
    );
}
