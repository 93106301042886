import {t} from "i18next";

export function saveSection(code, checkout, setCheckout, oldSection, newSection) {
    setCheckout({
        ...checkout,
        activeStep: checkout.activeStep+1,
        submitStatus: '',
        [code]: {
            ...oldSection,
            ...newSection
        }
    });
}

export function getFileBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
}

export async function getFilePayload(file) {
    const base64 = await getFileBase64(file);

    return {
        name: file.name,
        size: file.size,
        type: file.type,
        base64: base64,
    }
}

export function pluralise(amount, word) {
    const plural = word.endsWith('y')
        ? (word.substr(0, word.length - 1) + 'ies')
        : (word + 's');

    return (amount == 1)
        ? amount + ' ' + t(word)
        : amount + ' ' + t(plural);
}

export function getTotalCarriage(customerCart) {
    if (customerCart?.shipping_addresses) {
        return customerCart.shipping_addresses.reduce((acc,curr) => {
            return (acc + (curr.selected_shipping_method?.amount.value ?? 0));
        }, 0);
    }

    return 0;
}

export function getConsumablesCarriage(shippingSchedule) {
    const shipmentsWithConsumables = getShipmentsWithConsumables(shippingSchedule);
    const costPerShipment = shippingSchedule.cost_per_shipment ?? 0;
    return (costPerShipment * shipmentsWithConsumables.length);
}

export function getCartTaxAmount(cart)
{
    const productValue = cart.prices.subtotal_with_discount_excluding_tax.value;
    const totalShipping = cart.shipping_addresses[0]?.selected_shipping_method?.amount?.value ?? 0;
    const taxableValue = productValue + totalShipping;
    const grandTotal = cart.prices.grand_total.value;
    const currency = cart.prices.grand_total.currency;
    let taxValue = grandTotal - taxableValue;
    if (taxValue < 0) {taxValue = 0;}

    return {
        currency: currency,
        value: taxValue,
    };
}

export function getOrderInput(cart, checkout, additionalUploads, cache)
{
    const commonFields = getQuotationOrderCommonFields(checkout, additionalUploads);

    return {
        ...commonFields,
        cart_id: cart.id,
        shipping_emails: checkout.shipping_address?.ont_shipping_emails ?? '',
        ont_additional_confirmation_emails: cache?.ont_additional_confirmation_emails ?? '',
        quotation_number: checkout.payment_details?.quotation_number,
    }
}

export function getRedeemQuotationInput(cart, checkout, additionalUploads, cache)
{
    const commonFields = getQuotationOrderCommonFields(checkout, additionalUploads);

    return {
        ...commonFields,
        quotation_id: checkout.quotationId,
        shipping_emails: checkout.quotation_shipping?.ont_shipping_emails ?? '',
        dp_code: checkout.dpCode,
        ont_additional_confirmation_emails: cache?.ont_additional_confirmation_emails,
    }
}

// returns true if a shipment item exists with date of shipDate and is not a device
export function shipmentContainsConsumable(shipDate, schedule) {
    const rowContainsConsumableShipmentItem = row => {
        if (!row.is_dc_device && row.shipment_items) {
            for (const shipmentItem of row.shipment_items) {
                if (shipmentItem.date == shipDate && shipmentItem.qty > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    if (schedule.schedule_rows) {
        for (const parentRow of schedule.schedule_rows) {
            if (parentRow.is_dc_device) {
                return false;
            } else if (parentRow.sub_rows) {
                for (const subRow of parentRow.sub_rows) {
                    if (rowContainsConsumableShipmentItem(subRow)) return true;
                }
            } else if (parentRow.shipment_items) {
                if (rowContainsConsumableShipmentItem(parentRow)) return true;
            }
        }
    }

    return false;
}

export function getShipmentsWithConsumables(shippingSchedule) {
    const shipments = shippingSchedule.shipments ?? [];
    return shipments.filter(shipDate => shipmentContainsConsumable(shipDate, shippingSchedule));
}

export function frontendToBackendCartAddress(address) {
    // if we have a region id we should not send region as Magento takes that as a region code!
    if (address.region_id && address.region) {
        return {
            ...address,
            region_id: address.region_id,
            region: null,
            save_in_address_book: true
        }
    }

    return {
        ...address,
        save_in_address_book: true
    };
}

export function isOrderPermitted(cartData) {
    return !(cartData && cartData.hasOwnProperty('ont_is_order_permitted') && !cartData.ont_is_order_permitted);
}

function getQuotationOrderCommonFields(checkout, additionalUploads) {
    let commonFields = {
        fc_returns: checkout.declarations?.ont_fc_returns ?? '',
        fc_biobox: checkout.declarations?.ont_fc_biobox ?? 'none',
        fc_biobox_qty: checkout.declarations?.ont_fc_biobox_qty ?? 0,
        terms_agreed: checkout.terms?.ont_terms_agreed?.join(',') ?? 'Oxford Nanopore terms and conditions,Warranty restriction notice',
    };

    if (additionalUploads) {
        commonFields['additional_uploads'] = additionalUploads
    }

    return commonFields;
}

