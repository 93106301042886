import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import * as styles from './style.module.css';

export default function Crumb({crumb}) {
    return (
        <span className={styles.crumbUrl}>
            <CrumbLink crumb={crumb} />
            <span className={styles.slashIcon}>/</span>
            {/* <span className={styles.slashIcon}><FontAwesomeIcon icon={faChevronRight} size="sm" /></span> */}
        </span>
    );
}

function CrumbLink({crumb}) {
    if (crumb.url.startsWith('//') || crumb.url.startsWith('http')) {
        return <a href={crumb.url}>{crumb.label}</a>;
    }

    return <Link to={crumb.url}>{crumb.label}</Link>;
}
