import React, { useState } from "react";
import useSubmitTaxInfo from "./useSubmitTaxInfo";
import VatPreview from "./vatPreview";
import SalestaxPreview from "./salestaxPreview";
import NonePreview from "./nonePreview";
import * as styles from './style.module.css';
import Actions from "./actions";

export default function Preview({taxSystem, cartId, sectionCache}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const submitTaxInfo = useSubmitTaxInfo(setIsSubmitting, cartId, sectionCache, taxSystem);

    const submitter = {
        isSubmitting: isSubmitting,
        setIsSubmitting: setIsSubmitting,
        submit: submitTaxInfo
    };

    return (
        <div className={styles.preview}>
            <PreviewRouter taxSystem={taxSystem} sectionCache={sectionCache} />
            <Actions submitter={submitter} sectionCache={sectionCache} />
        </div>
    );
}

function PreviewRouter({taxSystem, sectionCache}) {
    const taxInfo = sectionCache.data.tax_info;

    switch (taxSystem.tax_system_code) {
        case 'vat':
            return <VatPreview taxInfo={taxInfo} />;
        case 'salestax':
            return <SalestaxPreview taxInfo={taxInfo} taxSystem={taxSystem} />;
        case 'none':
            return <NonePreview taxSystem={taxSystem} />
    }

    return <p>Unable to preview tax status</p>;
}
