import React from 'react';
import Standard from '../components/Authentication/Standard';


export default function StandardAuthenticate({ location }) {
    if (typeof window==='undefined') return null;

    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code') ?? null;

    return <Standard code={code} />;
}

