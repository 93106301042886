export function getExpandedSections(sections) {
    const expanded = [];
    for (const section of sections) {
        expanded.push(section);

        if (section.additionalItems) {
            expanded.push({
                label: 'Additional ' + section.label,
                items: section.additionalItems,
            })
        }
    }

    return expanded;
}
