import React from 'react';
import { useCheckoutContext } from '../../../../contexts/CheckoutContext';
import Price from "../../../Ui/Price";
import { getUiDate, getUtcDate } from '../../../ShippingScheduler/helpers';
import { getTotalCarriage, getCartTaxAmount, getShipmentsWithConsumables } from '../helpers';
import { getUiQuotationRef } from '../../../Quotation/helpers';
import {review, ul} from '../style.module.css';

export default function ReviewSummary({cartData}) {
    const [checkout] = useCheckoutContext();
    const taxAmout = getCartTaxAmount(cartData);
    const subtotal = cartData.prices.subtotal_excluding_tax;
    const subtotalWithDiscount = cartData.prices.subtotal_with_discount_excluding_tax;

    return(
        <div className={review}>
            <BasketItems items={cartData.items} subtotal={subtotal} />
            <QuotationSubtotal quotationId={checkout.quotationId} subtotal={subtotal} />
            {checkout.shipping_schedule && <Deliveries schedule={checkout.shipping_schedule.schedule} cartData={cartData} />}
            <Taxes taxAmount={taxAmout} />
            <OrderTotal grandTotal={(subtotalWithDiscount.value > 0) ? cartData.prices.grand_total : subtotalWithDiscount} />
        </div>
    );
}

function BasketItems({items, subtotal}) {
    function Item({item}) {
        if (!item.prices?.row_total?.currency) return null;

        return(
            <li>
                <div>{item.quantity}x {item.product.name}</div>
                <div><Price currencyCode={item.prices.row_total.currency} value={item.prices.row_total.value} /></div>
            </li>
        );
    }

    if (items.length) {
        return(
            <section>
                <h3>Basket items ({items.length}):</h3>
                <ul className={ul}>
                    {items.map(item => <Item key={item.id} item={item}/>)}
                </ul>
                <div>
                    <Price currencyCode={subtotal.currency} value={subtotal.value} />
                </div>
            </section>
        );
    }

    return null;
}

function QuotationSubtotal({quotationId, subtotal}) {
    if (quotationId) {
        return(
            <section>
                <h3>Quotation {getUiQuotationRef(quotationId)}</h3>
                <div>
                    <Price currencyCode={subtotal.currency} value={subtotal.value} />
                </div>
            </section>
        );
    }

    return null;
}

function Deliveries({schedule, cartData}) {
    const { device_carriage, cost_per_shipment } = schedule;
    const { currency } = cartData.prices.grand_total;

    const consumableShipments = getShipmentsWithConsumables(schedule);

    if (!consumableShipments.length && device_carriage <= 0) return null;

    // shipments may be an array of date strings (checkout) or an array of objects (quote redemption)
    const useShipments = consumableShipments.map(s => (typeof s === 'string') ? s : s.date);

    function Shipment({date}) {
        const shipDate = getUiDate(getUtcDate(date));
        return(
            <li>
                <div>Consumables shipment on {shipDate}</div>
                <div><Price currencyCode={currency} value={cost_per_shipment} /></div>
            </li>
        );
    }

    function DeviceCarriage() {
        if (schedule.device_carriage > 0) {
            return(
                <li>
                    <div>Device carriage</div>
                    <div><Price currencyCode={currency} value={schedule.device_carriage} /></div>
                </li>
            );
        }

        return null;
    }

    return(
        <section>
            <h3>Delivery:</h3>
            <ul className={ul}>
                <DeviceCarriage />
                {useShipments.map(shipment => <Shipment key={shipment} date={shipment} />)}
            </ul>
            <div>
                <Price currencyCode={currency} value={getTotalCarriage(cartData)} />
            </div>
        </section>
    );
}

function OrderTotal({grandTotal}) {
    const displayAmount = (grandTotal.value < 0) ? 0 : grandTotal.value;
    return(
        <section>
            <h2>
                <div>Order total:</div>
                <div><Price currencyCode={grandTotal.currency} value={displayAmount} /></div>
            </h2>
        </section>
    );
}

function Taxes({taxAmount}) {
    if (!taxAmount?.value) return null;

    return(
        <section>
            <h3>Tax:</h3>
            <div><Price currencyCode={taxAmount.currency} value={taxAmount.value} /></div>
        </section>
    );
}
