import React from 'react';
import AtcButton from '../../../AtcButton/atcButton';

export default function DynamicAtcButton({choices, products}) {
    const atcProducts = getAtcProducts(choices, products);

    return (
        <AtcButton 
            product={atcProducts.mainProduct ?? products[0]} 
            qty={1}
            isDisabled={!atcProducts.mainProduct}
            additionalProducts={atcProducts.additionalProducts}
            options={atcProducts.mainOptions}
        />
    );
}

function getAtcProducts(choices, products) {
    const skus = Object.keys(choices);

    let mainProduct = null;
    let mainOptions = [];
    let additionalProducts = [];

    skus.forEach(sku => {
        const skuData = choices[sku];
        if (skuData) {
            const product = products.find(product => product.sku===sku);
            if (product) {
                if (typeof skuData == 'number') {    // lab cert
                    if (mainProduct) {
                        additionalProducts.push({product: product, qty: 1, options: []})
                    } else {
                        mainProduct = product;
                    }
                } else {                             // device cert
                    const barcodes = Object.keys(skuData);
                    barcodes.forEach(barcode => {
                        const options = getOptionsForDevice(product, barcode, skuData[barcode]);
                        if (mainProduct) {
                            additionalProducts.push({product: product, qty: 1, options: options})
                        } else {
                            mainProduct = product;
                            mainOptions = options;
                        }
                    });
                }
            }
        }
    });

    return {mainProduct: mainProduct, mainOptions: mainOptions, additionalProducts: additionalProducts};
}

function getOptionsForDevice(product, barcode, quarters) {
    const options = [];

    const barcodeUid = getBarcodeUid(product);
    if (barcodeUid) options.push({uid: barcodeUid, value: barcode});

    const quartersUid = getSelectedQuartersUid(product, quarters);
    if (quartersUid) options.push(quartersUid);

    return options;
}

function getBarcodeUid(product) {
    for (let option of product.options) {
        if (option.title=='barcode') return option.value.uid;
    }
    return null;
}

function getSelectedQuartersUid(product, quarters) {
    for (let option of product.options) {
        if (option.title=='quarters') {
            for (let selection of option.dropDownValues) {
                if (selection.title == quarters) return selection.uid;
            }
        }
    }
    return null;
}
