import React from 'react';
import {getOntRegionsFromCountryCode, getRegionsFromCountryCode} from '../../../hooks/useCountries';
import { NanoInput } from '@nanoporetech-digital/components-react';
import MagentoRegions from './magentoRegions';
import AdditionalRegions from './additionalRegions';

export default function RegionInput(props) {

    const { countries, formData, updateFormData, prefix } = props;

    const magentoRegions = getRegionsFromCountryCode(countries, formData.country_code);
    if (magentoRegions.length) {
        return <MagentoRegions regions={magentoRegions} {...props} />
    }

    const additionalRegions = getOntRegionsFromCountryCode(countries, formData.country_code);
    if (additionalRegions.length) {
        return <AdditionalRegions regions={additionalRegions} {...props} />;
    }

    return <NanoInput
                autocomplete="off"
                maxlength="40"
                label="State/province"
                required="false"
                validateOn="submitThenDirty"
                showInlineError={true}
                name={`${prefix}region`}
                value={formData.region ?? ''}
                onNanoChange={e => updateFormData(e)}
           />;
}
