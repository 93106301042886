import React, { useState } from 'react';
import useRelatedProducts from '../../../hooks/useRelatedProducts';
import * as styles from './style.module.css';
import TopActions from '../../TopActions';
import Breadcrumbs from '../../Breadcrumbs';
import UIBackButton from '../../Ui/UiBackButton';
import ProgressBar from './ProgressBar';
import Section from './Section';
import Confirm from './Confirm';
import Summary from './Summary';
import Header from '../../Header';

export default function Configurator({product}) {
    const [focusKey, setFocusKey] = useState('');
    const steps = getSteps(product.items);
    injectSteps(product, steps);
    const [activeStep, setActiveStep] = useState(1);

    const [sections, setSections] = useState(initSections(steps));
    const activeSection = sections[activeStep-1];

    const related = useRelatedProducts(product);
    if (related?.length) {
        product = {...product, related_products: related};
    }

    const updateSection = section => {
        const newSections = [...sections];
        newSections[section.index] = section;
        setSections(newSections);
    };

    const goNextStep = () => {
        if (activeStep < steps.length) setActiveStep(activeStep+1);
    }

    const RenderSection = () => {
        if (steps[activeStep]==='Confirm') {
            return <Confirm product={product} sections={sections} />
        }

        const selectedChemistryType = getSelectedChemistryType(sections)

        return <Section
            focusKey={focusKey}
            setFocusKey={setFocusKey}
            product={product}
            section={activeSection}
            options={product.items}
            selectedChemistryType = {selectedChemistryType}
            summary={<Summary product={product} sections={sections} />}
            updateSection={updateSection}
            goNextStep={goNextStep}
        />
    }

    return (
        <>
            <TopActions black="black" main="inner" />
            <div className={styles.contentWrapper}>
                <Header categories={product.categories} title="Configure your package" />
                <div style={{marginBottom: "3rem"}} />
                <ProgressBar steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
                <RenderSection />
            </div>
        </>
    );
}

function initSections(steps) {
    const sections = [];

    for (let i = 1; i < steps.length-1; i++) {    // ignore first and last steps
        sections.push({
            selections: [],     // bundle selections
            items: [],          // summary items
            label: steps[i],
            index: (i-1),
            cache: {},          // working data
            additional: {},     // bundle additional products
        });
    }

    return sections;
}

function getSteps(options) {
    const steps = ['Select package'];

    if (options?.length) {
        const sortedOptions = [...options].sort(function (a, b) {
            return a.position - b.position;
        });

        sortedOptions.forEach(option => {
            if (option.title==='Kit 1') steps.push('Sequencing kits');
            if (option.title==='hidden') return;
            if (option.title.startsWith('Kit')) return;
            steps.push(option.title);
        });
    }

    return steps;
}

function injectSteps(product, steps) {
    // product specific special steps
    // switch(product.sku) {
    // }

    if (product.related_products?.length > 0) {
        steps.push('Training');
    }

    steps.push('Confirm');
}


function getSelectedChemistryType(sections) {
    for (const section of sections) {
        if (section.selectedChemistryType) {
            return section.selectedChemistryType;
        }
    }

    return null;
}
