import React, { useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { isProduction } from '../../../hooks/useEnvironment';

export default function OntCaptcha({saveValue, cssClass=''}) {

    const captchaRef = useRef(null);

    const siteKey = isProduction()
        ? "6LcykhMmAAAAAFMvbLGcnnMf98CIs44ZlrKepeCv"
        : "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

    function reset() {
        if (captchaRef.current.getValue()) {
            captchaRef.current.reset();
        }
    }

    return (
        <div className={cssClass}>
            <ReCAPTCHA sitekey={siteKey} onChange={saveValue} ref={captchaRef} />
        </div>
    );
}
