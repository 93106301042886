import React, { useState } from 'react';
import { NanoInput, NanoSelect, NanoOption, NanoCheckbox } from '@nanoporetech-digital/components-react';
import { updateCachedTaxInfo } from '../helpers';
import InfoText from './infoText';
import WarningText from './warningText';
import Actions from './actions';

export default function VatForm({taxSystem, sectionCache}) {
    const [showWarning, setShowWarning] = useState(false);
    const taxInfo = sectionCache.data.tax_info;
    const setTextField = e => {
        sectionCache.set({
            ...sectionCache.data,
            tax_info: {
                [e.target.name]: e.target.value
            }
        });
        setShowWarning(true);
    };

    return (
        <div>
            <InfoText taxSystem={taxSystem} />
            <NanoSelect
                label={taxSystem.dropdown_text}
                name="tax_status"
                value={taxInfo?.tax_status}
                onNanoChange={e => setTextField(e)}
            >
                <NanoOption key="vat_registered" value="vat_registered" label="VAT Registered" />
                <NanoOption key="not_registered" value="not_registered" label="Not VAT Registered" />
            </NanoSelect>
            <VatOptions taxSystem={taxSystem} sectionCache={sectionCache} />
            <WarningText show={showWarning} taxSystem={taxSystem}>Are you sure you wish to change VAT status?</WarningText>
            <Actions taxSystem={taxSystem} sectionCache={sectionCache} />
        </div>
    );
}

function VatOptions({taxSystem, sectionCache}) {
    const taxInfo = sectionCache.data.tax_info;
    const taxStatus = taxInfo?.tax_status ?? taxSystem.default_status_code;
    const disabled = (taxStatus !== 'vat_registered');
    const setTextField = e => updateCachedTaxInfo(sectionCache, e.target.name, e.target.value);
    const toggleCheckbox = e => updateCachedTaxInfo(sectionCache, e.target.name, e.target.checked);

    return (
        <div>
            <NanoInput
                disabled={disabled}
                autocomplete="off"
                maxlength="40"
                label="VAT registration number*"
                name="vat_number"
                value={taxInfo?.vat_number ?? ''}
                onNanoChange={e => setTextField(e)}
            />
            <NanoCheckbox
                disabled={disabled}
                label="VAT exempt"
                name="vat_exempt"
                onNanoChange={e => toggleCheckbox(e)}
            />
        </div>
    );
}
