// extracted by mini-css-extract-plugin
export var active = "style-module--active--9c2be";
export var badge = "style-module--badge--cab70";
export var cartActions = "style-module--cartActions--8a07f";
export var container = "style-module--container--da5bf";
export var controls = "style-module--controls--140b7";
export var details = "style-module--details--bbf8c";
export var eventCol = "style-module--eventCol--7be78";
export var eventRow = "style-module--eventRow--92035";
export var image = "style-module--image--a68a2";
export var inner = "style-module--inner--69e0b";
export var locations = "style-module--locations--64b7b";
export var price = "style-module--price--fdb78";
export var quantity = "style-module--quantity--7b00a";
export var sku = "style-module--sku--a708d";
export var tabSet = "style-module--tab-set--0a9eb";
export var tabs = "style-module--tabs--dd816";
export var title = "style-module--title--307a3";