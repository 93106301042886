import {addSeconds} from 'date-fns';
import { isDistributorRootStore } from '../../hooks/siteData';

export function getSessionExpiry(duration) {
    const date = new Date();
    return addSeconds(date, duration);
}

// when we land on /authenticate returning from SSO we don't have a subfolder in the URL
// authDestPath does start with the subfolder (i.e. /uk/ /eu/ /us/ /cn/ /jp/)
// so we will always send them back to the subfolder they came from
// but after login that may no longer be the correct store
// for main stores, WebStore will take care of redirecting them to the right one
// for ds stores, we need to do this manually
export function getRedirectUrl(authDestPath) {
    const to = authDestPath ?? '/';
    if (isDistributorRootStore()) { // if redirecting we need a full URL
        if (to.startsWith('/jp') || to.startsWith('/cn')) {
            const {origin} = new URL(window.location.href);
            return (origin+to);
        }
    }
    return to;
}
