import React from 'react';
import { NanoCheckbox, NanoInput } from '@nanoporetech-digital/components-react';
import * as styles from '../../style.module.css';

export default function FlowcellBiobox({settings}) {
    const {cache, setCache} = settings;
    const isBioboxNeeded = (cache.ont_fc_biobox !== 'none');

    function updateChoice(newValue) {
        // bug in onNanoChange? gets called twice once with old value, once with new
        if (newValue !== cache.ont_fc_biobox) {
            let resetQty = (cache.ont_fc_biobox_qty > 0) ? cache.ont_fc_biobox_qty : 1;
            if (newValue==='none') resetQty = 0;
            setCache({
                ...cache,
                ont_fc_biobox: newValue,
                ont_fc_biobox_qty: resetQty,
            });
        }
    }

    function updateQty(newValue) {
        const newValueInt = parseInt(newValue);
        if (!isNaN(newValueInt) && newValueInt !== cache.ont_fc_biobox_qty) {
            setCache({
                ...cache,
                ont_fc_biobox_qty: newValueInt,
            });
        }
    }

    function Radio({label, value}) {
        const isChecked = (cache.ont_fc_biobox && cache.ont_fc_biobox===value) ?? false;

        return(
            <li>
                <NanoCheckbox
                    key={value}
                    type="radio"
                    label={label}
                    name="ont_fc_biobox"
                    value={value}
                    checked={isChecked}
                    onNanoChange={(e) => updateChoice(e.target.value)}
                />
            </li>
        );
    }

    return(
        <div>
            <h3>Return box request</h3>
            <p>Please select a box size for the return of your flow cells after use:</p>
            <div className={styles.bioboxes}>
                <ul className={styles.ul}>
                    <Radio label="None" value="none" />
                    <Radio label="Small (up to 10 flow cells)" value="small" />
                    <Radio label="Medium (up to 20 flow cells)" value="medium" />
                    <Radio label="Large (up to 80 flow cells)" value="large" />
                </ul>
                <div><img src="/bio-boxes.png" height="120"/></div>
            </div>

            {isBioboxNeeded && <NanoInput
                type="number"
                min="1"
                max="5"
                required={true}
                show-inline-error="dirty"
                name="ont_fc_biobox_qty"
                value={cache.ont_fc_biobox_qty}
                label="Number of bio box requested"
                onNanoChange={(e) => updateQty(e.target.value)}
            />}
        </div>
    );
}
