// extracted by mini-css-extract-plugin
export var attributePoints = "style-module--attributePoints--b7e82";
export var ctaSelect = "style-module--ctaSelect--81232";
export var description = "style-module--description--3e03f";
export var details = "style-module--details--568b5";
export var hide = "style-module--hide--ccb68";
export var image = "style-module--image--4ebbe";
export var instructions = "style-module--instructions--781d8";
export var isMedium = "style-module--is-medium--d6d1e";
export var item = "style-module--item--6c536";
export var itemCta = "style-module--itemCta--65d08";
export var itemSelector = "style-module--itemSelector--bfa48";
export var itemStatusContent = "style-module--itemStatusContent--7dd29";
export var price = "style-module--price--93faf";
export var sectionContent = "style-module--sectionContent--94c5c";
export var sectionMain = "style-module--sectionMain--d0a20";
export var sectionStatus = "style-module--sectionStatus--37b63";
export var sectionStatusContent = "style-module--sectionStatusContent--1c0dd";
export var sku = "style-module--sku--fa6f9";
export var status = "style-module--status--feec9";
export var title = "style-module--title--d9ef1";