import React from 'react';
import Event from './event';
import GET_EVENT_PLACES_USED_COUNT from './getEventPlacesUsedCount.graphql';
import liveQuery from "../../../../utils/liveQuery";

export default function EventList(props) {
    const { data } = liveQuery(GET_EVENT_PLACES_USED_COUNT);
    const placesUsed = getPlacesUsedAsHash(data);

    function toggleEventSelection(event) {
        const newSelectedEvents = [...props.selectedEvents];
        const index = newSelectedEvents.findIndex(e => e.sys.id==event.sys.id);

        if (index >= 0) {
            newSelectedEvents.splice(index,1);
        } else {
            // newSelectedEvents.push(event);
            // as multiple ATC doesn't work, only allow 1 selection
            newSelectedEvents[0] = event;
        }

        props.setSelectedEvents(newSelectedEvents);
    }

    return props.events.map(event => {
        return (
            <Event 
                key={event.sys.id}
                event={event}
                price={props.price}
                sold={placesUsed[event.sys.id] ? placesUsed[event.sys.id] : 0}
                showSoldout={props.showSoldout}
                selectedLocation={props.selectedLocation}
                isSelected={!!props.selectedEvents.find(e => e.sys.id==event.sys.id)}
                toggleEventSelection={toggleEventSelection}
                showPrice={props.showPrice}
            />
        );
    });
};

function getPlacesUsedAsHash(data) {
    const placesUsed = {};
    if (data) {
        data.getEventPlacesUsedCount.items.forEach(item => {
            placesUsed[item.event_id] = item.total_places_used
        });
    }
    return placesUsed;
}
