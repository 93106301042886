import React, {useEffect, useLayoutEffect, useRef} from 'react';
import {NanoInput} from '@nanoporetech-digital/components-react';
import {useCustomerContext} from "../../../../contexts/CustomerContext";
import {validateEmailCsv} from "../../../Form/validate";

export default function AdditionalConfirmationEmails({cache, setCache}) {
    const updateField = e => {
        let isValid = true;
        const emailCsv = e.target.value;

        if (isRequired && !emailCsv) {
            isValid = false;
            if(inputField.current) {
                inputField.current.showError('Field is required');
            }
        }

        if (emailCsv) {
            if (!validateEmailCsv(emailCsv)) {
                isValid = false;
                if (inputField.current) {
                    inputField.current.showError('Please enter valid email addresses (comma-separated)');
                }
            }
        }

        if (isValid && inputField.current) {
            inputField.current.showError('');
        }

        setCache({
            ...cache,
            ont_additional_confirmation_emails: emailCsv,
            is_confirmation_emails_valid: isValid,
        });
    };

    const {customer} = useCustomerContext();
    const isRequired = !(
        customer.controlling_customer
        || customer.is_remote_mode
    );

    const headerText = isRequired
        ? 'Order confirmation recipients'
        : 'Additional order confirmation recipients';

    const inputField = useRef(null);

    useLayoutEffect(() => {
        if (!inputField.current) {
            return;
        }

        if (!isRequired && !cache.ont_additional_confirmation_emails) {
            setCache({
                ...cache,
                is_confirmation_emails_valid: true,
            });
        }

        if (isRequired && !cache.ont_additional_confirmation_emails) {
            inputField.current.showError('Field is required');
        }
    }, [inputField.current]);

    return (
        <>
            <h3>{headerText}</h3>
                <NanoInput
                    ref={inputField}
                    name="ont_additional_confirmation_emails"
                    placeholder="Multiple addresses may be comma separated"
                    required={isRequired ? 'true' : 'false'}
                    value={cache.ont_additional_confirmation_emails}
                    onNanoChange={e => updateField(e)}
                    debounce={500}
                    showInLineError={true}
                    validateOn="submitThenDirty"
                >
                    {!isRequired &&
                        <span slot="helper">If you leave this blank, only you will receive the order confirmation unless additional recipients have been set on your account.</span>
                    }
                </NanoInput>
        </>
    );
}
