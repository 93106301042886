import React, { useState } from "react";
import {isOrderPermitted, saveSection} from '../helpers';
import { getAddressString } from "../../Address/shippingSelect";
import { useCheckoutContext } from '../../../../contexts/CheckoutContext';
import SectionHeader from '../sectionHeader';
import BillingRouter from "./BillingRouter";
import * as styles from '../style.module.css';
import CheckoutPrevented from "../../CheckoutPrevented";

export const CODE = 'billing';
export const TITLE = 'Billing details';

export default function Billing({step, cartData}) {
    const [checkout, setCheckout] = useCheckoutContext();
    const isActive = checkout.activeStep===step;

    const section = checkout[CODE] ?? {};

    // local state to avoid global re-render on small changes
    const [cache, setCache] = useState({
        billing_info: null,
    });

    async function submit() {
        const invoiceToDifferentAccount = cache.billing_info.status == 'invacc';
        const highlight = cache.billing_info?.billing
            ? (invoiceToDifferentAccount ? 'On file' : getAddressString(cache.billing_info.billing))
            : '';

        saveSection(CODE, checkout, setCheckout, section, {
            canChange: true,
            highlight: highlight,
            billing_info: cache.billing_info,
        });
    }

    return(
        <div className={styles.section}>
            <SectionHeader step={step} code={CODE} title={TITLE} />
            {isActive && <SectionContent cartData={cartData} sectionCache={{data: cache, set: setCache, submit: submit}}/>}
        </div>
    );
}

function SectionContent({cartData, sectionCache}) {
    if (!isOrderPermitted(cartData)) {
        return (
            <CheckoutPrevented />
        );
    }

    return (
        <div className={styles.sectionContent}>
            <BillingRouter cartData={cartData} sectionCache={sectionCache} />
            <SubmitStatus />
        </div>
    );
}

function SubmitStatus() {
    const [checkout] = useCheckoutContext();

    return (checkout.submitStatus)
        ? <div className={styles.submitStatus}>{checkout.submitStatus}</div>
        : null;
}
