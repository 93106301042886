import React, { useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { getUtcDate, getHolidayDates, isShippingDay } from '../helpers';
import "react-datepicker/dist/react-datepicker.css";
import * as styles from '../style.module.css';

export default function Shipments({schedule, changeDate}) {
    return schedule.shipments.map(shipment => <Shipment key={shipment} shipment={shipment} schedule={schedule} changeDate={changeDate} />);
}

function Shipment({shipment, schedule, changeDate}) {
    // const [startDate, setStartDate] = useState(getUtcDate(shipment));
    const startDate = getUtcDate(shipment);
    const CustomInput = forwardRef(DateButton);
    const excludeDates = getHolidayDates(schedule);
    const fad = getUtcDate(schedule.first_available_date);
    const lad = getUtcDate(schedule.last_available_date);

    return (
        <>
            <th className={styles.shipmentItemSpacer}>&nbsp;</th>
            <th className={styles.shipmentDate}>
                <DatePicker
                    selected={startDate}
                    onChange={date => changeDate(shipment, date)}
                    dateFormat="dd MMM yyyy"
                    customInput={<CustomInput />}
                    filterDate={date => isShippingDay(schedule, date)}
                    excludeDates={excludeDates}
                    minDate={fad}
                    maxDate={lad}
                />
            </th>
        </>
    );
}

function DateButton({ value, onClick }, ref) {
    return(
        <button type="button" className="button button--primary" onClick={onClick} ref={ref}>
            {value}
        </button>
    );
}
