import React from 'react';
import {Helmet} from "react-helmet";
import Cart from '../components/Cart';

export default function CartPage(props) {

    if (typeof window==='undefined') return null;

    return (
        <>
            <Helmet>
                <title>Shopping basket</title>
            </Helmet>
            <Cart {...props} />
        </>
    );
}
