import React from 'react';
import { useMinionContext } from '../Configurator/useMinionContext';
import { getSelectedBundle } from '../Action/helpers';
import { witb, bundleChildren, bundleChild, childInfo } from './style.module.css';
import { getBundleContents } from './helpers';
import { useTranslation } from 'react-i18next';
import UiMarkdown from '../../../Ui/UiMarkdown';

export default function WhatsInTheBox() {
    const { t } = useTranslation();

    return (
        <section id="witb" className={witb}>
            <h2>{t("What's in the box")}</h2>
            <BundleChildren />
        </section>
    );
}

function BundleChildren() {
    const { minionConfig, bundleProducts } = useMinionContext();
    const selectedBundle = getSelectedBundle(bundleProducts, minionConfig);
    const children = getBundleContents(selectedBundle, minionConfig);

    return (
        <div className={bundleChildren}>
            {children.map((child, i) => <BundleChild key={i} child={child} />)}
        </div>
    );
}

function BundleChild({child}) {
    const { qty, name, sku, info } = child;
    return (
        <div className={bundleChild}>
            <strong>{qty}x</strong> {name} <em>{sku}</em>
            <ChildInfo info={info} />
        </div>
    );
}

function ChildInfo({info}) {
    if (!info) return null;

    return (
        <div className={childInfo}>
            <UiMarkdown source={info} skipHtml={true} />
        </div>
    );
}
