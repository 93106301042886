import { gql } from "@apollo/client";

export const orderFields = gql`
    fragment orderFields on Order {
        order_number
        purchase_qty
        purchase_value
        purchase_date_latest
        purchase_skus
        purchase_count
        purchase_date_first
    }
`;

export const redeemQuotationFields = gql`
    fragment redeemQuotationFields on QuotationOrder {
        order_number
        purchase_qty
        purchase_value
        purchase_date_latest
        purchase_skus
        purchase_count
        purchase_date_first
    }
`;

export const payerAuthenticationFields = gql`
    fragment payerAuthenticationFields on PayerAuthenticationData {
        code
        parameters
    }
`;
