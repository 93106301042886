import React, { useState } from "react";
import { NanoInput, NanoCheckbox } from '@nanoporetech-digital/components-react';

export default function InvoiceEmail({cachedBillingInfo, setBillingInfo, updateBillingInfo}) {
    const [isChecked, setIsChecked] = useState(!cachedBillingInfo.email_invoice_to);

    const toggleCheckbox = e => {
        if (e.target.checked && !isChecked) {
            setIsChecked(true);
            if (cachedBillingInfo.email_invoice_to) {
                setBillingInfo({
                    ...cachedBillingInfo,
                    email_invoice_to: null
                });
            }
        } else if (!e.target.checked && isChecked) {
            setIsChecked(false);
        }
    }

    return (
        <div>
            <h4>What email address should invoices be sent to?</h4>
            <NanoCheckbox 
                checked={isChecked}
                label="Use the above billing contact email address"
                name="invoice_email_toggle"
                onNanoChange={e => toggleCheckbox(e)} 
            />
            <InvoiceEmailInput 
                show={isChecked} 
                inputValue={cachedBillingInfo.email_invoice_to ?? cachedBillingInfo.finance_contact_email} 
                updateBillingInfo={updateBillingInfo}
            />
        </div>
    );
}

function InvoiceEmailInput({show, inputValue='', updateBillingInfo}) {
    return (show)
        ? null
        : <NanoInput autocomplete="off" showInlineError={true} name="email_invoice_to" value={inputValue} onNanoChange={e => updateBillingInfo(e)} inputmode="text" />;
}
