import React, {useMemo} from 'react';
import * as styles from './style.module.css';
import Price from "../../../Ui/Price";
import { getAllSectionsMetaData, TotalPrice } from '../Confirm/confirm';
import {getExpandedSections} from "../sectionHelper";

export default function Summary({product, sections}) {
    const {currency} = product.price.regularPrice.amount;
    const {surcharge} = useMemo(() => getAllSectionsMetaData(product, sections), [product, sections]);

    const expandedSections = getExpandedSections(sections);

    return (

        <div grid-states="xl-col-span-4 l-col-span-4 m-col-span-10 s-col-span-10" className={styles.summaryBox}>
            <h2 className={styles.title}>Selected package</h2>

            <h3 className={styles.product}><span>{product.name} </span>
          {product.ont_mask_sku && <span className={styles.sku}>{product.ont_mask_sku }</span>} </h3>
            <div className={styles.image}><img src={product.ont_image_url_1} /></div>
            <SummarySections sections={expandedSections} currency={currency} />
            <SummaryTotal product={product} surcharge={surcharge} />
        </div>

    );
}

function SummarySections({sections, currency}) {

    function SummarySection({section}) {
        if (section.items.length) {
            return(
                <div>
                    <h4>{section.label}</h4>
                    <ul>{section.items.map((item,i) => <SectionItem key={i} item={item} />)}</ul>
                </div>
            );
        }
        return null;
    }

    function SectionItem({item}) {
        const qty = item.custom_qty
            ? <strong>{item.custom_qty}</strong>
            : <span>{item.qty}x</span>;
        return (
            <li>
                <div className={styles.left}>{qty} {item.name}</div>
                <div className={styles.right}><ItemCost surcharge={item.surcharge} /></div>
            </li>
        );
    }

    function ItemCost({surcharge}) {
        return (surcharge > 0)
            ? <span><Price currencyCode={currency} value={surcharge} /></span>
            : <span className={styles.included}>Included</span>
    }

    return sections.map((section,i) => <SummarySection key={i} section={section}/>);
}

function SummaryTotal({product, surcharge}) {
    return(
        <ul>
            <li className={styles.total}>
                <div><h2>Total:</h2></div>
                <div><h2><TotalPrice product={product} surcharge={surcharge} /></h2></div>
            </li>
        </ul>
    );
}
