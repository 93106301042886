import React from 'react';
import SectionSubmit from '../../sectionSubmit';
import * as styles from '../../style.module.css';

export default function MyAccount({section, submitSection}) {
    async function submit() {
        submitSection({
            highlight: `No payment required`,
        });
    }

    return(
        <>
            <SectionContent />
            <SectionSubmit callback={submit} disable={false} />
        </>
    );
}

function SectionContent() {
    return(
        <div className={styles.sectionContent}>
            <p>You will be redirected to My Account to take payment.</p>
        </div>
    );
}
