import React from "react";
import * as styles from './style.module.css';

export default function ApprovalWarning() {
    return (
        <div className={styles.approvalWarning}>
            <div className="info-banner info-banner--caution">
                <div className="info-banner__content">
                    <p className="info-banner__text"><strong>Updating your billing details.</strong></p>
                    <p className="info-banner__text">Your order will be shipped once we have reviewed and confirmed these new details.</p>
                </div>
            </div>
        </div>
    );
}
