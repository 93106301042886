import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { useCartContext } from '../../contexts/CartContext';
import { useCart, useUpdateItem, updateContextCart } from '../../hooks/useCart';
import CartItems from './cartItems';
import CartSummary from './cartSummary';
import AdminSection from './AdminSection';
import * as styles from './style.module.css';
import { useCustomerContext, isExpired } from '../../contexts/CustomerContext';

export default function Cart(props) {
    return(
        <div className={styles.cart}>
            <div className={styles.continueShopping}>
                <Link to="/">
                    <FontAwesomeIcon icon={faChevronLeft} />&nbsp;&nbsp;Continue shopping
                </Link>
            </div>
            <h1>Shopping basket</h1>

            <CartContent />
        </div>
    );
}

function getUnexpiredCartId(customer, cart) {
    const isSessionExpired = customer.token && isExpired(customer);
    return isSessionExpired ? null : cart.id;
}

function CartContent() {
    const [updatingItemId, setUpdatingItemId] = useState(0);
    const { cart, setCart, resetCart } = useCartContext();
    const { customer } = useCustomerContext();
    const cartId = getUnexpiredCartId(customer, cart);
    const { loading, error, data, refetch } = useCart(cartId);
    const [updateItemMutation] = useUpdateItem(cartId);

    const [focusKey, setFocusKey] = useState(0);

    function updateItem(itemId, qty) {
        setUpdatingItemId(itemId);
        setFocusKey(itemId);
        updateItemMutation({variables: {cartId: cartId, itemId: itemId, qty: qty}})
            .then(data => {
                refetch();      // updates the graphql cart data
                updateContextCart(cart, setCart, data);     // updates the summary cart in the context
                setUpdatingItemId(0);
            })
            .catch(error => console.log('Error updating cart item', error));
    }

    const CartFinalContent = () => {
        if (loading) {
            return <p>Loading ...</p>;
        }

        if (error) {
            if (cart?.id?.length) resetCart();
            return <p>Your basket is empty.</p>;
        }

        if (!data?.cart?.items?.length) {
            return <p>Your basket is empty.</p>;
        }

        return (
            <div className={styles.cartContent}>
                <CartItems focusKey={focusKey} setFocusKey={setFocusKey} items={data.cart.items} updateItem={updateItem} updatingItemId={updatingItemId} refetchCart={refetch} />
                <CartSummary cartData={data.cart} refetchCart={refetch} />
            </div>
        );
    };

    return(
        <>
            <AdminSection refetchCart={refetch} />
            <CartFinalContent />
        </>
    );
}
