import React from 'react';
import * as styles from "../style.module.css";
import {Link} from "gatsby";
import {isTransactional, shouldShowPrice} from "../../../../hooks/siteData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/pro-light-svg-icons";
import {useTranslation} from "react-i18next";

export const CtaButton = ({target, productOrCategory}) => {
    return (
        <div className={styles.cta}>
            <Link className="button button--primary button--keyline" to={target}>
                <CtaButtonText productOrCategory={productOrCategory} />
            </Link>
        </div>
    );
}

function CtaButtonText({productOrCategory}) {
    const {t} = useTranslation();
    const showPrice = (productOrCategory.sku) ? shouldShowPrice(productOrCategory.sku) : true;
    const label = function() {
        if (!isTransactional() || !productOrCategory.ont_phase_info.allow_purchase || !showPrice || productOrCategory.ont_cta_button) {
            return productOrCategory.ont_cta_button ?? t('Detail');
        }

        return 'Buy';
    }();

    return (
        <span>{label} <FontAwesomeIcon icon={faChevronRight} /></span>
    );
}
