import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { documentation } from './style.module.css';

export default function Documentation({category}) {
    const docs = JSON.parse(category.ont_product_documentation) ?? [];

    return (
        <div className={documentation}>
            <ul>{docs.map((item, i) => <DocumentationItem item={item} key={i} />)}</ul>
        </div>
    );
}

function DocumentationItem({item}) {
    return (
        <li>
            <a href={item.link} target="_blank" rel="noreferrer">
                <span> <FontAwesomeIcon icon={faFilePdf} /> {item.name}</span>
                <span>Download</span>
            </a>
        </li>
    );
}
