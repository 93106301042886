import React, { useState, useLayoutEffect } from 'react';
import { useSetPaymentMethod } from '../../../../../hooks/useCart';
import { useCartContext } from '../../../../../contexts/CartContext';
import { useCheckoutContext } from '../../../../../contexts/CheckoutContext';
import SectionSubmit from '../../sectionSubmit';
import {useCreateCardinalToken, useCreateMicroformToken, usePlaceOrderWithCca, useRedeemQuotationWithCca} from "../../../../Payment/FlexMicroformPayment/hooks/useFlex";
import setUpPayments from "../../../../Payment/FlexMicroformPayment/setup";
import { gql, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCcVisa, faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import * as styles from '../../style.module.css';

export default function CreditCard({section, submitSection}) {
    const { cart, resetCart } = useCartContext();
    const [checkout, setCheckout] = useCheckoutContext();
    const [setPaymentMethodMutation] = useSetPaymentMethod();
    const [cache, setCache] = useState({
        expirationMonth: '',
        expirationYear: '',
    });

    const [createMicroformTokenMutation] = useCreateMicroformToken();
    const [createCardinalTokenMutation] = useCreateCardinalToken();
    const [placeOrderWithCcaMutation] = usePlaceOrderWithCca();
    const [redeemQuotationWithCcaMutation] = useRedeemQuotationWithCca();
    const [setMicroformPaymentDataMutation] = getSetMicroformPaymentDataMutation();
    const [microformState, setMicroformState] = useState({microform: {}});
    const [cardMeta, setCardMeta] = useState({});
    const [cardNumberData, setCardNumberData] = useState({valid: false, empty: true});
    const [securityCodeData, setSecurityCodeData] = useState({valid: false, empty: true});
    const cardType = getMagentoCardType(cardMeta.cybsCardType);

    const isValidDate =
        [1,2].includes(cache.expirationMonth.length)
        && cache.expirationYear.length === 4;

    const isEmptyDate = !(cache.expirationMonth || cache.expirationYear);

    const enableSubmit = cardNumberData.valid && securityCodeData.valid && isValidDate && cardType;

    useLayoutEffect(() => {
        setUpPayments(
            createMicroformTokenMutation,
            createCardinalTokenMutation,
            placeOrderWithCcaMutation,
            redeemQuotationWithCcaMutation,
            microformState,
            setMicroformState,
            cart,
            resetCart,
            setCardNumberData,
            setSecurityCodeData,
            setCardMeta,
            checkout,
            setCheckout
        )
    }, []);

    async function submit() {
        const basicPayload = checkout?.redeemQuote
            ? { quotation_id: checkout.quotationId, dp_code: checkout.dpCode }
            : { cart_id: cart.id };

        microformState.microform.createToken(
            {
                expirationMonth: cache.expirationMonth,
                expirationYear: cache.expirationYear
            },
            async function (err, token) {
                if (token) {
                    const { data } = await setMicroformPaymentDataMutation({
                        variables: {
                            paymentData: {
                                ...basicPayload,
                                token: token,
                                card_type: cardType,
                                exp_date: cache.expirationMonth + '-' + cache.expirationYear
                            }
                        }
                    });
                    if (data?.setMicroformPaymentData.success) {
                        submitSection({
                            highlight: `Card payment: ${cardMeta.brandedName}`,
                        });
                    }
                }
            }
        );
    }

    return(
        <>
            <div className={styles.sectionContent}>
                <ul className={styles.ccForm}>
                    <li>
                        <FontAwesomeIcon icon={faCcVisa} size="2x" color={cardType==='VI' ? '#0084a9' : '#EEEEEE'}/>
                        &nbsp;
                        <FontAwesomeIcon icon={faCcMastercard} size="2x" color={cardType==='MC' ? '#0084a9' : '#EEEEEE'}/>
                    </li>
                    <li>
                        <label><strong>Card number:</strong></label>
                        <div id="number-container" className={styles.flexField} />
                        <InvalidField
                            fieldName="card number"
                            isValid={cardNumberData.valid && cardType}
                            isEmpty={cardNumberData.empty}
                        />
                    </li>
                    <li>
                        <label><strong>Security code (CVV):</strong></label>
                        <div id="securityCode-container" className={styles.flexField} />
                        <InvalidField
                            fieldName="security code"
                            isValid={securityCodeData.valid}
                            isEmpty={securityCodeData.empty}
                        />
                    </li>
                    <li>
                        <label><strong>Expiry date:</strong></label>
                        <ExpiryDate cache={cache} setCache={setCache} />
                        <InvalidField
                            fieldName="expiry date"
                            isValid={isValidDate}
                            isEmpty={isEmptyDate}
                        />
                    </li>
                </ul>
            </div>
            <SectionSubmit callback={submit} disable={!enableSubmit} />
        </>
    );
}

function InvalidField({fieldName, isValid, isEmpty}) {
    return (
        <div style={{height: "20px"}}>
            {!isEmpty && !isValid &&
                <span style={{color: "#EF4135", fontSize: "12px", fontStretch: "75%"}}>
                    Please enter a valid {fieldName}.
                </span>
            }
        </div>
    );
}

function ExpiryDate({cache, setCache}) {

    function update(month, year) {
        const today = new Date();
        const thisMonth = (today.getMonth() + 1).toString().padStart(2, "0");
        const thisYear = today.getFullYear().toString();
        setCache({
            ...cache,
            expirationMonth: month ?? thisMonth,
            expirationYear: year ?? thisYear,
        });
    }

    return(
        <div className={styles.expiryDate}>
            <input
                type="text" min="1" max="31" placeholder="MM" size="3" maxLength="2"
                value={cache.expirationMonth}
                onChange={e => update(e.target.value, cache.expirationYear)}
                autoComplete="cc-exp-month"
            />
            &nbsp;/&nbsp;
            <input
                type="text" min="2021" max="2050" placeholder="YYYY" size="5" maxLength="4"
                value={cache.expirationYear}
                onChange={e => update(cache.expirationMonth, e.target.value)}
                autoComplete="cc-exp-year"
            />
        </div>
    );
}

function getSetMicroformPaymentDataMutation() {
    return useMutation(gql`
        mutation SetMicroformPaymentData($paymentData: SetMicroformPaymentDataInput!) {
            setMicroformPaymentData(input: $paymentData) {
                success
            }
        }
    `);
}

function getMagentoCardType(cybsCardType) {
    switch(cybsCardType) {
        case '001': return 'VI';
        case '002': return 'MC';
    }
    return '';
}
