import React from 'react';
import { NanoFileUpload } from '@nanoporetech-digital/components-react';
import * as styles from './style.module.css';

export default function UploadCertificate({setUpload}) {
    function addFile(e) {
        if (e.target.files?.length) {
            const file = e.target.files[e.target.files.length - 1]
            setUpload(file);
        }
    }

    return (
        <div className={styles.certificateUpload}>
            <NanoFileUpload
                name="certificate"
                className="multi"
                accept="image/png, image/jpeg, application/pdf, image/tiff, image/x-tiff, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                label="Please attach your exemption certificate*"
                max-file-size="2"
                max-files="2"
                onNanoChange={e => addFile(e)}
            >
                <span slot="helper">Accepted formats: png, jpg, pdf, tif, doc, docx, xls, xlsx</span>
            </NanoFileUpload>
        </div>
    );
}
