import React from 'react';
import { NanoSelect, NanoOption } from '@nanoporetech-digital/components-react';

export default function CountrySelect({countries, formData, updateFormData, prefix}) {
    if (!countries) return null;

    const placeholder = 'Please select a country...'

    return (
        <NanoSelect
            label="Country*"
            placeholder={placeholder}
            required="true"
            validateOn="submitThenDirty"
            showInlineError={true}
            name={`${prefix}country_code`}
            value={formData.country_code ?? ''}
            onNanoChange={e => updateFormData(e)}
        >
            <CountryOptions countries={countries} placeholder={placeholder}/>
        </NanoSelect>
    );
}

function CountryOptions({countries, placeholder}) {
    countries = [...countries].sort((a,b) => {
        const valA = a.full_name_english ?? a.id;
        const valB = b.full_name_english ?? b.id;

        if (valA < valB) return -1;
        if (valA > valB) return 1;
        return 0;
    });

    return <>
        <NanoOption key={placeholder} value={''} label={placeholder} />

        {countries.map(({id, full_name_english}) =>
            <NanoOption key={'key-' + id} value={id} label={full_name_english}/>
        )}
    </>;
}
