import React from 'react';
import Catalog from "../../Catalog";
import {useLazyQuery, gql} from "@apollo/client";
import {catalogFragments} from "../../../fragments/live/catalog";
import {catalogNavigationFragments} from "../../../fragments/live/catalog";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function LiveCatalog({categoryId, isHome=false}) {
    const [makeActiveCategoryQuery, activeCategoryResult] = useLazyQuery(
        liveActiveCategoryQuery,
        {
            variables: {
                categoryId: categoryId,
            },
            fetchPolicy: 'network-only',
        }
    );
    const [makeNavigationQuery, navigationResult] = useLazyQuery(
        liveNavigationQuery,
        {
            fetchPolicy: 'network-only',
        }
    );

    if (!activeCategoryResult.called) {
        makeActiveCategoryQuery();
    };
    if (!navigationResult.called) {
        makeNavigationQuery();
    }

    if (activeCategoryResult.loading || navigationResult.loading) {
        return (
            <center><br/><FontAwesomeIcon icon={faSpinner} size="lg" spin /></center>
        );
    }

    if (activeCategoryResult.called && activeCategoryResult.data
        && navigationResult.called && navigationResult.data
    )
    {
        return <Catalog
            category={activeCategoryResult.data.categoryList[0]}
            categories={navigationResult.data.categoryList[0].children}
            filterAttributes={navigationResult.data.customAttributeMetadata.items}
            liveMode={true}
            isHome={isHome}
        />;
    }

    return null;
}

export const liveActiveCategoryQuery = gql`
    query categoryList ($categoryId: String) {
        categoryList(filters: { ids: { eq: $categoryId } }) {
            id
            ...categoryContent
            children {
                id
                ...categoryContent
            }
        }
    }

    ${catalogFragments}
`;


export const liveNavigationQuery = gql`
    query mainQuery {
        customAttributeMetadata (
            attributes:
            [
                {
                    attribute_code: "ont_multiplexing"
                    entity_type: "4"
                },
                {
                    attribute_code: "ont_approach"
                    entity_type: "4"
                },
                {
                    attribute_code: "ont_sample_type"
                    entity_type: "4"
                }
            ]
        ) {
            items {
                ...navigationFilterAttributeData
            }
        }

        categoryList(filters: { name: { match: "Default Category" } }) {
            children {
                ...navigationCategoryData
                products {
                    items {
                        ...navigationProductData
                    }
                }
                children {
                    ...navigationCategoryData
                    children {
                        ...navigationCategoryData
                    }
                }
            }
        }
    }

    ${catalogNavigationFragments}
`;
