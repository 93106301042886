import PRODUCT_CONFIG from "../productConfig.json";

function getProductConfig() {
    return PRODUCT_CONFIG;
}

export function shouldAllowChemistrySwitch(sku) {
    const {preventChemistryTypeSwitch} = getProductConfig();
    return preventChemistryTypeSwitch.indexOf(sku) === -1;
}

export function shouldPreventChemistrySwitch(sku) {
    return !shouldAllowChemistrySwitch(sku);
}
