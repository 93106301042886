import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { NanoInput } from '@nanoporetech-digital/components-react';
import UiButton from '../Ui/UiButton';
import { useCartContext } from '../../contexts/CartContext';
import { useApplyCoupon, updateContextCart, useRemoveCoupon } from '../../hooks/useCart';
import * as styles from './style.module.css';

export default function CartPromo({cartData, refetchCart}) {
    const [reveal, setReveal] = useState(false);

    if (reveal) return (
        <div>
            <FontAwesomeIcon icon={faChevronDown} />&nbsp;&nbsp;
            <a onClick={() => setReveal(!reveal)}>Cancel</a>
            <PromoCode cartData={cartData} refetchCart={refetchCart} />
        </div>
    );

    return(
        <div>
            <FontAwesomeIcon icon={faChevronRight} />&nbsp;&nbsp;
            <a onClick={() => setReveal(!reveal)}>Add promo code</a>
        </div>
    );
}

function PromoCode({cartData, refetchCart}) {

    const [inputCode, setInputCode] = useState('');
    const [showError, setShowError] = useState(false);
    const [applyCouponMutation] = useApplyCoupon(cartData.id);
    const [removeCouponMutation] = useRemoveCoupon(cartData.id);
    const { cart, setCart } = useCartContext();
    const appliedCoupon = cartData.applied_coupon?.code;

    function applyPromo(e) {
        e.preventDefault();
        applyCouponMutation({variables: {cartId: cartData.id, couponCode: inputCode}})
            .then(data => {
                refetchCart();
                updateContextCart(cart, setCart, data);
                setShowError(false);
            })
            .catch(error => setShowError(true));
    }

    function removePromo() {
        removeCouponMutation({variables: {cartId: cartData.id}})
            .then(data => {
                refetchCart();
                updateContextCart(cart, setCart, data);
            })
            .catch(error => console.log('Error removing discount code', error));
    }

    function ErrorMessage() {
        if (showError) {
            return <div className={styles.error}>Unable to apply this code</div>;
        }
        return null;
    }

    if (appliedCoupon) {
        return(
            <div className={styles.promoForm}>
                <span className={styles.appliedPromoCode}>{appliedCoupon}</span>
                <a className={styles.removePromoCode} onClick={() => removePromo()}>Remove</a>
            </div>
        );
    }

    return(
        <form className={styles.promoForm} onSubmit={() => applyPromo()}>
            <div className={styles.promoInput}>
                <NanoInput
                clear-input="true"
                hide-label="true"
                placeholder="Enter promo code..."
                required="false"
                show-inline-error="false"
                value={inputCode}
                onNanoChange={e => setInputCode(e.target.value)}
                />
                <ErrorMessage />
            </div>
            <div className={styles.promoButton}>
                <UiButton
                className="button button--secondary button--keyline"
                onClick={e => applyPromo(e)}
                type="submit"
                >Apply</UiButton>
            </div>
        </form>
    );
}
