import React, { useState } from "react";
import { saveSection } from '../helpers';
import { useCheckoutContext } from '../../../../contexts/CheckoutContext';
import SectionHeader from '../sectionHeader';
import TaxRouter from "./TaxRouter";
import * as styles from '../style.module.css';

export const CODE = 'tax';
export const TITLE = 'Tax status';

export default function Tax({step, cartData}) {
    const [checkout, setCheckout] = useCheckoutContext();
    const isActive = checkout.activeStep===step;

    const section = checkout[CODE] ?? {};

    // local state to avoid global re-render on small changes
    const [cache, setCache] = useState({
        mode: 'edit',
        tax_info: null
    });

    async function submit() {
        const highlight = cache.tax_info ? 'On file' : '';

        saveSection(CODE, checkout, setCheckout, section, {
            canChange: true,
            highlight: highlight,
            tax_info: cache.tax_info,
        });
    }

    return(
        <div className={styles.section}>
            <SectionHeader step={step} code={CODE} title={TITLE} />
            {isActive && <SectionContent cartData={cartData} sectionCache={{data: cache, set: setCache, submit: submit}}/>}
        </div>
    );
}

function SectionContent(props) {
    return (
        <div className={styles.sectionContent}>
            <TaxRouter {...props} />
            <SubmitStatus />
        </div>
    );
}

function SubmitStatus() {
    const [checkout] = useCheckoutContext();

    return (checkout.submitStatus)
        ? <div className={styles.submitStatus}>{checkout.submitStatus}</div>
        : null;
}
