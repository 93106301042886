import React, {createContext, useContext, useState} from 'react';

const emptyCheckout = {
    activeStep: 1,
    submitStatus: null,
};

const CheckoutContext = createContext(emptyCheckout);

export function CheckoutContextProvider(props) {

    const [checkout, setCheckout] = useState(emptyCheckout);

    return (
        <CheckoutContext.Provider value={[checkout, setCheckout]}>
            {props.children}
        </CheckoutContext.Provider>
    );
}

export const useCheckoutContext = () => useContext(CheckoutContext);
