import React from 'react';
import Price from "../Ui/Price";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "gatsby";
import CartPromo from './cartPromo';
import { getTotalCarriage, getCartTaxAmount, pluralise } from '../Checkout/Sections/helpers';
import * as styles from './style.module.css';

export default function CartSummary({cartData, mode='cart', hideTaxes=false, shippingSchedule={}, refetchCart=null}) {
    const totals = cartData.prices;
    const numItems = cartData?.items?.length ?? 0;

    function Discount() {
        const discount = cartData.prices.discount;

        if (discount) {
            const discountValue = discount.amount.value;
            const basketValue = totals.subtotal_excluding_tax.value;
            const displayValue = (mode=='cart' && Math.abs(discountValue) > basketValue) ? (-basketValue) : discountValue;

            return(
                <li>
                    <div className={styles.left}>Discount:</div>
                    <div className={styles.right}><Price currencyCode={discount.amount.currency} value={displayValue} /></div>
                </li>
            );
        }

        return null;
    }

    function Tax() {
        const taxAmount = getCartTaxAmount(cartData);
        if (taxAmount.value <= 0 || mode==='cart' || hideTaxes) {
            return 'To be calculated';
        }

        return <Price currencyCode={taxAmount.currency} value={taxAmount.value} />;
    }

    function Subtotal() {
        const displayTotal = {...totals.grand_total};
        if (mode == 'cart' || totals.subtotal_with_discount_excluding_tax.value == 0) {
            displayTotal.value = totals.subtotal_with_discount_excluding_tax.value;
        } else {
            if (hideTaxes) {
                const taxAmount = getCartTaxAmount(cartData);
                displayTotal.value -= taxAmount.value;
            }
        }

        const displayValue = (displayTotal.value < 0) ? 0 : displayTotal.value;
        return <Price currencyCode={displayTotal.currency} value={displayValue} />;
    }

    return(
        <div  className={styles.summaryBox}>
            <h2>{mode=='quote' ? 'Quote' : 'Order'} summary</h2>
            <div>
                <ul>
                    <li>
                        <div className={styles.left}>{numItems ? pluralise(numItems, 'basket item') : 'Basket items'}</div>
                        <div className={styles.right}><Price currencyCode={totals.subtotal_excluding_tax.currency} value={totals.subtotal_excluding_tax.value} /></div>
                    </li>
                    <Discount />
                    <Delivery cartData={cartData} shippingSchedule={shippingSchedule} mode={mode} />
                    <li>
                        <div className={styles.left}>Tax:</div>
                        <div className={styles.right}><Tax /></div>
                    </li>
                    <li>
                        <div className={styles.left}>{mode=='checkout' ? 'Order total' : 'Subtotal'}:</div>
                        <div className={styles.right}><Subtotal /></div>
                    </li>
                </ul>
            </div>
            <CartOnly cartData={cartData} isCartMode={mode==='cart'} refetchCart={refetchCart} />
       </div>
    );
}

function CartOnly({cartData, isCartMode, refetchCart=null}) {
    if (isCartMode) {
        return (
            <>
                <CartPromo cartData={cartData} refetchCart={refetchCart} />
                <div className={styles.cta}>
                    <div>
                        <Link className="button button--secondary button--keyline" to="/createquote">
                            <span><FontAwesomeIcon icon={faFilePdf} />&nbsp;&nbsp;Create a quote</span>
                        </Link>
                    </div>
                    <div>
                        <Link className="button button--secondary" to="/checkout">
                            <span>Proceed to checkout</span>
                            <span><FontAwesomeIcon icon={faChevronRight} size="sm" /></span>
                        </Link>
                    </div>
                </div>
            </>
        );
    }

    return null;
}

function Delivery({cartData, shippingSchedule, mode}) {
    if (cartData?.shipping_addresses?.length && cartData.shipping_addresses[0].selected_shipping_method && mode!='cart') {
        const totalCarriage = getTotalCarriage(cartData);

        if (totalCarriage > 0)
        {
            return(
                <li>
                    <div className={styles.left}>
                        Delivery
                        {(shippingSchedule.device_carriage > 0) && <small> (consumables & device)</small>}:
                    </div>
                    <div className={styles.right}>
                        <Price currencyCode={cartData.shipping_addresses[0].selected_shipping_method.amount.currency} value={totalCarriage} />
                    </div>
                </li>
            );
        }
    }

    return(
        <li>
            <div className={styles.left}>Delivery:</div>
            <div className={styles.right}>To be calculated</div>
        </li>
    );
}
