export function isTaxInfoComplete(taxInfo, taxSystem)
{
    const taxStatus = taxInfo?.tax_status;
    switch(taxSystem.tax_system_code) {
        case 'salestax':
            if (taxStatus === 'pay_tax') return true;
            if (taxStatus === 'tax_exempt' && taxInfo?.certificate) return true;
            return false;
        case 'vat':
            if (taxStatus === 'not_registered') return true;
            if (taxStatus === 'vat_registered' && taxInfo?.vat_number) return true;
            return false;
    }
    
    return false;
}

export function updateCachedTaxInfo(sectionCache, key, value) {
    const taxInfo = sectionCache.data.tax_info;
    sectionCache.set({
        ...sectionCache.data,
        tax_info: {
            ...taxInfo,
            [key]: value
        }
    });
}

export function isUsTaxExemptCreditCard(checkout, taxSystem, taxInfo) {
    return (
        taxSystem.country_code === 'US' 
        && taxInfo?.tax_status === 'tax_exempt'
        && checkout.payment_method?.selected_payment_method === 'chcybersource'
    );
}
