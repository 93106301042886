import React from 'react';
import { NanoFileUpload } from '@nanoporetech-digital/components-react';
import * as styles from '../style.module.css';

export default function AdditionalUploads({cache, setCache}) {

    function updateCache(e) {
        const formFiles = (e.target.files?.length && !e.target.invalid) ? e.target.files : [];
        const cacheFiles = [];
        formFiles.forEach(file => cacheFiles.push(file));
        setCache({...cache, additional_uploads: cacheFiles});
    }

    return(
        <div className={styles.additionalUploads}>
            <h3>Upload additional documents</h3>
            <p>If you would like to submit any documents that you think may help us process your order better, please upload them here:</p>
            <NanoFileUpload
                name="additional_file"
                className="multi"
                accept="image/png, image/jpeg, application/pdf, image/tiff, image/x-tiff, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                maxFileSize="2"
                maxFiles="2"
                validateOn="dirty"
                onNanoChange={e => updateCache(e)}
            >
                <span slot="helper">Accepted formats: png, jpg, pdf, tif, doc, docx, xls, xlsx</span>
            </NanoFileUpload>
        </div>
    );
}
