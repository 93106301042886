import React, { useState } from 'react';
import useGenerateCustomerTokenFromRemoteToken from './useGenerateCustomerTokenFromRemoteToken';
import LoggingIn from '../Status/loggingIn';
import TokenError from '../Status/tokenError';
import CartError from '../Status/cartError';
import useLogin, { resetCredentials } from '../hooks/useLogin';

export default function Remote({code}) {
    const [customerTokenMutation, {called: customerTokenMutationCalled, data: customerTokenData, error: customerTokenError}] = useGenerateCustomerTokenFromRemoteToken(code);
    const [cartError, setCartError] = useState({});
    const newToken = customerTokenData?.generateCustomerTokenFromRemoteToken?.token;
    const performLogin = useLogin('/', setCartError, newToken);

    resetCredentials(!customerTokenMutationCalled);

    if (!customerTokenMutationCalled) {
        customerTokenMutation().then(data => {
            performLogin(data.data.generateCustomerTokenFromRemoteToken, {is_remote_mode: true});
        });
    }

    if (customerTokenError) {
        return <TokenError errorObject={customerTokenError} />
    }

    if (cartError && Object.keys(cartError).length > 0) {
        return <CartError errorObject={cartError} />
    }

    return <LoggingIn />;
}
