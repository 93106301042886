import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import { navigate } from 'gatsby';
import {useWebStoreContext} from "../../contexts/WebStoreContext";
import CONTINENTS_COUNTRIES from "./continentsCountries.json"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import { isDistributorCountry } from "../../utils/webStore";
import * as styles from './style.module.css';
import { NanoGrid } from '@nanoporetech-digital/components-react';
import DistributorModal from "./DistributorModal";

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../../assets/flags', false, /\.(png|jpe?g|svg)$/));

export default function CountrySelect(props) {
    const {webstore, setWebstore} = useWebStoreContext();
    const [textFilter, setTextFilter] = useState('')
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalCountryIso, setModalCountryIso] = useState('')

    const handleCountryClick = countryIso => {
        if (isDistributorCountry(countryIso)) {
            setModalCountryIso(countryIso)
            setModalIsOpen(true)
        }
        setWebstore({
            ...webstore,
            countryIsoPreference: countryIso,
            designatedStore: '',
        })
        if (isDistributorCountry(countryIso)) {
            setModalCountryIso(countryIso);
            setModalIsOpen(true);
        } else {
            navigate('/');
        }

    }

    return (
        <div className="contentWrapper">
            <Helmet>
                <title>Nanopore store: Choose country</title>
            </Helmet>

            <div className={styles.controls}>
                <TextFilter textFilter={textFilter} setTextFilter={setTextFilter} />
            </div>

            {CONTINENTS_COUNTRIES
                .filter(continent =>
                    continent.countries.findIndex(country =>
                        country.label.toLowerCase().includes(textFilter.toLowerCase())
                    ) !== -1
                )
                .map(continent =>
                    <div key={continent.code} className={styles.countriesContainer}>
                        <h2>{continent.label}</h2>

                        <NanoGrid l-cols="6" s-cols="4" xl-cols="10" className={styles.countries}>
                            {continent.countries
                                .filter(country =>
                                    country.label.toLowerCase().includes(textFilter.toLowerCase())
                                )
                                .map(country =>
                                    <div  grid-states="xl-col-span-1 l-col-span-1 s-col-span-1" key={country.code} className={styles.countryFlag}>
                                        <img width="24" src={images[country.code.toLowerCase() + '.svg'].default} />
                                        <a href="#"
                                           onClick={
                                               () => handleCountryClick(country.code)
                                           }
                                        >{country.label}</a>
                                    </div>
                                )
                            }
                        </NanoGrid>
                    </div>
                )
            }
            <DistributorModal
                isOpen={modalIsOpen}
                modalCountryIso={modalCountryIso}
                setModalIsOpen={setModalIsOpen}
            />
        </div>
    )
}

const TextFilter = ({textFilter, setTextFilter}) => {
    return (
        <div className={styles.filter}>
            <input
                className="form__entry-input"
                data-cy="countrySearch"
                placeholder="Search"
                type="text"
                value={textFilter}
                onChange={e => setTextFilter(e.currentTarget.value)}
            />
            <span><FontAwesomeIcon icon={faSearch} /></span>
        </div>
    )
}
