import React from 'react';
import Price from "../../../Ui/Price";
import * as styles from './style.module.css';
import { format } from "date-fns";
import {TogglePrice} from "../../../../hooks/siteData";
import { NanoGrid } from '@nanoporetech-digital/components-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default function Event(props) {
    const {event, price, selectedLocation, isSelected} = props;

    const places = event.capacity - props.sold;
    const showEvent =
        (props.showSoldout || places > 0) &&
        (!selectedLocation || selectedLocation === event.location ) &&
        isEventInTheFuture(event);

    if (!showEvent) {
        return null;
    }

    function LimitedAvailability() {
        if (places > 0 && places < 5) {
            return (
                <>
                    <br/>
                    <span className={styles.badge}>Limited availability</span>
                </>
            );
        }
        return null;
    };

    function EventDate() {
        const dateString = event.date;
        const date = getDateInContentfulTimezone(dateString);
        const formatted = format(date, "E, do MMMM, y");
        return <span>{formatted}</span>;
    };

    return (
        <NanoGrid xxlCols="5" xlCols="5" className={styles.eventRow}>
            <div className={styles.eventCol}>
                {event.location}
            </div>
            <div className={styles.eventCol}>
                <strong>{event.name}</strong>
                <LimitedAvailability />
            </div>
            <div className={styles.eventCol}>
                <EventDate />
            </div>
            <div className={styles.eventCol}>
                {props.showPrice && <TogglePrice><Price currencyCode={price.currency} value={price.value} /></TogglePrice>}
            </div>
            {places > 0 && <div className={styles.eventCol}>
                {isSelected && <FontAwesomeIcon icon={faCheck} color="#17bb75" />}
                <button
                    className="button button--primary button--keyline"
                    type="button"
                    onClick={() => props.toggleEventSelection(event)}
                >
                    Select
                </button>
            </div>}
        </NanoGrid>
    );
}

function isEventInTheFuture(event) {
    if (typeof window==='undefined' || !event.date) return true;

    const eventDate = new Date(event.date);
    return (eventDate > new Date());
}

function getDateInContentfulTimezone(dateString) {
    const date = new Date(dateString);

    if (dateString.length > 23) {
        let offset = dateString.substr(23);
        if (offset.length !== 6) {
            offset = '+00:00';
        }

        const sign = offset.substr(0,1);
        const hours = offset.substr(1,2);
        const mins = offset.substr(4,2);
        const utcToContentfulMins = ((parseInt(hours) * 60) + parseInt(mins)) * ((sign == '+') ? 1 : -1);
        const utcToLocalMins = date.getTimezoneOffset();
        const totalOffsetMS = (utcToContentfulMins + utcToLocalMins) * 60000;
        date.setTime(date.getTime() + totalOffsetMS);
    }

    return date;
}
