import React from 'react';

export default function NotFound() {
    return (
        <>
            <div>
                <h1>Page not found</h1>
                <p>The page you requested was not found, here are a few reasons why this may have happened.</p>
                <ul>
                    <li>If you typed the URL directly, please make sure the spelling is correct.</li>
                    <li>If you clicked on a link to get here, the link may be outdated</li>
                </ul>
                <h3>What can you do?</h3>
                <p>There are many ways you can get back on track with the store.</p>
                <ul>
                    <li>Go back to the previous page.</li>
                    <li>Use the search bar at the top of the page to search for your products.</li>
                    <li>
                        Follow these links to get you back on track<br/>
                        <a href="/">Store Home</a> | <a href="//myaccount.nanoporetech.com/profile">My Account</a>
                    </li>
                </ul>
            </div>
            <style jsx>{`
                div {margin: 4rem;}
                li  {font-size: smaller;}
            `}</style>
        </>
    );
}
