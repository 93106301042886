import {useQuery, useMutation, gql} from "@apollo/client"
import {orderFields, payerAuthenticationFields, redeemQuotationFields} from "../../../../fragments/live/order";

export function useCreateMicroformToken() {
    return useMutation(CREATE_MICROFORM_TOKEN_GQL)
}

export function usePlaceOrder() {
    return useMutation(PLACE_ORDER_GQL)
}

export function useCreateCardinalToken() {
    return useMutation(CREATE_CARDINAL_TOKEN_GQL)
}

export function usePlaceOrderWithCca() {
    return useMutation(RETRY_ORDER_GQL)
}

export function useRedeemQuotationWithCca() {
    return useMutation(RETRY_REDEEM_GQL);
}


const CREATE_MICROFORM_TOKEN_GQL = gql`
    mutation ($requestData: CreateMicroformTokenInput){
        createMicroformToken (input: $requestData) {
            token
        }
    }
`

const PLACE_ORDER_GQL = gql`
    mutation ($paymentData: SetMicroformPaymentDataInput!, $orderData: PlaceOrderInput!) {
        setMicroformPaymentData(input: $paymentData) {
            success
        }

        placeOrder(input: $orderData) {
            order {
                ...orderFields
            }

            payer_authentication {
                ...payerAuthenticationFields
            }
        }
    }
    ${orderFields}
    ${payerAuthenticationFields}
`

const CREATE_CARDINAL_TOKEN_GQL = gql`
    mutation ($requestData: CreateCardinalTokenInput) {
        createCardinalToken (input: $requestData) {
            success
            token
        }
    }
`

const RETRY_ORDER_GQL = gql`
    mutation ($ccaData: SetPaymentCcaResponseInput, $orderData: PlaceOrderInput) {
        setPaymentCcaResponse (
            input: $ccaData
        ) {
            success
        }

        placeOrder(input: $orderData) {
            order {
                ...orderFields
            }

            payer_authentication {
                ...payerAuthenticationFields
            }
        }
    }
    ${orderFields}
    ${payerAuthenticationFields}
`

const RETRY_REDEEM_GQL = gql`
    mutation ($ccaData: SetPaymentCcaResponseInput, $orderData: RedeemQuotationInput) {
        setPaymentCcaResponse (
            input: $ccaData
        ) {
            success
        }

        redeemQuotation(input: $orderData) {
            order {
                ...redeemQuotationFields
            }

            payer_authentication {
                ...payerAuthenticationFields
            }
        }
    }
    ${redeemQuotationFields}
    ${payerAuthenticationFields}
`
