import useLocalStorage from '../utils/useLocalStorage';
import { isProduction, getMyAccountEnvironment } from '../hooks/useEnvironment';
import { getUrlPathForCurrentStore } from '../hooks/siteData';

export default function useLoginLink() {
    if (typeof window === 'undefined') {
        // Not a browser
        return null;
    }

    const url = new URL(window.location.href);
    const isAuthUrl = url.pathname.includes('authenticate');
    const newDest = url.pathname + url.search;
    const [authDestUrl, setAuthDestUrl] = useLocalStorage('ont_auth_dest_path');

    if (isAuthUrl) {
        if (!authDestUrl) setAuthDestUrl('/');
    } else {
        if (authDestUrl !== newDest) setAuthDestUrl(newDest);
    }

    const link = isProduction()
        ? "https://id.customers.nanoporetech.com/oauth2/default/v1/authorize?client_id=0oa2in4i83mqhlXqI697&response_type=code&state=default&scope=openid&redirect_uri="
        : "https://id.customers-preview.nanoporetech.com/oauth2/default/v1/authorize?client_id=0oa5b34xzsGdTY1721d7&response_type=code&state=default&scope=openid&redirect_uri=";

    return link + getAuthenticateUrl();
}

export function getAuthenticateUrl() {
    if (typeof window === 'undefined') {
        // Not a browser
        return null;
    }

    const url = new URL(window.location.href);
    return `${url.origin}/authenticate/`;
}

export function getReAuthenticateUrl() {
    if (typeof window === 'undefined') {
        // Not a browser
        return null;
    }

    const url = new URL(window.location.href);
    return `${url.origin}/reauthenticate/`;
}

export function getCartUrl() {
    if (typeof window === 'undefined') {
        // Not a browser
        return null;
    }

    const url = new URL(window.location.href);
    const path = getUrlPathForCurrentStore();
    return `${url.origin}${path}cart/`;
}

export function getSsoLogoutUrl() {
    const myAccountEnv = getMyAccountEnvironment();
    const reAuthUrl = getReAuthenticateUrl();

    if (typeof window === 'undefined') {
        // Not a browser
        return null;
    }

    return `https://${myAccountEnv}.nanoporetech.com/sso/logout?RelayState=${reAuthUrl}`;
}
