import React from 'react';
import { Helmet } from "react-helmet";
import QuickOrder from '../components/QuickOrder';

export default function OrderPage() {
    if (typeof window==='undefined') return null;

    return (
        <>
            <Helmet>
                <title>Quick Order</title>
            </Helmet>
            <QuickOrder />
        </>
    );
}
