import { getBundlePrice, getCheapestBundle, getDearestBundle } from "../bundleHelpers";

export function getSurcharges(category) {
    const bundleProducts = category.products.items;
    return {
        base: getCheapestBundlePrice(bundleProducts),
        enhancedPack: getEnhancedPackSurcharge(bundleProducts),
        rapidStart: getRapidStartSurcharge(bundleProducts)
    }
}

function getEnhancedPackSurcharge(bundleProducts) {
    const cheapestPrice = getCheapestBundlePrice(bundleProducts);
    const dearestProduct = getDearestBundle(bundleProducts);
    const dearestPrice = getBundlePrice(dearestProduct);
    const surcharge = Math.abs(dearestPrice.value - cheapestPrice.value);
    return {currency: cheapestPrice.currency, value: surcharge};
}

function getRapidStartSurcharge(bundleProducts) {
    return getBundlePrice(bundleProducts[0].related_products[0]);
}

function getCheapestBundlePrice(bundleProducts) {
    const cheapestBundle = getCheapestBundle(bundleProducts);
    return getBundlePrice(cheapestBundle);
}

