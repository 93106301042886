import React, {useState} from 'react';
import { useCustomerContext } from '../../contexts/CustomerContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import Price from "../Ui/Price";
import AdminPrice from './AdminPrice';
import * as styles from './style.module.css';
import useQuantity from "../ProductQuantity/useQuantity";

export default function CartItems({focusKey, setFocusKey, items, updateItem, updatingItemId, refetchCart}) {
    const { customer } = useCustomerContext();

    return (
        <ul className={styles.itemList}>
            {items.map(item => <CartItem focusKey={focusKey} setFocusKey={setFocusKey} key={item.id} item={item} updateItem={updateItem} updatingItemId={updatingItemId} refetchCart={refetchCart} isAdmin={customer.is_admin_mode} />)}
        </ul>
    );
}

function CartItem({focusKey, setFocusKey, item, updateItem, updatingItemId, refetchCart, isAdmin}) {
    const product = item.product;

    const ItemStats = () => {
        if (item.id===updatingItemId) {
            return <div className={styles.stats}><center><FontAwesomeIcon icon={faSpinner} spin size="lg" /></center></div>
        }

        return(
            <div className={styles.stats}>
                <div>
                    <strong>Quantity: </strong>
                    <ItemQuantity focusKey={focusKey} setFocusKey={setFocusKey} item={item} updateItem={updateItem} isAdmin={isAdmin} />
                </div>
                <div className={styles.itemValues}>
                    <span>Item price: <Price currencyCode={item.prices.price.currency} value={item.prices.price.value} /></span><br/>
                    <strong>Subtotal: <Price currencyCode={item.prices.row_total.currency} value={item.prices.row_total.value} /></strong>
                </div>
            </div>
        );
    };

    return(
        <li className={styles.item}>
            <div className={styles.image}><img src={product.ont_image_url_1} /></div>
            <div className={styles.details}>
                <div>
                    <h3>{product.name} <span className={styles.sku}>{product.ont_mask_sku ?? product.sku}</span></h3>
                    <ExtraInfo item={item} />
                </div>
                <div>
                    <a onClick={() => updateItem(item.id, 0)}>Remove from basket</a>
                </div>
                <AdminPrice item={item} refetchCart={refetchCart} />
            </div>
            <ItemStats />
        </li>
    );
}

function ExtraInfo({item}) {
    if (item.configurable_options?.length) {
        return (
            <ul className={styles.extraInfo}>
                {item.configurable_options.map((option,i) =>
                    <li key={i}>{option.option_label}: {option.value_label}</li>)}
            </ul>
        );
    }

    if (item.bundle_options?.length) {
        return <BundleOptions options={item.bundle_options} />;
    }

    if (item.product.ont_included_products) {
        return <IncludedProducts included={item.product.ont_included_products} />;
    }

    return null;
}

function BundleOptions({options}) {
    console.log(options);
    const consolidated = {};
    options.forEach(option => {
        option.values.forEach(selection => {
            const sku = selection.sku;
            const accumulated = (typeof consolidated[sku] === 'undefined') ? 0 : consolidated[sku].quantity;
            consolidated[sku] = {
                quantity: accumulated + selection.quantity,
                label: selection.label,
                sku: selection.ont_mask_sku ?? sku,
                ont_included_products: selection.ont_included_products,
            }
        });
    });

    const skus = Object.keys(consolidated);

    return (
        <ul className={styles.bundleOptions}>
            {skus.map(sku => <BundleSelection key={sku} selection={consolidated[sku]} />)}
        </ul>
    );
}

function BundleSelection({selection}) {
    if (selection.sku === 'CARRIAGE') return null;
    return (
        <li>
            {selection.quantity}x {selection.label}
            <span className={styles.sku}> <small>{selection.sku}</small></span>
            <IncludedProducts included={selection.ont_included_products} />
        </li>
    );
}

function IncludedProducts({included}) {
    const includedItems = JSON.parse(included);

    if (!includedItems?.length) return null;

    const IncludedItem = ({label, sku}) => <span className={styles.includedProduct}>&nbsp;<FontAwesomeIcon icon={faCheck} size="sm" color="#17bb75" /> {label} <span className={styles.sku}><small>{sku}</small></span></span>;

    return includedItems.map((item, i) => {
        switch (item.sku) {
            case 'EXP-FLP002':
                return <IncludedItem key={i} label="Includes a Flow Cell Priming Kit" sku={item.sku} />;
            default:
                return <IncludedItem key={i} label="Includes" sku={item.sku} />;
        }
    });
}

function ItemQuantity({focusKey, setFocusKey, item, updateItem, isAdmin}) {
    const plusMinusProducts = isAdmin
        ? ['simple', 'virtual', 'bundle', 'configurable']
        : ['simple', 'virtual', 'bundle'];

    const qty = 0; const setQty = 0;

    const [
        quantityValue,
        quantityComponent
    ] = useQuantity(
        item.quantity,
        item.id,
        (newQty) => {updateItem(item.id, newQty)},
        null,
        1,
        focusKey,
        setFocusKey
    );

    if (plusMinusProducts.includes(item.product.type_id) && item.product.ont_render_type !== 'CONFIGURATOR') {
        return(
            <div className={styles.itemQty}>
                {quantityComponent}
            </div>
        );
    }

    return item.quantity;
}
