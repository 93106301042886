import React from 'react';
import { useCheckoutContext } from '../../../../../contexts/CheckoutContext';
import { NanoInput } from '@nanoporetech-digital/components-react';
import * as styles from './style.module.css';

export default function QuotationInfo({cache, setCache}) {
    const [checkout] = useCheckoutContext();

    function update(e) {
        setCache({
            ...cache,
            quotation_number: e.target.value,
        });
    }

    if (checkout.redeemQuote) {
        return null;    // if we are redeeming a quote we already know the quotation_number
    }

    return (
        <div className={styles.quotationInfo}>
            <NanoInput
                type="text"
                maxlength="6"
                required={false}
                validateOn="dirty"
                showInlineError={false}
                name="quotation_number"
                value={cache.quotation_number}
                label="Quotation number (optional)"
                onNanoChange={e => update(e)}
            />
        </div>
    );
}
