// extracted by mini-css-extract-plugin
export var article = "style-module--article--2701e";
export var checkbutton = "style-module--checkbutton--da2be";
export var chevron = "style-module--chevron--823d4";
export var closeLabel = "style-module--closeLabel--e7adb";
export var mobileMenuToggle = "style-module--mobileMenuToggle--02c10";
export var navigation = "style-module--navigation--00a9a";
export var navigationMenu = "style-module--navigationMenu--ae788";
export var noscroll = "style-module--noscroll--4b0d2";
export var openIcon = "style-module--openIcon--833fc";
export var sticky = "style-module--sticky--69fce";