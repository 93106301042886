import React from 'react';
import * as styles from './style.module.css';
import cx from 'classnames';
import { navigate } from 'gatsby';
import { NanoGrid, NanoSticker} from '@nanoporetech-digital/components-react';

export default function ProgressBar({steps, activeStep=0, setActiveStep}) {
    return (
        <>
            <NanoGrid l-cols="10" s-cols="1" xl-cols="10" class="header">
                <div grid-states="xl-col-span-10 xl-col-start-1 xl-row-start-1 l-col-span-10 l-row-start-1">
                    <NanoSticker onNanoStuck={e => adjustStuckPosition(e)} onNanoUnstuck={e => adjustUnstuckPosition(e)}>
                        <div className={styles.progress}>
                            {steps.map((step,i) => <ProgressStep key={i} index={i} name={step} activeStep={activeStep} setActiveStep={setActiveStep}/>)}
                        </div>
                    </NanoSticker>
                </div>
            </NanoGrid>
        </>
    );
}

function ProgressStep({index, name, activeStep, setActiveStep}) {
    const isActive = index===activeStep;
    const isComplete = (activeStep > index);
    const classes = function() {
        if (isActive) {
            return cx(styles.step, styles.active);
        } else if (isComplete) {
            return cx(styles.step, styles.complete);
        }
        return styles.step;
    }();

    const activate = (index) => {
        if (index === 0 && !isActive) {
            navigate(-1);
        } else if (index > 0 && isComplete && !isActive) {
            setActiveStep(index);
        }
    };
    return (
            <div className={cx(classes)} onClick={() => activate(index)}>{name}</div>
    );
}

// bug in NanoSticker - it miscalculates the screen width when "stuck", hence:

function adjustStuckPosition(e) {
    const progressBar = e.target.getElementsByClassName(styles.progress)[0];
    progressBar.style.marginLeft = "calc(40% - 50vw)";
}

function adjustUnstuckPosition(e) {
    const progressBar = e.target.getElementsByClassName(styles.progress)[0];
    progressBar.style.marginLeft = "calc(50% - 50vw)";
}
