import React from 'react';
import { NanoCheckbox } from '@nanoporetech-digital/components-react';
import * as styles from './style.module.css';

export default function Product(props) {
    return (
        <div className={styles.product + ' ' + styles[props.mode]}>
            <div className={styles.control}>
                <Checkbox {...props} />
            </div>
            <div className={styles.details}>
                <h2>{props.name}</h2>
                <div className={styles.status}>{props.status}</div>
            </div>
        </div>
    );
}

function Checkbox({product, mode, choices, setChoices, barcode=null, quarters=4}) {
    const currentChoice = choices[product.sku] ?? null;

    const updateLabCert = e => {
        const oldState = Boolean(currentChoice);
        const newState = e.target.checked;
        if (newState != oldState) {
            setChoices({...choices, [product.sku]: newState ? 1 : 0});
        }
    };

    const updateDeviceCert = e => {
        const oldState = Boolean(currentChoice ? currentChoice[barcode] : false);
        const newState = e.target.checked;
        if (newState != oldState) {
            const devices = currentChoice ?? {};
            setChoices({
                ...choices,
                [product.sku]: {
                    ...devices,
                    [barcode]: newState ? quarters : 0
                }
            });
        }
    };

    switch (mode) {
        case 'lab':
            return <NanoCheckbox checked={Boolean(currentChoice)} onNanoChange={updateLabCert} />;
        case 'dev':
            return <NanoCheckbox key={barcode} checked={Boolean(currentChoice ? currentChoice[barcode] : false)} onNanoChange={updateDeviceCert} />
    }

    return null;
}
