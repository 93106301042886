export function getUpdatedChoices(changedInput, newOption, oldChoices, sources) {
    if (oldChoices[changedInput] != newOption.value) {
        const choices = {...oldChoices, [changedInput]: newOption.value};
        return resetDownstreamChoices(changedInput, choices, sources);
    }
    return null;
}

export function getFilteredOptions(forInput, choices, sources) {
    const options = sources[forInput];
    const activeFilter = getPreviousInputFilter(forInput, choices, sources);

    if (activeFilter) {
        return options.filter(option => (!option.filter || option.filter===activeFilter));
    } else {
        const sequence = getSequenceFor(forInput, sources);
        return (sequence === 0) ? options : [];
    }
}

export function getSelectedOption(inputName, choices, sources) {
    const optionValue = choices[inputName] ?? '';
    const source = sources[inputName] ?? [];
    return (optionValue && source) ? source.find(option => option.value===optionValue) : null;
}

function getPreviousInputFilter(forInput, choices, sources) {
    const sequence = getSequenceFor(forInput, sources);
    if (sequence > 0) {
        const previousInput = sources.sequence[sequence - 1];
        const previousOption = getSelectedOption(previousInput, choices, sources);
        if (previousOption?.filter) return previousOption.filter;
    }

    return '';
}

function getSequenceFor(inputName, sources) {
    return sources.sequence.indexOf(inputName);
}

function getDownstreamInputs(fromInput, sources) {
    const sequence = getSequenceFor(fromInput, sources);
    return sources.sequence.slice(sequence + 1);
}

function resetDownstreamChoices(fromInput, choices, sources) {
    const downstreamInputs = getDownstreamInputs(fromInput, sources);
    const updatedChoices = {...choices};
    for (const input of downstreamInputs) {
        const filteredOptions = getFilteredOptions(input, choices, sources);
        if (filteredOptions.length === 1) {
            updatedChoices[input] = filteredOptions[0].value;
        } else if (updatedChoices[input]) {
            delete updatedChoices[input];
        }
    }
    return updatedChoices;
}

