import React from 'react';
import { useCustomerContext } from "../../../contexts/CustomerContext";
import { getCategoryUrlPath, isDirectBuy } from './helpers';
import cx from 'classnames';
import { Link } from 'gatsby';
import { NanoIcon } from '@nanoporetech-digital/components-react';
import * as styles from "./style.module.css";

// a clickable boxed link in the left nav
export default function NavLink({category, hasParent, isActive}) {
    const { customer } = useCustomerContext();
    const isLiveMode = (customer.has_extra_permission || customer.has_custom_pricing) && category.url_key != 'store-home';
    const url = getCategoryUrlPath(category, customer.has_extra_permission);
    const isDirect = isDirectBuy(category);
    const linkStyle = cx(styles.navLink, hasParent ? styles.hasParent : null, (isLiveMode && isActive) ? styles.active : null);

    return (
        // activeClassName takes care of highlighting active category for all except home, for which we use forceActiveLink
        <Link to={url} activeClassName={isLiveMode ? null : styles.active} className={linkStyle}>
            <div className={styles.contents}>
                <div>{category.name}</div>
                {isDirect && <NanoIcon name="light/chevron-right" />}
            </div>
        </Link>
    )
}
