import React, {useEffect, useState} from 'react';
import { useCheckoutContext } from '../../../../contexts/CheckoutContext';
import SectionHeader from '../sectionHeader';
import SectionSubmit from '../sectionSubmit';
import { pluralise, saveSection } from '../helpers';
import { getUtcDate, getUiDate } from '../../../ShippingScheduler/helpers';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShippingFast } from '@fortawesome/pro-light-svg-icons';
import * as styles from '../style.module.css';
import {NanoCheckbox} from "@nanoporetech-digital/components-react";
import AdditionalRecipients from "../ShippingAddress/additionalRecipients";
import {useRetrieveQuotationSchedule} from "../../../../hooks/useQuotationSchedule";

export const CODE = 'quotation_shipping';
export const TITLE = 'Shipping information';

export default function QuotationShipping({step, cartData}) {
    const [checkout, setCheckout] = useCheckoutContext();
    const isActive = checkout.activeStep===step;
    const [schedule, setSchedule] = useState(null);

    const section = checkout[CODE] ?? {
        ont_shipping_emails: '',
    };

    const [cache, setCache] = useState({
        showInput: !!section.ont_shipping_emails.length,
        ont_shipping_emails: section.ont_shipping_emails ?? '',
    });

    const {quotationId, dpCode} = checkout;
    const paymentMethod = checkout.payment_method?.selected_payment_method ?? null;

    const {called, loading, error, data} = useRetrieveQuotationSchedule(
        quotationId,
        dpCode,
        paymentMethod,
        !isActive
    );

    useEffect(() => {
        if (called && data?.retrieveQuotationSchedule?.shipping_schedule) {
            setSchedule(data?.retrieveQuotationSchedule?.shipping_schedule);

            setCheckout({
                ...checkout,
                shipping_schedule: {
                    schedule: data.retrieveQuotationSchedule.shipping_schedule,
                }
            });

        }
    }, [data?.retrieveQuotationSchedule?.shipping_schedule]);

    async function submit() {
        if (!checkout.redeemQuote && ['purchaseorder', 'free', 'ont_zero_value'].includes(section.selected_payment_method)) {
            await setPaymentMethodMutation({variables: {cartId: cartData.id, method: section.selected_payment_method, poNumber: "ONT"}});
        }
        saveSection(CODE, checkout, setCheckout, {}, {
            canChange: true,
            highlight: pluralise(cartData.shipping_schedule.shipments.length, 'shipment'),
            ont_shipping_emails: cache.ont_shipping_emails,
        });
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error!</div>;
    }

    return(
        <div className={styles.section}>
            <SectionHeader step={step} code={CODE} title={TITLE} />
            {isActive && schedule && <SectionContent
                schedule={schedule}
                checkout={checkout}
                setCheckout={setCheckout}
                cache={cache}
                setCache={setCache}
            />}
            {isActive && schedule && <SectionSubmit callback={submit} />}
        </div>
    );
}

function SectionContent({cache, setCache, schedule}) {
    if (!schedule?.shipments?.length) {
        return <div className={styles.sectionContent}>There are no physical items in this quotation.</div>
    }

    return (
        <div className={styles.sectionContent}>
            <h3>Your order will be dispatched in {pluralise(schedule.shipments.length, 'consignment')} as follows:</h3>
            <OrderSchedule schedule={schedule} />
            <div className="info-banner info-banner--caution">
                <div className="info-banner__content">
                    <span className="info-banner__heading">Your shipping schedule may have changed</span>
                    <p className="info-banner__text">
                        The schedule above reflects the shipments you previously requested.
                        However, the dates may have changed based on the time lapsed since your quotation.
                        If you would like to change these dates, please visit the My Account section after placing your order.
                    </p>
                </div>
            </div>
            <ShippingNotifications cache={cache} setCache={setCache} />

        </div>
    );
}

function OrderSchedule({schedule}) {
    return schedule.shipments.map((shipment,i) => <Shipment key={shipment.date} index={i} shipment={shipment} />)
}

function Shipment({shipment, index}) {
    function Contents() {
        return shipment.shipment_items.map((item,i) => <Item key={shipment.date + '_' + i} item={item} />)
    }

    function Item({item}) {
        return <li>{item.qty}x {item.name}</li>
    }

    return(
        <div className={styles.quotationShipment}>
            <h4><FontAwesomeIcon icon={faShippingFast} color="#0184aa" />&nbsp;&nbsp;Shipment #{index+1}</h4>
            <p>Estimated dispatch date: {getUiDate(getUtcDate(shipment.date))}</p>
            <ul><Contents /></ul>
        </div>
    );
}

function ShippingNotifications({cache, setCache}) {

    function toggle() {
        if (cache.showInput) {
            setCache({...cache, showInput: false, ont_shipping_emails: ''});
        } else {
            setCache({...cache, showInput: true});
        }
    }

    return (
        <div>
            <NanoCheckbox
                label="Add additional shipping notification recipients"
                checked={cache.showInput}
                onNanoChange={() => toggle()} />
            {cache.showInput && <AdditionalRecipients cache={cache} setCache={setCache} />}
        </div>
    );
}
