import React, { createContext, useContext, useState } from 'react';

const ChoicesContext = createContext(null);

export function ChoicesContextProvider({children, sources, postHigher}) {
    const [choices, setChoices] = useState({});

    return (
        <ChoicesContext.Provider value={{choices, setChoices, sources, postHigher}}>
            {children}
        </ChoicesContext.Provider>
    );
}

export const useChoicesContext = () => useContext(ChoicesContext);

