import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faTimes} from '@fortawesome/pro-light-svg-icons';
import { useCustomerContext } from '../../../contexts/CustomerContext';

export default function PersonaBanner() {
    const { customer } = useCustomerContext();

    if (!shouldDisplay(customer)) {
        return null;
    }

    return (
        <div className="info-banner info-banner--warning" style={{marginBottom: '0px'}}>
            <div className="info-banner__content">
                <span className="info-banner__label">Multiple accounts are associated to this email, please ensure you are on the correct account before you proceed.</span>
                <Account key={"invoice"} prefix={"Invoice account"} persona={customer.initial_persona} />
                <br/>
                <Account key={"active"} prefix={"Selected account"} persona={customer.active_persona} />
                <br />
                <SwitchButton />
                <CloseButton />
            </div>
        </div>
    );
}

const Account = ({prefix, persona}) => {
    return <>
        {prefix}: <code>{persona.c_number} ({persona.organisation_name})</code>
    </>;
}

const CloseButton = () => {
    const {customer, setCustomer}  = useCustomerContext()

    const closePanel = () => {
        setCustomer({
            ...customer,
            persona_banner_dismissed: true,
        })
    }

    return(
        <button
            className={`button button--secondary`}
            onClick={closePanel}
            style={{padding: "0.25rem 0.5rem", marginTop: "0.5rem", marginLeft: "0.5em"}}
        >
            <FontAwesomeIcon icon={faTimes} size="sm" /> Close
        </button>
    );
}

const SwitchButton = () => {
    const openDrawer = () => {
        const drawer = document.querySelector('#global-nav-main nano-menu-drawer nano-nav-item:nth-child(1)');
        drawer.open = true;
    }

    return (
        <button className={`button button--secondary`} onClick={openDrawer} style={{padding: "0.25rem 0.5rem", marginTop: "0.5rem"}}>
            <FontAwesomeIcon icon={faChevronLeft} size="sm" /> Change account
        </button>
    );
}

const shouldDisplay = (customer) => {
    return typeof window !== 'undefined'
        && (customer.has_multiple_personas && customer.initial_persona && customer.active_persona)
        && !(customer.is_admin_mode || customer.is_remote_mode)
        && !(customer.persona_banner_dismissed);
}
