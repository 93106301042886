import { useMutation, gql } from "@apollo/client";
import { getStandardResponseGraphQl } from "../../../../../hooks/useCart";

export default function useSetTaxInfo() {

	const STANDARD_RESPONSE = getStandardResponseGraphQl();

	return useMutation(gql`
		mutation SetTaxInfo($cartId: String!, $taxStatus: String, $vatNumber: String, $vatExempt: Boolean, $certificate: TaxCertificateInput, $paymentMethod: String) {
			setTaxInfo(
				input: {
					cart_id: $cartId
					tax_status: $taxStatus
					vat_number: $vatNumber
					vat_exempt: $vatExempt
					certificate: $certificate
					payment_method: $paymentMethod
				}
			) {
				cart {
					${STANDARD_RESPONSE}
				}
			}
		}
	`);
}
