import { useMutation, gql } from "@apollo/client";
import { orderFields } from "../../../fragments/live/order";

export default function usePlaceQuickOrder() {
    return useMutation(gql`
        mutation PlaceQuickOrder($orderData: QuickOrderInput) {
            placeQuickOrder(input: $orderData) {
                order {
                    ...orderFields
                }
            }
        }

        ${orderFields}
    `);
}
