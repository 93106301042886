export function getSelectedOptionMatchingSku(option, sku) {
    return option.options.find(selection => selection.product.sku==sku) ?? null;
}

export function getDearestBundle(bundleProducts) {
    return bundleProducts.reduce((acc, curr) => {
        return (!acc || curr.price_range.minimum_price.final_price.value >= acc.price_range.minimum_price.final_price.value) ? curr : acc;
    }, null);
}

export function getCheapestBundle(bundleProducts) {
    return bundleProducts.reduce((acc, curr) => {
        return (!acc || curr.price_range.minimum_price.final_price.value < acc.price_range.minimum_price.final_price.value) ? curr : acc;
    }, null);
}

export function getBundlePrice(bundleProduct) {
    const customPrice = bundleProduct.customPrice;
    const normalPrice = (bundleProduct.price_range)
        ? bundleProduct.price_range.minimum_price.final_price
        : bundleProduct.price.regularPrice.amount;

    return (customPrice?.currency && customPrice?.value) ? customPrice : normalPrice;
}
