import React from 'react';
import Markdown from 'react-markdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import remarkGfm from "remark-gfm";

const BlockquoteRenderer = props => {
    return(
        <>
            {
                props.children.map((child, i) => (
                    <div key={i}>
                        <strong>
                            <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                            <span>{child.props.children[0].props.children} ll</span>
                        </strong>
                    </div>
                ))
            }
            <style jsx>{`
                div {
                    color: #0084a9;
                }
            `}</style>
        </>
    );
};

export default function UiMarkdown(props) {
    const { source, newProps } = extractDeprecatedSourceProp(props);
    return (
        <Markdown
            {...newProps}
            components={{blockquote: BlockquoteRenderer}}
            remarkPlugins={[remarkGfm]}
            linkTarget="_blank"
        >
            {props.children ?? source}
        </Markdown>
    );
}

/*
Using a source prop is deprecated in react-markdown. Instead the content should be injected as children.
This function ensures backwards compatibility for our code.
*/
function extractDeprecatedSourceProp(originalProps) {
    const newProps = {...originalProps};
    const source = newProps.source ?? null;

    if (source) {
        delete newProps.source;
    }

    return {source, newProps};
}

