import React, { useEffect } from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import { useCustomerContext, isExpired } from '../../../contexts/CustomerContext';
import { useCustomerCart } from '../../../hooks/useCart';
import { useCartScheduler } from '../../../hooks/useShippingScheduler';
import CartSummary from '../../Cart/cartSummary';
import Sections from '../Sections';
import useLogout from '../../Authentication/hooks/useLogout';
import * as styles from './style.module.css';

export default function LoggedIn() {
    return(
        <div className={styles.checkout}>
            <div className={styles.proceed}>
                <Link to="/cart">
                    <FontAwesomeIcon icon={faChevronLeft} />&nbsp;&nbsp;Basket
                </Link>
            </div>
            <h1>Checkout</h1>
            <CheckoutContent />
        </div>
    );
}

function CheckoutContent() {
    const { customer } = useCustomerContext();
    const { loading: cartLoading, error: cartError, data: cartData } = useCustomerCart();
    const customerCart = cartData?.customerCart;

    const { loading: schedLoading, error: schedError, data: schedData } =
        useCartScheduler(customerCart?.id, (customerCart ? false : true));

    const logout = useLogout();

	useEffect(() => {
        if (customerCart) {
            // use this for init and re-init
        }
    }, [customerCart] )	// first render + when x changes

    if (cartLoading || schedLoading) {
        return <p>Loading ...</p>;
    }

    if (isExpired(customer)) {
        return <p>Your session has expired. Please click <a onClick={logout}>here</a> to log in again.</p>;
    }

    if (cartError) {
        return <p>Your basket is empty.</p>;
    }

    if (!customerCart?.items?.length) {
        return <p>Your basket is empty.</p>;
    }

    if (customerCart.messages?.length && customerCart.messages[0].message_type=='error') {
        const message = customerCart.messages[0];

        if (message.message_text.includes('Invalid store')) {
            return (
                <div className={styles.error}>
                    <p>{message.message_text}</p>
                    <p>Please <a onClick={logout}>click here to log in and redirect to the correct store</a>. You will lose the contents of your basket. If you believe your account currency has been set incorrectly, please contact customer services.</p>
                </div>
            );
        }

        return (
            <div className={styles.error}>
                <p>{message.message_text}</p>
                <p>Please return to your basket and check the contents. If you have any queries please contact customer services.</p>
            </div>
        );
    }

    return(
        <div className={styles.content}>
            <Sections cartData={customerCart} />
            <CartSummary cartData={customerCart} mode="checkout" shippingSchedule={schedData?.shippingScheduleSetup} />
        </div>
    );
}
