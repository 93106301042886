import React from 'react';
import { CheckoutContextProvider, useCheckoutContext } from '../../contexts/CheckoutContext';
import { useCustomerContext } from '../../contexts/CustomerContext';
import LoggedOut from './LoggedOut';
import LoggedIn from './LoggedIn';

export default function Checkout() {
    return (
        <CheckoutContextProvider>
            <CheckoutRouter />
        </CheckoutContextProvider>
    );
}

function CheckoutRouter() {
    const [checkout] = useCheckoutContext();
    const { customer } = useCustomerContext();
    return (customer.token?.length) ? <LoggedIn /> : <LoggedOut />;
}
