export default function getProductByUrlKey (urlKey, products) {
    const matchingUrlKeyProducts = products.filter(
        ({url_key}) => url_key === urlKey
    );

    return matchingUrlKeyProducts[0];
}

const getType = product => {
    return product.__typename.replace(/^Magento_/, '');
}
export const isSimple = product => {
    return getType(product) === 'SimpleProduct';
}
export const isConfigurable = product => {
    return getType(product) === 'ConfigurableProduct';
}
export const isBundle = product => {
    return getType(product) === 'BundleProduct';
}
export const isGrouped = product => {
    return getType(product) === 'GroupedProduct';
}
export const isVirtual = product => {
    return getType(product) === 'VirtualProduct';
}
