import React from 'react';
import Shipments from './Shipments';
import ScheduleRows from './ScheduleRows';
import { getConsumablesCarriage, getShipmentsWithConsumables, pluralise } from '../Checkout/Sections/helpers';
import Price from "../Ui/Price";
import { getIsoDateString, getUtcDate, addDaysToDate, modifySchedule, getNewShipmentItems, addBlankShipmentItems, buildSchedule, dedupeShipmentItems, deleteShipment, findNextShippingDate } from './helpers';
import * as styles from './style.module.css';

export default function ShippingScheduler({schedule, setSchedule, currency}) {
    const useSchedule = (schedule.shipments?.length > 0) ? schedule : initSchedule();

    function initSchedule() {
        const newSchedule = buildSchedule(schedule);
        setSchedule({...newSchedule, interacted: false});
        return newSchedule;
    }

    function newShipment() {
        const shipments = useSchedule.shipments;
        const newDate = getUtcDate(shipments[shipments.length - 1]);
        addDaysToDate(newDate, useSchedule.new_shipment_gap ?? 7);
        findNextShippingDate(useSchedule, newDate);
        modifySchedule(useSchedule, function(row) {
            return [...row.shipment_items, {date: getIsoDateString(newDate), qty: 0}]
        });
        setSchedule({...useSchedule, interacted: true});
    }

    function resetToDefault() {
        modifySchedule(useSchedule, function(row) {
            return getNewShipmentItems(useSchedule, row);
        });
        addBlankShipmentItems(useSchedule);
        setSchedule({...useSchedule, interacted: true});
    }

    function sendAllTogether() {
        modifySchedule(useSchedule, function(row) {
            return [{date: useSchedule.first_common_date, qty: row.qty}]
        });
        setSchedule({...useSchedule, interacted: true});
    }

    function removeShipment(shipment) {
        deleteShipment(useSchedule, shipment);
        setSchedule({...useSchedule, interacted: true});
    }

    function changeDate(shipment, to) {
        const newDate = getIsoDateString(to);
        modifySchedule(useSchedule, function(row) {
            const newShipmentItems = row.shipment_items.map(si => si.date===shipment ? {...si, date: newDate} : si);
            return dedupeShipmentItems(newShipmentItems);
        });
        setSchedule({...useSchedule, interacted: true});
    }

    function RemoveLink({shipment, index}) {
        return (
            <>
                <td className={styles.shipmentItemSpacer}>&nbsp;</td>
                <td className={styles.removeLink}>
                    {(index > 0) ? <a onClick={() => removeShipment(shipment)}>Remove</a> : ' '}
                </td>
            </>
        );
    }

    return(
        <div>
            <div className={styles.shippingScheduler}>
                <table cellSpacing="0" cellPadding="0" width="100%">
                    <thead>
                        <tr>
                            <th style={{fontWeight: "bold", textAlign: "left", fontSize: "larger"}}>Items shipping by:</th>
                            <Shipments schedule={useSchedule} changeDate={changeDate} />
                        </tr>
                    </thead>
                    <tbody>
                        <ScheduleRows schedule={useSchedule} setSchedule={setSchedule} />
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            {useSchedule.shipments.map((shipment,i) => <RemoveLink key={shipment} shipment={shipment} index={i} />)}
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className={styles.schedulerButtons}>
                <button type="button" className="button button--primary button--keyline" onClick={newShipment}>Add another delivery +</button>
                <button type="button" className="button button--primary button--keyline" onClick={resetToDefault}>Reset to default</button>
                <button type="button" className="button button--primary button--keyline" onClick={sendAllTogether}>Send all items together</button>
            </div>
            <ShippingCosts schedule={useSchedule} currency={currency}/>
        </div>
    );
}

function ShippingCosts({schedule, currency}) {
    const consumableShipmentCount = getShipmentsWithConsumables(schedule).length;
    const deviceCarriage = schedule.device_carriage ?? 0;
    const consumablesCarriage = getConsumablesCarriage(schedule);
    const totalCarriage = deviceCarriage + consumablesCarriage;

    function DeviceCarriage() {
        if (deviceCarriage > 0) {
            return <>Device delivery: <Price currencyCode={currency} value={schedule.device_carriage} /><br/></>;
        }
        return null;
    }

    function ConsumablesCarriage() {
        if (consumableShipmentCount > 0) {
            return <>{pluralise(consumableShipmentCount, 'consumable delivery')} @ <Price currencyCode={currency} value={schedule.cost_per_shipment} /> per delivery<br/></>;
        }
        return null;
    }

    function TotalCarriage() {
        let includes = '';
        if (deviceCarriage > 0 && consumablesCarriage > 0) {
            includes = ' (consumables & device)';
        } else if (deviceCarriage > 0) {
            includes = ' (device)';
        } else if (consumablesCarriage > 0) {
            includes = ' (consumables)';
        }

        return (
            <strong>Total delivery cost{includes}: <Price currencyCode={currency} value={totalCarriage} /></strong>
        );
    }

    return(
        <div className={styles.costs}>
            <DeviceCarriage />
            <ConsumablesCarriage />
            <TotalCarriage />
        </div>
    );
}
