import React, {useEffect, useState} from 'react';
import { NanoMenuDrawer, NanoNavItem, NanoIcon } from '@nanoporetech-digital/components-react';
import CUSTOMER_PERSONAS from './customerPersonas.graphql';
import { useLazyQuery } from '@apollo/client';
import { useCustomerContext } from '../../../contexts/CustomerContext';
import Persona from './persona';
import * as styles from './style.module.css';

export default function PersonaSwitcher() {
    const { customer, resetCustomer, setCustomer } = useCustomerContext();
    if (customer.is_admin_mode || customer.is_remote_mode) {
        return null;
    }

    const [getPersonas, {called, data}] = useLazyQuery(CUSTOMER_PERSONAS, {fetchPolicy: 'network-only'});
    const closeDrawer = e => {
        e.target.closest('nano-nav-item').open = false;
    }

    useEffect(() => {
        if (!called && customer.token) {
            getPersonas();
        }
    }, [called, customer.token]);

    const personas = data?.customerPersonas?.items ?? [];

    const [activePersona] = (personas.length > 0) ? personas.filter(({is_active}) => is_active === true) : [null];
    const initialPersona = customer.initial_persona ?? activePersona;
    const activePersonaId = activePersona?.id ?? null;
    const initialPersonaId = initialPersona?.id ?? null;

    useEffect(() => {
        setCustomer({
            ...customer,
            has_multiple_personas: personas.length > 1,
            active_persona: activePersona,
            initial_persona: initialPersona,
            controlling_customer: data?.customerPersonas?.controlling_customer,
        })
    }, [activePersonaId, initialPersonaId])

    if (personas.length) {
        return(
            <NanoMenuDrawer hide-height="false" open="false" style={{"zIndex": 2}}>
                <NanoNavItem id={""}>
                    <NanoIcon slot="icon-start" name="light/user" style={{fontSize: "1.25rem"}}/>
                    <NanoIcon slot="icon-end" name="light/chevron-right" />
                    <div slot="secondary">
                        <div className={styles.close}>
                            <button onClick={closeDrawer}>Close</button>
                        </div>
                        {personas.length > 0 && <PersonaList personas={personas} />}
                    </div>
                </NanoNavItem>
            </NanoMenuDrawer>
        );
    }

    // TODO: keep an eye on this - may need to be refactored
    if (customer?.token?.length && data && data.customerPersonas?.valid_session !== true) {
        console.log('Forcing customer reset');
        resetCustomer();
    }

    return null;
}

export function PersonaList({personas}) {
    const [activePersona] = personas.length > 0
        ? personas.filter(({is_active}) => is_active === true)
        : [null];

    const [activePersonaId, setActivePersonaId] = useState(activePersona ? activePersona.id : null);

    return (
        <ul className={styles.personas}>
            {personas.map(persona =>
                <Persona
                    key={persona.id}
                    persona={persona}
                    activePersonaId={activePersonaId}
                    setActivePersonaId={setActivePersonaId}
                />
            )}
        </ul>
    );
}
