import React from 'react';
import { NanoGrid } from "@nanoporetech-digital/components-react";
import SectionLink from './link';
import { featured } from './style.module.css';

export default function Featured({section}) {
    if (!section) return null;

    return (
        <div className={featured}>
            <h2>{section.title}</h2>
            <NanoGrid s-cols="1" l-cols="1">
                <p>{section.description}</p>
                <ul>
                    {section.items?.map((item, i) => <li key={i}><SectionLink item={item} /></li>)}
                </ul>
            </NanoGrid>
        </div>
    );
}
