import React from 'react';
import { useMinionContext } from '../Configurator/useMinionContext';
import { getSelectedBundle } from '../Action/helpers';
import { productPhase, level, name, help } from './style.module.css';

export default function ProductPhase() {
    const { minionConfig, bundleProducts } = useMinionContext();
    const selectedBundle = getSelectedBundle(bundleProducts, minionConfig);
    const phase = selectedBundle.ont_phase_info;

    if (!phase?.level) return null;

    return (
        <div className={productPhase}>
            <div>
                <span className={level}>{phase.level}</span>
                <span className={name}>{phase.name}</span>
            </div>
            {phase.help && <div className={help} dangerouslySetInnerHTML={{__html: phase.help}}></div>}
        </div>
    );
}
