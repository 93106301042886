import loadScript from "../../../utils/loadScript";
import {isDevelopmentServer, isProduction} from "../../../hooks/useEnvironment";
import {getOrderInput, getRedeemQuotationInput} from "../../Checkout/Sections/helpers";
import {navigate} from "gatsby";

export default function setUpPayments(
    createMicroformTokenMutation,
    createCardinalTokenMutation,
    placeOrderWithCcaMutation,
    redeemQuotationWithCcaMutation,
    microformState,
    setMicroformState,
    cart,
    resetCart,
    setCardNumberData,
    setSecurityCodeData,
    setCardMeta=null,
    checkout={},
    setCheckout=null
) {
    console.log("setUpPayments");

    const basicPayload = checkout?.redeemQuote
        ? { quotation_id: checkout.quotationId, dp_code: checkout.dpCode }
        : { cart_id: cart.id };

    loadScript(getSongbirdUrl()).then(() => {
        loadScript("/flex-microform.min.js").then(() => {
            createMicroformTokenMutation({
                variables: {
                    requestData: {
                        ...basicPayload
                    }
                }
            }).then((data) => {
                const microformRequestToken = data.data.createMicroformToken.token

                setUpFlex(
                    microformRequestToken,
                    microformState,
                    setMicroformState,
                    setCardMeta,
                    setCardNumberData,
                    setSecurityCodeData
                );

                createCardinalTokenMutation({
                    variables: {
                        requestData: {
                            ...basicPayload,
                            token: microformRequestToken,
                            cc_type: "VI",
                            exp_date: "06-2050"
                        }
                    }
                }).then(data => {
                    const response = data.data.createCardinalToken;
                    if (!response.success) {
                        return;
                    }

                    setUpCardinal(
                        response.token,
                        (checkout?.redeemQuote ? redeemQuotationWithCcaMutation : placeOrderWithCcaMutation),
                        cart,
                        checkout,
                        setCheckout,
                        resetCart
                    );
                })
            })
        }).catch((error) => {
            console.log('Error when loading flexState-microform.min.js', error);
        });
    })
}

function setUpFlex(
    requestToken,
    microformState,
    setMicroformState,
    setCardMeta,
    setCardNumberData,
    setSecurityCodeData
) {
    const flexStyles = {
        'input': {
            'font-size': '1rem',
            'font-family': 'HelveticaNow, Helvetica, Arial, sans-serif',
            'color': '#4a4a4a'
        },
        '::placeholder': { 'color': '#d4d4d4' },
        ':focus': { 'color': '#4a4a4a' },
        ':disabled': { 'cursor': 'not-allowed' },
        'valid': { 'color': '#17BB75' },
        'invalid': { 'color': '#EF4135' }
    };

    const newFlex = new Flex(requestToken)
    const newMicroform = newFlex.microform({styles: flexStyles})

    const number = newMicroform.createField('number', {placeholder: "Enter card number"})
    number.on('change', data => {
        if (setCardMeta && data.card[0]) setCardMeta(data.card[0]);
        setCardNumberData(data);
    });
    number.load("#number-container");

    const securityCode = newMicroform.createField('securityCode', {placeholder: "Enter security code"})
    securityCode.on('change', data => {
        setSecurityCodeData(data);
    });
    securityCode.load("#securityCode-container")

    setMicroformState(microformState => ({...microformState, microform: newMicroform}));
}

function setUpCardinal(token, retryOrderMutation, cart, checkout, setCheckout, resetCart) {
    const orderInput = checkout?.redeemQuote
        ? {...getRedeemQuotationInput(cart, checkout), payment_method: "chcybersource"}
        : getOrderInput(cart, checkout)

    Cardinal.configure({
        logging: {
            debug: 'verbose'
        }
    });

    Cardinal.setup('init', {
        jwt: token,
    })

    Cardinal.on('payments.setupComplete', function () {
        console.log("payment setup is complete")
    });

    Cardinal.on("payments.validated", function (data, jwt) {
        console.log("payments validated", data)
        // see https://cardinaldocs.atlassian.net/wiki/spaces/CC/pages/557065/Songbird.js#Songbird.js-payments.validated
        if (data.ErrorNumber === 0) {
            console.log("pvalidate success")
        }
        else {
            console.log("3d-secure failure")
        }

        console.log("cca response jwt", jwt)

        let ccaData = {
            cart_id: cart.id,
            jwt: jwt
        }
        if (checkout.redeemQuote) {
            ccaData['quotation_id'] = checkout.quotationId;
            ccaData['dp_code'] = checkout.dpCode;
        }

        retryOrderMutation({
            variables: {
                ccaData: ccaData,
                orderData: orderInput
            }
        }).then(data => {
            console.log("retried order mutation", data)
            resetCart();
            const orderData = checkout.redeemQuote
                ? data.data.redeemQuotation
                : data.data.placeOrder;


            if (orderData?.order?.order_number) {
                navigate('/checkout/success', {state: {order: orderData.order}});
            }
        }).catch(error => {
            // checkout has old state in it but that's ok we don't want it anyway
            setCheckout({
                ...checkout,
                submitStatus: (typeof error === 'string') ? error : error.message,
            });
        })
    });
}

function getSongbirdUrl() {
    return isProduction()
        ? 'https://songbird.cardinalcommerce.com/edge/v1/songbird.js'
        : 'https://songbirdstag.cardinalcommerce.com/edge/v1/songbird.js'
}
