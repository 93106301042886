import React from 'react';
import Price from '../Price';

export default function UiPublicOrCustomPrice({price, customPrice = {}, classes={}}) {
	const priceToUse = ((customPrice != null) && ("value" in customPrice) && (customPrice.currency?.length))
		? customPrice
		: price;

	return <Price currencyCode={priceToUse.currency} value={priceToUse.value} classes={classes} />;
}
