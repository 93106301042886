import React from 'react';

export default function TokenError({errorObject}) {
    return (
        <div style={{margin: '4rem'}}>
            <h2 style={{fontWeight: 'lighter'}}>Sorry, we could not log you in...</h2>
            <p>We have been unable to log you in to the store. This may be because you do not have a purchasing account.</p>
            <p>To register for an account please <a href="https://myaccount.nanoporetech.com/registration/apply">click here</a>.</p>
            <p>If you have a purchasing account and are still seeing this error, please contact Customer Services quoting the information below.</p>
            <code>{errorObject?.message}</code><br />
            {errorObject?.graphQLErrors?.length && <code>{errorObject?.graphQLErrors[0].debugMessage}</code>}
        </div>
    );
}
