import React from 'react';
import UiMarkdown from "../../../Ui/UiMarkdown";
import * as styles from './style.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { OptionalStatus } from './sectionStatus';
import { NanoGrid } from '@nanoporetech-digital/components-react';

export default function IncludedTraining(props) {

    const option = props.options.find(option => option.title===props.section.label);
    const selection = option.options[0];

    return (
        <>
            <div className={styles.sectionStatus}>
                <OptionalStatus noun={props.section.label} goNextStep={props.goNextStep} />
            </div>
            <NanoGrid l-cols="10"
                m-cols="10"
                s-cols="10"
                xl-cols="10"
                xxl-cols="10" className={styles.sectionMain}>
                <div grid-states="xl-col-span-6 l-col-span-6 m-col-span-10 s-col-span-10" className={styles.sectionContent}>
                        <Item selectionItem={selection} />
                </div>
                {props.summary}
            </NanoGrid>
        </>
    );
}

function Item({selectionItem}) {
    const product = selectionItem.product;
    const description = product.short_description.html.replace(/<[^>]+>/g, '');

    return (
        <div className={styles.item}>
            <h2 className={styles.title}>{product.name}</h2>
            <div className={styles.details}>
                <div className={styles.image}><img src={product.ont_image_url_1} /></div>
                <div className={styles.description}>
                    <div><UiMarkdown source={description} /></div>
                    <div className={styles.itemCta}><FontAwesomeIcon icon={faCheck} color="#17bb75"/> Included</div>
                </div>
            </div>
        </div>
    );
}
