import React from 'react';
import { NanoInput } from '@nanoporetech-digital/components-react';

export default function AdditionalRecipients({cache, setCache}) {

    const updateField = e => {
        const emailCsv = e.target.value;
        setCache({...cache, ont_shipping_emails: emailCsv});
        // could use e.target.showError here for inline form validation, but decided to do at section submit
    };

    return (
        <NanoInput
        clear-input="true"
        placeholder="Multiple addresses may be comma separated"
        required="false"
        value={cache.ont_shipping_emails}
        onNanoChange={e => updateField(e)}
        debounce={500}
        >
            <span slot="helper">If you leave this blank, only you and the Account Leader will receive shipping notifications.</span>
        </NanoInput>
    );
}
