import React from 'react';
import { useCustomerContext } from '../../../contexts/CustomerContext';
import useCountries, { getCountryName } from '../../../hooks/useCountries';

export default function Shipping({addresses, shippingAddressId}) {
    const countries = useCountries();
    if (!addresses?.length) return null;

    const index = addresses.findIndex(address => address.id==shippingAddressId);
    const address = addresses[index >= 0 ? index : 0]

    return (
        <div>
            <h3>Shipping address</h3>
            <ul>
                <li>{address.firstname} {address.lastname}</li>
                <li>{address.company}</li>
                <li>{address.street.join(', ')}</li>
                <li>{address.city}</li>
                <li>{address.region.region}</li>
                <li>{address.postcode}</li>
                <li>{getCountryName(countries,address.country_id)}</li>
                <li>Phone: {address.telephone}</li>
            </ul>
        </div>
    );
}
