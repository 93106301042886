import React from 'react';
import UiMarkdown from "../../../Ui/UiMarkdown";
import {sectionStatus, sectionMain, sectionContent, item, title, details, image, description as descriptionClass} from './style.module.css';
import { OptionalStatus } from './sectionStatus';
import UiRadio from '../../../Ui/UiRadio';
import { NanoGrid } from '@nanoporetech-digital/components-react';

const QTY_OPTIONS = [1,2,3];

export default function LegacyAnnualService(props) {
    const option = props.options.find(option => option.title===props.section.label);
    const selection = option.options[0];
    const selectedQty = props.section.cache.selectedQty ?? selection.quantity;

    const updateSection = qty => {
        const selections = [{id: option.option_id, quantity: selection.quantity, value: [selection.id.toString()]}];
        const items = [];
        const cache = {selectedQty: qty};
        const additional = {...props.section.additional};
        const additionalQty = qty - selection.quantity;

        if (additionalQty > 0) {
            const usePrice = selection.product.ont_custom_price ??  selection.product.price.regularPrice.amount;
            const surcharge = additionalQty * usePrice.value;
            items.push({name: selection.product.name, qty: additionalQty, surcharge: surcharge });
            additional[selection.product.sku] = {product: selection.product, qty: additionalQty};
        } else {
            additional[selection.product.sku] = {product: selection.product, qty: 0};
        }

        props.updateSection({...props.section, selections, items, cache, additional});
    }

    if (props.section.cache.selectedQty === undefined) {
        updateSection(3);
    }

    return (
        <>
            <NanoGrid className={sectionStatus}>
                <OptionalStatus noun="Annual service" goNextStep={props.goNextStep} />
            </NanoGrid>
            <NanoGrid  l-cols="10"
                       m-cols="10"
                       s-cols="1"
                       xl-cols="10"
                       xxl-cols="10" className={sectionMain}>
                <div grid-states="xl-col-span-6 l-col-span-6 m-col-span-10" className={sectionContent}>
                    <Item selectionItem={selection} selectedQty={selectedQty} updateSection={updateSection} />
                </div>
                {props.summary}
            </NanoGrid>
        </>

    );
}

function Item({selectionItem, selectedQty, updateSection}) {
    const product = selectionItem.product;
    const description = product.short_description.html.replace(/<[^>]+>/g, '');

    const Radios = () => {
        return QTY_OPTIONS.map(qty => {
            return (
                <UiRadio
                    key={qty}
                    name="qty"
                    value={qty}
                    defaultChecked={selectedQty===qty}
                    onClick={() => updateSection(qty)}
                >
                    {qty} year {selectionItem.quantity===qty && '(included)'}
                </UiRadio>
            );
        });
    }

    return (
        <div className={item}>
            <h2 className={title}>{product.name}</h2>
            <div className={details}>
                <div className={image}><img src={product.ont_image_url_1} /></div>
                <div className={descriptionClass}>
                    <div><UiMarkdown source={description} /></div>
                    <Radios />
                </div>
            </div>
        </div>
    );
}
