import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import Price from "../Ui/Price";
import {isNonTransactional} from "../../hooks/siteData";

const QuantityComponent = ({
    inputKey,
    state,
    setState,
    focusKey,
    mode = "default",
    unitPrice = 0,
    currencyCode = "",
    increments = 1,
    maxValue = null,
    onValueChange = () => {},
    disableKeyboardInput = false
}) => {
    if (isNonTransactional()) {
        return null;
    }

    const handleChange = (value) => {
        setState(value);

        if (value === '') {
            return;
        }
        onValueChange(value);
    }

    const sanitiseValue = value => {
        if (value === '') {return value;}
        value = parseInt(value) ?? 0;
        if (isNaN(value)) {
            value = 0;
        }

        return value;
    }

    const focused = inputKey === focusKey;
    if (mode === "years") {
        const items = []

        for (var i = 1; i <= maxValue; i++) {
            items.push(
                <span key={i.toString()}>
                    <input id={"qty_increment_" + i} type="radio" name="qty" value={i} />
                    <label className="button button--light" htmlFor={"qty_increment_" + i}>{i} {i === 1 ? 'year' : 'years'}&nbsp; <small><Price currencyCode={currencyCode} value={unitPrice * i}/></small></label>
                    <style jsx>{`
                        span {
                            margin-right: 1rem;
                        }
                        input {
                            display: none;
                        }
                        input[type="radio"]:checked+label, input[type="radio"]:checked+label:hover {
                            background-color: #0084a9;
                            color: #fff;
                            cursor: pointer;
                        }
                    `}</style>
                </span>
            )
        }

        return (
            <>
                <div className="container">
                    <div className="product-view-qty-buttons">
                        {items}
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
    <div className="container">
        <button
            disabled={state === 0}
            className="button button--qty minus btn"
            onClick={() => {
                const value = sanitiseValue(state);
                if (value > 0) {
                    handleChange(value - 1 * increments);
                }
            }}>
            <FontAwesomeIcon icon={faMinus}/>
        </button>
        <div className={clsx('sc-nano-input-h sc-nano-input-s has-label hydrated', focused && 'has-focus')}>
            <div className="input sc-nano-input">
                <div className="native-input-wrap sc-nano-input">
                    <input
                        key={inputKey}
                        autoFocus={focused}
                        className="native-input sc-nano-input quantityInput"
                        aria-labelledby="nano-input-1-lbl"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        spellCheck="false"
                        value={state}
                        onChange={e => {handleChange(sanitiseValue(e.target.value))}}
                        disabled={disableKeyboardInput}
                        type="text"/>
                </div>
            </div>
        </div>
        <button
            disabled={maxValue ? state === maxValue : false}
            className="button button--qty plus btn"
            onClick={() => {
                const value = sanitiseValue(state);
                if (maxValue) {
                    handleChange((value < maxValue) ? (value + (1 * increments)) : maxValue);
                } else {
                    handleChange(value + (1 * increments));
                }
            }}>
            <FontAwesomeIcon icon={faPlus}/>
        </button>
    </div>
    <style jsx>{`
    .container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;
        width: 7rem;
    }
    .btn {
        background-color: #0084a9;
        box-shadow: none;
        border-radius: 0;
        color: #FFF;
        font-size: 0.875rem;
        margin: 0px;
        padding: .4rem .6rem;
        box-shadow: none;
    }
    button:disabled {
        background-color: #ccd1d3;
        border-left: 1px solid #0084a9;
        border-top: 1px solid #0084a9;
        border-bottom: 1px solid #0084a9;

    }
    button:disabled.plus{
        border-right: 1px solid #0084a9;
        border-left: 1px solid transparent;
      }

    .btn.disabled{
        background-color: #ccd1d3;
    }
    .btn:hover {
        box-shadow: none;
    }
    .btn:focus {
        border-radius: 0px;
    }
    .sc-nano-input {
        font-size: 1rem;
    }
    .sc-nano-input-h {
        --placeholder-color: initial;
        --placeholder-font-style: initial;
        --placeholder-font-weight: initial;
        --placeholder-opacity: .5;
        --padding-top: 0;
        --padding-end: var(--nano-input-padding-end, var(--nano-input-padding, 8px));
        --padding-bottom: var(--nano-input-padding-bottom, var(--nano-input-padding, 8px));
        --padding-start: var(--nano-input-padding-start, var(--nano-input-padding, 8px));
        --color: "currentColor";
        --color--invalid: var(--nano-color-danger-rgb, 239, 65, 53);
        --color--focus: var(--nano-color-base, var(--nano-color-primary, #0084a9));
        --color--focus-rgb: var(--nano-color-base-rgb, var(--nano-color-primary-rgb, 0, 132, 169));
        --color--focus-shade: var(--nano-color-shade, var(--nano-color-primary-shade, #007090));
        --color--focus-tint: var(--nano-color-tint, var(--nano-color-primary-tint, #2696b6));
        --color--focus-contrast: var(--nano-color-contrast, var(--nano-color-primary-contrast, #fff));
        --input-font-size: .87em;
        --input-text-color: var(--nano-input-text-color, #4a4a4a);
        --input-border-width: var(--nano-input-border-width, 2px);
        --input-border-color: var(--nano-input-border-color, #e4e6e8);
        --input-border-radius: var(--nano-input-border-radius, 0);
        --input-border-width-sh: var(--input-border-width) var(--input-border-width) var(--input-border-width) var(--input-border-width);
        --input-border-style: var(--nano-input-border-style, solid) var(--input-border-width) var(--nano-input-border-color, #e4e6e8);
        --input-border-style--focus: var(--nano-input-border-style, solid) var(--input-border-width) var(--nano-input-border-color--focus, #90c6e7);
        --input-border-style--invalid: var(--nano-input-border-style, solid) var(--input-border-width) var(--nano-input-border-color--invalid, #fac6c3);
        --input-border-style--invalid-focus: var(--nano-input-border-style, solid) var(--input-border-width) var(--nano-input-border-color--invalid, #f36d64);
        --input-bg-color: var(--nano-input-background-color, #fff);
        --input-bg-color--focus: var(--input-bg-color);
        --input-bg-color--invalid: var(--nano-input-background-color, #fee8de);
        --invalid-msg-color: rgba(var(--color--invalid), 1);
        --invalid-msg-font-size: .75em;
        --clear-btn-color: rgba(0, 0, 0, .3);
        --clear-btn-color--hover: rgba(var(--color--focus-rgb), 1);
        --clear-btn-color--invalid: rgba(var(--color--invalid), 1);
        --label-color: var(--nano-input-label-color, "currentColor");
        --label-color--focus: var(--label-color);
        --label-color--invalid: "currentColor";
        --label-font-size: initial;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        padding: 0 !important;
        color: var(--color);
    }
    .input.sc-nano-input {
        font-family: inherit;
        font-size: inherit;
        font-style: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        text-decoration: inherit;
        text-overflow: inherit;
        text-transform: inherit;
        text-align: inherit;
        white-space: inherit;
        color: inherit;
        border-radius: var(--input-border-radius);
        width: 100%;
        padding: 0 !important;
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        background: var(--input-bg-color);
        border: var(--input-border-style);
        border-width: var(--input-border-width-sh);
        font-size: var(--input-font-size);
        border: 1px solid #0084a9;
    }
    .native-input-wrap.sc-nano-input {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .native-input.sc-nano-input {
        padding-left: unset;
        padding-right: unset;
        -webkit-padding-start: var(--padding-start);
        padding-inline-start: var(--padding-start);
        -webkit-padding-end: var(--padding-end);
        padding-inline-end: var(--padding-end);
    }
    .native-input.sc-nano-input {
        border-radius: var(--input-border-radius);
        padding-left: var(--padding-start);
        padding-right: var(--padding-end);
        padding-top: var(--padding-top);
        padding-bottom: var(--padding-top);
        font-family: inherit;
        font-size: inherit;
        font-style: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        text-decoration: inherit;
        text-overflow: inherit;
        text-transform: inherit;
        text-align: inherit;
        white-space: inherit;
        color: inherit;
        color: var(--input-text-color);
        display: inline-block;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        min-width: 0;
        border: 0;
        outline: none;
        background: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        line-height: 2em;
        min-height: 2em;
        margin: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 52px;
    }
    `}</style>
    </>
    );

}

export default function useQuantity(
        initialQuantity = 0,
        inputKey,
        onValueChange = () => {},
        maxValue = null,
        increments = 1,
        focusKey,
        setFocusKey,
        disableKeyboardInput = false
    ) {
    const [state, setState] = useState(initialQuantity);

    return [
        state,
        <QuantityComponent
            key={'qc-' + inputKey}
            focusKey={focusKey}
            setFocusKey={setFocusKey}
            state={state}
            setState={setState}
            onValueChange={onValueChange}
            inputKey={inputKey}
            maxValue={maxValue}
            increments={increments}
            disableKeyboardInput={disableKeyboardInput}
        />,
        setState
    ];
}

