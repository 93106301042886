import React, { useState, useEffect } from 'react';
import { useQuotationContext } from '../../../contexts/QuotationContext';
import { ConfirmedRecipients } from './Section/Shipment/notificationList';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLifeRing, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NanoCheckbox } from '@nanoporetech-digital/components-react';
import * as styles from './style.module.css';
import { Link } from 'gatsby';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import UiButton from '../../Ui/UiButton';
import { NanoInput } from '@nanoporetech-digital/components-react';


export default function Success() {
    const [quotation] = useQuotationContext();
    const [followUp, setFollowUp] = useState(false);
    const redemptionLink = `/quote/retrieve/?quotationId=${quotation.quotation_id}&dpCode=${quotation.dp_code}`;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={styles.quoteCreate}>
            <div className={styles.proceed}>
                <Link to="/">
                    <FontAwesomeIcon icon={faChevronLeft} />&nbsp;&nbsp;Continue shopping
                </Link>
            </div>
            <h1>Thank you</h1>
            <h2>Your quote has been created</h2>
            <div className={styles.quoteSuccess}>
                <div className={styles.recipients}>
                    <p>We have sent this quote to the following recipients:</p>
                    <ConfirmedRecipients notifications={quotation.notifications} />
                </div>
                <div className={styles.divider}>
                    <h3>What next?</h3>
                    <div className={styles.whatNext}>
                        <div><FontAwesomeIcon icon={faEnvelope} size="2x" color="#90c6e7" /></div>
                        <div>
                            <h4>Check your mail</h4>
                            <p>Your quote, and instructions on how to convert it to an order, have been sent to you and all other specified recipients</p>
                        </div>
                    </div>
                    <div className={styles.whatNext}>
                        <div><FontAwesomeIcon icon={faLifeRing} size="2x" color="#90c6e7" /></div>
                        <div>
                            <h4>Request a follow-up call</h4>
                            <p>We can call you and help with any enquiries you have relating to the quote</p>
                            <NanoCheckbox
                                label="Request a call"
                                checked={followUp}
                                onNanoChange={() => setFollowUp(!followUp)} />
                            {followUp && <FollowUp setFollowUp={setFollowUp} />}
                        </div>
                    </div>
                </div>
                <div className={styles.divider}>
                    <div className={styles.checkoutNow}>
                        <div>
                            <h3>Or you can checkout now</h3>
                            <div>You can place this order now by going straight to checkout</div>
                        </div>
                        <div>
                            <Link to={redemptionLink} className="button button--secondary button--keyline">
                                Checkout now&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faChevronRight} size="sm" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function FollowUp({setFollowUp}) {
    const [phoneNumber, setPhoneNumber] = useState('');

    function submit(e) {
        e.preventDefault();
        console.log('Follow up number', phoneNumber);
        setFollowUp(false);
    }

    return(
        <form onSubmit={e => submit(e)}>
            <div className={styles.followUp}>
                <div>
                    <NanoInput
                    type="tel"
                    clear-input="true"
                    hide-label="true"
                    placeholder="Enter your phone number"
                    required="true"
                    show-inline-error="submitThenDirty"
                    value={phoneNumber}
                    onNanoChange={e => setPhoneNumber(e.target.value)}
                    />
                </div>
                <div>
                    <UiButton className="button button--secondary" type="submit">Call me</UiButton>
                </div>
            </div>
        </form>
    );
}
