export function getUiQuotationRef(quotationId) {
    const num = quotationId.toString();
    return 'ONT-' + num.padStart(6, '0');
}

export function frontendToBackendCartAddress(address) {
    // if we have a region id we should not send region as Magento takes that as a region code!
    if (address.region_id && address.region) {
        return {
            ...address,
            region_id: address.region_id,
            region: null
        }
    }

    return address;
}
