import React from 'react';
import { NanoGrid } from '@nanoporetech-digital/components-react';
import { productInformation } from './style.module.css';
import { useTranslation } from 'react-i18next';
import ProductPhase from '../ProductPhase';
import Documentation from '../Documentation';

export default function ProductInformation({category}) {
    const { t } = useTranslation();

    return (
        <section className={productInformation}>
            <h2>{t("Product Information")}</h2>
            <NanoGrid sCols={1} mCols={1} lCols={2} xlCols={2} xxlCols={2}>
                <ProductPhase />
                <Documentation category={category} />
            </NanoGrid>
        </section>
    );
}
