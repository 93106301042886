import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { useCartContext } from '../../../../contexts/CartContext';
import { useAddMetaToCart } from './useCartMeta';
import UiButton from '../../../Ui/UiButton';
import * as styles from './style.module.css';

export default function MetaSave({state}) {
    const { cart } = useCartContext();
    const [addMetaToCartMutation] = useAddMetaToCart();
    const isSaving = state.saveStatus.startsWith('Saving');
    const isError = state.saveStatus.startsWith('Error');

    const ButtonLabel = () => isSaving ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Save';

    const submitMetaData = () => {
        state.setSaveStatus('Saving ...');
        addMetaToCartMutation({variables: {cartId: cart.id, metaData: state.metaData}})
            .then(data => {
                state.setSaveStatus(data.data.addMetaToCart.message_text);
            })
            .catch(error => {
                state.setSaveStatus('Error: ' + getErrorMessage(error));
            });
    }

    return (
        <div className={styles.buttons}>
            <UiButton
                className="button button--primary"
                onClick={submitMetaData}
                type="button"
                disabled={isSaving}
            ><ButtonLabel /></UiButton>
            &nbsp;&nbsp;
            <span className={isError ? styles.error : null}>{state.saveStatus}</span>
        </div>
    );
}

function getErrorMessage(err) {
    let errorString = (typeof err === 'string') ? err : err.message;

    if (errorString.includes('No cart id provided') || errorString.includes('The current user cannot perform operations on cart')) {
        errorString = "Your admin session has expired. Please close this browser window and log in again";
    }

    return errorString;
}
