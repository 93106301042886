import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useCartContext } from '../../../contexts/CartContext';
import { useCustomerContext } from '../../../contexts/CustomerContext';
import { NanoInput } from '@nanoporetech-digital/components-react';
import UiButton from '../../Ui/UiButton';
import { useApplyCustomPrice, updateContextCart } from '../../../hooks/useCart';
import * as styles from './style.module.css';

export default function AdminPrice({item, refetchCart}) {
    const { cart, setCart } = useCartContext();
    const { customer } = useCustomerContext();
    const [showCustomPrice, setShowCustomPrice] = useState(false);
    const [customPrice, setCustomPrice] = useState(item.prices.price.value);
    const [applyCustomPriceMutation] = useApplyCustomPrice(cart.id);

    if (!customer.is_admin_mode) return null;

    const applyCustomPrice = () => {
        applyCustomPriceMutation({variables: {cartId: cart.id, itemId: item.id, customPrice: customPrice}})
            .then(data => {
                refetchCart();
                updateContextCart(cart, setCart, data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const CustomPriceLink = () => showCustomPrice
        ? <span><FontAwesomeIcon icon={faChevronDown} /> Hide custom price</span>
        : <span><FontAwesomeIcon icon={faChevronRight} /> Set custom price</span>;

    return (
        <div className={styles.adminPrice}>
            <a onClick={() => setShowCustomPrice(!showCustomPrice)}><CustomPriceLink /></a>
            <CustomPriceField item={item} showCustomPrice={showCustomPrice} customPrice={customPrice} setCustomPrice={setCustomPrice} applyCustomPrice={applyCustomPrice} />
        </div>
    );
}

function CustomPriceField({item, showCustomPrice, customPrice, setCustomPrice, applyCustomPrice}) {
    if (!showCustomPrice) return null;

    return (
        <div className={styles.customPrice}>
            <div>
                <span className={styles.currency}>{item.prices.price.currency}</span>
                <NanoInput
                    clear-input="true"
                    hide-label="true"
                    placeholder="Set custom price..."
                    required="false"
                    show-inline-error="none"
                    value={customPrice}
                    onNanoChange={e => setCustomPrice(e.target.value)}
                >
                    <ErrorMessage />
                </NanoInput>
            </div>
            <div className={styles.apply}>
                <UiButton
                    className="button button--secondary button--keyline"
                    onClick={applyCustomPrice}
                    type="button"
                >Apply</UiButton>
            </div>
        </div>
    );
}

function ErrorMessage() {
    return null;
}
