import React from 'react';
import UiMarkdown from "../../../Ui/UiMarkdown";
import * as styles from './style.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import UiButton from '../../../Ui/UiButton';
import { MandatoryStatus } from './sectionStatus';
import { NanoGrid } from '@nanoporetech-digital/components-react';

export default function Flowcells(props) {
    const option = props.options.find(option => option.title===props.section.label);
    const selectedId = props.section.cache.selectedId ?? 0;

    const updateSection = selectedItem => {
        const selections = [];
        const items = [];
        const cache = {};

        let selectedChemistryType = null;

        if (Object.keys(selectedItem).length) {
            selections.push({id: option.option_id, quantity: 1, value: [selectedItem.id.toString()]});
            items.push({
                name: selectedItem.product.name,
                qty: selectedItem.quantity,
                surcharge: 0.00,
            });
            selectedChemistryType = selectedItem.product.ont_chemistry_type;
            cache['selectedId'] = selectedItem.id;
        }

        props.updateSection({
            ...props.section,
            selections,
            items,
            cache,
            selectedChemistryType: selectedChemistryType
        });
    }

    return (
        <>
        <MandatoryStatus allowContinue={selectedId ? true : false} noun="your flow cell type" goNextStep={props.goNextStep} />
        <NanoGrid  l-cols="10"
            m-cols="10"
            s-cols="10"
            xl-cols="10"
            xxl-cols="10" className={styles.sectionMain}>
                <div grid-states="xl-col-span-6 l-col-span-6 s-col-span-10 m-col-span-10" className={styles.sectionContent}>
                    {option.options
                        .sort((a, b) => {
                            return a.position - b.position;
                        })
                        .map(flowCell =>
                            <Flowcell
                                key={flowCell.id}
                                selectionItem={flowCell}
                                selectedId={selectedId}
                                updateSection={updateSection}
                            />
                        )
                    }
                </div>
                {props.summary}
        </NanoGrid>
        </>
    );
}

function Flowcell({selectionItem, selectedId, updateSection}) {
    const product = selectionItem.product;
    const description = product.short_description.html.replace(/<[^>]+>/g, '');
    const Button = () => {
        if (selectionItem.id === selectedId) {
            return(
                <UiButton className="button button--secondary button--keyline" onClick={() => updateSection({})}>
                    <FontAwesomeIcon icon={faCheck} color="#17bb75"/> Selected
                </UiButton>
            );
        }

        return (
            <UiButton className="button button--primary button--keyline" onClick={() => updateSection(selectionItem)}>
                Select
            </UiButton>
        );
    };

    return (
        <div className={styles.item}>
            <h2 className={styles.title}>{selectionItem.quantity}x {product.name}</h2>
            <div className={styles.details}>
                <div className={styles.image}><img src={product.ont_image_url_1} /></div>
                <div className={styles.description}>
                    <div><UiMarkdown source={description} /></div>
                    <div className={styles.itemCta}><Button /></div>
                </div>
            </div>
        </div>
    );
}
