import { useQuery, useMutation, useLazyQuery, gql } from "@apollo/client";

export function useCart(id) {
    return useQuery(CART, {
        variables: { cartId: id },
        fetchPolicy: 'no-cache',
    });

    // useful destructure of return value: { loading, error, data }
    // and error.message and data.cart
}

export function useCustomerCart(skipFlag=false) {
    return useQuery(CUSTOMER_CART, {fetchPolicy: 'network-only', skip: skipFlag});
}

export function useLazyCustomerCart(callback) {
    return useLazyQuery(CUSTOMER_CART, {fetchPolicy: 'network-only', onCompleted: callback});
}

export function useMergeCarts() {
    return useMutation(MERGE_CARTS);
}

export function useAddItem(isBundle=false) {
    return isBundle ? useMutation(ADD_BUNDLE_TO_CART) : useMutation(ADD_TO_CART);
}

export function useRemoveItem(cartId) {
    return useMutation(REMOVE_ITEM);
}

export function useUpdateItem(cartId) {
    return useMutation(UPDATE_ITEM);
}

export function useApplyCustomPrice(cartId) {
    return useMutation(APPLY_CUSTOM_PRICE);
}

export function useApplyCoupon(cartId) {
    return useMutation(APPLY_COUPON);
}

export function useRemoveCoupon(cartId) {
    return useMutation(REMOVE_COUPON);
}

export function useSaveSchedule(cartId) {
    return useMutation(SAVE_SCHEDULE, {
        refetchQueries: [
            {
                query: CART,
                variables: { cartId: cartId }
            }
        ]
    });
}

function addCartRefetchOption(options, cartId='') {
    if (cartId.length) {
        return {
            ...options,
            refetchQueries: [
                {
                    query: CART,
                    variables: { cartId: cartId }
                }
            ]
        };
    }
    return options;
}

export function useSetGuestBillingAddress(cartId='') {
    return useMutation(GUEST_BILLING_ADDRESS, addCartRefetchOption({}, cartId));
}

export function useSetGuestShippingAddress(cartId='') {
    return useMutation(GUEST_SHIPPING_ADDRESS, addCartRefetchOption({}, cartId));
}

export function useSetCustomerBillingAddress(cartId='') {
    return useMutation(CUSTOMER_BILLING_ADDRESS, addCartRefetchOption({}, cartId));
}

export function useSetCustomerExistingShippingAddress(cartId='') {
    return useMutation(CUSTOMER_EXISTING_SHIPPING_ADDRESS, addCartRefetchOption({}, cartId));
}

export function useSetCustomerNewShippingAddress(cartId='') {
    return useMutation(CUSTOMER_NEW_SHIPPING_ADDRESS, addCartRefetchOption({}, cartId));
}

export function useSetShippingMethod(cartId='') {
    return useMutation(SET_SHIPPING_METHOD, addCartRefetchOption({}, cartId));
}

export function useSetPaymentMethod(cartId='') {
    return useMutation(SET_PAYMENT_METHOD, addCartRefetchOption({}, cartId));
}

export function getUniversalCartItem(product, qty, options=[]) {
    const enteredOptions = [];
    const selectedOptions = [];

    for (let option of options) {
        if (typeof option === 'string') {
            selectedOptions.push(option);
        } else {
            enteredOptions.push(option);
        }
    }

    return { quantity: qty, sku: product.sku, selected_options: selectedOptions, entered_options: enteredOptions };
}

export function getBundleCartItem(product, qty, options=[]) {
    const bundleOptions = options?.length ? [...options] : getBundleDefaultOptions(product);
    return {
        data: {
            quantity: qty,
            sku: product.sku
        },
        bundle_options: bundleOptions
    };
}

export function getBundleDefaultOptions(product) {
    const options = [];
    if (product.items?.length) {
        product.items.forEach(group => {
            if (group.required && (group.type==='checkbox' || group.options.length==1)) options.push({
                id: group.option_id,
                quantity: 0,    // not relevant, use for variable qty like SLDW if single option in group
                value: group.options.map(option => option.id.toString())
            });
        });
    }
    return options;
}

export function updateContextCart(oldCart, setCart, response) {
    if (response && response.data) {
        const keys = Object.keys(response.data);
        const firstKey = keys.length ? keys[0] : null;
        const cart = firstKey ? response.data[firstKey].cart : null;
        if (cart) {
            const newCart = {...oldCart, id: cart.id, items: cart.items, subtotal: cart.prices.subtotal_excluding_tax, total_quantity: cart.total_quantity, is_virtual: cart.is_virtual };
            setCart(newCart);
            return cart;
        }
    }
    return oldCart;
}

export function getStandardResponseGraphQl() {
    return STANDARD_RESPONSE;
}

const STANDARD_RESPONSE = `
id
email
total_quantity
is_virtual
ont_is_order_permitted
items {
  id
  product {
    __typename
    name
    sku
    ont_mask_sku
    ont_image_url_1
    type_id
    ont_included_products
    ont_render_type
    categories {
        name
        breadcrumbs {
            category_name
        }
    }
  }
  quantity
  prices {
    row_total {
        currency
        value
    }
    price {
        currency
        value
    }
  }
  ... on ConfigurableCartItem {
    configurable_options {
      option_label
      value_label
    }
  }
  ... on BundleCartItem {
    bundle_options {
      values {
        id
        label
        quantity
        sku
        ont_mask_sku
        ont_included_products
      }
      id
    }
  }
}
applied_coupon {
  code
}
prices {
    discount {
        amount {
            currency
            value
        }
    }
    subtotal_excluding_tax {
        currency
        value
    }
    subtotal_with_discount_excluding_tax {
        currency
        value
    }
    grand_total {
        value
        currency
    }
}
available_payment_methods {
    code
    title
}
selected_payment_method {
    code
}
shipping_addresses {
    country {
        code
    }
    selected_shipping_method {
        amount {
            currency
            value
        }
    }
}
messages {
    message_type
    message_text
}
has_tax_billing_info
`;

const ADD_TO_CART = gql`
mutation AddToCart($cartId: String!, $items: [CartItemInput!]!) {
    addProductsToCart(
        cartId: $cartId
        cartItems: $items
    ) {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;

const ADD_BUNDLE_TO_CART = gql`
mutation AddBundleToCart($cartId: String!, $items: [BundleProductCartItemInput!]!) {
    addBundleProductsToCart(
        input: {
            cart_id: $cartId
            cart_items: $items
        }
    ) {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;

const CART = gql`
    query ($cartId: String!) {
        cart(cart_id: $cartId) {
            ${STANDARD_RESPONSE}
        }
    }
`;

const CUSTOMER_CART = gql`
    query {
        customerCart {
            ${STANDARD_RESPONSE}
        }
    }
`;

const REMOVE_ITEM = gql`
mutation ($cartId: String!, $itemId: Int!) {
    removeItemFromCart(
        input: {
            cart_id: $cartId,
            cart_item_id: $itemId
        }
    )
    {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;

const UPDATE_ITEM = gql`
mutation ($cartId: String!, $itemId: Int!, $qty: Float! ) {
    updateCartItems(
        input: {
            cart_id: $cartId,
            cart_items: [
                {
                  cart_item_id: $itemId
                  quantity: $qty
                }
            ]
        }
    )
    {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;

const APPLY_CUSTOM_PRICE = gql`
mutation ($cartId: String!, $itemId: Int!, $customPrice: Float!) {
    applyCustomPrice(
        input: {
            cart_id: $cartId
            item_id: $itemId
            custom_price: $customPrice
        }
    )
    {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;

const APPLY_COUPON = gql`
mutation ($cartId: String!, $couponCode: String!) {
    applyCouponToCart(
        input: {
            cart_id: $cartId,
            coupon_code: $couponCode
        }
    )
    {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;

const REMOVE_COUPON = gql`
mutation ($cartId: String!) {
    removeCouponFromCart(
        input: {
            cart_id: $cartId
        }
    )
    {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;

const GUEST_BILLING_ADDRESS = gql`
mutation ($cartId: String!, $address: CartAddressInput!, $useForShipping: Boolean!, $customerAddressId: Int) {
    setBillingAddressOnCart(
        input: {
            cart_id: $cartId
            billing_address: {
                address: $address
                use_for_shipping: $useForShipping
                customer_address_id: $customerAddressId
            }
        }
    )
    {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;

const GUEST_SHIPPING_ADDRESS = gql`
mutation ($cartId: String!, $address: CartAddressInput!, $customerAddressId: Int) {
    setShippingAddressesOnCart(
        input: {
            cart_id: $cartId
            shipping_addresses: [{
                address: $address
                customer_address_id: $customerAddressId
            }]
        }
    )
    {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;

const CUSTOMER_BILLING_ADDRESS = gql`
mutation ($cartId: String!, $useForShipping: Boolean!, $customerAddressId: Int) {
    setBillingAddressOnCart(
        input: {
            cart_id: $cartId
            billing_address: {
                use_for_shipping: $useForShipping
                customer_address_id: $customerAddressId
            }
        }
    )
    {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;

const CUSTOMER_EXISTING_SHIPPING_ADDRESS = gql`
mutation ($cartId: String!, $customerAddressId: Int) {
    setShippingAddressesOnCart(
        input: {
            cart_id: $cartId
            shipping_addresses: [{
                customer_address_id: $customerAddressId
            }]
        }
    )
    {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;

const CUSTOMER_NEW_SHIPPING_ADDRESS = gql`
mutation ($cartId: String!, $address: CartAddressInput!) {
    setShippingAddressesOnCart(
        input: {
            cart_id: $cartId
            shipping_addresses: [{
                address: $address
            }]
        }
    )
    {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;

const SET_SHIPPING_METHOD = gql`
mutation ($cartId: String!) {
    setShippingMethodsOnCart(
        input: {
            cart_id: $cartId,
            shipping_methods: [{
              carrier_code: "nanoporeshipping"
              method_code: "nanoporeshipping"
            }]
        }
    )
    {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;

const SET_PAYMENT_METHOD = gql`
mutation ($cartId: String!, $method: String!, $poNumber: String) {
    setPaymentMethodOnCart(
        input: {
            cart_id: $cartId,
            payment_method: {
              code: $method
              purchase_order_number: $poNumber
            }
        }
    )
    {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;

const MERGE_CARTS = gql`
mutation ($guestCartId: String!, $customerCartId: String!) {
    mergeCarts(
        source_cart_id: $guestCartId
        destination_cart_id: $customerCartId
    ) {
        ${STANDARD_RESPONSE}
    }
}
`;

const SAVE_SCHEDULE = gql`
mutation ShippingScheduleSave($cartId: String!, $rows: [ScheduleInputRow!]!, $interacted: Boolean) {
    shippingScheduleSave(
        input: {
            cart_id: $cartId
            rows: $rows
            interacted: $interacted
        }
    ) {
        cart {
            ${STANDARD_RESPONSE}
        }
    }
}
`;
