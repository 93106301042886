export function getTotalPrice(minionConfig, surcharges) {
    let price = surcharges.base.value;

    if (minionConfig.enhancedPack) {
        price += surcharges.enhancedPack.value;
    }

    if (minionConfig.rapidStart) {
        price += surcharges.rapidStart.value;
    }

    return {currency: surcharges.base.currency, value: price};
}
