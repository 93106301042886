import React from 'react';
import { isStoreSwitchNeeded } from '../helpers';
import * as styles from './style.module.css';

export default function StoreSwitchWarning({billingInfo}) {
    if (isStoreSwitchNeeded(billingInfo)) {
        return (
            <div className={styles.storeSwitchWarning}>
                <div className="info-banner info-banner--caution">
                    <div className="info-banner__content">
                        <p className="info-banner__text">You have chosen a billing address in a different country to the current store location.</p>
                        <p className="info-banner__text">After confirming this address, we will change store location to match your new billing address.</p>
                        <p className="info-banner__text"><strong>You will lose your basket items once the store location has been changed.</strong></p>
                    </div>
                </div>
            </div>
        );
    }

    return null;
}
