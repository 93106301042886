import React from 'react';
import UiButton from '../../Ui/UiButton';
import { getCwsUrl } from '../helpers';

export default function Button({item}) {
    const linkUrl = item.link.url;
    const useUrl = linkUrl.startsWith('/') ? getCwsUrl() + linkUrl : linkUrl;

    return (
        <UiButton type="button" className="button button--primary button--keyline" onClick={() => location.href=useUrl}>
            {item.link_label}
        </UiButton>
    );
}
