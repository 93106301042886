import React from 'react';
import { getTotalPrice } from './helpers';
import { totals, price } from './style.module.css';
import { useMinionContext } from '../Configurator/useMinionContext';
import { useTranslation } from 'react-i18next';
import Price from '../../../Ui/Price';

export default function Totals() {
    const { minionConfig, surcharges } = useMinionContext();
    const { t } = useTranslation();
    const totalPrice = getTotalPrice(minionConfig, surcharges); // surcharges already reflect any custom pricing

    return (
        <div className={totals}>
            <span>{t("Total")}</span>
            <span className={price}><Price currencyCode={totalPrice.currency} value={totalPrice.value} /></span>
        </div>
    );
}
