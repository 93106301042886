import React from 'react';
import {Helmet} from "react-helmet";
import { navigate } from 'gatsby';
import { useCustomerContext, isExpired } from '../contexts/CustomerContext';
import OrderScheduler from '../components/ShippingScheduler/OrderScheduler';

export default function SchedulePage(props) {
    const { customer } = useCustomerContext();

    if (typeof window==='undefined') return null;

    const urlParams = new URLSearchParams(props.location.search);
    const orderId = urlParams.get('order') ?? null;
    const referrer = urlParams.get('referrer') ?? null;

    if (!orderId) navigate('/404');

    if (!customer.token.length || isExpired(customer)) navigate('/reauthenticate');

    return (
        <>
            <Helmet>
                <title>Order schedule</title>
            </Helmet>
            <OrderScheduler referrer={referrer} orderId={orderId} />
        </>
    );
}
