import { getSelectedOptionMatchingSku } from '../bundleHelpers';

export function getBundleContents(bundleProduct, minionConfig) {
    const contents = getHiddenContents(bundleProduct);

    bundleProduct.items.forEach(option => {
        const title = option.title.toLowerCase();
        let selectedOption = null;

        if (title.startsWith('flow cell')) {
            selectedOption = getSelectedOptionMatchingSku(option, minionConfig.flowcell);
            if (!selectedOption) contents.push({qty: option.options[0].qty, name: 'Flow cells'});
        }

        if (title.startsWith('kit')) {
            selectedOption = getSelectedOptionMatchingSku(option, minionConfig.kit);
            if (!selectedOption) contents.push({qty: option.options[0].qty, name: 'Sequencing kit'});
        }

        if (selectedOption) {
            const content = getDisplayContentFromSelectedOption(selectedOption, true);
            contents.push(content);
        }
    });
    return contents;
}

function getHiddenContents(bundleProduct) {
    const hidden = bundleProduct.items.find(item => item.title=='hidden');
    const options = hidden?.options ?? [];
    return options.map(option => getDisplayContentFromSelectedOption(option));
}

function getDisplayContentFromSelectedOption(option, includeInfo=false) {
    return {
        qty: option.qty,
        name: option.label,
        sku: option.product.ont_mask_sku ?? option.product.sku,
        info: includeInfo ? option.product.short_description.html : ''
    }
}
