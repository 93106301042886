import React, { useState } from 'react';
import { useCheckoutContext } from '../../../../contexts/CheckoutContext';
import SectionHeader from '../sectionHeader';
import SectionSubmit from '../sectionSubmit';
import { saveSection, pluralise } from '../helpers';
import { NanoCheckbox } from '@nanoporetech-digital/components-react';
import * as styles from '../style.module.css';

export const CODE = 'terms';
export const TITLE = 'Terms and conditions';

export default function Terms({step, cartData}) {
    const [checkout, setCheckout] = useCheckoutContext();
    const isActive = checkout.activeStep===step;
    const section = checkout[CODE] ?? {};

    // local state to avoid global re-render on small changes
    const [cache, setCache] = useState({
        ont_terms_agreed: [],
    });

    const minTermsToBeAgreed = cartHasDevice(cartData) ? 1 : 2;
    const disableSubmit = (cache.ont_terms_agreed.length < minTermsToBeAgreed);

    async function submit() {
        saveSection(CODE, checkout, setCheckout, section, {
            canChange: true,
            highlight: 'Agreed to ' + pluralise(cache.ont_terms_agreed.length, 'condition'),
            ont_terms_agreed: cache.ont_terms_agreed,
        });
    }

    return(
        <div className={styles.section}>
            <SectionHeader step={step} code={CODE} title={TITLE} />
            {isActive && <SectionContent settings={{cache, setCache, section}} cartData={cartData} />}
            {isActive && <SectionSubmit callback={submit} disable={disableSubmit} />}
        </div>
    );
}

function SectionContent({settings, cartData}) {
    const {cache, setCache} = settings;
    const ont_terms_agreed = [...cache.ont_terms_agreed];

    function update(checkbox) {
        if (checkbox.checked) {
            if (ont_terms_agreed.findIndex(t => t==checkbox.value) == -1) {
                ont_terms_agreed.push(checkbox.value);
            }
        } else {
            const index = ont_terms_agreed.findIndex(t => t==checkbox.value);
            if (index >= 0) ont_terms_agreed.splice(index, 1);
        }
        setCache({
            ...cache,
            ont_terms_agreed: ont_terms_agreed,
        });
    }

    function NoDeviceWarrantyNotice() {
        if (cartHasDevice(cartData)) {
            return null;
        }

        return (
            <div>
                <p className={styles.divider}>Your basket does not contain any sequencing device and all our products require a Nanopore device to work. Using our products on a Nanopore device on another account, even in the same organisation, may invalidate the consumable warranty. This is detailed in our <a href="https://nanoporetech.com/commercial_information">Terms & Conditions</a> which will need to be accepted to place an order. To avoid the warranty limitation please add a starter pack to you order. To proceed with your purchase without a device please acknowledge the warranty restriction below:</p>
                <NanoCheckbox
                    name="warranty_restriction"
                    type="checkbox"
                    label="I confirm that I wish to proceed to place an order without a device and understand the warranty restriction"
                    value="Warranty restriction notice"
                    checked={cache.ont_terms_agreed.findIndex(t => t=='Warranty restriction notice') >= 0}
                    onNanoChange={(e) => update(e.target)}
                />
            </div>
        );
    }

    return(
        <div className={styles.sectionContent}>
            <div>
                <p>I have read the <a target="_blank" href="https://nanoporetech.com/commercial_information">Terms and Conditions</a> that pertain to this order, including the Nanopore Product terms and conditions, Metrichor Terms of Use, and MinKNOW/Metrichor Agent EULA.</p>
                <NanoCheckbox
                    name="full_terms"
                    type="checkbox"
                    label="I confirm, accept and agree to all"
                    value="Oxford Nanopore terms and conditions"
                    checked={cache.ont_terms_agreed.findIndex(t => t=='Oxford Nanopore terms and conditions') >= 0}
                    onNanoChange={(e) => update(e.target)}
                />
            </div>
            <NoDeviceWarrantyNotice />
        </div>
    );
}

function cartHasDevice(cartData) {
    for (const item of cartData.items) {
        for (const category of item.product.categories) {
            if (category.name.toLowerCase() == 'devices') return true;
            if (category.breadcrumbs) {
                for (const parentCategory of category.breadcrumbs) {
                    if (parentCategory?.category_name?.toLowerCase() == 'devices') return true;
                }
            }
        }
    }

    return cartData.contains_devices ?? false;
}
