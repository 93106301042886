import {graphql, useStaticQuery} from "gatsby";
import {useCustomerContext} from "../../contexts/CustomerContext";
import {gql, useQuery} from "@apollo/client";
import {useState} from "react";

export const getExtraContent = () => {
    const data =  useStaticQuery(graphql`
query {
  magento {
    cmsBlocks(identifiers: ["home-header-1", "home-compare", "home-register-interest", "home-footer", "home-help"]) {
      items {
        identifier
        title
        content
      }
    }
    categoryList(filters: {url_key: {eq: "store-home"}}) {
      products {
        items {
          id
          name
          url_key
          ont_image_url_1
          price_range {
            minimum_price {
              final_price {
                currency
                value
              }
            }
          }
        }
      }
    }
  }
}
    `);

    const liveQuery = gql`
query {
  categoryList(filters: {url_key: {eq: "store-home"}}) {
    products {
      items {
        id
        name
        url_key
        ont_image_url_1
        price_range {
          minimum_price {
            final_price {
              currency
              value
            }
          }
        }
      }
    }
  }
}
    `;
    let homeCategory = data.magento.categoryList[0];

    const [extraContent, setExtraContent] = useState({
        cmsBlocks: getHomeCmsBlocks(data.magento.cmsBlocks.items),
        carousel: {position: 3, category: homeCategory},
    });

    const {liveData} = useQuery(liveQuery, {
        skip: !shouldMakeLiveQuery(),
        onCompleted: liveData => {
            setExtraContent({
                ...extraContent,
                carousel: {position: 3, category: liveData.categoryList[0]},
            })
        },
        fetchPolicy: 'network-only',

    })

    return extraContent;
}

function getHomeCmsBlocks(cmsBlocks) {
    const result = {
        'header': [],
        'right_marketing': null,
        'footer': null,
        'right_col': null,
        'help': null
    };

    cmsBlocks.forEach(block => {
        switch (block?.identifier) {
            case 'home-header-1':
            case 'home-header-2':
            case 'home-header-3':
            case 'home-header-4':
            case 'home-header-5':
                result.header.push(block);
                break;
            case 'home-register-interest':
                result.right_marketing = block;
                break;
            case 'home-footer':
                result.footer = block;
                break;
            case 'home-compare':
                result.right_col = block;
                break;
            case 'home-help':
                result.help = block;
                break;
        }
    });

    return result;
}

function shouldMakeLiveQuery() {
    const { customer } = useCustomerContext()
    return customer.has_extra_permission;
}
