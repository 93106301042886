import React from 'react';
import { NanoInput, NanoSelect, NanoOption, NanoCheckbox } from '@nanoporetech-digital/components-react';
import * as styles from './style.module.css';

export default function MetaForm({state}) {
    const updateState = field => state.setMetaData({...state.metaData, [field.name]: field.value});

    return (
        <div className={styles.metaData}>
            <NanoSelect
                label="Sales origin"
                placeholder="Please select ..."
                required="false"
                name="ont_origin_code"
                value={state.metaData.ont_origin_code ?? ''}
                onNanoChange={e => updateState(e.target)}
            >
                <NanoOption key="Shop" value="Shop" label="Shop" />
                <NanoOption key="Re-order" value="Re-order" label="Re-order" />
                <NanoOption key="FOC" value="FOC" label="Free of charge" />
                <NanoOption key="VWR" value="VWR" label="VWR" />
                <NanoOption key="Upgrade" value="Upgrade" label="Upgrade" />
            </NanoSelect>
            <NanoSelect
                label="Inco terms"
                placeholder="Please select ..."
                required="false"
                name="ont_inco_terms"
                value={state.metaData.ont_inco_terms ?? ''}
                onNanoChange={e => updateState(e.target)}
            >
                <NanoOption key="DDP" value="DDP" label="DDP" />
                <NanoOption key="DAP" value="DAP" label="DAP" />
            </NanoSelect>
            <NanoSelect
                label="Sales tag"
                placeholder="Please select ..."
                required="false"
                name="ont_sales_tag"
                value={state.metaData.ont_sales_tag ?? ''}
                onNanoChange={e => updateState(e.target)}
            >
                <NanoOption key="FOCSales" value="FOCSales" label="FOCSales" />
                <NanoOption key="FOCMarketing" value="FOCMarketing" label="FOCMarketing" />
                <NanoOption key="FOCMetrichor" value="FOCMetrichor" label="FOCMetrichor" />
                <NanoOption key="FOCR&D" value="FOCR&D" label="FOCR&D" />
            </NanoSelect>
            <NanoInput
                clear-input="true"
                label="Custom order number"
                required="false"
                name="override_increment_id"
                value={state.metaData.override_increment_id ?? ''}
                onNanoChange={e => updateState(e.target)}
            />
            <NanoCheckbox
                label="Send order confirmation email"
                name="ont_admin_send_email"
                checked={Boolean(state.metaData.ont_admin_send_email)}
                onNanoChange={e => updateState({name: e.target.name, value: e.target.checked})}
            />
        </div>
    );
}
