import React from 'react';
import { useCheckoutContext } from '../../../../contexts/CheckoutContext';
import SectionHeader from '../sectionHeader';
import { saveSection } from '../helpers';
import PurchaseOrder from './PurchaseOrder';
import CreditCard from './CreditCard';
import Free from './Free';
import MyAccount from './MyAccount';
import * as styles from '../style.module.css';

export const CODE = 'payment_details';
export const TITLE = 'Payment details';

export default function PaymentDetails({step}) {
    const [checkout, setCheckout] = useCheckoutContext();
    const isActive = checkout.activeStep===step;
    const section = checkout[CODE] ?? {};

    async function submit(data) {
        saveSection(CODE, checkout, setCheckout, section, {
            canChange: true,
            ...data,
        });
    }

    return(
        <div className={styles.section}>
            <SectionHeader step={step} code={CODE} title={TITLE} />
            {isActive && <SectionContent section={section} submitSection={submit} />}
        </div>
    );
}

function SectionContent({section, submitSection}) {
    const [checkout] = useCheckoutContext();

    switch(checkout.payment_method.selected_payment_method) {
        case 'purchaseorder':
            return <PurchaseOrder
                section={section}
                submitSection={submitSection}
                billingAddress={checkout.billing?.billing_info?.billing ?? null} />
        case 'chcybersource':
            return <CreditCard section={section} submitSection={submitSection} />
        case 'free':
        case 'ont_zero_value':
            return <Free section={section} submitSection={submitSection} />
        case 'ont_my_account':
            return <MyAccount section={section} submitSection={submitSection} />
    }

    return <p>Sorry, there has been an error. Please select an alternative payment method.</p>
}
