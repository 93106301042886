import React from 'react';
import { NanoSlides, NanoSlide } from '@nanoporetech-digital/components-react';
import Header from '../../../Header';
import {slide as slideClass, slides as slidesClass} from "./style.module.css";

export default function Carousel({category, cmsBlocks}) {
    const slides = cmsBlocks.header;

    if (!slides?.length) return null;

    return (
        <div className={slidesClass}>
            <Header category={category} title={category.name} isHome={true} isProductPage={false} />

            <NanoSlides navbtns="false" pager="false" autoplay="false" current-slide="0">
                {slides.map(slide => <Slide key={slide.identifier} slide={slide} />)}
            </NanoSlides>
        </div>
    );
}

function Slide({slide}) {
    return (
        <NanoSlide>
            <div className={slideClass} dangerouslySetInnerHTML={{__html: slide.content}}/>
        </NanoSlide>
    );
}
