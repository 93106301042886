import React from 'react';
import { isChinaStore, shouldShowQrCodes } from '../../hooks/siteData';
import QrCode from '../QrCode';
import * as styles from './style.module.css';

export default function TopActions({isLight, black, category, isProductPage = true}) {
    const categoryClass = category?.name?.split(' ')[0].toLowerCase();
    const subcribeUrl = 'https://nanoporetech.com/subscribe';
    const container = (isProductPage && shouldShowQrCodes()) ? styles.qrProductContainer : styles.normalContainer;
    const openMavenoid = () => {
        if (typeof window !== 'undefined') {
            window.mavenoid?.push({ event: 'assistant-open' });
        }
    }

    return (
        <div className={container}>
            <div className={styles.actions}>
                <a href="https://nanoporetech.com/getting-started-with-minion" className="button button--secondary">Get started</a>
                <a onClick={openMavenoid} className="button button--primary">Talk to us</a>
                <a href={subcribeUrl} className={`button button--light button--keyline ${black} ${categoryClass} `}>Subscribe</a>
                <QrCode isLight={isLight} />
            </div>
        </div>
    );
}
