import React from 'react';
import { NanoSelect, NanoOption } from '@nanoporetech-digital/components-react';

export default function AdditionalRegions({ regions, formData, updateFormData, prefix }) {
    const placeholder = "Please select a state or province...";

    return (
        <NanoSelect
            key={'additional_regions_' + formData.country_code}
            label="State/Province*"
            placeholder={placeholder}
            required={true}
            validateOn="submitThenDirty"
            showInlineError={true}
            name={`${prefix}region`}
            value={formData.region ?? ''}
            onNanoChange={e => updateFormData(e)}
        >
            <NanoOption key={'placeholder'} value={''} label={placeholder} />
            {regions.map((region, i) => <NanoOption key={i} value={region} label={region} />)}
        </NanoSelect>
    );
}
