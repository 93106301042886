import React from 'react';

export default function NonePreview({taxSystem}) {
    return (
        <div>
            <div className="info-banner info-banner--advisory">
                <div className="info-banner__content">
                    <p className="info-banner__label">Information</p>
                    <p className="info-banner__text">{taxSystem.info_text}</p>
                </div>
            </div>
        </div>
    );
}
