export const link_collections = [
    {
        header:"Getting Started",
        links: [
            { text: "Buy a MiniON starter pack",
                href: "/minion.html"
            },
            { text: "Nanopore store",
                href: "/"
            },
            { text: "Sequencing service providers",
                href: "//nanoporetech.com/services/providers"
            },
            { text: "Channel partners",
                href: "//nanoporetech.com/about/channel-partners"
            },
            { text: "Japanese dealers",
                href: "//nanoporetech.com/about/partners/japanese-dealers"
            },
        ]
    },
    {
        header:"Nanopore Technology",
        links: [
            { text: "Subscribe to Nanopore updates",
                href: "//nanoporetech.com/#subscribe"
            },
            { text: "Resources and publications",
                href: "//nanoporetech.com/resource-centre"
            },
            { text: "What is the Nanopore Community",
                href: "//nanoporetech.com/community"
            },
        ]
    },
    {
        header:"About Oxford Nanopore",
        links: [
            { text: "News",
                href: "//nanoporetech.com/about-us/news"
            },
            { text: "Company timeline",
                href: "//nanoporetech.com/about-us/history"
            },
            { text: "Sustainability",
                href: "//nanoporetech.com/sustainability"
            },
            { text: "Leadership team",
                href: "//nanoporetech.com/about-us/team/executive"
            },
            { text: "Media resources & contacts",
                href: "//nanoporetech.com/about-us/for-the-media"
            },
            { text: "For investors",
                href: "//nanoporetech.com/about-us/investors"
            },
            { text: "For partners",
                href: "//nanoporetech.com/about-us/for-partners"
            },
            { text: "Working at Oxford Nanopore",
                href: "//nanoporetech.com/careers"
            },
            { text: "Current vacancies",
                href: "//ejnh.fa.em2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1/requisitions"
            },
            { text: "Commercial information",
                href: "//nanoporetech.com/commercial_information"
            },
        ]
    },
    {
        header:"Contact Us",
        links: [
            { text: "Oxford, UK",
                href: "//nanoporetech.com/contact"
            },
            { text: "Harwell, UK",
                href: "//nanoporetech.com/contact"
            },
            { text: "New York (NY), USA",
                href: "//nanoporetech.com/contact"
            },
            { text: "Cambridge (MA), USA",
                href: "//nanoporetech.com/contact"
            },
            { text: "San Francisco (CA), USA",
                href: "//nanoporetech.com/contact"
            },
            { text: "Shanghai, China",
                href: "//nanoporetech.com/contact"
            },
            { text: "Tokyo, Japan",
                href: "//nanoporetech.com/contact"
            },
            { text: "Singapore, Singapore",
                href: "//nanoporetech.com/contact"
            },

        ]
    }
];
