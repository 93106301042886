import React from 'react';
import TopActions from '../../../TopActions';
import Breadcrumbs from '../../../Breadcrumbs';
import UIBackButton from '../../../Ui/UiBackButton';
import { TransactionalContent, isChinaStore } from '../../../../hooks/siteData';
import ProductImage from "../../Image";
import UiMarkdown from '../../../Ui/UiMarkdown';
import {contentWrapper, productContent, image, details} from './style.module.css';
import Header from '../../../Header';


export default function StandardTop({children, category, product=null}) {

    const sku = product.ont_mask_sku ?? product.sku ?? null;
    const img = product.ont_image_url_1 ?? category.ont_image_url_1 ?? null;

    //hello
    return (
        <>
            <TopActions black="black"/>
            <div className={contentWrapper}>
            <Header category={product} title={product.name}/>
                <div className={productContent}>
                    <div className={image}>
                        {img && <ProductImage src={img} />}
                    </div>
                    <div className={details}>
                        <div>
                            <UiMarkdown source={product.short_description?.html ?? ''} />
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
}
