import React from 'react';
import { Link } from 'gatsby';
import UiButton from '../../Ui/UiButton'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import * as styles from './style.module.css';
import useLoginLink from '../../../hooks/useLoginLink';
import { isProduction } from '../../../hooks/useEnvironment';

export default function LoggedOut() {
    const loginLink = useLoginLink();
    const registerLink = isProduction()
        ? '//myaccount.nanoporetech.com/registration/apply'
        : '//registration-test.dig-dev.nanoporetech.com/account';

    return(
        <div className={styles.loggedOut}>
            <div className={styles.proceed}>
                <Link to="/cart">
                    <FontAwesomeIcon icon={faChevronLeft} />&nbsp;&nbsp;Basket
                </Link>
            </div>
            <h1>Please log in to continue</h1>
            <div className={styles.boxes}>
                <div className={styles.box}>
                    <h2>Existing customers</h2>
                    <div className={styles.content}>
                        <p>If you are already registered, follow the link below to sign into your account.</p>
                        <p>After logging in you will be redirected to checkout your items.</p>
                    </div>
                    <div className={styles.cta}>
                        <UiButton className="button button--primary" onClick={() => location.href=loginLink}>
                            Log in &nbsp; <FontAwesomeIcon icon={faChevronRight} size="sm" />
                        </UiButton>
                    </div>
                </div>

                <div className={styles.box}>
                    <h2>New customers</h2>
                    <div className={styles.content}>
                        <p>Register for the Nanopore community to be able to:</p>
                        <ul>
                            <li>Purchase MinION Starter Kit</li>
                            <li>Place orders in the Nanopore Store</li>
                            <li>Access the Nanopore Community</li>
                        </ul>
                    </div>
                    <div className={styles.cta}>
                        <UiButton className="button button--primary" onClick={() => location.href=registerLink}>
                            Register &nbsp; <FontAwesomeIcon icon={faChevronRight} size="sm" />
                        </UiButton>
                    </div>
                </div>

            </div>
        </div>
    );
}
