import React, { useState } from 'react';
import { useCheckoutContext } from '../../../../contexts/CheckoutContext';
import SectionHeader from '../sectionHeader';
import SectionSubmit from '../sectionSubmit';
import { saveSection } from '../helpers';
import FlowcellReturns from './FlowcellReturns';
import FlowcellBiobox from './FlowcellBiobox';
import * as styles from '../style.module.css';

export const CODE = 'declarations';
export const TITLE = 'Flow cell declaration';

export default function Declarations({step}) {
    const [checkout, setCheckout] = useCheckoutContext();
    const isActive = checkout.activeStep===step;
    const section = checkout[CODE] ?? {};

    // local state to avoid global re-render on small changes
    const [cache, setCache] = useState({
        ont_fc_returns: null,
        ont_fc_biobox: 'none',
        ont_fc_biobox_qty: 0,
    });

    const disableSubmit = !cache.ont_fc_returns || (cache.ont_fc_biobox !== 'none' && cache.ont_fc_biobox_qty < 1);

    async function submit() {
        saveSection(CODE, checkout, setCheckout, section, {
            canChange: true,
            highlight: 'Completed',
            ont_fc_returns: cache.ont_fc_returns,
            ont_fc_biobox: cache.ont_fc_biobox,
            ont_fc_biobox_qty: cache.ont_fc_biobox_qty,
        });
    }

    return(
        <div className={styles.section}>
            <SectionHeader step={step} code={CODE} title={TITLE} />
            {isActive && <SectionContent settings={{cache, setCache, section}} />}
            {isActive && <SectionSubmit callback={submit} disable={disableSubmit} />}
        </div>
    );
}

function SectionContent({settings}) {
    return(
        <div className={styles.sectionContent}>
            <p>Oxford Nanopore Technologies warrants products purchased by customers in the Oxford Nanopore Store so that: (a) Instruments will perform according to specifications in all material respects (b) the number of single pores per MinION/GridION Flow Cell shall be greater than or equal to 800 nanopores, the number of single pores per PromethION Flow Cell shall be greater than or equal to 5000 nanopores, the number of single pores per Flongle Flow Cell shall be greater than or equal to 50 nanopores determined by the completion of MinKNOWs platform QC test. Flow cell QC needs to be performed before use for MinION/GridION Flow Cells and on receipt for PromethION and Flongle Flow Cells. Oxford Nanopore Technologies have to be notified within 2 business days for warranty claims. Flow Cells need to be used as per the useful life as stipulated on our product page. Further information on warranty and how Oxford Nanopore will remedy product issues are available in our terms and conditions.</p>
            <FlowcellReturns settings={settings} />
        </div>
    );
}
