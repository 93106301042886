import React from 'react';
import { useCustomerContext } from "../../contexts/CustomerContext";
import Banners from './Banners/banners';
import ModalPicker from './ModalPicker';
import Accordions from './Accordions';

export default function Messages() {
    const { customer, setCustomer } = useCustomerContext();

    const messages = customer?.messages ?? [];

    function closeFirstModal(redirect = null) {
        const index = messages.findIndex((message) => message.type==='modal');
        if (index >= 0) {
            messages.splice(index, 1);
            setCustomer({
                ...customer,
                messages: messages
            });
        }
        if (redirect) {
            window.location.href = redirect;
        }
    }

    return (
        <div>
            <ModalPicker messages={messages} closeModal={closeFirstModal} />
            <Banners messages={messages} />
            <Accordions messages={messages} />
        </div>
    );
}
