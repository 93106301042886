import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import MetaForm from './metaForm';
import MetaSave from './metaSave';

export default function MetaPanel({state}) {

    if (state.reveal) return (
        <div>
            <FontAwesomeIcon icon={faChevronDown} />&nbsp;&nbsp;
            <a onClick={() => state.setReveal(!state.reveal)}>Hide meta data</a>
            <MetaForm state={state} />
            <MetaSave state={state} />
        </div>
    );

    return(
        <div>
            <FontAwesomeIcon icon={faChevronRight} />&nbsp;&nbsp;
            <a onClick={() => state.setReveal(!state.reveal)}>Add custom meta data to the order</a>
        </div>
    );
}
