import { gql } from "@apollo/client";

export const catalogFragments = gql`
    fragment categoryContent on CategoryInterface {
        __typename
        name
        url_key
        level
        include_in_menu
        #    id
        ont_category_type
        is_anchor
        position
        description
        ont_image_url_1
        ont_cta_button
        ont_category_phase
        ont_product_text
        ont_product_documentation
        ont_phase_info {
            id
            level
            name
            help
            allow_purchase
        }
        breadcrumbs {
            category_id
            category_level
            category_name
            category_url_key
        }
        products(pageSize: 100) {
            __typename
            items {
                id
                categories {
                    id
                }
                sku
                name
                short_description {
                    html
                }
                description {
                    html
                }
                url_key
                ont_image_url_1
                ont_prep_display
                ont_throughput
                ont_read_len_display
                ont_concentration
                ont_badges
                ont_approach
                ont_sample_type
                ont_multiplexing
                ont_mask_sku
                ont_cta_button
                ont_product_phase
                ont_phase_info {
                    id
                    level
                    name
                    help
                    allow_purchase
                }
                ont_custom_price {
                    currency
                    value
                }
                price_range {
                    minimum_price {
                        final_price {
                            currency
                            value
                        }
                    }
                }
                ont_custom_price {
                    currency
                    value
                }
                ... on ConfigurableProduct {
                    variants {
                      product {
                        sku
                        price_range {
                          minimum_price {
                            final_price {
                              value
                              currency
                            }
                          }
                        }
                        ont_custom_price {
                            currency
                            value
                        }
                      }
                      attributes {
                        code
                        label
                      }
                    }
                }
            }
        }
    }
`;

export const catalogNavigationFragments = gql`
    fragment navigationCategoryData on CategoryInterface {
        id
        name
        url_key
        is_anchor
        position
        level
        ont_category_type
        include_in_menu
    }

    fragment navigationFilterAttributeData on Attribute {
        attribute_code
        attribute_type
        attribute_options {
            label
            value
        }
    }

    fragment navigationProductData on ProductInterface {
        id
        name
        url_key
    }
`;
