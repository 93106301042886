import React from 'react';
import UiButton from '../../../../Ui/UiButton/uiButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faSpinner } from '@fortawesome/pro-light-svg-icons';

export default function ContinueButton({submitter}) {

    const Content = () => {
        return (submitter.isSubmitting)
        ? <FontAwesomeIcon icon={faSpinner} spin />
        : <span>Continue &nbsp;<FontAwesomeIcon icon={faChevronRight} size="sm" /></span>;
    }

    return (
        <UiButton className="button button--secondary" disabled={submitter.isSubmitting} onClick={submitter.submit}>
            <Content />
        </UiButton>
    );
}
