import React from 'react';
import * as styles from './style.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import devices from './data.json';
import UiMarkdown from '../../Ui/UiMarkdown';
import UiButton from '../../Ui/UiButton';
import ContentfulImage from "../../Contentful/ContentfulImage";
import ProductPhase from '../../AtcButton/ProductPhase';

export default function ProductHeader({category}) {
    const data = devices[0][category.name.toLowerCase()]
        ?? {
            documentation: "",
            description: "",
        };

    const documentation = category.ont_product_documentation
        ? JSON.parse(category.ont_product_documentation)
        : data.documentation;

    return(
        <>
            <div grid-states="xxl-col-span-10 xl-col-span-10 s-col-span-10" className={styles.header}>
                <div className={styles.headerName}>{category.name}</div>
            </div>
            <div grid-states="xxl-col-span-2 xl-col-span-2 s-col-span-10 xl-row-start-2 xxl-row-start-2">
                <div className={styles.headerImage}>
                    <ContentfulImage src={category.ont_image_url_1} width={600}/>
                </div>
            </div>
            <div grid-states="xxl-col-span-4 xl-col-span-4 s-col-span-10 xl-row-start-2 xxl-row-start-2">
                <div className={styles.headerDesc}>
                    <UiMarkdown source={category.ont_product_text ?? data.description} skipHtml={true} />
                    <div className={styles.phase}>
                        <HeaderButton category={category} />
                        <ProductPhase product={category} />
                    </div>
                </div>
            </div>
            <div grid-states="xxl-col-span-4 xl-col-span-4 s-col-span-10 s-row-start-6 m-row-start-6 xl-row-start-2 xxl-row-start-2">
                <Documentation documentation={documentation} />
            </div>
        </>
    );
}

const Documentation = ({documentation}) => {
    if (!documentation.length) return null;


    return (
        <div className={styles.headerDocs}>
            <div>PRODUCT DOCUMENTATION</div>
            <ul>{documentation.map((item,i) => <DocumentationItem item={item} key={i} />)}</ul>
        </div>
    );
}

const DocumentationItem = ({item}) => {
    return(
        <li>
        <a href={item.link}>
        <span> <FontAwesomeIcon icon={faFilePdf} />
           {item.name}</span>
           <span>Download</span>
        </a>
        </li>
    );
}

const HeaderButton = ({category}) => {
    if (category.ont_phase_info.allow_purchase) return null;

    const goToLink = () => {
        window.location.href = category.ont_cta_link;
    }

    return(
        <UiButton className="button button--primary button--keyline" onClick={goToLink}>
            <span>{category.ont_cta_button ?? 'Register to pre-order'}</span>
        </UiButton>
    );
}
