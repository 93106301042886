import { useQuery, useMutation, gql } from "@apollo/client";

export function useAddMetaToCart() {
    return useMutation(gql`
        mutation ($cartId: String!, $metaData: CartMetaData!) {
            addMetaToCart(
                input: {
                    cart_id: $cartId
                    cart_meta: $metaData
                }
            ) {
                message_type
                message_text
            }
        }
    `);
}

export function useGetCartMeta(id) {
    return useQuery(gql`
        query ($cartId: String!) {
            getCartMeta(cartId: $cartId) {
                ont_origin_code
                ont_inco_terms
                ont_sales_tag
                override_increment_id
                ont_admin_send_email
            }
        }
    `, {
        variables: { cartId: id },
        fetchPolicy: 'network-only',
    });
}
