import { useEffect } from 'react';
import { useWebStoreContext } from "../../../contexts/WebStoreContext";
import { useCustomerContext } from "../../../contexts/CustomerContext";
import { useCartContext } from '../../../contexts/CartContext';
import { getSessionExpiry } from '../helpers';
import useCartLoader from './useCartLoader';

export default function useLogin(redirectUrl, setCartError, newToken) {
    const { webstore, setWebstore } = useWebStoreContext();
    const { customer, setCustomer } = useCustomerContext();
    const loadCart = useCartLoader(redirectUrl, setCartError);

    useEffect(() => {
        if (newToken && customer.token && newToken === customer.token) loadCart();
    });

    return (customerToken, additionalData) => {
        console.log('Initialising customer and store from new token');
        initCustomerContext(setCustomer, customerToken, additionalData);
        initWebstore(setWebstore, webstore, customerToken);
    }
}

export function resetCredentials(triggerReset = true) {
    const { customer, resetCustomer } = useCustomerContext();
    const { setWebstore } = useWebStoreContext();
    const { resetCart } = useCartContext();

    useEffect(() => {
        if (triggerReset && customer.token) {
            console.log('Forcing customer, cart and store reset');
            resetCustomer();
            resetCart();
            setWebstore({});
        }
    }, [triggerReset]);
}

function initCustomerContext(setCustomer, customerToken, additionalData) {
    setCustomer({
        token: customerToken.token,
        has_extra_permission: customerToken.has_extra_permission,
	    has_custom_pricing: customerToken.has_custom_pricing,
        email: customerToken.email,
        intended_store_view: customerToken.intended_store_view,
        expiry: getSessionExpiry(customerToken.session_duration),
        messages: customerToken.messages,
        ...additionalData
    });
}

function initWebstore(setWebstore, webstore, customerToken) {
    setWebstore({
        ...webstore,
        designatedStore: customerToken.intended_store_view
    });
}

