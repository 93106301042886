import React from 'react';
import { getCwsUrl } from '../helpers';
import { topLink } from './style.module.css';

export default function Link({menuData}) {
    const linkUrl = menuData.link.url;
    const useUrl = linkUrl.startsWith('/') ? getCwsUrl() + linkUrl : linkUrl;

    return (
        <span>
            <a className={topLink} href={useUrl}>{menuData.label}</a>
        </span>
    );
}
