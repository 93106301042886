import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import OrderRouter from './OrderRouter';
import * as styles from './style.module.css';

export default function QuickOrder() {
    return (
        <div className={styles.quickOrder}>
            <IntroText />
            <OrderRouter />
        </div>
    );
}

function IntroText() {
    return (
        <>
            <h2>Quick Order</h2>
            <p className={styles.download}>
                <FontAwesomeIcon icon={faDownload} />&nbsp;&nbsp;
                <a className="" href="/quick_order_template.xlsx">Click here to download a blank order template</a>
            </p>
            <p>
                This facility is for pre-approved accounts only. Usage implies acceptance of our <a target="_blank" href="https://nanoporetech.com/commercial_information">Terms and Conditions</a>, including the Nanopore Product terms and conditions, Metrichor Terms of Use, and MinKNOW/Metrichor Agent EULA.
            </p>
        </>
    );
}
