import React from 'react';

const UiRadio = props => {
    return(
        <>
            <label className="radio">
                {props.children}
                <input id={props.name + '_' + props.value}
                       type="radio"
                       name={props.name}
                       value={props.value}
                       defaultChecked={props.defaultChecked}
                       checked={props.checked}
                       className={props.className}
                       onChange={props.onChange}
                       onClick={props.onClick}
                />
                <span className="outer" />
            </label>
            <style jsx>{`
                .radio {
                  display: block;
                  position: relative;
                  padding-left: 35px;
                  margin-bottom: 0.75rem;
                  cursor: pointer;
                  font-size: 1rem;
                  color: #455556;
                  -webkit-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                  font-weight: lighter;
                }

                .radio input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  height: 0;
                  width: 0;
                }

                .radio .outer {
                  position: absolute;
                  top: 1px;
                  left: 0;
                  height: 20px;
                  width: 20px;
                  border: solid 1px #363636;
                  background-color: transparent;
                  border-radius: 50%;
                }

                .radio:hover input ~ .outer {
                  background-color: #eee;
                }

                .radio input:checked ~ .outer {
                  background-color: transparent;
                }

                .radio .outer:after {
                  content: "";
                  position: absolute;
                  display: none;
                }

                .radio input:checked ~ .outer:after {
                  display: block;
                }

                .radio .outer:after {
                  top: 4px;
                  left: 4px;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: #007ca7;
                }
            `}</style>
        </>
    );
};

export default UiRadio;
