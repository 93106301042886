import React from 'react';
import { NanoGrid, NanoIcon } from "@nanoporetech-digital/components-react";
import Section from '../Section';
import SectionFeatured from '../Section/featured';
import SectionMobile from '../Section/mobile';
import * as styles from "./style.module.css";

export default function Dropdown({menuData, config = {}}) {
    const sections = menuData.items;
    const { cssColumnCount = 1 } = config;
    const featuredSection = sections.find(section => section.featured===true);

    return (
        <div slot="secondary" className={styles['mdd' + menuData.title]}>
            <div className={styles.content}>
                <div className={styles.megadd}>
                    <div className={styles.wrapper}>
                        <NanoGrid className={styles.grid} s-cols="1" l-cols={cssColumnCount}>
                            {sections.map((section, i) => <Section item={section} config={config} key={i} />)}
                        </NanoGrid>
                    </div>
                    <SectionFeatured section={featuredSection} />
                </div>
            </div>
            <div className={styles.burgerMenu + ' ' + styles.burgerContent}>
                <button
                    className={styles.backBtn}
                    onClick={e => {e.target.closest('nano-nav-item').open = false}}
                >
                    <NanoIcon name="solid/chevron-left" slot="icon-end" />&nbsp;{menuData.title}
                </button>
                {sections.map((section, i) => <SectionMobile section={section} key={i} />)}
            </div>
        </div>
    );
}

