import React from 'react';
import AtcButton from '../../../AtcButton/atcButton';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function DynamicAtcButton({choices, products}) {
    const atcProducts = getAtcProducts(choices, products);

    return (
        <AtcButton
            product={atcProducts.mainProduct ?? products[0]}
            qty={atcProducts.mainQty}
            isDisabled={!atcProducts.mainProduct}
            additionalProducts={atcProducts.additionalProducts}
            goToQuote={true}
        >
            <span>Get a quote &nbsp;<FontAwesomeIcon icon={faFilePdf} /></span>
        </AtcButton>
    );
}

function getAtcProducts(choices, products) {
    const skus = Object.keys(choices);

    let mainProduct = null;
    let mainQty = 0;
    let additionalProducts = [];

    skus.forEach(sku => {
        if (choices[sku] > 0) {
            const product = products.find(product => product.sku===sku);
            if (product) {
                if (mainProduct) {
                    additionalProducts.push({product: product, qty: choices[sku], options: []})
                } else {
                    mainProduct = product;
                    mainQty = choices[sku];
                }
            }
        }
    });

    return {mainProduct: mainProduct, mainQty: mainQty, additionalProducts: additionalProducts};
}
