import React, { useState } from 'react';
import DynamicAtcButton from './dynamicAtcButton';
import Configurator from './Configurator';
import * as styles from './style.module.css';

export default function LoggedIn({category, products, ownedDevicesAndCertifications}) {
    const [choices, setChoices] = useState({});
    return (
        <div>
            <Configurator certSummary={ownedDevicesAndCertifications} products={products} choices={choices} setChoices={setChoices} />
            <Status choices={choices} products={products} />
            <Buttons choices={choices} products={products} />
        </div>
    );
}

function Status({choices, products}) {
    return null;
}

function Buttons(props) {
    return (
        <div className={styles.buttons}>
            <DynamicAtcButton {...props} />
        </div>
    );
}
