import {shouldShowPrice} from "../../../../hooks/siteData";
import * as styles from "../style.module.css";
import UiPublicOrCustomPrice from "../../../Ui/UiPublicOrCustomPrice";
import React from "react";

export const ProductPrice = ({product, prefix}) => {
    if (!product || !shouldShowPrice(product.sku)) {
        return null;
    }

    return (
        <div className={styles.price}>
            {prefix && <span>{prefix}</span>}
            <strong><UiPublicOrCustomPrice price={product.price_range.minimum_price.final_price} customPrice={product.ont_custom_price} /></strong>
        </div>
    );
}
