import {isProduction} from "../../../../hooks/useEnvironment";
import React from "react";

export function MyAccountForm({orderNumber, cartData, formRef}) {
    const url = isProduction()
        ? "https://myaccount.nanoporetech.com/payments"
        : "https://myaccount-test.nanoporetech.com/payments";

    return <div>
        <form ref={formRef} action={url} method={"POST"} >
            <input type={"hidden"} name={"order_payment[reference]"} value={orderNumber}/>
            <input type={"hidden"} name={"order_payment[amount]"} value={cartData.prices.grand_total.value}/>
            <input type={"hidden"} name={"order_payment[currency]"} value={cartData.prices.subtotal_excluding_tax.currency}/>
        </form>
    </div>
}
