import React from 'react';
import { useCheckoutContext } from '../../../../contexts/CheckoutContext';
import SectionHeader from '../sectionHeader';
import SectionSubmit from '../sectionSubmit';
import { NanoCheckbox } from '@nanoporetech-digital/components-react';
import { useSetPaymentMethod } from '../../../../hooks/useCart';
import {isOrderPermitted, saveSection} from '../helpers';
import * as styles from '../style.module.css';
import CheckoutPrevented from "../../CheckoutPrevented";

export const CODE = 'payment_method';
export const TITLE = 'Payment method';

export default function PaymentMethod({step, cartData}) {
    const [checkout, setCheckout] = useCheckoutContext();
    const isActive = checkout.activeStep===step;
    const [setPaymentMethodMutation] = useSetPaymentMethod();
    const section = checkout[CODE] ?? {
        selected_payment_method: cartData.selected_payment_method?.code
    };

    const disableSubmit = section.selected_payment_method ? false : true;

    async function submit() {
        if (!checkout.redeemQuote) {
            await setPaymentMethodMutation(
                {variables:{cartId: cartData.id, method: section.selected_payment_method, poNumber: "ONT"}});
        }
        saveSection(CODE, checkout, setCheckout, section, {
            canChange: true,
            highlight: cartData.available_payment_methods.find(method => method.code===section.selected_payment_method).title,
            selected_payment_method: section.selected_payment_method,
        });
    }

    return(
        <div className={styles.section}>
            <SectionHeader step={step} code={CODE} title={TITLE} />
            {isActive && <SectionContent cartData={cartData} section={section} />}
            {isActive && <SectionSubmit callback={submit} disable={disableSubmit} />}
        </div>
    );
}

function SectionContent({cartData, section}) {
    if (!isOrderPermitted(cartData)) {
        return (
            <CheckoutPrevented />
        );
    }
    return (
        <div className={styles.sectionContent}>
            <h3>Please choose a payment method:</h3>
            <PaymentMethodsList methods={cartData.available_payment_methods} section={section}/>
        </div>
    );
}

function PaymentMethodsList({methods, section}) {
    const [checkout, setCheckout] = useCheckoutContext();
    const currentValue = section.selected_payment_method;

    function update(newValue) {
        // bug in onNanoChange? gets called twice once with old value, once with new
        if (newValue !== currentValue) {
            setCheckout({
                ...checkout,
                [CODE]: {
                    selected_payment_method: newValue
                }
            });
        }
    }

    function isFreeOrder() {
        for (const method of methods) {
            if (['free', 'ont_zero_value'].includes(method.code)) return true;
        }
        return false;
    }

    return (
        <>
            <ul className={styles.ul}>
                {methods.map(method =>
                    <PaymentMethodItem
                        key={method.code}
                        method={method}
                        currentValue={currentValue}
                        update={update}
                        isFreeOrder={isFreeOrder()}
                    />
                )}
            </ul>
            <PurchaseOrderNotice methodCode={currentValue} />
        </>
    );
}

function PaymentMethodItem({method, currentValue, update, isFreeOrder}) {
    if (isFreeOrder && !['free', 'ont_zero_value'].includes(method.code)) return null;

    return (
        <li>
            <NanoCheckbox
                key={method.code}
                type="radio"
                label={method.title}
                name="payment_method"
                value={method.code}
                checked={method.code===currentValue}
                onNanoChange={(e) => update(e.target.value)}
            />
        </li>
    );
}

function PurchaseOrderNotice({methodCode}) {
    if (methodCode === 'purchaseorder') {
        return (
            <div className="info-banner info-banner--caution">
                <div className="info-banner__content">
                    <p className="info-banner__text">When your payment method is a purchase order, the billing address on your purchase order must match the billing address entered here at checkout.</p>
                    <p className="info-banner__text"><strong>Any discrepancies may result in a delay to processing your order.</strong></p>
                </div>
            </div>
        );
    }

    return null;
}
