import React, { useState } from 'react';
import numeral from 'numeral';

export const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor:'#99a3a6',
      borderRadius: 3
    }),
    menu: (provided, state) => ({
      ...provided,
      height: 'auto',
    }),
    menuList: (provided, state) => ({
        ...provided,
        maxHeight: 'inherit',
        height: 'auto',
    }),
    option: (provided, state) => {
    return {
        ...provided,
        borderBottom: '1px solid #cccccc',
        padding: '10px',
        // backgroundColor: 'transparent',
        color: 'rgb(74, 74, 74)',
        // backgroundColor: state.isSelected ? '#2684ff' : 'transparent'
    };
    },
    singleValue: (provided, state) => ({
        ...provided,
        width: '100%',
        paddingRight: '.5rem'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '100%',
    }),
    indicatorSeparator: (defaultStyles) => ({...defaultStyles, marginTop: 0, marginBottom: 0, backgroundColor: '#99a3a6'}),
    dropdownIndicator: (defaultStyles) => ({...defaultStyles, padding: '7px 11px 6px', color: '#99a3a6'}),
};


