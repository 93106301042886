import React from 'react';
import UiButton from '../../Ui/UiButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import * as styles from './style.module.css';

export default function SubmitButton({children, minWidth = "160px", type = "submit", isDisabled = false, isSubmitting = false}) {

    return (
        <UiButton
            type={type}
            className="button button--secondary"
            disabled={isDisabled || isSubmitting}
        >
            <span className={styles.contentWrapper} style={{minWidth: minWidth}}>
                {isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin /> : children}
            </span>
        </UiButton>
    );
}
