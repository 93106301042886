import { getDay } from 'date-fns';

export const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// converts JS Date to a string in the format yyyy-mm-dd
export function getIsoDateString(date) {
    var d = date.getDate();
    if (d < 10) d = '0' + d.toString();

    var m = date.getMonth() + 1;
    if (m < 10) m = '0' + m.toString();

    return date.getFullYear() + '-' + m + '-' + d;
}

// converts a string or date to a JS Date
export function getUtcDate(date)
{
    if (typeof date === 'string') { // expected: a GMT date
        date = new Date(date);
    } else {                        // expected: a date object in the locale
        date = new Date( Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) );
    }

    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
}

// converts JS date to a presentable date like 1 Feb 2021
export function getUiDate(jsDate) {
    var d = jsDate.getDate();
    var m = MONTHS[jsDate.getMonth()];
    var y = jsDate.getFullYear().toString();

    return d + ' ' + m + ' ' + y;
}

// generates an array of shipment items based on the rules for a schedule row
export function getNewShipmentItems(schedule, scheduleRow) {
    const shipmentItems = [];
    const addShipmentItem = (date, qty) => shipmentItems.push({date, qty});
    const fad = scheduleRow.fad;

    if (scheduleRow.split_suggested > 1 && scheduleRow.split_max != 1) {
        const useDate = getUtcDate(fad);
        const numShipments = scheduleRow.split_suggested
        const totalQty = scheduleRow.qty;
        const perShipment = Math.floor(totalQty / numShipments);
        const remainder = totalQty % numShipments;

        addShipmentItem(fad, perShipment);
        for(let i = 1; i < numShipments; i++) {
            addDaysToDate(useDate, scheduleRow.split_suggested_gap);
            findNextShippingDate(schedule, useDate);
            const isLastShipment = i == (numShipments - 1);
            const shipmentQty = perShipment + (isLastShipment ? remainder : 0);
            addShipmentItem(getIsoDateString(useDate), shipmentQty);
        }
    } else {
        addShipmentItem(fad, scheduleRow.qty);
    }

    return shipmentItems;
}

export function addDaysToDate(date, days) {
    date.setDate(date.getDate() + days);
}

// a list of all the unique dates in a shipmentItems array
export function getShipmentDates(shipmentItems) {
    const dates = shipmentItems.map(si => si.date);
    return [...new Set(dates)].sort();
}

// applies callback to shipment items of each scheduler row and sub row
// note that schedule is passed by reference and thus modified
export function modifySchedule(schedule, callback) {
    const shipmentItemRegister = [];

    schedule.schedule_rows.forEach(row => {
        if (row.is_shippable) {
            row.shipment_items = callback(row);
            shipmentItemRegister.push(...row.shipment_items);
        }

        if (row.sub_rows?.length) {
            row.sub_rows.forEach(sub => {
                sub.shipment_items = callback(sub);
                shipmentItemRegister.push(...sub.shipment_items);
            });
        }
    });

    schedule.shipments = getShipmentDates(shipmentItemRegister);
}

// based on list of all shipments creates blank shipment items in each row where needed
export function addBlankShipmentItems(schedule) {
    modifySchedule(schedule, function(row) {
        schedule.shipments.forEach(shipment => {
            if (row.shipment_items.find(item => item.date===shipment) === undefined) {
                row.shipment_items.push({date: shipment, qty: 0});
            }
        });
        return row.shipment_items;
    });
}

// the JSON to send to Magento
export function getScheduleSaveData(schedule) {
    const allRows = [];
    schedule.schedule_rows.forEach(row => {
        if (row.is_shippable) {
            allRows.push({
                row_id: row.row_id,
                item_id: row.item_id,
                shipment_items: row.shipment_items
            });
        }
        if (row.sub_rows?.length) {
            row.sub_rows.forEach(sub => {
                allRows.push({
                    row_id: sub.row_id,
                    item_id: sub.item_id,
                    shipment_items: sub.shipment_items
                });
            });
        }
    });
    return allRows;
}

export function buildSchedule(schedule) {
    // we need to ensure schedule contains shipments and rows contain shipment_items
    // because even if we pass by reference we cannot add new properties later
    const newRows = schedule.schedule_rows.map(row => {
        if (row.sub_rows?.length) {
            const subRows = row.sub_rows.map(sub => {return {...sub, shipment_items: []}});
            return {...row, sub_rows: subRows};
        }
        return {...row};
    });

    const newSchedule = {...schedule, schedule_rows: newRows, shipments: []};

    // use shipping rules to generate critical shipments
    modifySchedule(newSchedule, function(row) {
        return getNewShipmentItems(newSchedule, row);
    });

    // fill in the blanks in each row
    addBlankShipmentItems(newSchedule);

    return newSchedule;
}

export function getHolidayDates(schedule) {
    return schedule.holidays.map(holiday => getUtcDate(holiday));
}

export function getOriginalDates(openDates) {
    return Object.keys(openDates).map(dateString => {return getUtcDate(dateString)})
}

export function getExcludeDates(schedule, openDates) {
    const holidayDates = getHolidayDates(schedule);
    const originalDates = getOriginalDates(openDates);

    return holidayDates.filter(hd => {
        return !originalDates.map(od => {return od.getTime()}).includes(hd.getTime())
    })
}

export function isShippingDay(schedule, date) {
    const index = getDay(date);
    const day = DAYS[index];
    return schedule.shipping_days.includes(day);
}

export function isHoliday(holidays, date) {
    return (holidays.find(h => h.getTime()===date.getTime()) ? true : false);
}

export function findNextShippingDate(schedule, nextDate) {
    const holidays = getHolidayDates(schedule);
    while (!isShippingDay(schedule, nextDate) || isHoliday(holidays, nextDate)) {
        addDaysToDate(nextDate, 1);
    }
}

// if an array of shipment items contains duplicate shipment dates, merges them
export function dedupeShipmentItems(shipmentItems)
{
    const deduped = [];

    shipmentItems.forEach(si => {
        const existing = deduped.find(d => d.date===si.date);
        if (existing) {
            existing.qty += si.qty;
        } else {
            deduped.push({...si});
        }
    });

    return deduped;
}

export function findEmptyShipments(schedule) {
    const emptyShipments = [];
    const doesRowContainShipment = (row, shipment) => {
        let rowContainsShipment = false;
        row.shipment_items.forEach(si => { if (si.date===shipment && si.qty > 0) rowContainsShipment = true; });
        return rowContainsShipment;
    };

    schedule.shipments.forEach(shipment => {
        let shipmentHasItems = false;
        schedule.schedule_rows.forEach(row => {
            if (row.is_shippable && doesRowContainShipment(row, shipment)) shipmentHasItems = true;
            if (!shipmentHasItems && row.sub_rows?.length) row.sub_rows.forEach(subRow => {
                if (doesRowContainShipment(subRow, shipment)) shipmentHasItems = true;
            });
        });
        if (!shipmentHasItems) emptyShipments.push(shipment);
    });

    return emptyShipments;
}

// deletes a shipment by deleting relevant shipment items - the shipments array will update itself
export function deleteShipment(schedule, shipment) {
    modifySchedule(schedule, function(row) {
        const found = row.shipment_items.findIndex(si => si.date===shipment);
        if (found >= 0) row.shipment_items.splice(found, 1);
        return row.shipment_items;
    });
}

export function deleteEmptyShipments(schedule) {
    const emptyShipments = findEmptyShipments(schedule);
    emptyShipments.forEach(shipment => deleteShipment(schedule, shipment));
}

// look for the first shipment date within a bundle - may be null
export function getFirstShipmentDateInBundle(parentRow) {
    let firstDate = getFirstShipmentItemDate(parentRow.shipment_items); // may be null

    for (const sub of parentRow.sub_rows) {
        const firstChildDate = getFirstShipmentItemDate(sub.shipment_items);
        if (firstChildDate && (!firstDate || firstChildDate < firstDate)) {
            firstDate = firstChildDate;
        }
    }

    return firstDate;
}

// gets the first (earliest, non-zero) shipment item from an array of shipment items - may be null
export function getFirstShipmentItemDate(shipmentItems) {
    if (!shipmentItems) {
        return null;
    }

    return shipmentItems.reduce((firstDate, shipmentItem) => {
        const itemDate = getUtcDate(shipmentItem.date);
        return (shipmentItem.qty > 0 && (!firstDate || itemDate < firstDate))
            ? itemDate
            : firstDate;
    }, null);
}
