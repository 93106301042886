import React from 'react';
import { NanoInput } from '@nanoporetech-digital/components-react';
import CountrySelect from './CountrySelect'
import RegionInput from './RegionInput';
import useCountries from '../../hooks/useCountries';

export default function ShippingForm({formData, setFormData}) {
    const countries = useCountries();

    function updateFormData(e) {
        e.target.showError('');
        if (e.target.name==='shipping_country_code') {
            setFormData({...formData, country_code: e.target.value, region_id: 0, region: '', postcode: ''});
        } else if (e.target.name==='shipping_region_id') {
            const regionText = e.target.options.find(o => o.value===e.target.value)?.label;
            setFormData({...formData, region_id: e.target.value, region: regionText});
        } else if (e.target.name==='shipping_region') {
            setFormData({...formData, region_id: 0, region: e.target.value});
        } else {
            const shortName = e.target.name.replace('shipping_', '');
            setFormData({...formData, [shortName]: e.target.value});
        }
    }

    return(
        <>
            <h3>Enter your shipping address</h3>
            <CountrySelect type="shipping" countries={countries} formData={formData} updateFormData={updateFormData} prefix="shipping_" />
            <NanoInput maxlength="25" label="First name*" required="true" validateOn="submitThenDirty" showInlineError={true} name="shipping_firstname" value={formData.firstname} onNanoChange={e => updateFormData(e)} type="text" inputmode="text" />
            <NanoInput maxlength="25" label="Last name*" required="true" validateOn="submitThenDirty" showInlineError={true} name="shipping_lastname" value={formData.lastname} onNanoChange={e => updateFormData(e)} type="text" inputmode="text" />
            <NanoInput maxlength="40" label="Telephone*" required="true" validateOn="submitThenDirty" showInlineError={true} name="shipping_telephone" value={formData.telephone} onNanoChange={e => updateFormData(e)} type="tel" inputmode="tel" />
            <NanoInput maxlength="40" label="Organisation*" required="true" validateOn="submitThenDirty" showInlineError={true} name="shipping_company" value={formData.company} onNanoChange={e => updateFormData(e)} type="text" inputmode="text" />
            <NanoInput maxlength="80" label="Address*" required="true" validateOn="submitThenDirty" showInlineError={true} name="shipping_street" value={formData.street} onNanoChange={e => updateFormData(e)} type="text" inputmode="text" />
            <NanoInput maxlength="40" label="City*" required="true" validateOn="submitThenDirty" showInlineError={true} name="shipping_city" value={formData.city} onNanoChange={e => updateFormData(e)} type="text" inputmode="text" />
            <RegionInput countries={countries} formData={formData} updateFormData={updateFormData} prefix="shipping_" />
            <NanoInput maxlength="40" label="Zip/postcode*" required="true" validateOn="submitThenDirty" showInlineError={true} name="shipping_postcode" value={formData.postcode} onNanoChange={e => updateFormData(e)} type="text" inputmode="text" />
        </>
    );
}
