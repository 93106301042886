import React, {useMemo} from 'react';
import AtcButton from '../../../AtcButton';
import {title, image, confirm, group, right, ctaBottom, included} from './style.module.css';
import Price from "../../../Ui/Price";
import { getBundleDefaultOptions } from "../../../../hooks/useCart";
import { NanoGrid } from '@nanoporetech-digital/components-react';

export default function Confirm({product, sections}) {
    const {
        included,
        extras,
        options,
        additionalProducts,
        surcharge
    } = useMemo(() => getAllSectionsMetaData(product, sections), [product, sections]);

    const {currency} = product.price.regularPrice.amount;

    return(
        <NanoGrid l-cols="10" m-cols="10" s-cols="10" xl-cols="10" xxl-cols="10" className={confirm}>
            <div grid-states="xl-col-span-4 l-col-span-4 m-col-span-10 l-row-start-1 xl-row-start-1 l-col-start-1 xl-col-start-1 m-row-start-2 m-col-start-1 s-col-span-10">
                <h1 className={title}>{product.name} <span>{product.ont_mask_sku}</span> </h1>
                <div className={image}><img src={product.ont_image_url_1} /></div>
            </div>

            <div grid-states="xl-col-span-8 l-col-span-8 s-col-span-10 m-col-span-10 m-row-start-3 s-col-span-10 m-col-start-1 m-col-span-10 xl-row-start-1 xl-col-start-5 l-row-start-1 l-col-start-5">
                <div className={group}>
                    <h2>Package details</h2>
                </div>
                <BundleContents bundle={product} />
                <Items items={included} type="included" currency={currency} />
                <Items items={extras} type="extras" currency={currency} />
                <div className={group}>
                    <div>
                        <h2>Total</h2>
                        <h2 className={right}>
                            <TotalPrice product={product} surcharge={surcharge} />
                        </h2>
                    </div>
                </div>
                <div className={ctaBottom}>
                    <AtcButton product={product} qty={1} options={options} additionalProducts={additionalProducts} goToCart={true} mobile="mobile"/>
                </div>
            </div>
        </NanoGrid>
    );
}

function Items({items, type, currency}) {
    const Item = ({item}) => {
        const qty = item.custom_qty
            ? <strong>{item.custom_qty}</strong>
            : <span>{item.qty}x</span>;

        return(
            <div>
                <div>{qty} {item.name}</div>
                <div className={right}><ItemValue item={item} currency={currency} /></div>
            </div>
        );
    };

    if (items.length) {
        return(
            <div className={group}>
                <h3>{type==='included' ? 'Your choices:' : 'Additional items:'}</h3>
                {items.map((item,i) => <Item key={i} item={item} />)}
            </div>
        );
    }

    return null;
}

function ItemValue({item, currency}) {
    if (item.surcharge > 0) {
        return(
            <Price currencyCode={currency} value={item.surcharge} />
        );
    }

    return <span className={included}>Included</span>;
}

export function getAllSectionsMetaData(mainProduct, sections) {
    const included = [];
    const extras = [];
    const options = getBundleDefaultOptions(mainProduct);
    const additionalProducts = [];

    let surcharge = 0.0;

    sections.forEach(section => {
        section.items.forEach(item => {
            if (item.surcharge > 0) {
                extras.push(item);
                surcharge += item.surcharge;
            } else {
                included.push(item);
            }
        });

        section.additionalItems?.forEach(item => {
            included.push(item);
            if (item.surcharge > 0) {
                surcharge += item.surcharge;
            }
        });

        section.selections.forEach(selection => {
            const duplicate = options.findIndex(o => o.id==selection.id);
            if (duplicate >= 0) options.splice(duplicate, 1);
            options.push(selection);
        });

        const additionalKeys = Object.keys(section.additional);
        additionalKeys.forEach(key => additionalProducts.push(section.additional[key]));
    });

    return {included, extras, options, additionalProducts, surcharge};
}

export function TotalPrice({product, surcharge = 0}) {
    const {currency, value: price} = product.ont_custom_price ?? product.price.regularPrice.amount;

    return <Price currencyCode={currency} value={price + surcharge} />;
}

export function BundleContents({bundle}) {
    let contents = [];

    bundle.items.forEach(option => {
        if (option.title==='hidden') {
            const selections = option.options.map(selection => {
                if (selection.product.sku==='CARRIAGE') return null;
                return `${selection.quantity}x ${selection.product.name}`;
            });
            contents = contents.concat(selections.filter(ele => ele));  // filter out null elements first
        }
    });

    return (
        <div className={group}>
            <h3>Included as standard:</h3>
            <p>{contents.join(', ')}</p>
        </div>
    );
}
