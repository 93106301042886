import React from 'react';
import Product from './product';
import { getUtcDate, getUiDate } from '../../../../ShippingScheduler/helpers';
import { isExpiryWithinOneMonth } from '../../helpers';

export default function DeviceCert({certSummary, products, choices, setChoices}) {
    const devices = certSummary?.devices;
    if (!devices) return null;

    const labCertification = certSummary.lab_certification;

    return devices.map(device => <Device key={device.barcode} device={device} products={products} labCertification={labCertification} choices={choices} setChoices={setChoices} />);
}

function Device({device, products, labCertification, choices, setChoices, barcode}) {
    const sku = device.device_certification?.sku;
    if (!sku) return null;

    const product = products.find(product => product.sku===sku);
    if (!product) return null;

    const deviceCertification = device.device_certification;
    const fullPrice = product.price_range.minimum_price.final_price;

    return (
        <Product 
            product={product}
            name={deviceCertification.name + ' ' + device.barcode}
            status={getStatus(labCertification, deviceCertification, fullPrice)}
            quarters={getProRataQuarters(labCertification, deviceCertification)}
            mode={isPurchaseAllowed(deviceCertification) ? 'dev' : 'info'}
            choices={choices}
            setChoices={setChoices}
            barcode={device.barcode}
        />
    );
}

function getStatus(labCertification, deviceCertification, fullPrice) {
    switch (deviceCertification.status) {
        case 'active':
            return getActiveStatus(labCertification, deviceCertification, fullPrice);
        case 'unknown':
            return 'Certification in progress';
    }

    return getNewPurchaseStatus(labCertification, deviceCertification, fullPrice);
}

function getActiveStatus(labCertification, deviceCertification, fullPrice) {
    const labCertExpiry = labCertification.expiry ? getUtcDate(labCertification.expiry) : null;
    const devCertExpiry = getUtcDate(deviceCertification.expiry);   // if status is active then this must exist
    const formatter = Intl.NumberFormat('en-US', {style: "currency", currency: fullPrice.currency});
    const price = getPrice(labCertification, deviceCertification, fullPrice);

    let status = 'Certified until ' + getUiDate(devCertExpiry);
    if (isPurchaseAllowed(deviceCertification)) {
        if (price < fullPrice.value) {
            status += '. Extend to ' + getUiDate(labCertExpiry) + ' for ' + formatter.format(price);
        } else {
            status += '. Purchase another year for ' + formatter.format(fullPrice.value);
        }
    }

    return status;
}

function getNewPurchaseStatus(labCertification, deviceCertification, fullPrice) {
    const labCertExpiry = labCertification.expiry ? getUtcDate(labCertification.expiry) : null;
    const formatter = Intl.NumberFormat('en-US', {style: "currency", currency: fullPrice.currency});
    const price = getPrice(labCertification, deviceCertification, fullPrice);

    if (price < fullPrice.value) {
        return 'Certify until ' + getUiDate(labCertExpiry) + ' for ' + formatter.format(price);
    }

    return 'Purchase 1-year certification for ' + formatter.format(fullPrice.value);
}

function isPurchaseAllowed(deviceCertification) {
    if (!deviceCertification.status || deviceCertification.status=='expired') {
        return true;
    }

    if (deviceCertification.status=='active') {
        const devCertExpiry = getUtcDate(deviceCertification.expiry);
        return isExpiryWithinOneMonth(devCertExpiry);
    }

    return false;
}

function getProRataQuarters(labCertification, deviceCertification) {
    if (isPurchaseAllowed(deviceCertification)) {
        const labCertExpiry = labCertification.expiry ? getUtcDate(labCertification.expiry) : null;
        const today = new Date();
        return (labCertExpiry && !isExpiryWithinOneMonth(labCertExpiry) && today.getTime() < labCertExpiry.getTime())
            ? Math.ceil((labCertExpiry.getTime() - today.getTime()) / (1000 * 60 * 60 * 24 * 91))
            : 4;
    }

    return 0;
}

function getPrice(labCertification, deviceCertification, fullPrice) {
    const quarters = getProRataQuarters(labCertification, deviceCertification);
    return (quarters > 0 && quarters < 4) ? (fullPrice.value * quarters / 4) : fullPrice.value;
}
