import React from 'react';

// old components
import SimpleProduct from '../SimpleProduct';
import ConfigurableFlowcellProduct from '../ConfigurableFlowcellProduct';
import BundleFamilyProduct from '../BundleFamilyProduct';

// newer components
import Workshop from './Virtual/Workshop';
import Configurator from './Configurator';
import {isBundle, isConfigurable, isGrouped, isSimple, isVirtual} from "../../utils/product";

export default function Product({product, urlKey, siblingProducts}) {
    const renderType = product.ont_render_type;

    if (renderType === 'BUNDLEFAMILY') {
        return <BundleFamilyProduct
                    urlKey={urlKey}
                    product={product}
                    siblingProducts={siblingProducts}
                />;
    }

    if (isSimple(product)) {
        if (renderType === 'CERTIFICATION') {
            return null;
        }
    }

    if (isConfigurable(product)) {
        if (renderType === 'FLOWCELL' || product.sku === 'VCT-V2002D') {
            return (
                <ConfigurableFlowcellProduct
                    urlKey={urlKey}
                    product={product}
                    siblingProducts={siblingProducts}
                />
            );
        }
    }

    if (isBundle(product)) {
        if (renderType === 'CONFIGURATOR') {
            return <Configurator product={product}/>;
        }
    }

    if (isVirtual(product)) {
        if (renderType === 'WORKSHOP') {
            return <Workshop product={product}/>;
        }
    }

    return <SimpleProduct product={product}/>;;
}
