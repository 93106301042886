import React from "react";
import { navigate } from "gatsby";
import LiveCatalog from "../components/Live/LiveCatalog";

export default function LiveCatalogPage(props) {

    if (typeof window==='undefined') return null;

    const urlParams = new URLSearchParams(props.location.search);
    const id = urlParams.get('id') ?? null;

    if (!id) navigate('/404');

    return (
        <LiveCatalog categoryId={id} />
    );
}
