import React, { useState } from 'react';
import {Helmet} from "react-helmet";
import { useCustomerContext } from '../../contexts/CustomerContext';
import { navigate } from 'gatsby';
import TopActions from '../TopActions';
import CatalogBanner from './CatalogBanner';
import CatalogGrid from './CatalogGrid';
import * as styles from "./style.module.css";
import {isLoginRequired} from "../../hooks/siteData";
import {getExtraContent} from "./extraContent";

export default function Catalog({
    category,
    categories,
    filterAttributes,
    liveMode,
    isHome = false,
}) {
    const [filters, setFilters] = useState(getPreFilter(filterAttributes));
    const [textFilter, setTextFilter] = useState('');
    const { customer } = useCustomerContext();

    if (isLoginRequired() && !customer.email) {
        // Prevent display of half-rendered page before redirect
        return null;
    }

    if (customer.has_extra_permission || customer.has_custom_pricing) {
	    if (!liveMode && category.id && !isHome) navigate(`/catalog/?id=${category.id}`);
    }

    const extraContent = isHome ? getExtraContent() : null;

    return (
        <>
            <Helmet>
                <title>Nanopore store: {category.name}</title>
            </Helmet>
            <TopActions grid-states="l-col-start-2" isLight={true} isProductPage={false} category={category}/>
            <div className={styles.catalog}>
                <CatalogBanner category={category} cmsBlocks={extraContent?.cmsBlocks} />
                <CatalogGrid
                    activeCategory={category ?? 0}
                    categories={categories}
                    textFilter={textFilter}
                    setTextFilter={setTextFilter}
                    filterAttributes={filterAttributes}
                    filters={filters}
                    setFilters={setFilters}
                    cmsBlocks={extraContent?.cmsBlocks}
                    carousel={extraContent?.carousel}
                />
            </div>
        </>
    );
}

function getPreFilter(attributes) {
    if (typeof window === "undefined") {
        return {};
    }
    const urlParams = new URLSearchParams(location.search);
    const filter = urlParams.get('filter');
    const preFilter = {};

    if (filter) {
        attributes.forEach(attribute => {
            attribute.attribute_options.forEach(option => {
                if (option.label.toLowerCase() === filter.toLowerCase()) preFilter[attribute.attribute_code] = option.value;
            });
        });
    }

    return preFilter;
}
