import { useQuery, useMutation, gql } from "@apollo/client";

export function useRetrieveQuotationSchedule(quotationId, dpCode, paymentMethod, skipFlag=false) {
    console.log('in the thing');
    return useQuery(QUOTATION_SCHEDULE, {
        variables: { quotationId: quotationId, dpCode: dpCode, paymentMethod: paymentMethod},
        fetchPolicy: 'network-only',
        skip: skipFlag,
    });
}

const QUOTATION_SCHEDULE = gql`
    query($quotationId: String!, $dpCode: String!, $paymentMethod: String) {
        retrieveQuotationSchedule(quotationId: $quotationId, dpCode: $dpCode, paymentMethod: $paymentMethod) {
            shipping_schedule {
                shipments {
                    date
                    shipment_items {
                        date
                        item_id
                        sku
                        name
                        qty
                        pack_size
                    }
                }
                cost_per_shipment
            }
        }
    }
`;
