import React, {useState, useEffect} from "react";
import ReactModal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const Modal = (props) => {
    // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
    // ReactModal.setAppElement('#root');

    function closeModal() {
        props.setter(false);
    }

    const modalStyles = {
        content: {
            top         : '25%',
            left        : '50%',
            right       : 'auto',
            bottom      : 'auto',
            marginRight : '-50%',
            transform   : 'translate(-50%, -50%)',
            padding     : '1.5rem 2rem 0',
            width       : '85%',
            maxWidth    : '1440px',
            borderTop   : '6px solid #0084a9',
            borderRight : '0px',
            borderBottom: '0px',
            borderLeft  : '0px',
            borderRadius: '2px',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            boxShadow: '0 2px 2px rgb(74 74 74 / 30%)',
            zIndex: 2
        }
    };

    var closeButtonStyles = {
        color: "#B5AEA7",
        display: "block",
        height: "100%",
        width: "100%",
    };

    return (
        <ReactModal
            isOpen={props.open}
            onRequestClose={closeModal}
            style={modalStyles}
            ariaHideApp={false}
        >
            {!props.hideClosers && <div className="close-container">
                <FontAwesomeIcon
                    className="close-btn"
                    icon={faTimes}
                    onClick={closeModal}
                    style={closeButtonStyles}
                />
            </div>}
            {props.title && <h2 className="title">{props.title}</h2>}
            <div className="message">{props.message}</div>
            {!props.hideClosers && <div className="modal-container__toolbar-wrap">
                <div className="modal-container__toolbar">
                    <div className="modal-container__toolbar-inner">
                        <button
                            type="button"
                            className="modal-container__close-btn modal-container__close-btn--toolbar"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>}
            <style jsx>{`
            .close-container {
                text-indent: 200%;
                white-space: nowrap;
                overflow: hidden;
                display: inline-block;
                width: 25px;
                height: 25px;
                position: absolute;
                padding: 1.5rem;
                right: 0;
                top: 0;
                box-sizing: content-box;
                z-index: 1;
            }
            `}</style>
        </ReactModal>
    );
}

export default Modal;
