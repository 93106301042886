import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import UiButton from '../../../Ui/UiButton';
import * as styles from './style.module.css';
import { NanoGrid } from '@nanoporetech-digital/components-react';

export function DynamicStatus({tallyStats, noun, goNextStep}) {
    const {maxPossible, tallyTotal, maxAvailable} = tallyStats;
    const label = maxPossible > 1 ? `${noun}s` : noun;
    const Icon = () => {
        return (maxAvailable > 0)
            ? <FontAwesomeIcon icon={faInfoCircle} color="#007ca7" />
            : <FontAwesomeIcon icon={faCheck} color="#17bb75" />;
    };
    return (
        <>
            <div grid-states="xl-col-span-6 l-col-span-6 m-col-span-10 s-col-span-10" className={styles.instructions}>

                <h2>Select <span>{maxPossible}</span> {label}</h2>
            </div>
            <div grid-states="xl-col-span-4 l-col-span-4 m-col-span-10 s-col-span-10" className={styles.status}>
                <div><Icon /></div>
                <div>{tallyTotal} of {maxPossible} {label} added</div>
                    <UiButton className="button button--secondary" disabled={maxAvailable>0} onClick={goNextStep}>
                            Continue <FontAwesomeIcon icon={faChevronRight} size="sm" />
                    </UiButton>
            </div>
            </>
    );
}

export function MandatoryStatus({noun, allowContinue, goNextStep}) {
    return (
        <NanoGrid l-cols="10"
        m-cols="10"
        s-cols="1"
        xl-cols="10"
        xxl-cols="10" className={styles.sectionStatus}>
            <div grid-states="xl-col-span-8 l-col-span-8 m-col-span-10" className={styles.instructions}>
                <h2>Select {noun}</h2>
            </div>
            <div grid-states="xl-col-span-2 l-col-span-2 m-col-span-10" className={styles.status}>
                <div>
                    <UiButton className="button button--secondary" disabled={!allowContinue} onClick={goNextStep}>
                            Continue <FontAwesomeIcon icon={faChevronRight} size="sm" />
                    </UiButton>
                </div>
            </div>
        </NanoGrid>
    );
}

export function OptionalStatus({noun, goNextStep}) {
    return (
        <NanoGrid l-cols="10"
        m-cols="10"
        s-cols="1"
        xl-cols="10"
        xxl-cols="10">
            <div grid-states="xl-col-span-8 l-col-span-8 m-col-span-10" className={styles.instructions}>
                <h2>{noun}</h2>
            </div>
            <div grid-states="xl-col-span-2 l-col-span-2 m-col-span-10" className={styles.status}>
                    <UiButton className="button button--secondary" onClick={goNextStep}>
                            Continue <FontAwesomeIcon icon={faChevronRight} size="sm" />
                    </UiButton>
            </div>
        </NanoGrid>
    );
}
