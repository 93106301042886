import React from 'react';
import EditButton from './editButton';
import ContinueButton from './continueButton';
import {buttons} from './style.module.css';

export default function Actions({sectionCache, submitter}) {
    return (
        <div>
            <div className={buttons}>
                <div><EditButton sectionCache={sectionCache} /></div>
                <div><ContinueButton submitter={submitter} /></div>
            </div>
        </div>
    );
}
