import { getBundleDefaultOptions } from "../../../../hooks/useCart";
import { getSelectedOptionMatchingSku, getDearestBundle, getCheapestBundle } from '../bundleHelpers';

export function getBundleOptions(bundleProduct, minionConfig) {
    const bundleOptions = getBundleDefaultOptions(bundleProduct);

    bundleProduct.items.forEach(option => {
        const title = option.title.toLowerCase();
        let selectedOption = null;

        if (title.startsWith('flow cell')) {
            selectedOption = getSelectedOptionMatchingSku(option, minionConfig.flowcell);
        }

        if (title.startsWith('kit')) {
            selectedOption = getSelectedOptionMatchingSku(option, minionConfig.kit);
        }

        if (selectedOption) {
            bundleOptions.push({
                id: option.option_id,
                quantity: selectedOption.qty,
                value: [selectedOption.id]
            });
        }
    });

    return bundleOptions;
}

export function getSelectedBundle(bundleProducts, minionConfig) {
    return (minionConfig.enhancedPack) ? getDearestBundle(bundleProducts) : getCheapestBundle(bundleProducts);
}

export function getAdditionalProducts(bundleProducts, minionConfig) {
    if (minionConfig.rapidStart) {
        return [{product: bundleProducts[0].related_products[0], qty: 1}];
    }
    return [];
}

export function getIsButtonDisabled(minionConfig) {
    return !(minionConfig.flowcell && minionConfig.kit)
}

