import { useCartContext } from "../../../../../contexts/CartContext";
import { useCheckoutContext } from "../../../../../contexts/CheckoutContext";
import { updateContextCart } from "../../../../../hooks/useCart";
import { getFilePayload } from "../../helpers";
import useSetTaxInfo from "./useSetTaxInfo";

export default function useSubmitTaxInfo(setIsSubmitting, cartId, sectionCache, taxSystem) {
    const {cart, setCart} = useCartContext();
    const [checkout, setCheckout] = useCheckoutContext();
    const [mutation] = useSetTaxInfo();
    const taxInfo = sectionCache.data.tax_info

    const afterSubmit = data => {
        setIsSubmitting(false);
        updateContextCart(cart, setCart, data);
        sectionCache.submit();
    }

    const handleError = error => {
        setIsSubmitting(false);
        setCheckout({
            ...checkout,
            submitStatus: error.message
        });
    }

    return async () => {
        setIsSubmitting(true);
        switch (taxSystem.tax_system_code) {
            case 'vat':
                mutation({
                    variables: {
                        cartId: cartId, 
                        taxStatus: taxInfo.tax_status,
                        vatNumber: taxInfo.vat_number,
                        vatExempt: Boolean(taxInfo.vat_exempt)
                    }
                })
                .then(data => afterSubmit(data))
                .catch(error => handleError(error));
                break;
            case 'salestax':
                let variables = {
                    cartId: cartId, 
                    taxStatus: taxInfo.tax_status,
                    paymentMethod: checkout.payment_method?.selected_payment_method
                };
                if (taxInfo.tax_status == 'tax_exempt' && taxInfo.certificate) {
                    const certificatePayload = await getFilePayload(taxInfo.certificate);
                    variables = {...variables, certificate: certificatePayload };
                }
                mutation({variables: variables})
                .then(data => afterSubmit(data))
                .catch(error => handleError(error));
                break;
            case 'none':
                mutation({
                    variables: {
                        cartId: cartId
                    }
                })
                .then(data => afterSubmit(data))
                .catch(error => handleError(error));
                break;
        }
    };
}
