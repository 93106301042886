import React from 'react';
import LabCert from './labCert';
import DeviceCert from './deviceCert';

export default function Configurator(props) {
    return (
        <>
            <LabCert {...props} />
            <DeviceCert {...props} />
        </>
    );
}
