import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Welcome from './welcome';
import Guest from './guest';
import Customer from './customer';
import * as styles from '../style.module.css';

export default function AddressSection({step, activeSection, setActiveSection, initialMode}) {
    const [mode, setMode] = useState(initialMode);
    const [status, setStatus] = useState('');

    function SectionContent() {
        if (step!==activeSection) return null;

        return (
            <>
                {!mode && <Welcome setMode={setMode} />}
                {mode==='guest' && <Guest setMode={setMode} setActiveSection={setActiveSection} status={status} setStatus={setStatus} />}
                {mode==='customer' && <Customer setMode={setMode} setActiveSection={setActiveSection} status={status} setStatus={setStatus} />}
            </>
        );
    }

    function ChangeLink() {
        if (activeSection===2) {
            return (
                <div className={styles.changeLink}>
                    <FontAwesomeIcon icon={faCheck} color="#16bb75" />
                    &nbsp;&nbsp;
                    <a onClick={() => setActiveSection(1)}>Change</a>
                </div>
            );
        }

        return null;
    }

    return (
        <div className={styles.section}>
            <h2>
                <div>
                    <span className={styles.stepNumber}>{step}</span>
                    <span>Billing & delivery address</span>
                </div>
                <ChangeLink />
            </h2>
            <SectionContent />
        </div>
    );
}
