import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import UiButton from "../../../../Ui/UiButton";
import * as styles from './style.module.css';

export default function Actions() {
    return (
        <div className={styles.actions}>
            <UiButton type="submit" className="button button--secondary">
                <span>Continue &nbsp;<FontAwesomeIcon icon={faChevronRight} size="sm" /></span>
            </UiButton>
        </div>
    );
}
