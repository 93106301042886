import React from 'react';
import { Helmet } from "react-helmet";
import Header from '../../Header';
import Configurator from './Configurator';
import LicenseWarranty from './LicenseWarranty';
import RapidStartInfo from './RapidStartInfo';
import { contentWrapper } from './style.module.css'

export default function Minion({category}) {
    return (
        <>
            <Helmet>
                <title>Nanopore store: {category.name}</title>
            </Helmet>
            <div className={contentWrapper}>
                <Header category={category} title={category.name} />
                <Configurator category={category} />
                <LicenseWarranty />
                <RapidStartInfo />
            </div>
        </>
    );
}
