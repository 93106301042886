import React from 'react';
import Dropdown from '../Dropdown';
import { topLink } from './style.module.css';

export default function Drawer({menuData, config}) {
    return (
        <>
            <span className={topLink}>{menuData.title}</span>
            <Dropdown menuData={menuData} config={config} />
        </>
    );
}
