import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { isTransactional, shouldShowPrice } from "../../hooks/siteData";
import { useMutation, gql } from "@apollo/client";
import { useCartContext } from '../../contexts/CartContext';
import { useAddItem, getUniversalCartItem, getBundleCartItem } from '../../hooks/useCart';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faShoppingCart } from '@fortawesome/pro-light-svg-icons';
import RegisterButton from './registerButton';
import EnquireButton from './enquireButton';
import { toast } from 'react-toastify';
import MiniCart from './miniCart';
import ProductPhase from './ProductPhase';
import * as styles from './style.module.css';

export default function AtcButton({ mobile='', product, qty, isDisabled, options=[], additionalProducts=[], goToCart=false, goToQuote=false, showPhase=true, children }) {
    const {cart, setCart, resetCart} = useCartContext();
    const [newCartMutation] = useMutation(gql`mutation { createEmptyCart }`);
    const [addUniversalMutation] = useAddItem();
    const [addBundleMutation] = useAddItem(true);
    const [isWorking, setIsWorking] = useState(false);
    const isPrimaryBundle = product.__typename.includes('BundleProduct');

    const buttonClick = () => {
        setIsWorking(true);
        if (cart.id) {
            addToCart(cart);
        } else {
            newCartMutation()
                .then(data => addToCart({...cart, id: data.data.createEmptyCart}))
                .catch(error => console.log('Unable to create a new cart', error));
        }
    };

    const addToCart = useCart => {
        const updateCartItems = atcResult => {      // e.g. atcResult.data.addSimpleProductsToCart.cart.items
            setIsWorking(false);
            if (atcResult && atcResult.data) {
                const keys = Object.keys(atcResult.data);
                const firstKey = keys.length ? keys[0] : null;
                const cartResponse = firstKey ? atcResult.data[firstKey].cart : null;
                const newCart = {...useCart, total_quantity: cartResponse.total_quantity, items: cartResponse.items, subtotal: cartResponse.prices.subtotal_excluding_tax, is_virtual: cartResponse.is_virtual };
                if (cartResponse) setCart(newCart);
                if (goToCart) {
                    navigate('/cart');
                } else if (goToQuote) {
                    navigate('/createquote');
                } else {
                    toast(<MiniCart product={product} qty={qty} cart={newCart}/>, {
                        progressStyle: {background: "#17bb75"},
                    });
                }
            }
        }

        const {universals, bundles} = getCartItems(product, qty, options, additionalProducts);
        const cartId = useCart.id;

        if (universals.length && bundles.length) {
            if (isPrimaryBundle) {
                addBundleMutation({variables: {cartId: cartId, items: bundles}})
                    .then(() => {
                        addUniversalMutation({variables: {cartId: cartId, items: universals}})
                            .then(data => updateCartItems(data))
                            .catch(error => handleCartError(error));
                    })
                    .catch(error => handleCartError(error));
            } else {
                addUniversalMutation({variables: {cartId: cartId, items: universals}})
                .then(() => {
                    addBundleMutation({variables: {cartId: cartId, items: bundles}})
                        .then(data => updateCartItems(data))
                        .catch(error => handleCartError(error));
                })
                .catch(error => handleCartError(error));
            }
        }
        else {
            if (isPrimaryBundle) {
                addBundleMutation({variables: {cartId: cartId, items: bundles}})
                    .then(data => updateCartItems(data))
                    .catch(error => handleCartError(error));
            } else {
                addUniversalMutation({variables: {cartId: cartId, items: universals}})
                    .then(data => updateCartItems(data))
                    .catch(error => handleCartError(error));
            }
        }
    }

    function handleCartError(err) {
        const errString = err.toString() ?? '';
        if (
            errString.includes("Wrong store code specified for cart") ||
            errString.includes("The cart isn't active") ||
            errString.includes("assign cart to store in different website")
        ) {
            resetCart();
            newCartMutation()
                .then(data => addToCart({...cart, id: data.data.createEmptyCart}))
                .catch(error => console.log('Unable to add to cart or create a new cart', [errString, error?.toString()]));
            return;
        } else {
            console.log('Nothing added to the cart', errString);
        }
        setIsWorking(false);
    }

    const ButtonSwitcher = () => {
        if (!isTransactional() || !shouldShowPrice(product.sku) || product.ont_cta_link) {
            return <EnquireButton product={product} isDisabled={isDisabled} mobile={mobile} />;
        } else if (product.ont_phase_info.allow_purchase) {
            return (
                <button className={`${mobile} button button--secondary btn-cart`} type="button" onClick={buttonClick} disabled={isDisabled || isWorking}>
                    <SpinnerButton isWorking={isWorking}>{children}</SpinnerButton>
                </button>
            );
        } else {
            return <RegisterButton product={product} isDisabled={isDisabled} mobile={mobile} />;
        }
    }

    return (
        <div className={styles.atcButton}>
            <ButtonSwitcher />
            {showPhase && <ProductPhase product={product}/>}
        </div>
    );
}

function SpinnerButton({isWorking, children}) {
    return (isWorking)
        ? <FontAwesomeIcon icon={faSpinner} spin />
        : (children ?? <span>Add to basket &nbsp;<FontAwesomeIcon icon={faShoppingCart} /></span>);
}

export function getConfigurableOptions(variant) {
    if (variant && variant.attributes?.length) {
        return variant.attributes.map(attr => attr.uid);
    }
    return [];
}

function getCartItems(mainProduct, mainQty, mainOptions, additionalProducts=[]) {
    const universals = [];
    const bundles = [];

    // distinguish between bundles and everything else because they are different mutations
    if (mainProduct.__typename.includes('BundleProduct')) {
        bundles.push(getBundleCartItem(mainProduct, mainQty, mainOptions));
    } else {
        universals.push(getUniversalCartItem(mainProduct, mainQty, mainOptions));
    }

    additionalProducts.forEach(({product, qty, options}) => {
        if (product.__typename.includes('BundleProduct')) {
            bundles.push(getBundleCartItem(product, qty, options));
        } else {
            universals.push(getUniversalCartItem(product, qty, options));
        }
    });

    return {universals, bundles};
}
