const LOQATE_URL = 'https://api.addressy.com/Capture/Interactive';
const API_KEY = 'TM94-FM19-GK93-KA17';

export async function findAddresses(searchString, origin=null) {
    if (!searchString?.length) return [];

    const url = new URL(LOQATE_URL + '/Find/v1.1/json3.ws')
    url.searchParams.set('Key', API_KEY);
    url.searchParams.set('Text', searchString);
    if (origin) url.searchParams.set('Origin', origin);

    return performFetch(url);
}

export async function retrieveAddress(id) {
    if (!id?.length) return [];
    
    const url = new URL(LOQATE_URL + '/Retrieve/v1.20/json3.ws')
    url.searchParams.set('Key', API_KEY);
    url.searchParams.set('Id', id);

    return performFetch(url);
}

export function buildAddressObject(apiData) {
    const address = {
        company: null,
        street: null,
        city: null,
        region: {
            region: null,
            region_code: null,
        },
        postcode: null,
        country_code: null,
    };

    if (apiData.length) {
        const apiAddress = apiData[0];
        address.company = apiAddress.Company;
        address.street = getStreet(apiAddress);
        address.city = apiAddress.City;
        address.region.region = apiAddress.ProvinceName;
        address.region.region_code = apiAddress.ProvinceCode;
        address.postcode = apiAddress.PostalCode;
        address.country_code = apiAddress.CountryIso2;
    }

    return address;
}

export async function buildResults(response) 
{
    const addresses = [];
    const containers = [];
    const items = (response.Items?.length > 0 && !response.Items[0].Error) ? response.Items : [];

    items.forEach(item => {
        switch (item.Type) {
            case 'Address':
                addresses.push(item);
                break;
            case 'Postcode':
                containers.push(item.Id);
                break;
        }
    });

    await Promise.all(containers.map(containerId => {
        return findContainerAddresses(containerId).then(containerResponse => {
            buildResults(containerResponse).then(containerResults => {
                addresses.push(...containerResults);
            });
        });
    }));

    return addresses;
};

function getStreet(apiAddress) {
    let street = '';
    if (apiAddress.Line1.length) street += apiAddress.Line1;
    if (apiAddress.Line2.length) street += ', ' + apiAddress.Line2;
    if (apiAddress.Line3.length) street += ', ' + apiAddress.Line3;
    if (apiAddress.Line4.length) street += ', ' + apiAddress.Line4;
    if (apiAddress.Line5.length) street += ', ' + apiAddress.Line5;
    if (street.length > 80) {
        street = street.substring(0, 80);
    }
    return street;
}

async function performFetch(url) {
    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return await response.json();
    } catch(error) {
        console.log('Fetch error', error);
        return [];
    }
}

async function findContainerAddresses(containerId) {
    if (!containerId?.length) return [];

    const url = new URL(LOQATE_URL + '/Find/v1.1/json3.ws')
    url.searchParams.set('Key', API_KEY);
    url.searchParams.set('Container', containerId);

    return performFetch(url);
}
