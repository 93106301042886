import React from 'react';
import { useTranslation } from 'react-i18next';
import { rapidStartInfo } from './style.module.css';

export default function RapidStartInfo() {
    const { t } = useTranslation();

    return (
        <section className={rapidStartInfo}>
            <h2>{t("Optional available training")}</h2>
            <a target="_blank" href="/minion-rapid-start-training.html">{t("Rapid Start Online Training")}</a>
        </section>
    );
}
