import React from 'react';
import UiButton from '../../../../Ui/UiButton/uiButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { getBillingInfoWithNewValue } from '../helpers';

export default function EditButton({sectionCache}) {
    const cachedBillingInfo = sectionCache.data.billing_info;
    if (['myaccount', 'invacc'].includes(cachedBillingInfo.status)) return null;

    const activateForm = () => {
        const updatedBillingInfo = getBillingInfoWithNewValue(cachedBillingInfo, 'status', 'form');
        sectionCache.set({billing_info: updatedBillingInfo});
    }

    return (
        <UiButton className="button button--primary" onClick={activateForm}>
            <span><FontAwesomeIcon icon={faChevronLeft} size="sm" />Edit billing details</span>
        </UiButton>
    );
}

