import React from 'react';

export default function CartError({errorObject}) {
    return (
        <div style={{margin: '4rem'}}>
            <h2 style={{fontWeight: 'lighter'}}>Sorry, something went wrong...</h2>
            <p>An error occured during login. If you have trouble adding items to your cart, please contact Customer Services with the information below.</p>
            <code>{errorObject?.message}</code><br />
            {errorObject?.graphQLErrors?.length && <code>{errorObject?.graphQLErrors[0].debugMessage}</code>}
        </div>
    );
}
