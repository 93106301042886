import React from 'react';
import SectionLink from './link';
import { NanoNavItem } from "@nanoporetech-digital/components-react";

export default function Mobile({section}) {
    if (!section) return null;
    if (section.component === 'menu-cta') return null;

    return (
        <>
            <h3>{section.title}</h3>
            {section.items?.map((item, i) =>
                <NanoNavItem key={i}>
                    <SectionLink item={item} />
                </NanoNavItem>
            )}
        </>
    );
}
