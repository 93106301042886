import React from 'react';
import Breadcrumbs from '../Breadcrumbs';
import UIBackButton from '../Ui/UiBackButton';
import {details, title as titleClass, sku as skuClass, inner, productHeader, catalogHeader} from './style.module.css';

export default function Header({category, title, sku, categories, isHome, isProductPage = true}) {
   const headerClass = isProductPage ? productHeader : catalogHeader;

   const Info = () => {
      if (isHome) {
         return null;
      } else if (isProductPage) {
         return (
            <div>
               <UIBackButton />
               <div className={details}>
                  <div className={titleClass}>{title}</div>
                  <div className={skuClass}>{sku}</div>
               </div>
            </div>
         );
      } else {
         return (
            <div>
               <div className={details}>
                  <div className={titleClass}>{title}</div>
               </div>
            </div>
         );
      }
   }

   return (
      <div className={headerClass}>
         <div className={inner}>
            <Breadcrumbs category={category} categories={categories} />
            <Info />
         </div>
      </div>
   );
}
