import { useMutation, gql } from '@apollo/client';

export default function useGenerateCustomerTokenFromSsoCode() {
    return useMutation(gql`mutation ($ssoCode: String!, $countryCode: String) {
        generateCustomerTokenFromSsoCode(
            ssoCode: $ssoCode
			countryCode: $countryCode
        ) {
            token
            has_extra_permission
            has_custom_pricing
            intended_store_view
            email
            session_duration
            messages {
                type
                title
                content
            }
        }
    }`);
}
