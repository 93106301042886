import React, { useState } from "react";
import { useWebStoreContext } from "../../../contexts/WebStoreContext";
import { NanoInput } from "@nanoporetech-digital/components-react";
import { findAddresses, retrieveAddress, buildAddressObject, buildResults } from "./helpers";
import Spinner from "./spinner";
import LoqateResults from "./loqateResults";
import * as styles from './style.module.css';

export default function LoquateForm({setSelectedAddress}) {
    const [results, setResults] = useState([]);
    const [active, setActive] = useState(false);
    const { webstore } = useWebStoreContext();

    const performLookup = searchString => {
        setActive(true);
        findAddresses(searchString, webstore.geoIpCountryIso).then(firstResponse => {
            buildResults(firstResponse).then(finalResults => {
                setResults(finalResults);
                setActive(false);
            });
        });
    };

    const performRetrieve = id => {
        setActive(true);
        setResults([]);
        retrieveAddress(id).then(response => {
            const address = buildAddressObject(response.Items ?? []);
            setActive(false);
            setSelectedAddress(address);
        });
    };

    return (
        <div className={styles.loqate}>
            <NanoInput
                name="loqate"
                maxlength="128"
                label="Billing address*"
                validateOn="submitThenDirty"
                showInlineError={true}
                onNanoChange={e => performLookup(e.target.value)}
                type="text"
                inputmode="text"
                debounce="1000"
            />
            <Spinner isActive={active} />
            <LoqateResults results={results} performRetrieve={performRetrieve} />
        </div>
    );
}
