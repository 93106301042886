import React from 'react';
import DataElements from './dataElements';
import OntCaptcha from '../../Form/OntCaptcha/ontCaptcha';
import SubmitButton from '../../Form/SubmitButton/submitButton';
import { getFilePayload } from '../../Checkout/Sections/helpers';
import * as styles from '../style.module.css';

export default function OrderForm({formData, setFormData, submitter}) {

    const setFileValue = e => setFormData({...formData, [e.target.name]: getLastFile(e)});
    const setTextValue = e => setFormData({...formData, [e.target.name]: e.target.value});
    const setCaptcha = val => setFormData({...formData, captcha: val});

    const isSubmitDisabled = !(formData.identifier.length && formData.po_file && formData.order_file && formData.captcha);

    function getLastFile(e) {
        const len = e.target.files?.length;
        return (len > 0) ? e.target.files[len - 1] : null;
    }

    async function submitForm(e) {
        e.preventDefault();

        const payload = {
            ...formData,
            order_file: await getFilePayload(formData.order_file),
            po_file: await getFilePayload(formData.po_file)
        }

        submitter.placeOrder(payload);
    }

    return (
        <form className={styles.orderForm} onSubmit={e => submitForm(e)}>
            <DataElements formData={formData} dataActions={{setFileValue, setTextValue}} />
            <OntCaptcha saveValue={setCaptcha} cssClass={styles.captcha} />
            <div className={styles.buttons}>
                <SubmitButton type="submit" isDisabled={isSubmitDisabled} isSubmitting={submitter.isSubmitting}>
                    <span>Place order</span>
                </SubmitButton>
            </div>
        </form>
    );
}
