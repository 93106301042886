import { useQuery, gql } from "@apollo/client";

export default function useTaxSystem(cartId) {
    return useQuery(TAX_SYSTEM, {variables: { cartId: cartId }});
}

const TAX_SYSTEM = gql`
    query ($cartId: String!) {
        getTaxSystem(cartId: $cartId) {
            country_code
            org_type_code
            info_text
            info_text_po
            dropdown_text
            tax_system_code
            default_status_code
            warning_text
        }
    }
`;
