import React from 'react';
import Totals from '../Totals';
import AtcButton from '../../../AtcButton/atcButton';
import { action } from './style.module.css';
import { getAdditionalProducts, getBundleOptions, getSelectedBundle, getIsButtonDisabled } from './helpers';
import { useMinionContext } from '../Configurator/useMinionContext';

export default function Action() {
    const { minionConfig, bundleProducts } = useMinionContext();

    const selectedBundle = getSelectedBundle(bundleProducts, minionConfig);
    const bundleOptions = getBundleOptions(selectedBundle, minionConfig);
    const additional = getAdditionalProducts(bundleProducts, minionConfig);
    const isDisabled = getIsButtonDisabled(minionConfig);

    return (
        <div className={action}>
            <Totals />
            <AtcButton
                product={selectedBundle}
                options={bundleOptions}
                additionalProducts={additional}
                qty={1}
                showPhase={false}
                goToCart={true}
                isDisabled={isDisabled}
            />
        </div>
    );
}
