import SITE_DATA from "../siteData.json";

export const STATIC_CN = 'static_cn_store_view';
export const STATIC_JP = 'static_jp_store_view';

export default function useSiteData() {

    return combined;
}
const combined = {
    ...SITE_DATA["default"],
    ...SITE_DATA[getSiteKey()],
}

export function getWebsiteCode() {
    const {websiteCode} = useSiteData()

    return websiteCode;
}

export function getLabel(siteKey=null) {
    const {label} = siteKey ? SITE_DATA[siteKey] : useSiteData();
    return label;
}

export function getLanguage() {
    const {language} = useSiteData();

    return language;
}

function shouldShowPrice(sku) {
    const {hidePriceSkus, hideAllPrices} = useSiteData();
    if (hideAllPrices) {
        return false;
    }

    // If sku is not in the hidden list, return true.
    return hidePriceSkus.indexOf(sku) === -1;
}

function shouldShowQrCodes() {
    const {showQrCodes} = useSiteData();

    return showQrCodes;
}

function isTransactional() {
    const {isTransactional} = useSiteData();
    return isTransactional;
}

function isNonTransactional() {
    return !isTransactional();
}

function isLoginRequired() {
    const {isLoginRequired} = useSiteData();
    return isLoginRequired && process.env.GATSBY_ENABLE_MAINTENANCE_MODE !== 'true';
}

export function getSiteKey() {
    let key = 'default'
    if (process.env.GATSBY_STORE_VIEW_CODE !== undefined) {
        key = process.env.GATSBY_STORE_VIEW_CODE
    }
    return key;
}

function getUrlPath(storeViewCode) {
    return SITE_DATA[storeViewCode ?? 'default']['urlPath'];
}

export function getUrlPathForCurrentStore() {
    const storeViewCode = getSiteKey();
    return SITE_DATA[storeViewCode ?? 'default']['urlPath'];
}

export function TogglePrice({sku, children}) {
    return shouldShowPrice(sku) ? children : null;
}

export function TransactionalContent({children}) {
    return isTransactional() ? children : null;
}

export function NonTransactionalContent({children}) {
    return isNonTransactional() ? children : null;
}

export function isChinaStore() {
    const key = getSiteKey();
    return key===STATIC_CN;
}

export function isStaticJapanStore() {
    const key = getSiteKey();
    return key===STATIC_JP;
}

export function isMainSite() {
    const key = getSiteKey();
    return (key.startsWith('main') || key==='default');
}

export function isDistributorStore() {
    const key = getSiteKey();
    return key.startsWith('ds_');
}

export function isDistributorRootStore() {
    const key = getSiteKey();
    return key.startsWith('ds_ds');
}

export {shouldShowPrice, isTransactional, isNonTransactional, isLoginRequired, shouldShowQrCodes, getUrlPath};
