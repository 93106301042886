import React from 'react';
import { NanoSelect, NanoOption } from '@nanoporetech-digital/components-react';

export default function ShippingSelect({addresses, selection, changeSelection}) {
	const usableAddresses = getUsableShippingAddresses(addresses);
    return (
        <NanoSelect
        label="Deliver to a different address"
        placeholder="Select address..."
        required="false"
        show-inline-error="true"
        name="shipping_address_id"
        value={selection}
        onNanoChange={e => changeSelection(e.target.value)}
        >
            {usableAddresses.map(address => <NanoOption key={address.id} value={address.id} label={getAddressString(address)} />)}
            <NanoOption value="_new" label="-- New address --" />
        </NanoSelect>
    );
}

export function getSelectedAddressId(shippingAddresses, selection) {
    switch(selection) {
        case '': return shippingAddresses[0].id;
        case '_new': return null;
    }
    return parseInt(selection);
}

export function getAddressString(address) {
    const parts = [
        address.company,
        address.street[0],
        address.city,
        address.region?.region,
        address.postcode,
        address.country_id,
        address.telephone
    ];

    if (address.firstname && address.lastname) {
        parts.unshift(address.firstname + ' ' + address.lastname);
    }

    return parts.filter(Boolean).join(", ");
}

export function getUsableShippingAddresses(allAddresses) {
	const withoutBilling = allAddresses.filter(address => !address.default_billing);
	return (withoutBilling.length > 0) ? withoutBilling : allAddresses;
}
