import React, { Fragment, useState } from "react";
import Actions from "../Actions";
import useSubmitBillingInfo from "./useSubmitBillingInfo";
import MyAccountNote from "./myAccountNote";
import * as styles from './style.module.css';
import useCountries, { getCountryName } from "../../../../../hooks/useCountries";
import StoreSwitchWarning from "./storeSwitchWarning";
import InvAccNote from "./invAccNote";

export default function Preview({cartId, sectionCache}) {
    const countries = useCountries();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const submitBillingInfo = useSubmitBillingInfo(setIsSubmitting, cartId, sectionCache);
    const billingInfo = sectionCache.data.billing_info;

    const submitter = {
        isSubmitting: isSubmitting,
        setIsSubmitting: setIsSubmitting,
        submit: submitBillingInfo
    };

    return (
        <div className={styles.preview}>
            <Content billingInfo={billingInfo} countries={countries} />
            <StoreSwitchWarning billingInfo={billingInfo} />
            <Actions sectionCache={sectionCache} submitter={submitter} />
            <MyAccountNote billingInfo={billingInfo} />
        </div>
    );
}

function Content({billingInfo, countries}) {
    if (billingInfo.status == 'invacc') {
        return <InvAccNote billingInfo={billingInfo} />;
    }

    const billing = billingInfo.billing;
    return (
        <div>
            <h3>Please confirm that these billing details are correct:</h3>
            <div className={styles.subsection}>
                <h4>Finance contact name:</h4>
                <p>{billingInfo.finance_firstname} {billingInfo.finance_lastname}</p>
            </div>
            <div className={styles.subsection}>
                <h4>Billing telephone number:</h4>
                <p>{billing.telephone}</p>
            </div>
            <div className={styles.subsection}>
                <h4>Finance contact email:</h4>
                <p>{billingInfo.finance_email}</p>
            </div>
            <div className={styles.subsection}>
                <h4>Billing address:</h4>
                <p>
                    {billing.firstname} {billing.lastname}<br />
                    {billing.company}<br />
                    {billing.street}<br />
                    {billing.city}<br />
                    {billing.region}{billing.region && <br />}
                    {billing.postcode}<br />
                    {getCountryName(countries, billing.country_code)}
                </p>
            </div>
            <div className={styles.subsection}>
                <h4>Invoices should be sent to:</h4>
                <p><InvoiceEmails billingInfo={billingInfo} /></p>
            </div>
        </div>
    );
}

function InvoiceEmails({billingInfo}) {
    const emails = billingInfo.email_invoice_to ?? billingInfo.finance_email;
    const emailArray = emails.split(',');
    return emailArray.map(email => <Fragment key={email.trim()}>{email.trim()}<br/></Fragment>);
}
