import React from 'react';

export default function Banners({messages}) {
    const relevantMessages = messages.filter(message => {
        return (message.type == 'banner');
    });

    if (relevantMessages.length) {
        return (
            <div>
                {relevantMessages.map((message, i) => <Banner key={i} message={message} />)}
            </div>
        );
    }

    return null;
}

function Banner({message}) {
    return (
        <div className="info-banner info-banner--advisory" style={{marginBottom: '0px'}}>
            <div className="info-banner__content">
                <span className="info-banner__label">{message.title}</span>
                <p className="info-banner__text" style={{whiteSpace: 'pre-line'}}>{message.content}</p>
            </div>
        </div>
    );
}
