import React from 'react';
import Sections from '../../../Checkout/Sections';
import CartSummary from '../../../Cart/cartSummary';
import * as styles from '../style.module.css'

export default function Redeem({quotation}) {
    const cartData = mapQuotationToCartData(quotation);

    return(
        <div className={styles.redeemQuote}>
            <Sections cartData={cartData} />
            <CartSummary cartData={cartData} mode="checkout" />
        </div>
    );
}

function mapQuotationToCartData(quotation) {

    const totalDiscount = quotation.order.discount;
    const currency = quotation.order.currency;
    const subtotalAfterDiscount = quotation.order.sub_total - totalDiscount;
    const grandTotalWithoutTax = quotation.order.grand_total - quotation.order.tax;

    return {
        available_payment_methods: getAvailablePaymentMethods(quotation),
        total_quantity: 1,
        items: [],
        prices: {
            subtotal_excluding_tax: {currency: currency, value: quotation.order.sub_total},
            subtotal_with_discount_excluding_tax: {currency: currency, value: subtotalAfterDiscount},
            grand_total: {currency: currency, value: quotation.order.grand_total},
        },
        shipping_addresses: [{
            selected_shipping_method: {
                amount: {currency: currency, value: quotation.order.shipping}
            }
        }],
        shipping_schedule: quotation.shipping_schedule,
        contains_devices: quotation.order.contains_devices,
        contains_flowcells: quotation.order.contains_flowcells
    };
}

function getAvailablePaymentMethods(quotation) {
    const methods = [];

    quotation.available_payment_methods.forEach(method => {
        if (['purchaseorder', 'chcybersource'].includes(method.code)) {
            methods.push(method);
        }
    });

    return methods;
}
