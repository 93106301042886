import React from "react";
import { getMyAccountEnvironment } from '../../../../../hooks/useEnvironment';
import * as styles from './style.module.css';

export default function MyAccountNote({billingInfo}) {
    if (billingInfo.status == 'myaccount') {
        const myAccountEnv = getMyAccountEnvironment();

        return (
            <p className={styles.myAccountNote}>
                Please note: Billing address details may be edited in <a href={`https://${myAccountEnv}.nanoporetech.com/account/billing_and_tax`}>My Account</a>, and will be subject to approval before your order is shipped.
            </p>
        );
    }

    return false;
}
