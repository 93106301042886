import React from 'react';

export default function ProductImage({src}) {
    const sizedImageUrl = `${src}?w=389`;

    return(
        <>
            <div className="image-wrapper">
                <div className="image" />
            </div>
            <style jsx>{`

                .image-wrapper {
                    width: 35%;
                    text-align: left;
                }
                .image {
                    width: 100%;
                    height: 290px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    // background-size: 100% auto;
                    background-image: url(${sizedImageUrl});

                }
                @media only screen and (max-width: 768px), all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait){
                    .image-wrapper{
                        width: 100%;
                        padding-top:3rem;
                    }

                    .image{
                        // background-position: center;
                        height: 200px;
                    }
                }
            `}</style>
        </>
    );
}
