import React, { Fragment } from 'react';
import * as styles from './style.module.css';
import Carousel from "./Carousel";
import {SubCategoryItem} from "./Item/subcategory";
import {ProductItem} from "./Item/product";

export default function CatalogContent({category, filters, textFilter, carousel}) {
    const products = category.products.items.length ? getFilteredProducts(category.products.items, filters, textFilter) : [];
    const categoryChildren = category.children.length ? getFilteredCategories(category.children, textFilter) : [];

    return (
        <div className={styles.content} data-cy="catalogContent">
            <ItemCount products={products} categoryChildren={categoryChildren} filters={filters} textFilter={textFilter} />
            <ChildCategories categoryChildren={categoryChildren} carousel={carousel} />
            <Products products={products} />
        </div>
    );
}

const ItemCount = ({products, categoryChildren, filters, textFilter}) => {
    if (!textFilter && !Object.keys(filters).length) return null;

    const itemCount = products.length + categoryChildren.reduce((acc,curr) => curr.products.items?.length ? acc+1 : acc, 0);

    return (
        <div className={styles.itemCount}>
            {itemCount} {(itemCount === 1) ? 'item' : 'items'}
        </div>
    );
}

const ChildCategories = ({categoryChildren, carousel}) => {
    return categoryChildren.map((child, i) => {
        if (i+1 === carousel.position) {
            return (
                <Fragment key={'c' + i}>
                    <Carousel category={carousel.category} />
                    <SubCategoryItem subCategory={child} />
                </Fragment>
            );
        } else {
            return <SubCategoryItem key={'c' + i} subCategory={child} />;
        }
    });
}

const Products = ({products}) => {
    return products.map(product => <ProductItem key={'p' + product.id} product={product} />);
}

const getFilteredProducts = (products, filters, textFilter) => {
    return getTextFilteredProducts(products, textFilter).filter(product => {
        for (let key in filters) {
            if (parseInt(filters[key]) === 1 || parseInt(filters[key]) === 0) {
                // filter is boolean
                if (!(product[key] === undefined || product[key] === null || product[key] == filters[key])) {
                    // Attribute value is set in magento but does not match
                    return false;
                }
            }
            else {
                // Not a boolean filter
                if (product[key] === undefined || product[key] != filters[key]) {
                    // Attribute not set in magento or does not match
                    return false;
                }
            }
        }
        return true;
    });
}

const getTextFilteredProducts = (products, textFilter) => {
    if (! textFilter) {
        return products;
    }

    return products.filter(product => {
        return doesFieldMatch(textFilter, [
            product.name,
            product.sku,
            product.description.html
        ]);
    })
}

const getFilteredCategories = (categories, textFilter) => {
    const allowedCategories = categories.filter(category => {
        return ['CONFIGURATOR','FLOWCELLS','FAMILY','MINION'].includes(category.ont_category_type);
    });

    if (! textFilter) {
        return allowedCategories;
    }

    return allowedCategories.filter(category => {
        return doesFieldMatch(textFilter, [category.name, category.description]);
    })
}

const doesFieldMatch = (textFilter, fields) => {
    for (const field of fields) {
        if (field.toLowerCase().includes(textFilter.toLowerCase())) {
            return true;
        }
    }
    return false;
}

