import React, { useState } from "react";
import BillingForm from "../BillingForm";
import LoqateForm from "../LoqateForm";
import useCountries, { getRegionFromCountryAndRegionCode } from "../../../hooks/useCountries";
import * as styles from './style.module.css';

export default function NewBillingForm({address, setAddress}) {
    const [manualMode, setManualMode] = useState(address?.street?.length > 0);
    const countries = useCountries();

    const setAddressFromLoqate = loqateAddress => {
        const regionData = getRegionData(countries, loqateAddress);
        setAddress({
            ...loqateAddress,
            firstname: address.firstname,
            lastname: address.lastname,
            telephone: address.telephone,
            ...regionData
        });
        setManualMode(true);
    }

    return (
        <div className={styles.newBillingForm}>
            <p className={styles.manualMode}>Enter your organisation's billing address by searching. You may type the company name or part of the address to find it. If you are unable to find the correct address you may <a onClick={() => setManualMode(!manualMode)}>manually enter the billing address</a>.</p>
            {manualMode ? <BillingForm formData={address} setFormData={setAddress} fullForm={false}/> : <LoqateForm setSelectedAddress={setAddressFromLoqate} />}
        </div>
    );
}

function getRegionData(countries, loqateAddress) {
    const regionObject = loqateAddress.region;
    const countryCode = loqateAddress.country_code;
    let regionId = 0;

    if (regionObject.region_code) {
        const region = getRegionFromCountryAndRegionCode(countries, countryCode, regionObject.region_code);
        regionId = region?.id ?? 0;
    }

    return {
        region_id: regionId,
        region: loqateAddress.region.region
    };
}
