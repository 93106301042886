import React from 'react';
import UiSelect from '../../UiSelect';
import { useChoicesContext } from '../useChoicesContext';
import { getSelectedOption, getUpdatedChoices, getFilteredOptions } from './helpers';

export default function UiLinkedSelect({name, optionFormat=null}) {
    const { choices, setChoices, sources, postHigher } = useChoicesContext();

    const updater = selected => {
        const updatedChoices = getUpdatedChoices(name, selected, choices, sources);
        if (updatedChoices) {
            postHigher(updatedChoices);
            setChoices(updatedChoices)
        }
    }

    const selectedOption = getSelectedOption(name, choices, sources);
    const filteredOptions = getFilteredOptions(name, choices, sources);

    return (
        <UiSelect
            id={name}
            value={selectedOption}
            onChange={selected => updater(selected)}
            isSearchable={false}
            isClearable={false}
            isDisabled={!filteredOptions.length}
            options={filteredOptions}
            formatOptionLabel={optionFormat}
        />
    );
}

