import React, {useEffect, useRef, useState} from 'react';
import UiMarkdown from "../../../Ui/UiMarkdown";
import * as styles from './style.module.css';
import { DynamicStatus } from './sectionStatus';
import { ta } from 'date-fns/locale';
import useQuantity from "../../../ProductQuantity/useQuantity";



export default function InclusiveKits(props) {
    const selectedChemistryType = props.selectedChemistryType;
    const tally = props.section.cache;
    const kitsGroups = getKitsGroups(props.options);
    const tallyStats = getTallyStats(kitsGroups, tally);
    const increments = getKitsPerGroup(kitsGroups);

    const {focusKey, setFocusKey} = props;

    const updateSection = (selectionProduct, qty) => {
        const newTally = buildNewTally(props.section.cache, selectionProduct, qty, getMaxKits(kitsGroups));
        const selections = spreadTallyIntoSelections(newTally, kitsGroups, increments);
        setFocusKey('product-' + selectionProduct.sku);
        props.updateSection({...props.section, selections, items: Object.values(newTally), cache: newTally});
    };

    return (
        <>
        <div className={styles.sectionStatus}>
        <DynamicStatus tallyStats={tallyStats} noun="sequencing kit" goNextStep={props.goNextStep} />
        <div className={styles.sectionStatusContent}>
        <div className={styles.itemStatusContent}>
            {kitsGroups[0].options
                .filter(kit => {
                    if (!kit.product) { // potential permission missing?
                        return false;
                    }
                    if (!selectedChemistryType || !kit.product.ont_chemistry_type) {
                        return true;
                    }
                    return kit.product.ont_chemistry_type === selectedChemistryType;
                })
                .sort((a,b) => {
                    return (a.position < b.position) ? -1 : 1
                })
                .map(kit =>
                    <InclusiveKit
                        focusKey={focusKey}
                        setFocusKey={setFocusKey}
                        key={kit.id}
                        kit={kit}
                        maxQty={tallyStats.maxAvailable}
                        updateSection={updateSection}
                        tally={tally}
                    />
                )
              }
          </div>
        {props.summary}
        </div>
        </div>
        </>
    );
}

function InclusiveKit({focusKey, setFocusKey, kit, maxQty, tally, updateSection}) {
    const product = kit.product;
    if (!product) return null;

    const initialQty = tally[product.sku]?.qty ?? 0;

    const increment = kit.quantity;
    const disableKeyboardQuantityInput = increment > 1;

    const [quantityValue, quantityComponent] = useQuantity(
        initialQty,
        'product-' + product.sku,
        (newQty) => {updateSection(product, newQty)},
        maxQty + initialQty,
        increment,
        focusKey,
        setFocusKey,
        disableKeyboardQuantityInput
    );

    return (
        <div className={styles.item}>
            <h2 className={styles.title}>{product.name} <span className={styles.sku}>{product.sku}</span></h2>
            <div className={styles.details}>
                <div className={styles.image}><img src={product.ont_image_url_1} /></div>
                <div className={styles.description}>
                    <UiMarkdown source={product.description.html} skipHtml />
                    <ul className={styles.attributePoints}>
                        {product.ont_prep_display && <li>Preparation time: {product.ont_prep_display}</li> }
                        {product.ont_throughput && <li>Throughput: {product.ont_throughput}</li> }
                        {product.ont_read_len_display && <li>Read length: {product.ont_read_len_display}</li> }
                        {product.ont_concentration && <li>Input amount: {product.ont_concentration}</li> }
                    </ul>
                </div>
            </div>
            <div className={styles.itemSelector}>
                {quantityComponent}
            </div>
        </div>
    );
}

function getKitsGroups(options) {
    const groups = [];
    options.forEach(option => {
        if (option.title.startsWith('Kit')) groups.push(option);
    });
    return groups;
}

function getTallyStats(groups, tally) {
    const maxPossible = groups.length * getKitsPerGroup(groups);
    const uniqueSkus = Object.keys(tally) ?? [];
    const tallyTotal = uniqueSkus.reduce((acc,sku) => acc + tally[sku].qty, 0);
    const maxAvailable = maxPossible - tallyTotal;
    return {maxPossible, tallyTotal, maxAvailable};
}

function getKitsPerGroup(kitsGroups) {
    return (kitsGroups[0]?.options[0]?.quantity ?? 1);
}

function getMaxKits(kitsGroups) {
    return kitsGroups.length * getKitsPerGroup(kitsGroups);
}

// the tally simply gives us name,qty,surcharge per sku
function buildNewTally(oldTally, changedProduct, changedQty, maxKits) {
    // create a new tally and remove the item that is in play (we'll add it back later if >0)
    const newTally = {...oldTally};
    if (newTally[changedProduct.sku] !== undefined) delete newTally[changedProduct.sku];
    const kitsCount = Object.values(newTally).reduce((total, tallyItem) => total + tallyItem.qty, 0);

    // look at new qty and adjust if it exceeds max
    let newQty = isNaN(parseInt(changedQty)) ? 0 : parseInt(changedQty);
    const excess = kitsCount + newQty - maxKits;
    if (excess > 0) newQty -= excess;

    if (newQty > 0) newTally[changedProduct.sku] = {name: changedProduct.name, qty: newQty, surcharge: 0.00};
    return newTally;
}

function spreadTallyIntoSelections(tally, kitsGroups, increments) {
    const selections = [];

    let groupIndex = 0;
    Object.keys(tally).forEach(sku => {
        let qtyToSpread = tally[sku].qty;
        while (qtyToSpread > 0 && groupIndex < kitsGroups.length) {
            const group = kitsGroups[groupIndex++];
            const selection = group.options.find(selection => selection.product.sku===sku);
            selections.push({
                id: group.option_id,
                quantity: 1,    // useless
                value: [selection.id.toString()]
            });
            qtyToSpread -= increments;
        }
    });

    return selections;
}
