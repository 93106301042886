import React from 'react';
import { NanoSelect, NanoOption } from '@nanoporetech-digital/components-react';

export default function MagentoRegions({ regions, formData, updateFormData, prefix }) {
    const placeholder = "Please select a state or province...";

    return (
        <NanoSelect
            key={'magento_regions_' + formData.country_code}
            label="State/Province*"
            placeholder={placeholder}
            required={true}
            validateOn="submitThenDirty"
            showInlineError={true}
            name={`${prefix}region_id`}
            value={formData.region_id ?? 0}
            onNanoChange={e => updateFormData(e)}
        >
            <NanoOption key={'placeholder'} value={0} label={placeholder} />
            {regions.map(region => <NanoOption key={region.id} value={region.id} label={region.name} />)}
        </NanoSelect>
    );
}
