export function getUniqueFlowcellChoices(bundleProducts) {
    const choices = {};
    bundleProducts.forEach(bundleProduct => {
        const option = getFlowcellOptionFromBundleProduct(bundleProduct);
        addOptionOptionsToChoices(option, choices);
    });
    return Object.values(choices);
}

function getFlowcellOptionFromBundleProduct(product) {
    const options = product.items ?? [];
    return options.find(option => option.title.toLowerCase() === 'flow cells'); // may return undefined
}

function addOptionOptionsToChoices(option, choices) {
    option?.options?.forEach(flowcell => {
        choices[flowcell.product.sku] = {
            label: getCustomLabel(flowcell.label),
            value: flowcell.product.sku,
            filter: flowcell.product.ont_chemistry_type,
            sku: flowcell.product.ont_mask_sku ?? flowcell.product.sku
        };
    });
}

function getCustomLabel(label) {
    if (label.toLowerCase().includes('rna')) return 'RNA';
    return label.replace(/Flow Cell/i, 'DNA');
}

