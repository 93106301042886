import { useMutation, gql } from "@apollo/client";

export default function useGenerateCustomerTokenFromRemoteToken(code) {
    return useMutation(gql`mutation {
        generateCustomerTokenFromRemoteToken(
            remoteToken: "${code}"
        ) {
            token
            has_extra_permission
	    has_custom_pricing
            intended_store_view
            email
            session_duration
        }
    }`);
}
