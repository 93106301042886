import React from 'react';
import UiButton from '../../../../Ui/UiButton';
import useLoginLink from '../../../../../hooks/useLoginLink';
import * as styles from '../style.module.css';

export default function Welcome({setMode}) {
    const loginLink = useLoginLink();

    return (
        <div className={styles.sectionInner}>
            <div className={styles.welcomeContent}>
                <p>Logging in will auto-fill your address and contact information, and help us prepare your quote quickly.</p>
                <div>
                    <UiButton
                    className="button button--secondary"
                    onClick={() => location.href=loginLink}>
                        Log in
                    </UiButton>
                </div>
            </div>
            <div className={styles.or}><span>or</span></div>
            <div className={styles.welcomeContent}>
                <div>
                    <UiButton
                    className="button button--secondary button--keyline"
                    onClick={() => setMode('guest')}>
                        Continue as guest
                    </UiButton>
                </div>
                <p>You will need to specifiy your billing and delivery addresses.</p>
            </div>
        </div>
    );
}
