import React from 'react';
import { Link } from 'gatsby';
import cx from "classnames";
import * as styles from "../style.module.css";
import {Thumbnail} from "./thumbnail";
import UiMarkdown from "../../../Ui/UiMarkdown";
import {ProductPrice} from "./productPrice";
import {CtaButton} from "./ctaButton";
import ProductPhase from "../../../AtcButton/ProductPhase";
import {getProductUrlPath} from "../helpers";

export const ProductItem = ({product}) => {
    return (
        <div className={cx(styles.standard, styles.item)}>
            <h2 className={styles.title}>{product.name} <span className={styles.sku}>{product.ont_mask_sku ?? product.sku}</span></h2>
            <div className={styles.details}>
                <Link to={getProductUrlPath(product)} className={styles.image}><Thumbnail src={product.ont_image_url_1} /></Link>
                <div className={styles.description}>
                    <UiMarkdown source={product.description.html} skipHtml />
                    <ul className={styles.attributePoints}>
                        {product.ont_prep_display && <li>Preparation time: {product.ont_prep_display}</li> }
                        {product.ont_throughput && <li>Throughput: {product.ont_throughput}</li> }
                        {product.ont_read_len_display && <li>Read length: {product.ont_read_len_display}</li> }
                        {product.ont_concentration && <li>Input amount: {product.ont_concentration}</li> }
                    </ul>
                    <div className={styles.priceContainer}>
                    <ProductPrice product={product} />
                    <CtaButton
                        productOrCategory={product}
                        target={getProductUrlPath(product)}
                    />
                    <ProductPhase product={product} />
                    </div>
                </div>

            </div>
        </div>
    );
}
