export default function useEnvironment() {
    if (typeof window === 'undefined') {
        // Not a browser
        return null;
    }

    const url = new URL(window.location.href);

    if (
        url.hostname.startsWith('staging')
    ) return 'staging';

    if (
        url.hostname.startsWith('test')
    ) return 'test';

    if (
        url.hostname.startsWith('dev') ||
        url.hostname.startsWith('localhost')
    ) return 'dev';

    if (
        url.hostname.startsWith('snd')
    ) return 'snd';

    return 'prod';
}

export function isProduction() {
    const env = useEnvironment();
    return ['prod','staging'].includes(env);
}

export function isDevelopmentServer() {
    if (typeof window === 'undefined') {
        // Not a browser
        return false;
    }
    const env = useEnvironment();
    const url = new URL(window.location.href);
    return url.hostname.startsWith('dev');
}

export function getMyAccountEnvironment() {
    return isProduction() ? 'myaccount' : 'myaccount-test';
}

export function getRegistrationEnvironment() {
    return isProduction() ? 'registration' : 'registration-test';
}

function getEnvPrefix() {
    const env = useEnvironment();
    return (env && env != 'prod') ? `${env}-` : '';
}

export function getDistributorStoreUrl() {
    const prefix = getEnvPrefix();
    return `//${prefix}distributors.store.nanoporetech.com/`;
}

export function getPartnerStoreUrl() {
    const prefix = getEnvPrefix();
    return `//${prefix}partners.store.nanoporetech.com/`;
}

