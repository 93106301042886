import React, {useState} from 'react';
import InclusiveKits from './inclusiveKits';
import Flowcells from './flowcells';
import AnnualService from './annualService';
import IncludedTraining from './includedTraining';
import Training from './training';

export default function Section(props) {
    // console.log('fancy props', {...props, focusKey: focusKey, setFocusKey: setFocusKey});

    // props.focusKey = focusKey;
    // props.setFocusKey = setFocusKey;

    switch (props.section.label) {
        case 'Sequencing kits':
            return <InclusiveKits {...props} />;
        case 'Flow cells':
            return <Flowcells {...props} />;
        case 'Annual service':
            return <AnnualService {...props} />;
        case 'Included training':
        case 'Included voucher':
            return <IncludedTraining {...props} />;
        case 'Training':
            return <Training {...props} />;
    }

    if (props?.section?.label?.startsWith('Annual service')) {
        return <AnnualService {...props} />;
    }

    return null;
}
