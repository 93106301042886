import { useQuery, useMutation, gql } from "@apollo/client";
import { getStandardResponseGraphQl } from "../../../../hooks/useCart";

export function useAdminCatalog() {
	return useQuery(gql`
query {
  adminCatalog {
    product {
      id
      sku
      name
      __typename
      ont_render_type
      price {
        regularPrice {
          amount {
            currency
            value
          }
        }
      }
      ... on ConfigurableProduct {
        variants {
          attributes {
            uid
          }

          product {
            id
            sku
            name
            price_range {
              minimum_price {
                final_price {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
}
	`);
}

export function useAdminCartQuickAdd() {
	const STANDARD_RESPONSE = getStandardResponseGraphQl();

	return useMutation(gql`
		mutation AdminCartQuickAdd($cartId: String!, $items: [SimpleProductCartItemInput!]!) {
			adminCartQuickAdd(
				input: {
					cart_id: $cartId
					cart_items: $items
				}
			) {
				cart {
					${STANDARD_RESPONSE}
				}
			}
		}
	`);
}
