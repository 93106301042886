import { useMutation, gql } from "@apollo/client";
import { getStandardResponseGraphQl } from "../../../../../hooks/useCart";

export default function useSetBillingInfo() {

	const STANDARD_RESPONSE = getStandardResponseGraphQl();

	return useMutation(gql`
		mutation SetBillingInfo($cartId: String!, $emailInvoiceTo: String, $financeEmail: String!, $financeFirstname: String!, $financeLastname: String!, $billingAddress: CustomerAddressInput) {
			setBillingInfo(
				input: {
					cart_id: $cartId
                    email_invoice_to: $emailInvoiceTo
					finance_email: $financeEmail
					finance_firstname: $financeFirstname
					finance_lastname: $financeLastname
                    billing_address: $billingAddress
				}
			) {
				cart {
					${STANDARD_RESPONSE}
				}
			}
		}
	`);
}
