import React from 'react';
import { NanoGrid } from '@nanoporetech-digital/components-react';
import { useMinionContext } from '../Configurator/useMinionContext';
import UiLinkedInputs from '../../../Ui/UiLinkedInputs';
import SelectFlowcells, { getFlowcellOptions } from '../SelectFlowcells';
import SelectKits, { getKitOptions } from '../SelectKits';
import { useTranslation } from 'react-i18next';

export default function Configure() {
    const { minionConfig, setMinionConfig, bundleProducts } = useMinionContext(); // do not use this to render UiLinkedInputs
    const { t } = useTranslation();

    const sources = {
        flowcell: getFlowcellOptions(bundleProducts),
        kit: getKitOptions(bundleProducts),
        sequence: ['flowcell', 'kit']
    }

    const updater = lowerLevelChoices => {
        setMinionConfig({
            ...minionConfig,
            flowcell: lowerLevelChoices.flowcell ?? '',
            kit: lowerLevelChoices.kit ?? '',
        });
    }

    return (
        <div>
            <h3>{t("Configure your Starter Pack")}</h3>
            <NanoGrid sCols={1} sSize={400} mCols={2} lCols={2} xlCols={2} xxlCols={2} style={{position: 'relative', zIndex: 1}}>
                <UiLinkedInputs sources={sources} updater={updater}>
                    <SelectFlowcells name="flowcell" />
                    <SelectKits name="kit" />
                </UiLinkedInputs>
            </NanoGrid>
        </div>
    );
}
