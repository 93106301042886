import { gql } from "@apollo/client";

export const categoryFragments = gql`
    fragment CategoryFields on CategoryInterface {
        name
        url_path
        url_key
        ont_category_type
        ont_image_url_1
        ont_cta_button
        ont_cta_link
        ont_category_phase
        ont_product_text
        ont_product_documentation
        ont_phase_info {
            id
            level
            name
            help
            allow_purchase
        }
        breadcrumbs {
            category_id
            category_level
            category_name
            category_url_key
        }
    }

    fragment ProductFields on ProductInterface {
        __typename
        sku
        name
        description {
            html
        }
        short_description {
            html
        }
        ont_product_overview
        url_key
        ont_image_url_1
        ont_product_phase
        ont_phase_info {
            id
            level
            name
            help
            allow_purchase
        }
        price_range {
            minimum_price {
                final_price {
                    currency
                    value
                }
            }
        }
        ont_custom_price {
            currency
            value
        }
        related_products {
            __typename
            sku
            name
            ont_custom_price {
                currency
                value
            }
            price {
                regularPrice {
                    amount {
                        currency
                        value
                    }
                }
            }
        }
    }

    fragment BundleProductFields on BundleProduct {
        items {
            option_id
            position
            required
            title
            type
            options {
                can_change_quantity
                id
                is_default
                label
                position
                qty
                product {
                    sku
                    short_description {
                        html
                    }
                    ont_mask_sku
                    ont_chemistry_type
                }
            }
        }
    }

    fragment VirtualProductFields on VirtualProduct {
        options {
            title
            ... on CustomizableFieldOption {
                value {
                    uid
                }
            }
            ... on CustomizableDropDownOption {
                dropDownValues: value {
                    title
                    uid
                }
            }
        }
    }
`;
