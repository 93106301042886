import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import * as styles from './style.module.css';

export default function Spinner({isActive}) {
    if (!isActive) return null;

    return (
        <div className={styles.spinner}>
            <FontAwesomeIcon icon={faSpinner} size="sm" spin />
        </div>
    );
}
