import React, { useState } from 'react';
import { Link } from 'gatsby';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCartContext } from '../../../contexts/CartContext';
import { useCart } from '../../../hooks/useCart';
import CartSummary from '../../Cart/cartSummary';
import AddressSection from './Section/Address';
import ShipmentSection from './Section/Shipment';
import { QuotationContextProvider, useQuotationContext } from '../../../contexts/QuotationContext';
import Success from './success';
import {quoteCreate, proceed, content, sections} from './style.module.css';
import { useCustomerContext } from '../../../contexts/CustomerContext';

export default function QuotationCreate() {
    return (
        <QuotationContextProvider>
            <CreateOrSuccess />
        </QuotationContextProvider>
    );
}

function CreateOrSuccess() {
    const [quotation] = useQuotationContext();

    return (quotation.quotation_id && quotation.dp_code)
        ? <Success />
        : <Create />
}

function Create() {
    return (
        <div className={quoteCreate}>
            <div className={proceed}>
                <Link to="/cart">
                    <FontAwesomeIcon icon={faChevronLeft} />&nbsp;&nbsp;Basket
                </Link>
            </div>
            <h1>Create a quote</h1>
            <Quotation />
        </div>
    );
}

function Quotation() {
    const { cart, resetCart } = useCartContext();
    const { customer } = useCustomerContext();
    const { loading, error, data } = useCart(cart.id);
    const [activeSection, setActiveSection] = useState(1);
    const [quotation, setQuotation] = useQuotationContext();

    if (loading) {
        return <p>Loading ...</p>;
    }

    if (error) {
        if (cart.id?.length) resetCart();
        return <p>Your basket is empty.</p>;
    }

    if (!data?.cart?.items?.length) {
        return <p>Your basket is empty.</p>;
    }

    if (data.cart.messages?.length && data.cart.messages[0].message_type === 'error') {
        const message = data.cart.messages[0];

        return (
            <div>
                <p>{message.message_text}</p>
                <p>Please return to your basket and check the contents. If you have any queries please contact customer services.</p>
            </div>
        );
    }

    const initialMode = (customer.token) ? (data.cart.has_tax_billing_info ? 'customer' : 'guest') : '';

    // init
    (function() {
        if (data.cart.email && !quotation.email) {
            setQuotation({...quotation, email: data.cart.email, notifications: [data.cart.email]});
        }
    })();

    return(
        <div className={content}>
            <div className={sections}>
                <AddressSection step={1} activeSection={activeSection} setActiveSection={setActiveSection} initialMode={initialMode} />
                <ShipmentSection step={2} activeSection={activeSection} setActiveSection={setActiveSection} initialMode={initialMode} />
            </div>
            <CartSummary cartData={data.cart} mode="quote" hideTaxes={!quotation.apply_taxes} shippingSchedule={quotation.schedule} />
        </div>
    );
}
