import React, { useRef } from "react";
import { NanoInput } from "@nanoporetech-digital/components-react";
import { isValidPhoneNumber } from 'react-phone-number-input'
import { getBillingInfoWithNewValue, getBillingInfoWithNewAddressValue } from '../helpers';
import { autofill, validate, validateEmailCsvFormField, isMandatoryDataComplete } from "../../../../Form/validate";
import NoRegistrationWarning from "./noRegistrationWarning";
import BillingNotice from "./billingNotice";
import UiTelephone from "../../../../Ui/UiTelephone";
import NewBillingForm from "../../../../Form/NewBillingForm";
import ApprovalWarning from "./approvalWarning";
import Actions from "./actions";
import * as styles from './style.module.css';
import InvoiceEmail from "./invoiceEmail";

export default function BillingInfo({sectionCache}) {
    const billingInfoForm = useRef(null);
    const cachedBillingInfo = sectionCache.data.billing_info;
    const cachedBillingAddress = cachedBillingInfo.billing;

    const setBillingInfo = billingInfo => sectionCache.set({billing_info: billingInfo});

    function updateBillingInfo(e) {
        e.target.showError('');
        const updatedBillingInfo = getBillingInfoWithNewValue(cachedBillingInfo, e.target.name, e.target.value);
        setBillingInfo(updatedBillingInfo);
    }

    function updateAddress(key, value) {
        const updatedBillingInfo = getBillingInfoWithNewAddressValue(cachedBillingInfo, key, value);
        setBillingInfo(updatedBillingInfo);
    }

    function setAddress(address) {
        const updatedBillingInfo = getBillingInfoWithNewValue(cachedBillingInfo, 'billing', address);
        setBillingInfo(updatedBillingInfo);
    }

    function submit(e) {
        e.preventDefault();

        if (autofill(cachedBillingAddress, billingInfoForm.current, 'billing_')) {
            setAddress(cachedBillingAddress);
        }

        // as billing form might be hidden, check mandatory fields manually
        if (!isMandatoryDataComplete(
            cachedBillingAddress,
            ['company', 'street', 'city', 'postcode', 'country_code'],
            billingInfoForm.current['loqate']
        )) return;

        // if we are here then basic form validation has passed - now run custom validations
        if (!validate(billingInfoForm.current, 'finance_', true)) return;
        if (!validate(billingInfoForm.current, 'billing_', true)) return;
        if (!isValidPhoneNumber(cachedBillingAddress.telephone)) return;
        if (!validateEmailCsvFormField(billingInfoForm.current.email_invoice_to)) return;

        const updatedBillingInfo = getBillingInfoWithNewValue(cachedBillingInfo, 'status', 'complete');
        setBillingInfo(updatedBillingInfo);
    }

    return (
        <form ref={billingInfoForm} onSubmit={e => submit(e)}>
            <NoRegistrationWarning billingInfo={cachedBillingInfo} />
            <BillingNotice />
            <div className={styles.name}>
                <NanoInput autocomplete="off" maxlength="25" label="Finance contact first name*" required="true" validateOn="submitThenDirty" showInlineError={true} name="finance_firstname" value={cachedBillingInfo.finance_firstname ?? ''} onNanoChange={e => updateBillingInfo(e)} type="text" inputmode="text" />
                <NanoInput autocomplete="off" maxlength="25" label="Finance contact last name*" required="true" validateOn="submitThenDirty" showInlineError={true} name="finance_lastname" value={cachedBillingInfo.finance_lastname ?? ''} onNanoChange={e => updateBillingInfo(e)}  type="text" inputmode="text" />
            </div>
            <NanoInput autocomplete="off" label="Finance contact email address*" required="true" validateOn="submitThenDirty" showInlineError={true} name="finance_email" value={cachedBillingInfo.finance_email ?? ''} onNanoChange={e => updateBillingInfo(e)} type="email" inputmode="text" />
            <UiTelephone address={cachedBillingAddress} label="Billing telephone number*" updateAddress={updateAddress} />
            <NewBillingForm address={cachedBillingAddress} setAddress={setAddress} />
            <InvoiceEmail cachedBillingInfo={cachedBillingInfo} setBillingInfo={setBillingInfo} updateBillingInfo={updateBillingInfo} />
            <ApprovalWarning />
            <Actions />
        </form>
    );
}
