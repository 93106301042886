import React from 'react';
import { useCheckoutContext } from '../../../contexts/CheckoutContext';
import * as styles from './style.module.css';

export default function SectionHeader({code, step, title}) {
    const [checkout] = useCheckoutContext();
    const section = checkout[code] ?? {};

    return(
        <div>
            <h2>
                <div>
                    <span className={styles.stepNumber}>{step}</span>
                    <span>{title}</span>
                </div>
                <ChangeLink step={step} section={section} />
            </h2>
            <Highlight isActive={checkout.activeStep===step} section={section} />
        </div>
    );
}

function ChangeLink({step, section}) {
    const [checkout, setCheckout] = useCheckoutContext();
    if (step >= checkout.activeStep || !section.canChange) return null;

    return (
        <div className={styles.changeLink}>
            <a onClick={() => setCheckout({...checkout, activeStep: step, submitStatus: ''})}>Change</a>
        </div>
    );
}

function Highlight({isActive, section}) {
    if (section.highlight && !isActive) {
        return <div className={styles.highlights}>{section.highlight}</div>;
    }

    return null;
}
