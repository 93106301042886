import React from 'react';
import { NanoSlides } from '@nanoporetech-digital/components-react';
import Slide from '../Slide';
import mainImage from './minion1b_sp.jpg';
import { slides } from './style.module.css';

export default function ImageBox({category}) {
    const images = getAllImagesFromCategory(category);

    return (
        <div className={slides}>
            <NanoSlides navbtns={true} pager={true}>
                {images.map((url, i) => <Slide url={url} key={i} />)}
            </NanoSlides>
        </div>
    );
}


function getAllImagesFromCategory(category) {
    return [
        mainImage,
        category.ont_image_url_1
    ];
}
