import React, {useEffect} from 'react';
import { CheckoutContextProvider, useCheckoutContext } from '../../../contexts/CheckoutContext';
import { useCartContext } from '../../../contexts/CartContext';
import { useCustomerContext } from '../../../contexts/CustomerContext';
import { useWebStoreContext } from '../../../contexts/WebStoreContext';
import { useRetrieveQuotation } from '../../../hooks/useQuotation';
import { getSiteKey } from '../../../hooks/siteData';
import { getCartUrl } from '../../../hooks/useLoginLink';
import Redeem from './Redeem';
import InvalidStore from './invalidStore';
import * as styles from './style.module.css';
import { navigate } from "gatsby";

export default function Retrieve(props) {
    return (
        <CheckoutContextProvider>
            <RetrieveRouter {...props} />
        </CheckoutContextProvider>
    );
}

function RetrieveRouter({quotationId, dpCode}) {
    const [checkout, setCheckout] = useCheckoutContext();
    const { setCart } = useCartContext();
    const { customer } = useCustomerContext();
    const { webstore } = useWebStoreContext();

    const { loading, error, data } = useRetrieveQuotation(quotationId, dpCode);
    const quotation = data?.retrieveQuotation;
    const isGuestQuote = !!quotation?.new_cart;

    useEffect(() => {
        if (!checkout.redeemQuote) {
            setCheckout({...checkout, redeemQuote: true, quotationId: quotationId, dpCode: dpCode});
        }
    }, []);

    useEffect(() => {
        if (isGuestQuote) {
            setCart({id: quotation.new_cart});
        }
    }, [quotation]);

    useEffect(() => {
        // set up shipping schedule for customer quotes
        if (quotation && !checkout.shipping_schedule) {
            setCheckout({
                ...checkout,
                shipping_schedule: {
                    schedule: quotation.shipping_schedule
                },
                shipping_address: {
                    shippingCountryId: quotation.shipping_country_id
                },
                billing: {
                    billing_info: {
                        billing: {
                            country_code: quotation.billing_country_id,
                        }
                    }
                },
            });
        }
    }, [quotation]);

    if (loading) {
        return(
            <div className={styles.retrieveQuote}>
                <h1>Retrieve a quote</h1>
                <p>Loading ...</p>
            </div>
        );
    }

    const designatedStore = webstore.designatedStore?.length ? webstore.designatedStore : webstore.guessedStore;

    if (isGuestQuote) {
        if (designatedStore !== getSiteKey() || error?.message?.includes('not valid in the current store')) {
            return(
                <div className={styles.retrieveQuote}>
                    <h1>Retrieve a quote</h1>
                    <InvalidStore quotationId={quotationId} designatedStore={designatedStore} />
                </div>
            );
        }

        navigate(getCartUrl());
        return null;
    }

    // all remaining errors
    if (error) {
        return(
            <div className={styles.retrieveQuote}>
                <h1>Retrieve a quote</h1>
                <p>A valid quote was not found.</p>
            </div>
        );
    }

    // customer quotes
    return(
        <div className={styles.retrieveQuote}>
            <h1>Complete your purchase</h1>
            <Redeem quotation={quotation} />
        </div>
    );
}
