import React, { useState } from 'react';
import Product from './Product';
import useLoginLink from '../../../../hooks/useLoginLink';
import DynamicAtcButton from './dynamicAtcButton';
import * as styles from './style.module.css';

export default function LoggedOut({category, products}) {
    const [choices, setChoices] = useState({});

    const Content = () => products.map(product => <Product key={product.sku} product={product} choices={choices} setChoices={setChoices} />);

    return (
        <div>
            <Content />
            <Status choices={choices} products={products} />
            <Buttons choices={choices} products={products} />
        </div>
    );
}

function Status({choices, products}) {
    return null;
}

function Buttons(props) {
    const loginLink = useLoginLink();

    return (
        <div className={styles.buttons}>
            <button className="button button--secondary" onClick={() => location.href=loginLink}>Log in to purchase</button>
            <DynamicAtcButton {...props} />
        </div>
    );
}
