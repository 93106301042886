import React, { useState } from 'react';
import ShipmentItems from '../ShipmentItems';
import cx from 'classnames';
import { getUtcDate, getFirstShipmentDateInBundle, getFirstShipmentItemDate } from '../helpers';
import * as styles from '../style.module.css';

export default function ScheduleRows({schedule, setSchedule}) {
    // pre-validate all rows before rendering
    if (preValidate(schedule)) {
        if (schedule.hasError) setSchedule({...schedule, hasError: false});
    } else {
        if (!schedule.hasError) setSchedule({...schedule, hasError: true});
    }

    return schedule.schedule_rows.map((scheduleRow,i) =>
        <ScheduleRow key={i} scheduleRow={scheduleRow} schedule={schedule} setSchedule={setSchedule} />);
}

function ScheduleRow({scheduleRow, schedule, setSchedule}) {
    function RowName({row, validationMessage}) {
        if (row.contents?.length) {
            const contents = row.contents.join(', ');
            return (
                <td className={styles.rowName}>
                    <span>{row.name} {validationMessage && <em> - {validationMessage}</em>}</span><br/>
                    <small>Includes: {contents}</small>
                </td>
            );
        }

        return <td className={styles.rowName}><span>{row.name} {validationMessage && <em> - {validationMessage}</em>}</span></td>;
    }

    function SubRows() {
        const lastIndex = scheduleRow.sub_rows.length - 1;
        return scheduleRow.sub_rows.map((subRow, i) => {
            return <RowContent key={subRow.item_id} row={subRow} parentRow={scheduleRow} isLastInGroup={i==lastIndex} />
        });
    }

    function RowContent({row, parentRow=null, isLastInGroup=false}) {
        const classes = [];
        classes.push(parentRow ? styles.sub : styles.parent);
        if (isLastInGroup) classes.push(styles.last);
        const [validationPassed, validationMessage] = validate(schedule, row, parentRow);
        if (!validationPassed) classes.push(styles.error);

        return (
            <tr className={cx(classes)}>
                <RowName row={row} validationMessage={validationMessage} />
                {row.shipment_items?.length && <ShipmentItems shipmentItems={row.shipment_items} schedule={schedule} setSchedule={setSchedule} rowId={row.row_id} />}
            </tr>
        );
    }

    const hasSubRows = scheduleRow.sub_rows?.length;

    return(
        <>
            <RowContent row={scheduleRow} isLastInGroup={!hasSubRows} />
            {hasSubRows && <SubRows />}
        </>
    );
}

function preValidate(schedule) {
    for (const row of schedule.schedule_rows) {
        if (!validate(schedule, row)[0]) return false;
        if (row.sub_rows?.length) {
            for (const sub of row.sub_rows) {
                if (!validate(schedule, sub, row)[0]) return false;
            }
        }
    }
    return true;
}

function validate(schedule, row, parentRow=null) {
    if (!row.is_shippable) return [true, ''];

    const fad = getUtcDate(row.fad);
    const lad = getUtcDate(row.lad);
    const shipmentItems = row.shipment_items;
    const rowTotal = shipmentItems.reduce((acc, curr) => acc+curr.qty, 0);

    // enforce row totals
    if (rowTotal < row.qty) return [false, `${row.qty - rowTotal} left to add`];
    if (rowTotal > row.qty) return [false, `${rowTotal - row.qty} to reduce`];

    // enforce ship first
    if (row.is_ship_first) {
        const firstShipmentDate = parentRow ? getFirstShipmentDateInBundle(parentRow) : getUtcDate(schedule.shipments[0]);
        const firstShipmentInRow = getFirstShipmentItemDate(shipmentItems);
        if (firstShipmentDate && firstShipmentInRow && firstShipmentDate.getTime() != firstShipmentInRow.getTime()) {
            return [false, 'Must be in first shipment'];
        }
    }

    // enforce max splits
    const numberOfNonZeroShipmentItems = shipmentItems.reduce((acc, curr) => {
        return acc + (curr.qty > 0 ? 1 : 0);
    }, 0);
    if (row.split_max > 0 && numberOfNonZeroShipmentItems > row.split_max) return [false, `Cannot split beyond ${row.split_max} shipments`];

    // enforce lead times
    for (const shipmentItem of shipmentItems) {
        if (shipmentItem.qty > 0) {
            const siDate = getUtcDate(shipmentItem.date);
            if (siDate.getTime() < fad.getTime()) return [false, `Cannot ship before ${fad}`];
            if (siDate.getTime() > lad.getTime()) return [false, `Cannot ship after ${lad}`];
        }
    }

    return [true, ''];
}
